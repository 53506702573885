import { EnumNames } from '@/models/types'

export enum TagType {
  Personal = 1,
  Payment,
  Geo,
  Discount,
  Equipment,
  Employer,
}

export const tagTypes: EnumNames = {
  [TagType.Personal]: 'Personal',
  [TagType.Payment]: 'Payment',
  [TagType.Geo]: 'Geo',
  [TagType.Discount]: 'Discount',
  [TagType.Equipment]: 'Equipment',
  [TagType.Employer]: ' Employer',
}
