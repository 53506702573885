import API, { MAX_LIMIT, paramsByIds } from '@/api/api'
import { ISubscription } from '@/models/subscription/Subscription'
import { IAdditionalCharge } from '@/models/subscription/AdditionalCharge'
import { IInstallation } from '@/models/subscription/Installation'
import { List } from '@/models/List'
import { ObjectParams } from '@/models/types'
import { IActivation } from '@/models/subscription/Activation'
import { ChargeLoad } from '@/requests/ChargeLoad'
import { ChargeLoadLandlineError, ChargeLoadMobileError } from '@/responses/errors'
import { SepaGenerateInvoicesRequest, SepaGenerateRequest } from '@/requests/SepaRequest'
import { IdentityRequest } from '@/requests/IdentityRequest'
import { ISubscriptionService } from '@/models/subscription/SubscriptionService'
import { ISubscriptionCreate, ISubscriptionUpdate } from '@/requests/subscription'
import { SubscriptionsWithoutServices } from '@/responses/services'

export const URL_CORE_MODULE = '/core'

const URL_SUBSCRIPTION_SERVICES = URL_CORE_MODULE + '/subscription-services'

const URL_SUBSCRIPTIONS = URL_CORE_MODULE + '/subscriptions'
const URL_SUBSCRIPTIONS_VER = URL_SUBSCRIPTIONS + '/versions'
const URL_SUBSCRIPTIONS_ACTIVATION = URL_SUBSCRIPTIONS + '/activations'
const URL_SUBSCRIPTIONS_ACTIVATE_REMESA = URL_SUBSCRIPTIONS + '/activate-remesa'
const URL_SUBSCRIPTIONS_DEACTIVATE_REMESA = URL_SUBSCRIPTIONS + '/deactivate-remesa'
const URL_SUBSCRIPTIONS_DEACTIVATE_PREPAID = URL_SUBSCRIPTIONS + '/deactivate-prepaid'

const URL_ADDITIONAL_CHARGES = URL_CORE_MODULE + '/additional-charges'
const URL_ADDITIONAL_CHARGES_LOAD_MOBILE = URL_ADDITIONAL_CHARGES + '/load/mobile'
const URL_ADDITIONAL_CHARGES_LOAD_LANDLINE = URL_ADDITIONAL_CHARGES + '/load/landline'

const URL_INSTALLATIONS = URL_CORE_MODULE + '/installations'
const URL_INSTALLATIONS_VER = URL_INSTALLATIONS + '/versions'
const URL_INSTALLATIONS_COMPLETE = URL_INSTALLATIONS + '/complete'

const URL_SUBSCRIPTIONS_SEPA = URL_CORE_MODULE + '/sepa/generate'
const URL_SUBSCRIPTIONS_SEPA_RENEW = URL_CORE_MODULE + '/sepa/renew-remesa'
const URL_SUBSCRIPTIONS_SEPA_INVOICES = URL_CORE_MODULE + '/sepa/generate-invoices'

export const coreApi = {
  subscription: {
    getByID: async function (id: number): Promise<ISubscription> {
      const res = await API.get(API.buildURL(URL_SUBSCRIPTIONS + '/' + id))
      return (await res.json()) as Promise<ISubscription>
    },

    getByContractIDs: async function (IDs: number[]): Promise<ISubscription[]> {
      if (!IDs || !IDs.length) return []
      const res = await API.get(
        API.buildURL(URL_SUBSCRIPTIONS, { ...paramsByIds(IDs, 'contractIDs'), limit: MAX_LIMIT })
      )
      return (await ((await res.json()) as Promise<List<ISubscription>>)).items
    },

    create: async function (req: ISubscriptionCreate): Promise<void> {
      await API.post(API.buildURL(URL_SUBSCRIPTIONS), req)
    },

    update: async function (data: ISubscriptionUpdate): Promise<void> {
      await API.put(API.buildURL(URL_SUBSCRIPTIONS), data)
    },

    delete: async function (id: number): Promise<ISubscription> {
      const res = await API.delete(API.buildURL(URL_SUBSCRIPTIONS) + '/' + id)
      return (await res.json()) as Promise<ISubscription>
    },

    cancel: async function (rec: IdentityRequest): Promise<ISubscription> {
      const res = await API.put(API.buildURL(URL_SUBSCRIPTIONS + '/cancel'), rec)
      return (await res.json()) as Promise<ISubscription>
    },

    deleteActive: async function (id: number): Promise<ISubscription> {
      const res = await API.delete(API.buildURL(URL_SUBSCRIPTIONS) + '/active/' + id)
      return (await res.json()) as Promise<ISubscription>
    },

    list: async function (params: ObjectParams): Promise<List<ISubscription>> {
      const res = await API.get(API.buildURL(URL_SUBSCRIPTIONS, { ...params }))
      return (await res.json()) as Promise<List<ISubscription>>
    },

    stories: async function (id: number, params: ObjectParams = {}): Promise<List<ISubscription>> {
      const res = await API.get(API.buildURL(URL_SUBSCRIPTIONS_VER + '/' + id, { ...params }))
      return (await res.json()) as Promise<List<ISubscription>>
    },

    withoutServices: async function (personID: number): Promise<SubscriptionsWithoutServices[]> {
      const res = await API.get(
        API.buildURL(URL_SUBSCRIPTIONS + '/persons/' + personID + '/without-services')
      )
      return (await res.json()) as Promise<SubscriptionsWithoutServices[]>
    },
  },

  sepa: {
    generate: async function (request: SepaGenerateRequest): Promise<Blob> {
      const res = await API.post(API.buildURL(URL_SUBSCRIPTIONS_SEPA), request)
      return res.blob()
    },

    renew: async function (request: SepaGenerateRequest): Promise<Blob> {
      const res = await API.post(API.buildURL(URL_SUBSCRIPTIONS_SEPA_RENEW), request)
      return res.blob()
    },

    invoices: async function (request: SepaGenerateInvoicesRequest): Promise<Blob> {
      const res = await API.post(API.buildURL(URL_SUBSCRIPTIONS_SEPA_INVOICES), request)
      return res.blob()
    },
  },

  activations: {
    list: async function (params: ObjectParams): Promise<List<IActivation>> {
      const res = await API.get(API.buildURL(URL_SUBSCRIPTIONS_ACTIVATION, { ...params }))
      return (await res.json()) as Promise<List<IActivation>>
    },

    activateRemesa: async function (req: IActivation): Promise<ISubscription> {
      const res = await API.put(API.buildURL(URL_SUBSCRIPTIONS_ACTIVATE_REMESA), req)
      return (await res.json()) as Promise<ISubscription>
    },

    deactivateRemesa: async function (req: IActivation): Promise<ISubscription> {
      const res = await API.put(API.buildURL(URL_SUBSCRIPTIONS_DEACTIVATE_REMESA), req)
      return (await res.json()) as Promise<ISubscription>
    },

    deactivatePrepaid: async function (req: IActivation): Promise<ISubscription> {
      const res = await API.put(API.buildURL(URL_SUBSCRIPTIONS_DEACTIVATE_PREPAID), req)
      return (await res.json()) as Promise<ISubscription>
    },
  },

  additionalCharge: {
    getByID: async function (id: number): Promise<IAdditionalCharge> {
      const res = await API.get(API.buildURL(URL_ADDITIONAL_CHARGES + '/' + id))
      return (await res.json()) as Promise<IAdditionalCharge>
    },

    getByIDs: async function (IDs: number[]): Promise<IAdditionalCharge[]> {
      if (!IDs || !IDs.length) return []
      const res = await API.get(
        API.buildURL(URL_ADDITIONAL_CHARGES, { ...paramsByIds(IDs), limit: MAX_LIMIT })
      )
      return (await ((await res.json()) as Promise<List<IAdditionalCharge>>)).items
    },

    add: async function (additionalService: IAdditionalCharge): Promise<IAdditionalCharge> {
      const res = await API.post(API.buildURL(URL_ADDITIONAL_CHARGES), additionalService)
      return (await res.json()) as Promise<IAdditionalCharge>
    },

    update: async function (additionalService: IAdditionalCharge): Promise<IAdditionalCharge> {
      const res = await API.put(API.buildURL(URL_ADDITIONAL_CHARGES), additionalService)
      return (await res.json()) as Promise<IAdditionalCharge>
    },

    list: async function (params: ObjectParams): Promise<List<IAdditionalCharge>> {
      const res = await API.get(API.buildURL(URL_ADDITIONAL_CHARGES, { ...params }))
      return (await res.json()) as Promise<List<IAdditionalCharge>>
    },

    count: async function (params: ObjectParams): Promise<number> {
      const res = await API.get(API.buildURL(URL_ADDITIONAL_CHARGES + '/count', { ...params }))
      return (await res.json()) as Promise<number>
    },

    loadMobile: async function (request: ChargeLoad): Promise<ChargeLoadMobileError[]> {
      const res = await API.post(API.buildURL(URL_ADDITIONAL_CHARGES_LOAD_MOBILE), request)
      const errs = await res.json()
      return errs ? (errs as Promise<ChargeLoadMobileError[]>) : []
    },

    loadLandline: async function (request: ChargeLoad): Promise<ChargeLoadLandlineError[]> {
      const res = await API.post(API.buildURL(URL_ADDITIONAL_CHARGES_LOAD_LANDLINE), request)
      const errs = await res.json()
      return errs ? (errs as Promise<ChargeLoadLandlineError[]>) : []
    },
  },

  installation: {
    getByID: async function (id: number): Promise<IInstallation> {
      const res = await API.get(API.buildURL(URL_INSTALLATIONS + '/' + id))
      return (await res.json()) as Promise<IInstallation>
    },

    getByIDs: async function (IDs: number[]): Promise<IInstallation[]> {
      if (!IDs || !IDs.length) return []
      const res = await API.get(
        API.buildURL(URL_INSTALLATIONS, { ...paramsByIds(IDs), limit: MAX_LIMIT })
      )
      return (await ((await res.json()) as Promise<List<IInstallation>>)).items
    },

    getByAddressIDs: async function (IDs: number[]): Promise<IInstallation[]> {
      if (!IDs || !IDs.length) return []
      const res = await API.get(
        API.buildURL(URL_INSTALLATIONS, { ...paramsByIds(IDs, 'addressIDs'), limit: MAX_LIMIT })
      )
      return (await ((await res.json()) as Promise<List<IInstallation>>)).items
    },

    getByPersonIDs: async function (IDs: number[]): Promise<IInstallation[]> {
      if (!IDs || !IDs.length) return []
      const res = await API.get(
        API.buildURL(URL_INSTALLATIONS, { ...paramsByIds(IDs, 'personIDs'), limit: MAX_LIMIT })
      )
      return (await ((await res.json()) as Promise<List<IInstallation>>)).items
    },

    add: async function (installation: IInstallation): Promise<IInstallation> {
      const res = await API.post(API.buildURL(URL_INSTALLATIONS), installation)
      return (await res.json()) as Promise<IInstallation>
    },

    update: async function (installation: IInstallation): Promise<IInstallation> {
      const res = await API.put(API.buildURL(URL_INSTALLATIONS), installation)
      return (await res.json()) as Promise<IInstallation>
    },

    list: async function (params: ObjectParams): Promise<List<IInstallation>> {
      const res = await API.get(API.buildURL(URL_INSTALLATIONS, { ...params }))
      return (await res.json()) as Promise<List<IInstallation>>
    },

    complete: async function (req: IInstallation): Promise<IInstallation> {
      const res = await API.put(API.buildURL(URL_INSTALLATIONS_COMPLETE), req)
      return (await res.json()) as Promise<IInstallation>
    },

    stories: async function (id: number, params: ObjectParams = {}): Promise<List<IInstallation>> {
      const res = await API.get(API.buildURL(URL_INSTALLATIONS_VER + '/' + id, { ...params }))

      return (await res.json()) as Promise<List<IInstallation>>
    },
  },

  subscriptionService: {
    getByID: async function (id: number): Promise<ISubscriptionService> {
      const res = await API.get(API.buildURL(URL_SUBSCRIPTION_SERVICES + '/' + id))
      return (await res.json()) as Promise<ISubscriptionService>
    },

    getByIDs: async function (IDs: number[]): Promise<ISubscriptionService[]> {
      if (!IDs || !IDs.length) return []
      const res = await API.get(
        API.buildURL(URL_SUBSCRIPTION_SERVICES, { ...paramsByIds(IDs), limit: MAX_LIMIT })
      )
      return (await ((await res.json()) as Promise<List<ISubscriptionService>>)).items
    },

    add: async function (installation: ISubscriptionService): Promise<ISubscriptionService> {
      const res = await API.post(API.buildURL(URL_SUBSCRIPTION_SERVICES), installation)
      return (await res.json()) as Promise<ISubscriptionService>
    },

    update: async function (installation: ISubscriptionService): Promise<ISubscriptionService> {
      const res = await API.put(API.buildURL(URL_SUBSCRIPTION_SERVICES), installation)
      return (await res.json()) as Promise<ISubscriptionService>
    },

    list: async function (params: ObjectParams): Promise<List<ISubscriptionService>> {
      const res = await API.get(API.buildURL(URL_SUBSCRIPTION_SERVICES, { ...params }))
      return (await res.json()) as Promise<List<ISubscriptionService>>
    },
  },
}
