
import { Component, Prop, Vue } from 'vue-property-decorator'
import { NavSection } from './types'

@Component
export default class NavPart extends Vue {
  @Prop({ type: Object, required: true }) section!: NavSection
  @Prop({ type: Boolean }) isOpen!: boolean

  isOpenSection = false

  get openClass(): string {
    return this.isOpenSection ? 'fas fa-minus' : 'fas fa-plus'
  }

  toggleOpenSection(): void {
    this.isOpenSection = !this.isOpenSection
  }
}
