
import { Component, Vue } from 'vue-property-decorator'
import { ROUTE_PERSON_SEARCHER } from '@/router/searcher'
import _debounce from 'lodash/debounce'
import { RawLocation } from 'vue-router/types/router'

@Component
export default class AppSearch extends Vue {
  query = ''

  debouncedOnChange: () => void = _debounce(this.changeRoute, 300)

  changeRoute(): void {
    let loc: RawLocation = {
      name: ROUTE_PERSON_SEARCHER,
      query: {
        q: this.query,
      },
    }

    if (this.$router.currentRoute.name === ROUTE_PERSON_SEARCHER) {
      this.$router.currentRoute.query['q'] != this.query && this.$router.replace(loc)
    } else {
      this.$router.push(loc)
    }
  }
}
