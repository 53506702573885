
import { Component, Prop, Vue } from 'vue-property-decorator'
import { NavItem, NavSection } from '@/components/app/nav/types'
import { MainStore } from '@/store/MainStore'
import NavPart from '@/components/app/nav/NavPart.vue'
import { CAN_ACCESS_STOCK } from '@/permissions/stock'
import {
  ROUTE_EQUIPMENT_ORPHANS,
  ROUTE_STOCK_CONSUMABLES,
  ROUTE_STOCK_EQUIPMENTS,
  ROUTE_STOCK_EQUIPMENTS_MODELS,
  ROUTE_STOCK_HOME,
  ROUTE_STOCK_MANUFACTURERS,
  ROUTE_STOCK_MOVEMENTS,
  ROUTE_STOCK_OUTSTANDING,
  ROUTE_STOCK_PROVIDERS,
  ROUTE_STOCK_TYPES,
  ROUTE_STOCK_WAYBILLS,
  ROUTE_STOCK_WAYBILLS_INCOMING,
  ROUTE_STOCK_WAYBILLS_ISSUED,
} from '@/router/stock'
import { EVENT_INPUT } from '@/utils/event-constants'

@Component({
  components: { NavPart },
})
export default class NavPartStock extends Vue {
  @Prop({ type: Boolean }) isOpen!: boolean

  get isShow(): boolean {
    return MainStore.isUserCan(CAN_ACCESS_STOCK)
  }

  section = new NavSection('Stock', 'fas fa-boxes', ROUTE_STOCK_HOME, [
    new NavItem('Home', ROUTE_STOCK_HOME, 'fas fa-home'),
    new NavItem('Consumables', ROUTE_STOCK_CONSUMABLES, 'fab fa-phabricator'),
    new NavItem('Equipment', ROUTE_STOCK_EQUIPMENTS, 'fas fa-swatchbook'),
    new NavItem('Equipment models', ROUTE_STOCK_EQUIPMENTS_MODELS, 'fas fa-clone'),
    new NavItem('Types', ROUTE_STOCK_TYPES, 'fas fa-clipboard-list'),
    new NavItem('Manufacturers', ROUTE_STOCK_MANUFACTURERS, 'fas fa-industry'),
    new NavItem('Providers', ROUTE_STOCK_PROVIDERS, 'far fa-address-card'),
    new NavItem('Waybills', ROUTE_STOCK_WAYBILLS, 'fas fa-file-invoice'),
    new NavItem('Issued Goods', ROUTE_STOCK_WAYBILLS_ISSUED, 'fas fa-box-tissue'),
    new NavItem('Incoming Goods', ROUTE_STOCK_WAYBILLS_INCOMING, 'fas fa-inbox'),
    new NavItem('Movements', ROUTE_STOCK_MOVEMENTS, 'fas fa-people-carry'),
    new NavItem('Outstanding', ROUTE_STOCK_OUTSTANDING, 'fas fa-outdent'),
    new NavItem('Orphans', ROUTE_EQUIPMENT_ORPHANS, 'fas fa-unlink'),
  ])

  created(): void {
    this.$emit(EVENT_INPUT, this.section.items)
  }
}
