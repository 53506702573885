import {
  downloadHtml,
  internetDataHtml,
  speedLimitsHtml,
  speedLimitStr,
  uploadHtml,
} from '@/utils/html'
import { DATA_FACTOR } from '@/models/internet/constants'
import { formatTime } from '@/utils/Converter'

export interface IInternetData {
  sessionID: string
  nas: string
  nasIP: string
  username: string
  stationID: string
  framedIP: string
  numberIP: number
  inputOctets: number
  inputGigawords: number
  outputOctets: number
  outputGigawords: number
  downloadSpeedLimit: number
  uploadSpeedLimit: number
  sessionTimeout: number
}

export class InternetData implements IInternetData {
  sessionID = ''
  nas = ''
  nasIP = ''
  username = ''
  stationID = ''
  framedIP = ''
  numberIP = 0
  inputOctets = 0
  inputGigawords = 0
  outputOctets = 0
  outputGigawords = 0
  downloadSpeedLimit = 0
  uploadSpeedLimit = 0
  sessionTimeout = 0

  constructor(src: IInternetData | null = null) {
    if (src) {
      this.initParent(src)
    }
  }

  initParent(src: IInternetData) {
    Object.assign(this, src)
  }

  get downloadSpeedLimitStr(): string {
    return speedLimitStr(this.downloadSpeedLimit)
  }

  get uploadSpeedLimitStr(): string {
    return speedLimitStr(this.uploadSpeedLimit)
  }

  get download(): number {
    return this.outputGigawords * DATA_FACTOR + this.outputOctets
  }

  get upload(): number {
    return this.inputGigawords * DATA_FACTOR + this.inputOctets
  }

  get downloadHtml(): string {
    return downloadHtml(this.download)
  }

  get uploadHtml(): string {
    return uploadHtml(this.upload)
  }

  get allDataHtml(): string {
    return internetDataHtml(this.download, this.upload)
  }

  get speedLimitsHtml(): string {
    return speedLimitsHtml(this.downloadSpeedLimit, this.uploadSpeedLimit)
  }

  get sessionTimeoutStr(): string {
    return formatTime(this.sessionTimeout, true)
  }

  get framedIPLink(): string {
    return `<a class="font-weight-bold" href="http://${this.framedIP}" target="_blank">${this.framedIP}</a>`
  }
}
