import { dateOrUndefined, dateString } from '@/utils'
import _omitBy from 'lodash/omitBy'
import _isUndefined from 'lodash/isUndefined'
import { Converter } from '@/utils/Converter'
import _range from 'lodash/range'

export interface IWorkflowSettings {
  icon: string
  style: string
  dates: IDates
}

export class WorkflowSettings implements IWorkflowSettings {
  icon = ''
  style = ''
  dates = new Dates()

  constructor(src: IWorkflowSettings | null = null) {
    if (src) {
      this.init(src)
    }
  }

  private init(src: IWorkflowSettings) {
    Object.assign(this, src)

    this.dates = new Dates(src.dates)
  }

  prepare(): WorkflowSettings {
    this.dates.prepare()

    return this
  }

  toInterface(): IWorkflowSettings {
    return {
      icon: this.icon,
      style: this.style,
      dates: this.dates.toInterface(),
    }
  }
}

export type ordinalWeekdays = { [key: number]: number[] }

export interface IDates {
  start?: Date
  end?: Date
  span?: number
  days?: number[]
  weekdays?: number[]
  ordinalWeekdays?: ordinalWeekdays // map[int]int -> { [-1]: [1,3] } // key: 1 to 6, -1 to -6 / value: 1: Sun to 7: Sat
  weeks?: number[]
  months?: number[]
  years?: number[]
  dailyInterval?: number
  weeklyInterval?: number
  monthlyInterval?: number
  yearlyInterval?: number
}

export class Dates implements IDates {
  start: Date | undefined = undefined
  end: Date | undefined = undefined
  span: number | undefined = undefined
  days: number[] | undefined = undefined
  weekdays: number[] | undefined = undefined
  ordinalWeekdays: ordinalWeekdays | undefined = undefined
  weeks: number[] | undefined = undefined
  months: number[] | undefined = undefined
  years: number[] | undefined = undefined
  dailyInterval: number | undefined = undefined
  weeklyInterval: number | undefined = undefined
  monthlyInterval: number | undefined = undefined
  yearlyInterval: number | undefined = undefined

  daysForm = ''
  weekdaysForm = ''
  ordinalWeekdaysForm = ''
  weeksForm = ''
  monthsForm = ''
  yearsForm = ''

  get isEmpty(): boolean {
    return (
      !this.start &&
      !this.end &&
      !this.days &&
      !this.weekdays &&
      !this.ordinalWeekdays &&
      !this.weeks &&
      !this.months &&
      !this.years &&
      !this.dailyInterval &&
      !this.weeklyInterval &&
      !this.monthlyInterval &&
      !this.yearlyInterval
    )
  }

  get startForm(): string {
    return dateString(this.start)
  }

  set startForm(date: string) {
    let start = dateOrUndefined(date)
    if (start && this.end && start > this.end) {
      start = new Date(this.end)
    }
    this.start = start
  }

  get endForm(): string {
    return dateString(this.end)
  }

  set endForm(date: string) {
    let end = dateOrUndefined(date)
    if (end && this.start && end < this.start) {
      end = new Date(this.start)
    }
    this.end = end
  }

  constructor(src: IDates | null = null) {
    if (src) {
      this.init(src)
    }
  }

  private init(src: IDates) {
    Object.assign(this, src)

    this.start = dateOrUndefined(src.start)
    this.end = dateOrUndefined(src.end)

    this.ordinalWeekdaysForm = ordinalWeekdaysToStr(src.ordinalWeekdays)

    this.daysForm = this.days ? this.days.join(', ') : ''
    this.weekdaysForm = this.weekdays ? this.weekdays.join(', ') : ''
    this.weeksForm = this.weeks ? this.weeks.join(', ') : ''
    this.monthsForm = this.months ? this.months.join(', ') : ''
    this.yearsForm = this.years ? this.years.join(', ') : ''
  }

  setOrdinalWeekdaysForm(): void {
    this.ordinalWeekdays = strToOrdinalWeekdays(this.ordinalWeekdaysForm)
    this.ordinalWeekdaysForm = ordinalWeekdaysToStr(this.ordinalWeekdays)
  }

  setDaysForm(): void {
    this.days = strToNums(this.daysForm, [..._range(-1, -32), ..._range(1, 32)])
    this.daysForm = this.days ? this.days.join(', ') : ''
  }

  setWeekdaysForm(): void {
    this.weekdays = strToNums(this.weekdaysForm, _range(1, 8))
    this.weekdaysForm = this.weekdays ? this.weekdays.join(', ') : ''
  }

  setWeeksForm(): void {
    this.weeks = strToNums(this.weeksForm, [..._range(-1, -7), ..._range(1, 7)])
    this.weeksForm = this.weeks ? this.weeks.join(', ') : ''
  }

  setMonthsForm(): void {
    this.months = strToNums(this.monthsForm, _range(1, 13))
    this.monthsForm = this.months ? this.months.join(', ') : ''
  }

  setYearsForm(): void {
    this.years = strToNums(this.yearsForm)
    this.yearsForm = this.years ? this.years.join(', ') : ''
  }

  prepare(): Dates {
    this.setOrdinalWeekdaysForm()
    this.setDaysForm()
    this.setWeekdaysForm()
    this.setWeeksForm()
    this.setMonthsForm()
    this.setYearsForm()

    return this
  }

  toAttribute(): IDates {
    return _omitBy(this.toInterface(), _isUndefined)
  }

  toInterface(): IDates {
    return {
      start: this.start || undefined,
      end: this.end || undefined,
      span: this.span || undefined,
      days: this.days || undefined,
      weekdays: this.weekdays || undefined,
      ordinalWeekdays: this.ordinalWeekdays || undefined,
      weeks: this.weeks || undefined,
      months: this.months || undefined,
      years: this.years || undefined,
      dailyInterval: this.dailyInterval || undefined,
      weeklyInterval: this.weeklyInterval || undefined,
      monthlyInterval: this.monthlyInterval || undefined,
      yearlyInterval: this.yearlyInterval || undefined,
    }
  }
}

// result 2: 3; -1: 1, 2; -5: 2, 4, 6
export function ordinalWeekdaysToStr(data: ordinalWeekdays | undefined): string {
  if (!data) {
    return ''
  }

  let res = JSON.stringify(data) // {"2":[3],"-1":[1,2],"-5":[2,4,6]}
  res = res
    .replaceAll(/\s/g, '')
    .replaceAll(/,"/g, '; ')
    .replaceAll(/:/g, ': ')
    .replaceAll(/,/g, ', ')
    .replaceAll(/[{}\\[\]"]/g, '')
    .trim()

  return res
}

//  1 : 1 ; 6: -6; -1: 1 , 6 ; -6: 2, 4, 7; sfdff; W:1; 1: W; 0: 1; -7: 1; 1: 0; 1: -1; 1: 8
// {"-1": [1, 6], "-6": [2, 4, 7], "1": [1]}

const ordinalWeekdaysKeyRange = [..._range(-1, -7), ..._range(1, 7)]
const ordinalWeekdaysValueRange = _range(1, 8)

export function strToOrdinalWeekdays(data: string): ordinalWeekdays | undefined {
  const items: string[] = []

  data
    .replaceAll(/\s/g, '')
    .split(';')
    .forEach((item) => {
      const parts = item.split(':')

      if (parts.length == 2) {
        const key = Converter.numberOrNull(parts[0])

        if (key && ordinalWeekdaysKeyRange.includes(key)) {
          const values: number[] = []
          parts[1].split(',').forEach((v) => {
            const value = Converter.numberOrNull(v)

            if (value && ordinalWeekdaysValueRange.includes(value)) {
              values.push(value)
            }
          })

          if (values.length) {
            items.push(`"${key}":[${values.join(',')}]`)
          }
        }
      }
    })

  return items.length ? JSON.parse('{' + items.join(',') + '}') : undefined
}

function strToNums(str: string, filter?: number[]): number[] | undefined {
  const res: number[] = []
  str
    .split(',')
    .map((v) => v.trim())
    .forEach((v) => {
      const num = Converter.numberOrNull(v)
      if (num) {
        if (filter && filter.includes(num)) {
          res.push(num)
        } else {
          res.push(num)
        }
      }
    })

  return res.length ? res : undefined
}
