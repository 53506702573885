export interface ISession {
  sessID: string
  expire: Date
}

export class Session implements ISession {
  sessID = ''
  expire = new Date()

  constructor(src: ISession | null = null) {
    if (src) {
      this.init(src)
    }
  }

  private init(src: ISession) {
    Object.assign(this, src)
    this.expire = new Date(src.expire)
  }
}
