import { CommonModel, IModel } from '@/models/Model'
import { MainStore } from '@/store/MainStore'
import { discountsApi } from '@/api/discounts-api'
import { Price } from '@/models/tariffs/Price'
import { Tag } from '@/models/tags/tag'
import { PersonSimple } from '@/models/persons/PersonSimple'
import { dateString, formatDate } from '@/utils'
import { checkHtml } from '@/utils/html'
import { Money } from '@/models/Money'
import { Product } from '@/models/products/Product'
import { formatContractID } from '@/models/persons/Contract'

export interface IDiscount extends IModel {
  id: number
  amount: number
  isPercentages: boolean
  isActive: boolean
  isActiveWithDates: boolean
  title: string
  startDate: Date | null
  stopDate: Date | null
  personID: number
  tagID: number
  contractID: number
  subscriptionID: number
  priceID: number
  productID: number
  createdBy: number
  createdAt: Date | null
}

export class Discount extends CommonModel implements IDiscount {
  static ProductID = 121

  id = 0
  amount = 0
  isPercentages = false
  isActive = true
  isActiveWithDates = true
  title = ''
  startDate: Date | null = new Date()
  stopDate: Date | null = null
  personID = 0
  tagID = 0
  contractID = 0
  subscriptionID = 0
  priceID = 0
  productID = 0
  createdBy = 0
  createdAt: Date | null = null

  // after load
  person: PersonSimple | null = null
  tag: Tag | null = null
  price: Price | null = null
  product: Product | null = null
  whoCreated: PersonSimple | null = null

  // for Form
  startDateView = dateString(new Date())
  stopDateView = ''
  moneyAmount: Money = new Money()

  get titleHtml(): string {
    const res =
      (this.title ? this.title + ',  ' : '') +
      this.amountHtml +
      (this.price ? `, Price: ${this.priceHtml}` : '') +
      (this.productHtml ? `, Product: ${this.productHtml}` : '')

    return this.isActiveWithDates ? res : `<s>${res}</s>`
  }

  get personFullName(): string {
    return this.person ? this.person.fullName : ''
  }

  get amountHtml(): string {
    return this.isPercentages ? `${this.moneyAmount.value} %` : this.moneyAmount.withVatEuro
  }

  get createdAtHtml(): string {
    return formatDate(this.createdAt)
  }

  get startDateHtml(): string {
    return formatDate(this.startDate)
  }

  get stopDateHtml(): string {
    return formatDate(this.stopDate)
  }

  get priceHtml(): string {
    if (!this.price) return ''
    return `(id:${this.price.id}) ${this.price.name} (${this.price.moneyValue.withVatEuro})`
  }

  get productHtml(): string {
    return this.product ? `(${this.product.id}) ${this.product.name}` : ''
  }

  get tagHtml(): string {
    return this.tag ? this.tag.html : ''
  }

  get isActiveHtml(): string {
    return checkHtml(this.isActiveWithDates)
  }

  get formattedContractID(): string {
    return formatContractID(this.contractID)
  }

  get whoCreatedName(): string {
    return this.whoCreated ? this.whoCreated.fullName : ''
  }

  get personLinkHtml(): string {
    return this.person ? this.person.viewLinkHtml('_self') : ''
  }

  constructor(src: Discount | IDiscount | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: Discount | IDiscount) {
    Object.assign(this, src)

    this.startDate = this.dateOrNull(src.startDate)
    this.stopDate = this.dateOrNull(src.stopDate)
    this.createdAt = this.dateOrNull(src.createdAt)
    this.moneyAmount = src.isPercentages ? Money.withValue(src.amount) : new Money(src.amount)

    if (src instanceof Discount) {
      this.startDateView = src.startDateView || dateString(this.startDate)
      this.stopDateView = src.stopDateView || dateString(this.stopDate)
    } else {
      this.startDateView = dateString(this.startDate)
      this.stopDateView = dateString(this.stopDate)
    }

    this.initModel(src)
  }

  apply = (value: number | string | Money): Money => {
    const res = value instanceof Money ? value.value : Number(value)

    return this.isPercentages
      ? Money.withValue(res - Money.percentageOfValue(res, this.amount))
      : Money.withValue(res - this.moneyAmount.value)
  }

  async save(): Promise<boolean> {
    try {
      return this.id ? await this.update() : await this.add()
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async add(): Promise<boolean> {
    try {
      this.init(await discountsApi.discount.add(this.prepare().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async update(): Promise<boolean> {
    try {
      this.init(await discountsApi.discount.update(this.prepare().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async delete(): Promise<boolean> {
    if (!confirm(`Are you sure you want to delete discount?`)) {
      return false
    }

    try {
      await discountsApi.discount.delete(this.id)
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  prepare(): Discount {
    this.startDate = this.startDateView ? new Date(this.startDateView) : null
    this.stopDate = this.stopDateView ? new Date(this.stopDateView) : null
    this.amount = this.isPercentages ? this.moneyAmount.value : this.moneyAmount.forStorage
    this.productID = this.product ? this.product.id : this.productID

    return this
  }

  toInterface(): IDiscount {
    return {
      id: this.id,
      amount: this.amount,
      isPercentages: this.isPercentages,
      isActive: this.isActive,
      isActiveWithDates: this.isActiveWithDates,
      title: this.title,
      startDate: this.startDate,
      stopDate: this.stopDate,
      personID: this.personID,
      tagID: this.tagID,
      contractID: this.contractID,
      subscriptionID: this.subscriptionID,
      priceID: this.priceID,
      productID: this.productID,
      createdBy: this.createdBy,
      createdAt: this.createdAt,
    }
  }
}
