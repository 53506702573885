import { ObjectParams } from '@/models/types'
import { List } from '@/models/List'
import { Autocomplete } from '@/models/autocomplete/Autocomplete'
import { User } from '@/models/user/user'
import { UserGet } from '@/models/user/UserGet'

export class AutoUser extends Autocomplete<User> {
  constructor(params: ObjectParams = {}) {
    super()
    this.withParams(params)
  }

  getKey(item: User): string | number {
    return item.personID
  }

  getText(item: User): string {
    return item.username
  }

  async list(params: ObjectParams): Promise<List<User>> {
    return await UserGet.list(params)
  }

  requiredParams(searchLine: string): ObjectParams {
    return { username: searchLine }
  }
}
