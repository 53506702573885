
import { Component, Prop, Vue } from 'vue-property-decorator'
import {
  ROUTE_PERSON_WHENCES,
  ROUTE_PERSONS,
  ROUTE_PERSONS_CONTRACTS,
  ROUTE_PERSONS_EDIT,
} from '@/router/persons'
import { NavItem, NavSection } from '@/components/app/nav/types'
import { MainStore } from '@/store/MainStore'
import { CAN_ACCESS_PERSONS } from '@/permissions/persons'
import NavPart from '@/components/app/nav/NavPart.vue'
import { EVENT_INPUT } from '@/utils/event-constants'
@Component({
  components: { NavPart },
})
export default class NavPartClients extends Vue {
  @Prop({ type: Boolean }) isOpen!: boolean

  get isShow(): boolean {
    return MainStore.isUserCan(CAN_ACCESS_PERSONS)
  }

  section = new NavSection('Clients', 'fas fa-address-book', ROUTE_PERSONS, [
    new NavItem('Clients', ROUTE_PERSONS, 'fas fa-users'),
    new NavItem('Acquisition channels', ROUTE_PERSON_WHENCES, 'fab fa-searchengin'),
    new NavItem('Add client', ROUTE_PERSONS_EDIT, 'fas fa-user-plus'),
    new NavItem('Contracts', ROUTE_PERSONS_CONTRACTS, 'fas fa-file-contract'),
  ])

  created(): void {
    this.$emit(EVENT_INPUT, this.section.items)
  }
}
