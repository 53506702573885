import { MainStore } from '@/store/MainStore'
import { personsApi } from '@/api/persons-api'
import { List } from '@/models/List'
import { ObjectParams } from '@/models/types'
import { PersonSimple } from '@/models/persons/PersonSimple'
import { MAX_LIMIT, paramsByIds } from '@/api/api'
import { ContractGet } from '@/models/persons/ContractGet'

export class PersonSimpleGet {
  static async list(params: ObjectParams): Promise<List<PersonSimple>> {
    const list: List<PersonSimple> = { count: 0, items: [] }
    try {
      const l = await personsApi.simple.list({ ...params, isClient: true })
      list.count = l.count
      list.items = l.items.map((v) => new PersonSimple(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return list
  }

  static async listWithNoClient(params: ObjectParams): Promise<List<PersonSimple>> {
    const list: List<PersonSimple> = { count: 0, items: [] }
    try {
      const l = await personsApi.simple.list(params)
      list.count = l.count
      list.items = l.items.map((v) => new PersonSimple(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return list
  }

  static async byID(id: number): Promise<PersonSimple> {
    let item = new PersonSimple()
    try {
      item = new PersonSimple(await personsApi.simple.getByID(id))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return item
  }

  static async byIDs(ids: number[]): Promise<PersonSimple[]> {
    let items: PersonSimple[] = []
    try {
      items = (await personsApi.simple.getByIDs(ids)).map((v) => new PersonSimple(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return items
  }

  static async mapByIDs(ids: number[]): Promise<Map<number, PersonSimple>> {
    const persons = await PersonSimpleGet.byIDs(ids)

    const pMap = new Map<number, PersonSimple>()
    persons.forEach((v) => pMap.set(v.id, new PersonSimple(v)))

    return pMap
  }

  static async mapByContractIDs(ids: number[]): Promise<Map<number, PersonSimple>> {
    const pMap = new Map<number, PersonSimple>()

    if (!ids || !ids.length) {
      return pMap
    }

    const res = await ContractGet.list({ ...paramsByIds(ids), limit: MAX_LIMIT })

    res.items.forEach((v) => {
      if (v.person) {
        pMap.set(v.id, v.person)
      }
    })

    return pMap
  }

  static async mapBySubscriptionIDs(ids: number[]): Promise<Map<number, PersonSimple>> {
    const pMap = new Map<number, PersonSimple>()

    if (!ids || !ids.length) {
      return pMap
    }

    try {
      const items = await personsApi.simple.mapBySubscriptions(ids)
      for (const key in items) {
        pMap.set(Number(key), new PersonSimple(items[key]))
      }
    } catch (e) {
      await MainStore.addError(e as Error)
    }

    return pMap
  }
}
