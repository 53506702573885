
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ItemToSelect } from './item-to-select'
import _truncate from 'lodash/truncate'
import { EVENT_INPUT, EVENT_SELECT } from '@/utils/event-constants'
import { eventTargetValue } from '@/utils/html'
import { IdCreator } from '@/utils/IdCreator'

@Component
export default class SearchableInput extends Vue {
  @Prop({ required: true, type: Array }) items!: ItemToSelect[]
  @Prop({ type: String }) searchLine!: string
  @Prop({ type: String, default: '' }) label!: string
  @Prop({ type: String, default: 'Enter the data to select' }) placeholder!: string
  @Prop({ type: Number, default: 0 }) minLengthToSearch!: number
  @Prop({ type: Boolean }) disabled!: boolean
  @Prop({ type: Boolean }) disableEnter!: boolean
  @Prop({ type: String }) inputClass!: string
  @Prop({ type: Boolean }) cleanAfterSelection!: boolean
  @Prop({ type: Boolean }) isInputInvalid!: boolean
  @Prop({ type: String }) inputInvalidTitle?: string
  @Prop({ type: Boolean }) focusTrigger!: boolean
  @Prop({ type: Boolean }) isSm!: boolean
  @Prop({ type: Boolean }) offInvalid!: boolean

  ID = new IdCreator()
  refItemSlug = 'SearchableInput-item-ref-'
  refInputSlug = 'SearchableInput-input-ref'
  inputLine = ''
  focusCounter = 0
  isFirstFocus = false
  itemToSelect: ItemToSelect[] = []
  isShowDropdown = false

  get classInput(): string {
    let classes = [
      this.inputClass,
      this.isSm ? 'form-control-sm' : '',
      this.isInputInvalid ? 'is-invalid' : '',
    ]

    return classes.join(' ')
  }

  get invalidFeedback(): string {
    return this.inputInvalidTitle || 'This value is not allowed!'
  }

  @Watch('searchLine', { immediate: true })
  onLoadSearchLine(v: string | null | undefined): void {
    this.inputLine = v ?? ''
  }

  @Watch('items')
  onLoadItems(items: ItemToSelect[]): void {
    this.itemToSelect = items.slice()
    this.addEmptyLineIsNeed()
  }

  @Watch('focusTrigger')
  setFocusToInput(): void {
    this.hideDropdown()
    ;(this.$refs[this.refInputSlug] as HTMLElement).focus()
  }

  @Watch('focusCounter')
  onFocusCounter(): void {
    if (this.focusCounter <= 0) {
      this.hideDropdown()
    }
  }

  addEmptyLineIsNeed(): void {
    if (this.itemToSelect.length) {
      this.itemToSelect.unshift({
        key: '',
        text: '',
        value: null,
      })
    }
  }

  setInputLine(event: Event): void {
    this.inputLine = eventTargetValue(event).trim()
    if (this.inputLine.length < this.minLengthToSearch) {
      return
    }
    this.onInput(this.inputLine)
  }

  truncate(v: string): string {
    return _truncate(v, { length: 100 })
  }

  nextItem(index: number): void {
    if (this.isFirstFocus) {
      this.emitInputData()
      return
    }

    if (index <= -1) {
      this.setFocusToInput()
    } else if (typeof this.itemToSelect[index] !== 'undefined') {
      ;(this.$refs[this.refItemSlug + index] as HTMLElement[])[0].focus()
    }
  }

  setInputFocus(): void {
    this.isFirstFocus = true
    this.incrementFocus()
  }

  emitInputData(): void {
    this.isFirstFocus = false
    if (!this.inputLine) {
      this.onInput('')
    }
    this.showDropdown()
  }

  onInput(v: string): void {
    this.$emit(EVENT_INPUT, v)
  }

  incrementFocus(): void {
    this.focusCounter++
  }

  emitBlurAndDecrementFocus(): void {
    this.$emit('blur')
    setTimeout(() => {
      this.focusCounter--
    }, 100)
  }

  decrementFocus(): void {
    setTimeout(() => {
      this.focusCounter--
    }, 100)
  }

  selectFirstElement(): void {
    if (this.disableEnter) {
      return
    }

    if (this.itemToSelect.length > 1) {
      this.selectItem(this.itemToSelect[1])
    }
    this.$emit('onPressEnter')
  }

  selectItem(item: ItemToSelect): void {
    this.inputLine = this.cleanAfterSelection ? '' : (this.inputLine = item.text)
    this.$emit(EVENT_SELECT, item.value)
    this.hideDropdown()
  }

  hideDropdown(): void {
    this.isShowDropdown = false
  }

  showDropdown(): void {
    this.isShowDropdown = true
  }
}
