import { CommonModel, IModel } from '@/models/Model'
import { MainStore } from '@/store/MainStore'
import { geoApi } from '@/api/geo-api'
import { IMunicipality, Municipality } from '@/models/geo/Municipality'
import {
  GeoStatus,
  StreetType,
  streetTypes,
  isValid,
  isVerified,
  statusHtml,
} from '@/models/geo/constants'
import { coloringName } from '@/models/geo/utils'

export interface IStreet extends IModel {
  id: number
  type: StreetType
  name: string
  latitude: number
  longitude: number
  municipalityID: number
  status: GeoStatus
  weight: number
  municipality: IMunicipality | null
}

export class Street extends CommonModel implements IStreet {
  id = 0
  type: StreetType = 0
  name = ''
  latitude = 0
  longitude = 0
  municipalityID = 0
  status: GeoStatus = 0
  weight = 0
  municipality: Municipality | null = null

  // for use in forms
  numByForm = ''

  get isValidForm(): boolean {
    if (this.id) {
      return Boolean(this.numByForm)
    }

    return Boolean(
      this.name && this.type && this.numByForm && this.municipality && this.municipality.isValidForm
    )
  }

  get isSetFormValues(): boolean {
    return Boolean(this.name && this.type && this.numByForm)
  }

  get isValid(): boolean {
    return isValid(this.status)
  }

  get isVerified(): boolean {
    return isVerified(this.status)
  }

  get statusHtml(): string {
    return statusHtml(this.status)
  }

  get typeName(): string {
    return streetTypes[this.type] || ''
  }

  get nameStr(): string {
    return `${this.typeName} ${this.name}`.trim()
  }

  get titleHtml(): string {
    return coloringName(this)
  }

  constructor(src: IStreet | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: IStreet) {
    Object.assign(this, src)
    this.municipality = src.municipality ? new Municipality(src.municipality) : null

    this.initModel(src)
  }

  async save(): Promise<boolean> {
    try {
      return this.id ? await this.update() : await this.add()
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async add(): Promise<boolean> {
    try {
      this.init(await geoApi.street.add(this.prepare().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async update(): Promise<boolean> {
    try {
      this.init(await geoApi.street.update(this.prepare().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async delete(): Promise<boolean> {
    if (!confirm(`Are you sure you want to delete street?`)) {
      return false
    }

    try {
      await geoApi.street.delete(this.id)
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async approve(): Promise<boolean> {
    if (!confirm(`Are you sure you want to confirm the entry?`)) {
      return false
    }

    try {
      this.init(await geoApi.street.approve({ id: this.id }))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async reject(): Promise<boolean> {
    if (!confirm(`Are you sure you want to reject the entry?`)) {
      return false
    }

    try {
      this.init(await geoApi.street.reject({ id: this.id }))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  prepare(): Street {
    this.municipalityID = this.municipality ? this.municipality.id : 0

    return this
  }

  toInterface(): IStreet {
    return {
      id: this.id,
      type: this.type,
      name: this.name,
      latitude: this.latitude,
      longitude: this.longitude,
      municipalityID: this.municipalityID,
      status: this.status,
      weight: this.weight,
      municipality: this.municipality ? this.municipality.toInterface() : null,
    }
  }
}
