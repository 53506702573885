import { RouteConfig } from 'vue-router/types/router'
import { CAN_ACCESS_CDR } from '@/permissions/cdr'
import { ReconciliationType } from '@/requests/ReconciliationFiles'

export const ROUTE_CABLEMOVIL = 'ROUTE_CABLEMOVIL'
export const ROUTE_CABLEMOVIL_FILES = 'ROUTE_CABLEMOVIL_FILES'
export const ROUTE_CABLEMOVIL_ADD_CHARGES = 'ROUTE_CABLEMOVIL_ADD_CHARGES'

export const ROUTE_SEWAN = 'ROUTE_SEWAN'
export const ROUTE_SEWAN_FILES = 'ROUTE_SEWAN_FILES'
export const ROUTE_SEWAN_ADD_CHARGES = 'ROUTE_SEWAN_ADD_CHARGES'

export const ROUTE_RECONCILIATION_SEWAN_FILES = 'ROUTE_RECONCILIATION_SEWAN_FILES'
export const ROUTE_RECONCILIATION_CABLEMOVIL_FILES = 'ROUTE_RECONCILIATION_CABLEMOVIL_FILES'

const cdrRoutes: RouteConfig[] = [
  {
    name: ROUTE_CABLEMOVIL,
    path: '/cablemovil',
    meta: { permission: CAN_ACCESS_CDR },
    component: () => import(/* webpackChunkName: "cdr" */ '@/components/cdr/CablemovilList.vue'),
  },

  {
    name: ROUTE_CABLEMOVIL_FILES,
    path: '/cablemovil/files',
    meta: { permission: CAN_ACCESS_CDR },
    component: () =>
      import(/* webpackChunkName: "cdr" */ '@/components/cdr/CablemovilFilesList.vue'),
  },

  {
    name: ROUTE_CABLEMOVIL_ADD_CHARGES,
    path: '/cablemovil/add-charges',
    meta: { permission: CAN_ACCESS_CDR },
    component: () =>
      import(/* webpackChunkName: "cdr" */ '@/components/cdr/CablemovilAddCharges.vue'),
  },

  {
    name: ROUTE_SEWAN,
    path: '/sewan',
    meta: { permission: CAN_ACCESS_CDR },
    component: () => import(/* webpackChunkName: "cdr" */ '@/components/cdr/SewanList.vue'),
  },

  {
    name: ROUTE_SEWAN_FILES,
    path: '/sewan/files',
    meta: { permission: CAN_ACCESS_CDR },
    component: () => import(/* webpackChunkName: "cdr" */ '@/components/cdr/SewanFilesList.vue'),
  },

  {
    name: ROUTE_SEWAN_ADD_CHARGES,
    path: '/sewan/add-charges',
    meta: { permission: CAN_ACCESS_CDR },
    component: () => import(/* webpackChunkName: "cdr" */ '@/components/cdr/SewanAddCharges.vue'),
  },

  {
    name: ROUTE_RECONCILIATION_SEWAN_FILES,
    path: '/reconciliation/process-sewan-files',
    meta: { permission: CAN_ACCESS_CDR },
    component: () =>
      import(/* webpackChunkName: "cdr" */ '@/components/cdr/ReconciliationProcessFiles.vue'),
    props: () => ({
      reconciliationType: ReconciliationType.Sewan,
    }),
  },
  {
    name: ROUTE_RECONCILIATION_CABLEMOVIL_FILES,
    path: '/reconciliation/process-cablemovil-files',
    meta: { permission: CAN_ACCESS_CDR },
    component: () =>
      import(/* webpackChunkName: "cdr" */ '@/components/cdr/ReconciliationProcessFiles.vue'),
    props: () => ({
      reconciliationType: ReconciliationType.Cablemovil,
    }),
  },
]

export default cdrRoutes
