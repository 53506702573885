import { Installation } from '@/models/subscription/Installation'
import { PersonSimple } from '@/models/persons/PersonSimple'
import { Contract } from '@/models/persons/Contract'

export interface IPersonSearch {
  personID: number
  fullName: string
  username: string
  contracts: string[]
  addresses: string[]
  documents: string[]
  contacts: string[]
  equipments: string[]
}

export class PersonSearch {
  personID = 0
  fullName = ''
  username = ''
  contracts: string[] = []
  addresses: string[] = []
  documents: string[] = []
  contacts: string[] = []
  equipments: string[] = []

  // after load
  installations: Installation[] = []
  person: PersonSimple | undefined = undefined
  contractModels: Contract[] = []

  get contractIDs(): number[] {
    return this.contracts.map((v) => {
      return Number(v.replaceAll(/\D/g, ''))
    })
  }

  get documentsWithoutContracts(): string[] {
    return this.documents.filter((v) => !v.toLowerCase().includes('contract'))
  }

  constructor(src: IPersonSearch | null = null) {
    if (src) {
      this.init(src)
    }
  }

  private init(src: IPersonSearch) {
    Object.assign(this, src)

    this.contracts = src.contracts ? [...src.contracts] : []
    this.addresses = src.addresses ? [...src.addresses] : []
    this.documents = src.documents ? [...src.documents] : []
    this.contacts = src.contacts ? [...src.contacts] : []
    this.equipments = src.equipments ? [...src.equipments] : []
  }

  isContractContainsQuery(query: string): boolean {
    if (this.personID.toString() == query) {
      return true
    }

    for (let i = 0; i < this.contracts.length; i++) {
      if (this.contracts[i].includes(query)) {
        return true
      }
    }

    return false
  }
}
