import { CommonModel, IModel } from '@/models/Model'
import { MainStore } from '@/store/MainStore'
import { personsApi } from '@/api/persons-api'
import { BillingLinkType, billingLinkTypes } from '@/models/persons/constants'

export interface IBillingLink extends IModel {
  id: number
  type: BillingLinkType
  value: string
  personID: number
}

export class BillingLink extends CommonModel implements IBillingLink {
  id = 0
  type: BillingLinkType = 0
  value = ''
  personID = 0

  get typeHtml(): string {
    return billingLinkTypes[this.type]
  }

  get linkHtml(): string {
    return `<a href="${this.value}" target="_blank">${this.value}</a>`
  }

  constructor(src: IBillingLink | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: IBillingLink) {
    Object.assign(this, src)

    this.initModel(src)
  }

  async save(): Promise<boolean> {
    try {
      return this.id ? await this.update() : await this.add()
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async add(): Promise<boolean> {
    try {
      this.init(await personsApi.billingLink.add(this.toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async update(): Promise<boolean> {
    try {
      this.init(await personsApi.billingLink.update(this.toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async delete(): Promise<boolean> {
    if (!confirm(`Are you sure you want to delete BillingLink?`)) {
      return false
    }

    try {
      await personsApi.billingLink.delete(this.id)
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  toInterface(): IBillingLink {
    return {
      id: this.id,
      type: this.type,
      value: this.value,
      personID: this.personID,
    }
  }
}
