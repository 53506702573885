import { CommonModel, IModel } from '@/models/Model'
import { MainStore } from '@/store/MainStore'
import { stockApi } from '@/api/stock-api'
import { IManufacturer, Manufacturer } from '@/models/stock/Manufacturer'
import { IType, Type } from '@/models/stock/Type'
import { Money } from '@/models/Money'
import { Product } from '@/models/products/Product'
import { SettingType, settingTypes, settingTypesWithSessions } from '@/models/service/constants'

export interface IEquipmentModel extends IModel {
  id: number
  name: string
  barcode: string
  specification: string
  descriptionLink: string
  instructionLink: string
  manufacturerID: number
  typeID: number
  productID: number
  internalPrice: number
  externalPrice: number
  minBalance: number
  balanceInStock: number
  settingTypes: SettingType[]
  manufacturer: IManufacturer | null
  type: IType | null
}

export class EquipmentModel extends CommonModel implements IEquipmentModel {
  id = 0
  name = ''
  barcode = ''
  specification = ''
  descriptionLink = ''
  instructionLink = ''
  manufacturerID = 0
  typeID = 0
  productID = 0
  internalPrice = 0
  externalPrice = 0
  minBalance = 0
  balanceInStock = 0
  settingTypes: SettingType[] = []
  manufacturer: Manufacturer | null = null
  type: Type | null = null

  // for view
  moneyInternalPrice = new Money()
  moneyExternalPrice = new Money()

  // after load
  product: Product | null = null

  constructor(src: IEquipmentModel | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: IEquipmentModel) {
    Object.assign(this, src)

    this.manufacturer = src.manufacturer ? new Manufacturer(src.manufacturer) : null
    this.type = src.type ? new Type(src.type) : null
    this.settingTypes = src.settingTypes ? [...this.settingTypes] : []

    this.moneyInternalPrice = new Money(src.internalPrice)
    this.moneyExternalPrice = new Money(src.externalPrice)

    this.initModel(src)
  }

  get html(): string {
    let result = ''
    if (this.type) {
      result += ', <b>Type:</b> ' + this.typeName
    }
    result += ', <b>Model:</b> ' + this.name

    return result.replace(/^, +/, '')
  }

  get nameWithManufacturer(): string {
    return this.manufacturerName ? `(${this.manufacturerName}) ${this.name}` : this.name
  }

  get typeName(): string {
    return this.type ? this.type.name : ''
  }

  get manufacturerName(): string {
    return this.manufacturer ? this.manufacturer.name : ''
  }

  get balanceInStockHtml(): string {
    return this.minBalance <= this.balanceInStock
      ? `<b class="text-success">${this.balanceInStock}</b>`
      : `<b class="text-danger">${this.balanceInStock}</b>`
  }

  get servicesHtml(): string {
    return this.settingTypes.map((v) => settingTypes[v]).join('<br>')
  }

  get canViewSession(): boolean {
    for (const st of this.settingTypes) {
      if (settingTypesWithSessions.includes(st)) {
        return true
      }
    }
    return false
  }

  async save(): Promise<boolean> {
    try {
      return this.id ? await this.update() : await this.add()
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async add(): Promise<boolean> {
    try {
      this.init(await stockApi.model.add(this.prepare().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async update(): Promise<boolean> {
    try {
      this.init(await stockApi.model.update(this.prepare().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async delete(): Promise<boolean> {
    if (!confirm(`Are you sure you want to delete model?`)) {
      return false
    }

    try {
      await stockApi.model.delete(this.id)
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  prepare(): EquipmentModel {
    this.manufacturerID = this.manufacturer ? this.manufacturer.id : this.manufacturerID
    this.typeID = this.type ? this.type.id : this.typeID
    this.productID = this.product ? this.product.id : this.productID
    this.internalPrice = this.moneyInternalPrice.forStorage
    this.externalPrice = this.moneyExternalPrice.forStorage

    return this
  }

  toInterface(): IEquipmentModel {
    return {
      id: this.id,
      name: this.name,
      barcode: this.barcode,
      specification: this.specification,
      descriptionLink: this.descriptionLink,
      instructionLink: this.instructionLink,
      manufacturerID: this.manufacturerID,
      typeID: this.typeID,
      productID: this.productID,
      internalPrice: this.internalPrice,
      externalPrice: this.externalPrice,
      minBalance: this.minBalance,
      balanceInStock: this.balanceInStock,
      settingTypes: [...this.settingTypes],
      manufacturer: this.manufacturer ? this.manufacturer.toInterface() : null,
      type: this.type ? this.type.toInterface() : null,
    }
  }
}
