import { Money } from '@/models/Money'

export class PaymentBoxes {
  prepaidCount = 0
  postpaidCount = 0
  bankFailsCount = 0

  prepaidTotalAmount = 0
  postpaidTotalAmount = 0
  bankFailsTotalAmount = 0

  moneyPrepaidTotal = new Money()
  moneyPostpaidTotal = new Money()
  moneyBankFailsTotal = new Money()

  constructor(src: PaymentBoxes | null = null) {
    if (src) {
      this.init(src)
    }
  }

  private init(src: PaymentBoxes) {
    Object.assign(this, src)

    this.moneyPrepaidTotal = new Money(src.prepaidTotalAmount)
    this.moneyPostpaidTotal = new Money(src.postpaidTotalAmount)
    this.moneyBankFailsTotal = new Money(src.bankFailsTotalAmount)
  }

  boxes(): Box[] {
    return [
      {
        title: 'Remesa',
        amount: this.moneyPrepaidTotal.euro,
        count: `${this.prepaidCount}`,
        icon: 'fas fa-users',
        tooltip: 'Number and total amount of payments for the current month (not SEPA)',
        color: 'blue',
      },
      {
        title: 'Prepaid',
        amount: this.moneyPostpaidTotal.euro,
        count: `${this.postpaidCount}`,
        icon: 'fas fa-clipboard-list',
        tooltip: 'Amount of SEPA payments made in the current month',
        color: 'yellowgreen',
      },
      {
        title: 'Bank fails this month',
        amount: '',
        count: `${this.bankFailsCount}`,
        icon: 'fas fa-users',
        tooltip:
          'The number of contracts that have at least one subscription with the bank fail status.',
        color: 'chocolate',
      },
      {
        title: 'Bank fail amount this month',
        amount: this.moneyBankFailsTotal.euro,
        count: '',
        icon: 'fas fa-clipboard-list',
        tooltip:
          'The total amount of contracts that have at least one subscription with the bank fail status',
        color: 'crimson',
      },
    ]
  }
}

export interface Box {
  title: string
  amount: string
  count: string
  icon: string
  tooltip: string
  color: string
}
