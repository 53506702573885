// This file is generated automatically, do not make manual changes to it.
// generating script: shell/generate_permissions.go

export const CAN_ASSIGN_TAGS = 'tags-CanAssignTags'
export const CAN_DELETE_TAGS = 'tags-CanDeleteTags'
export const CAN_EDIT_TAGS = 'tags-CanEditTags'
export const CAN_VIEW_ASSIGNED_TAGS = 'tags-CanViewAssignedTags'
export const CAN_VIEW_TAGS = 'tags-CanViewTags'

const tagsServer = [
  {
    value: CAN_ASSIGN_TAGS,
    description: CAN_ASSIGN_TAGS,
  },
  {
    value: CAN_DELETE_TAGS,
    description: CAN_DELETE_TAGS,
  },
  {
    value: CAN_EDIT_TAGS,
    description: CAN_EDIT_TAGS,
  },
  {
    value: CAN_VIEW_ASSIGNED_TAGS,
    description: CAN_VIEW_ASSIGNED_TAGS,
  },
  {
    value: CAN_VIEW_TAGS,
    description: CAN_VIEW_TAGS,
  },
]

export default tagsServer
