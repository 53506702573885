import { FileInfo } from '@/models/FileInfo'

export enum ReconciliationType {
  Unknown = '',
  Cablemovil = 'cablemovil',
  Sewan = 'sewan',
}

export interface ReconciliationFiles {
  files: FileInfo[]
  type: ReconciliationType
}
