import store from '@/store'
import { A_GEO_INIT, A_GEO_INIT_ALL_ADDRESSES_PARTS } from '@/store/modules/geo/actions'

export class GeoStore {
  static init(): Promise<void> {
    return store.dispatch(A_GEO_INIT)
  }

  static async loadAllAddressesParts(): Promise<void> {
    await store.dispatch(A_GEO_INIT_ALL_ADDRESSES_PARTS)
  }
}
