
import { Component, Prop, Vue } from 'vue-property-decorator'
import { NavItem, NavSection } from '@/components/app/nav/types'
import { MainStore } from '@/store/MainStore'
import NavPart from '@/components/app/nav/NavPart.vue'
import { CAN_ACCESS_TARIFFS } from '@/permissions/tariffs'
import { ROUTE_TARIFFS, ROUTE_TARIFFS_PRICES } from '@/router/tariffs'
import { EVENT_INPUT } from '@/utils/event-constants'
@Component({
  components: { NavPart },
})
export default class NavPartTariffs extends Vue {
  @Prop({ type: Boolean }) isOpen!: boolean

  get isShow(): boolean {
    return MainStore.isUserCan(CAN_ACCESS_TARIFFS)
  }

  section = new NavSection('Tariffs', 'fas fa-hand-holding-usd', ROUTE_TARIFFS, [
    new NavItem('Tariffs', ROUTE_TARIFFS, 'fas fa-hand-holding-usd'),
    new NavItem('Prices', ROUTE_TARIFFS_PRICES, 'fas fa-money-bill'),
  ])

  created(): void {
    this.$emit(EVENT_INPUT, this.section.items)
  }
}
