import { EnumNames } from '@/models/types'

export const DATA_FACTOR = 4294967296 // 2^32

export enum NasType {
  Unknown = '',
  Mikrotik = 'mikrotik',
}

export const nasTypes: EnumNames = {
  [NasType.Mikrotik]: 'Mikrotik',
}

export enum NasStatus {
  Unknown = '',
  On = 'on',
  Off = 'off',
}

export const nasStatuses: EnumNames = {
  [NasStatus.On]: 'On',
  [NasStatus.Off]: 'Off',
}

export enum IpPoolType {
  Unknown = 0,
  Standard = 1,
  Expired = 2,
}

export const ipPoolTypes: EnumNames = {
  [IpPoolType.Standard]: 'Standard',
  [IpPoolType.Expired]: 'Expired',
}

export enum IPProtocol {
  Unknown = '',
  IPv4 = 'IPv4',
  IPv6 = 'IPv6',
}

export const IPProtocols: EnumNames = {
  [IPProtocol.IPv4]: 'IPv4',
  [IPProtocol.IPv6]: 'IPv6',
}
