import { EnumNames } from '@/models/types'

export enum SettingType {
  Unknown = 0,
  Fiber = 1,
  Wifi = 2,
  Cablemovil = 3,
  Landline = 4,
  IPTV = 5,
  QPSK = 6,
  Cable = 7,
  Sewan = 8,
  StaticIP = 9,
}

export const settingTypes: EnumNames = {
  [SettingType.Unknown]: 'Other',
  [SettingType.Fiber]: 'Fiber',
  [SettingType.Wifi]: 'Wifi',
  [SettingType.Cablemovil]: 'Cablemovil',
  [SettingType.Landline]: 'Landline',
  [SettingType.IPTV]: 'IPTV',
  [SettingType.QPSK]: 'QPSK',
  [SettingType.Cable]: 'Cable',
  [SettingType.Sewan]: 'Sewan',
  [SettingType.StaticIP]: 'Static IP',
}

export const mainSettingTypes = [
  SettingType.Unknown,
  SettingType.Fiber,
  SettingType.Cablemovil,
  SettingType.IPTV,
  SettingType.Wifi,
  SettingType.Sewan,
  SettingType.QPSK,
  SettingType.Cable,
  SettingType.Landline,
  SettingType.StaticIP,
]

export const settingTypesInternet = [SettingType.Cable, SettingType.Fiber, SettingType.Wifi]
export const settingTypesTV = [SettingType.QPSK]
export const settingTypesWithSessions = [...settingTypesInternet]
export const settingTypesMobile = [SettingType.Cablemovil, SettingType.Sewan]

export enum ServiceStatus {
  Unknown = 0,
  Active = 1,
  Cancelled = 2,
}

export const serviceStatuses: EnumNames = {
  [ServiceStatus.Active]: 'Active',
  [ServiceStatus.Cancelled]: 'Cancelled',
}
