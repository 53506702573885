import { MainStore } from '@/store/MainStore'

interface ToastStorage {
  add: (toast: Toast) => void
  delete: (toast: Toast) => void
}

class ToastStorageImpl implements ToastStorage {
  async add(toast: Toast): Promise<void> {
    await MainStore.addToast(toast)
  }

  async delete(toast: Toast): Promise<void> {
    await MainStore.removeToast(toast)
  }
}

export class Toast {
  static readonly minDelay = 100
  static readonly maxDelay = 30000
  static readonly storage: ToastStorage = new ToastStorageImpl()

  protected _delayMs = 1500
  protected _headerClass = ''
  src = ''
  title = ''
  info = ''
  mainStyle = ''
  msg = ''

  constructor(msg = '', delayMs = 3000) {
    this.msg = msg
    this.setDelayMs(delayMs)
  }

  static toasts(): Toast[] {
    return MainStore.getToasts()
  }

  getHeaderClass(): string {
    return this._headerClass
  }

  setHeaderClass(value: string): void {
    this._headerClass = value
  }

  getDelayMs(): number {
    return this._delayMs
  }

  setDelayMs(value: number): void {
    if (value <= 0) {
      this._delayMs = 0
    } else if (value < Toast.minDelay) {
      this._delayMs = Toast.minDelay
    } else if (value > Toast.maxDelay) {
      this._delayMs = Toast.maxDelay
    } else {
      this._delayMs = value
    }
  }

  async show(): Promise<void> {
    await Toast.storage.add(this)
    if (this.getDelayMs()) {
      setTimeout(() => {
        this.hide()
      }, this.getDelayMs())
    }
  }

  async hide(): Promise<void> {
    await Toast.storage.delete(this)
  }
}

export class ToastInfo extends Toast {
  protected _headerClass = 'bg-info'
}

export class ToastSuccess extends Toast {
  protected _headerClass = 'bg-success'
}

export class ToastDanger extends Toast {
  protected _headerClass = 'bg-danger'
}

export class ToastWarning extends Toast {
  protected _headerClass = 'bg-warning'
}
