
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Boxes } from '@/components/dashboards/models/Boxes'
import BTooltip from '@/components/common/bootstrap4/BTooltip.vue'
import { MainStore } from '@/store/MainStore'
import { CAN_DASHBOARD_BOXES } from '@/permissions/dashboard'
import { internetApi } from '@/api/internet-api'
import { ROUTE_INTERNET_SESSIONS } from '@/router/internet'
import { SessionCountsOnline } from '@/responses/internet'

@Component({
  components: { BTooltip },
})
export default class DashboardBoxes extends Vue {
  @Prop({ type: Object, required: true }) boxes!: Boxes

  countOnline: SessionCountsOnline = {
    online: 0,
    online_200: 0,
    online_expired: 0,
    online_expired_200: 0,
  }
  intervalId = 0

  async created(): Promise<void> {
    if (MainStore.isUserCan(CAN_DASHBOARD_BOXES)) {
      await this.loadCounts()
      this.intervalId = Number(setInterval(this.loadCounts, 5000))
    }
  }

  destroyed(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  }

  async loadCounts(): Promise<void> {
    this.countOnline = await internetApi.internetSession.countOnline()
  }

  toOnline(): void {
    this.$router.push({
      name: ROUTE_INTERNET_SESSIONS,
      query: {
        isTestPeriod: 'false',
        isActive: 'true',
        expired: 'false',
      },
    })
  }

  to200HOnline(): void {
    this.$router.push({
      name: ROUTE_INTERNET_SESSIONS,
      query: {
        isTestPeriod: 'true',
        isActive: 'true',
        expired: 'false',
      },
    })
  }

  toExpiredOnline(): void {
    this.$router.push({
      name: ROUTE_INTERNET_SESSIONS,
      query: {
        isTestPeriod: 'false',
        isActive: 'true',
        expired: 'true',
      },
    })
  }

  to200HExpiredOnline(): void {
    this.$router.push({
      name: ROUTE_INTERNET_SESSIONS,
      query: {
        isTestPeriod: 'true',
        isActive: 'true',
        expired: 'true',
      },
    })
  }
}
