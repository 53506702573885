
import { Component, Vue } from 'vue-property-decorator'
import BToastContainer from '@/components/common/bootstrap4/toast/BToastContainer.vue'
import { Toast } from '@/models/Toast'
import BToast from '@/components/common/bootstrap4/toast/BToast.vue'

@Component({
  components: { BToast, BToastContainer },
})
export default class AppToasts extends Vue {
  get toasts(): Toast[] {
    return Toast.toasts()
  }
}
