import { uniqueStr } from '@/utils/index'

export class IdCreator {
  private _ids = new Map<string, string>()

  make = (id: string): string => {
    let res = this._ids.get(id)
    if (!res) {
      res = uniqueStr(id)
      this._ids.set(id, res)
    }

    return res
  }

  uniq = (): string => {
    return uniqueStr()
  }
}
