import { PermissionGroup } from '@/utils/permissions'
import discountsServer from '@/permissions/discounts_server'

const prefix = 'discounts'

export const CAN_ACCESS_DISCOUNTS = prefix + '-access_module'

const discounts: PermissionGroup = {
  name: 'Discounts Module',
  permissions: [
    {
      value: CAN_ACCESS_DISCOUNTS,
      description: CAN_ACCESS_DISCOUNTS,
    },
    ...discountsServer,
  ],
}

export default discounts
