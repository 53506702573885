// This file is generated automatically, do not make manual changes to it.
// generating script: shell/generate_permissions.go

export const CAN_DELETE_CONSUMABLE = 'stock-CanDeleteConsumable'
export const CAN_DELETE_EQUIPMENT_MODEL = 'stock-CanDeleteEquipmentModel'
export const CAN_DELETE_MANUFACTURER = 'stock-CanDeleteManufacturer'
export const CAN_DELETE_MOVEMENT = 'stock-CanDeleteMovement'
export const CAN_DELETE_PROVIDER = 'stock-CanDeleteProvider'
export const CAN_DELETE_TYPE = 'stock-CanDeleteType'
export const CAN_DELETE_WAYBILL = 'stock-CanDeleteWaybill'
export const CAN_EDIT_CONSUMABLE = 'stock-CanEditConsumable'
export const CAN_EDIT_EQUIPMENT = 'stock-CanEditEquipment'
export const CAN_EDIT_EQUIPMENT_MAC = 'CanEditEquipmentMAC'
export const CAN_EDIT_EQUIPMENT_MODEL = 'stock-CanEditEquipmentModel'
export const CAN_EDIT_EQUIPMENT_SERIAL = 'CanEditEquipmentSerial'
export const CAN_EDIT_FORCED_WAYBILL = 'stock-CanEditForcedWaybill'
export const CAN_EDIT_MANUFACTURER = 'stock-CanEditManufacturer'
export const CAN_EDIT_MOVEMENT = 'stock-CanEditMovement'
export const CAN_EDIT_PROVIDER = 'stock-CanEditProvider'
export const CAN_EDIT_TYPE = 'stock-CanEditType'
export const CAN_EDIT_WAYBILL = 'stock-CanEditWaybill'
export const CAN_VIEW_CONSUMABLE = 'stock-CanViewConsumable'
export const CAN_VIEW_EQUIPMENT = 'stock-CanViewEquipment'
export const CAN_VIEW_EQUIPMENT_MODEL = 'stock-CanViewEquipmentModel'
export const CAN_VIEW_MANUFACTURER = 'stock-CanViewManufacturer'
export const CAN_VIEW_MOVEMENT = 'stock-CanViewMovement'
export const CAN_VIEW_PROVIDER = 'stock-CanViewProvider'
export const CAN_VIEW_TYPE = 'stock-CanViewType'
export const CAN_VIEW_WAYBILL = 'stock-CanViewWaybill'

const stockServer = [
  {
    value: CAN_DELETE_CONSUMABLE,
    description: CAN_DELETE_CONSUMABLE,
  },
  {
    value: CAN_DELETE_EQUIPMENT_MODEL,
    description: CAN_DELETE_EQUIPMENT_MODEL,
  },
  {
    value: CAN_DELETE_MANUFACTURER,
    description: CAN_DELETE_MANUFACTURER,
  },
  {
    value: CAN_DELETE_MOVEMENT,
    description: CAN_DELETE_MOVEMENT,
  },
  {
    value: CAN_DELETE_PROVIDER,
    description: CAN_DELETE_PROVIDER,
  },
  {
    value: CAN_DELETE_TYPE,
    description: CAN_DELETE_TYPE,
  },
  {
    value: CAN_DELETE_WAYBILL,
    description: CAN_DELETE_WAYBILL,
  },
  {
    value: CAN_EDIT_CONSUMABLE,
    description: CAN_EDIT_CONSUMABLE,
  },
  {
    value: CAN_EDIT_EQUIPMENT,
    description: CAN_EDIT_EQUIPMENT,
  },
  {
    value: CAN_EDIT_EQUIPMENT_MAC,
    description: CAN_EDIT_EQUIPMENT_MAC,
  },
  {
    value: CAN_EDIT_EQUIPMENT_MODEL,
    description: CAN_EDIT_EQUIPMENT_MODEL,
  },
  {
    value: CAN_EDIT_EQUIPMENT_SERIAL,
    description: CAN_EDIT_EQUIPMENT_SERIAL,
  },
  {
    value: CAN_EDIT_FORCED_WAYBILL,
    description: CAN_EDIT_FORCED_WAYBILL,
  },
  {
    value: CAN_EDIT_MANUFACTURER,
    description: CAN_EDIT_MANUFACTURER,
  },
  {
    value: CAN_EDIT_MOVEMENT,
    description: CAN_EDIT_MOVEMENT,
  },
  {
    value: CAN_EDIT_PROVIDER,
    description: CAN_EDIT_PROVIDER,
  },
  {
    value: CAN_EDIT_TYPE,
    description: CAN_EDIT_TYPE,
  },
  {
    value: CAN_EDIT_WAYBILL,
    description: CAN_EDIT_WAYBILL,
  },
  {
    value: CAN_VIEW_CONSUMABLE,
    description: CAN_VIEW_CONSUMABLE,
  },
  {
    value: CAN_VIEW_EQUIPMENT,
    description: CAN_VIEW_EQUIPMENT,
  },
  {
    value: CAN_VIEW_EQUIPMENT_MODEL,
    description: CAN_VIEW_EQUIPMENT_MODEL,
  },
  {
    value: CAN_VIEW_MANUFACTURER,
    description: CAN_VIEW_MANUFACTURER,
  },
  {
    value: CAN_VIEW_MOVEMENT,
    description: CAN_VIEW_MOVEMENT,
  },
  {
    value: CAN_VIEW_PROVIDER,
    description: CAN_VIEW_PROVIDER,
  },
  {
    value: CAN_VIEW_TYPE,
    description: CAN_VIEW_TYPE,
  },
  {
    value: CAN_VIEW_WAYBILL,
    description: CAN_VIEW_WAYBILL,
  },
]

export default stockServer
