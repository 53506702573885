export class UserSettings {
  color = ''

  constructor(src: UserSettings | null = null) {
    if (src) {
      this.init(src)
    }
  }

  private init(src: UserSettings) {
    Object.assign(this, src)
  }
}
