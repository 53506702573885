import { CommonModel, IModel } from '@/models/Model'
import { MainStore } from '@/store/MainStore'
import { stockApi } from '@/api/stock-api'
import { PersonSimple } from '@/models/persons/PersonSimple'
import _truncate from 'lodash/truncate'
import { EquipmentStatus, equipmentStatuses } from '@/models/stock/constants'
import { EquipmentModel, IEquipmentModel } from '@/models/stock/EquipmentModel'
import { Money } from '@/models/Money'
import { Contract, formatContractID } from '@/models/persons/Contract'
import { formatDate, formatDateTime } from '@/utils'
import router from '@/router'
import { ROUTER_PARAM_ID } from '@/utils/constants'
import { ROUTE_STOCK_MOVEMENT_VIEW, ROUTE_STOCK_WAYBILL_VIEW } from '@/router/stock'
import { contractToPersonRedirectHtml } from '@/models/persons/views'
import { EquipmentService } from '@/models/stock/EquipmentService'

const testPeriodHours = 200

export interface IEquipment extends IModel {
  id: number
  serial: string | null
  status: EquipmentStatus
  mac: string | null
  ssid: string | null
  ssidPassword: string
  customSsid: string
  customSsidPassword: string
  ap: string
  pppoeLogin: string | null
  pppoePassword: string
  stbID: string | null
  telephone: string
  reference: string | null
  staticIP: string | null
  ataLogin: string | null
  ataPassword: string
  iptvLogin: string
  iptvPassword: string
  personID: number
  contractID: number
  equipmentModelID: number
  lastWaybillID: number | null
  lastMovementID: number | null
  lastSalePrice: number
  purchasePrice: number
  dateIssue: Date | null
  note: string
  testPeriodStart: Date | null
  equipmentModel: IEquipmentModel | null
  services: EquipmentService[]
}

export class Equipment extends CommonModel implements IEquipment {
  id = 0
  serial: string | null = null
  status: EquipmentStatus = 0
  mac: string | null = null
  ssid: string | null = null
  ssidPassword = ''
  customSsid = ''
  customSsidPassword = ''
  ap = ''
  pppoeLogin: string | null = null
  pppoePassword = ''
  stbID: string | null = null
  telephone = ''
  reference: string | null = null
  staticIP: string | null = null
  ataLogin: string | null = null
  ataPassword = ''
  iptvLogin = ''
  iptvPassword = ''
  personID = 0
  contractID = 0
  equipmentModelID = 0
  lastWaybillID: number | null = null
  lastMovementID: number | null = null
  lastSalePrice = 0
  purchasePrice = 0
  dateIssue: Date | null = null
  note = ''
  testPeriodStart: Date | null = null
  equipmentModel: EquipmentModel | null = null
  services: EquipmentService[] = []

  // after load
  contract: Contract | null = null
  person: PersonSimple | null = null

  // for view
  moneyPurchasePrice = new Money()

  // other
  isWithoutServices = false

  constructor(src: IEquipment | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: IEquipment) {
    Object.assign(this, src)
    this.equipmentModel = src.equipmentModel ? new EquipmentModel(src.equipmentModel) : null
    this.moneyPurchasePrice = new Money(src.purchasePrice)
    this.dateIssue = this.dateOrNull(src.dateIssue)
    this.testPeriodStart = this.dateOrNull(src.testPeriodStart)
    this.services = src.services ? src.services.map((v) => new EquipmentService(v)) : []

    this.initModel(src)
  }

  get serviceIDs(): number[] {
    return this.services.map((v) => v.serviceID)
  }

  get html(): string {
    let result = this.modelHtml
    this.serial && (result += ', <b>Serial:</b> ' + this.serial)
    this.mac && (result += ', <b>MAC: </b>' + this.mac)
    this.ssid && (result += ', <b>SSID:</b> ' + this.ssid)
    this.pppoeLogin && (result += ', <b>Pppoe Login:</b> ' + this.pppoeLogin)
    this.ataLogin && (result += ', <b>Ata Login:</b> ' + this.ataLogin)
    this.stbID && (result += ', <b>Stb ID:</b> ' + this.stbID)
    this.telephone && (result += ', <b>Tel.:</b> ' + this.telephone)
    this.reference && (result += ', <b>Ref.:</b> ' + this.reference)
    this.staticIP && (result += ', <b>IP:</b> ' + this.staticIP)
    this.iptvLogin && (result += ', <b>IPTV Login:</b> ' + this.iptvLogin)
    this.iptvPassword && (result += ', <b>IPTV Password:</b> ' + this.iptvPassword)

    return result.replace(/^, +/, '')
  }

  get shortTitleHtml(): string {
    let result = ''
    this.modelName && (result += ', <b>Model:</b> ' + this.modelName)
    this.serial && (result += ', <b>Serial:</b> ' + this.serial)
    this.ssid && (result += ', <b>SSID:</b> ' + this.ssid)
    this.ap && (result += ', <b>AP:</b> ' + this.ap)
    this.pppoeLogin && (result += ', <b>Pppoe Login:</b> ' + this.pppoeLogin)
    this.mac && (result += ', <b>MAC: </b>' + this.mac)
    this.iptvLogin && (result += ', <b>IPTV Login:</b> ' + this.iptvLogin)
    this.ataLogin && (result += ', <b>Ata Login:</b> ' + this.ataLogin)
    this.telephone && (result += ', <b>Tel.:</b> ' + this.telephone)
    this.staticIP && (result += ', <b>IP:</b> ' + this.staticIP)

    return result.replace(/^, +/, '')
  }

  get personName(): string {
    return this.person ? this.person.fullName : ''
  }

  get modelHtml(): string {
    return this.equipmentModel ? this.equipmentModel.html : ''
  }

  get modelName(): string {
    return this.equipmentModel ? this.equipmentModel.name : ''
  }

  get modelTypeName(): string {
    return this.equipmentModel ? this.equipmentModel.typeName : ''
  }

  get statusHtml(): string {
    let textColorClass = 'font-weight-bold '
    switch (this.status) {
      case EquipmentStatus.TakenToStock:
      case EquipmentStatus.InProcessOfSetting:
      case EquipmentStatus.InProcessOfRepair:
      case EquipmentStatus.ReadyToIssue:
      case EquipmentStatus.ReceiveFromSupplier:
      case EquipmentStatus.ReceiveFromPerson:
      case EquipmentStatus.Reserved:
        textColorClass += 'text-success'
        break
      case EquipmentStatus.Basura:
      case EquipmentStatus.Lost:
        textColorClass += 'text-danger'
        break
      case EquipmentStatus.Issued:
      case EquipmentStatus.ReturnedToSupplier:
      case EquipmentStatus.Installed:
      case EquipmentStatus.Unknown:
        textColorClass += 'text-secondary'
        break
    }

    return `<span class="${textColorClass}">${this.statusName}</span>`
  }

  get statusForTransferHtml(): string {
    const textColorClass =
      'font-weight-bold ' + (this.status == EquipmentStatus.Issued ? 'text-success' : 'text-danger')

    return `<span class="${textColorClass}">${this.statusName}</span>`
  }

  get statusName(): string {
    return this.status ? equipmentStatuses[this.status] : ''
  }

  get dateIssueHtml(): string {
    return formatDate(this.dateIssue)
  }

  get testPeriodStartHtml(): string {
    return formatDateTime(this.testPeriodStart)
  }

  get formattedContractID(): string {
    return this.contractID ? formatContractID(this.contractID) : ''
  }
  get contractToPersonRedirectHtml(): string {
    return contractToPersonRedirectHtml(this.contractID)
  }

  get moneyExternalPrice(): Money {
    if (!this.equipmentModel) {
      throw new Error('Not load related EquipmentModel')
    }
    return this.equipmentModel.moneyExternalPrice
  }

  get moneyInternalPrice(): Money {
    if (!this.equipmentModel) {
      throw new Error('Not load related EquipmentModel')
    }
    return this.equipmentModel.moneyInternalPrice
  }

  get personLinkHtml(): string {
    return this.person ? this.person.viewLinkHtml('_self') : ''
  }

  get toWaybillLink(): string {
    if (!this.lastWaybillID) {
      return ''
    }

    const res = router.resolve({
      name: ROUTE_STOCK_WAYBILL_VIEW,
      params: {
        [ROUTER_PARAM_ID]: this.lastWaybillID.toString(),
      },
    })

    return `<a href="${res.href}" target="_blank">${this.lastWaybillID}</a>`
  }

  get toMovementLink(): string {
    if (!this.lastMovementID) {
      return ''
    }

    const res = router.resolve({
      name: ROUTE_STOCK_MOVEMENT_VIEW,
      params: {
        [ROUTER_PARAM_ID]: this.lastMovementID.toString(),
      },
    })

    return `<a href="${res.href}" target="_blank">${this.lastMovementID}</a>`
  }

  get canViewSession(): boolean {
    return this.equipmentModel ? this.equipmentModel.canViewSession : false
  }

  get testPeriodLeftHtml(): string {
    if (!this.testPeriodStart) {
      return ''
    }

    let hours = Math.floor(
      testPeriodHours - (new Date().getTime() - this.testPeriodStart.getTime()) / (1000 * 60 * 60)
    )
    if (hours < 0) {
      hours = 0
    }

    const className = hours > 0 ? 'text-success' : 'text-danger'

    return `<span class="${className}">(${hours}h left)</span>`
  }

  titleStr(max = 0): string {
    const res = this.html.replace(/<[^>]*>/g, '')
    return max > 0 ? _truncate(res, { length: max }) : res
  }

  titleForDropdown(): string {
    const res: string[] = []
    this.modelName && res.push(this.modelName)
    this.modelTypeName && res.push(this.modelTypeName)
    this.telephone && res.push(this.telephone)
    this.serial && res.push(this.serial)
    this.mac && res.push(this.mac)
    this.ssid && res.push(this.ssid.toString())
    this.pppoeLogin && res.push(this.pppoeLogin)
    this.ataLogin && res.push(this.ataLogin)
    this.iptvLogin && res.push('Login: ' + this.iptvLogin)
    this.iptvPassword && res.push('Password: ' + this.iptvPassword)

    return res.join(', ')
  }

  async save(): Promise<boolean> {
    try {
      return this.id ? await this.update() : await this.add()
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async add(): Promise<boolean> {
    try {
      this.init(await stockApi.equipment.add(this.prepare().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async update(): Promise<boolean> {
    try {
      this.init(await stockApi.equipment.update(this.prepare().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async resetStartTestPeriod(): Promise<boolean> {
    try {
      this.init(await stockApi.equipment.resetStartTestPeriod(this.prepare().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async attachService(serviceID: number, isCopy: boolean): Promise<boolean> {
    try {
      await stockApi.equipment.attachService({
        equipmentID: this.id,
        serviceID,
        isCopy,
      })
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async detachService(serviceID: number): Promise<boolean> {
    try {
      await stockApi.equipment.detachService({
        equipmentID: this.id,
        serviceID,
      })
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  prepare(): Equipment {
    this.purchasePrice = this.moneyPurchasePrice.forStorage
    this.equipmentModelID = this.equipmentModel ? this.equipmentModel.id : this.equipmentModelID
    return this
  }

  toInterface(): IEquipment {
    return {
      id: this.id,
      serial: this.serial,
      status: this.status,
      mac: this.mac,
      ssid: this.ssid,
      ssidPassword: this.ssidPassword,
      customSsid: this.customSsid,
      customSsidPassword: this.customSsidPassword,
      ap: this.ap,
      pppoeLogin: this.pppoeLogin,
      pppoePassword: this.pppoePassword,
      stbID: this.stbID,
      telephone: this.telephone,
      reference: this.reference,
      staticIP: this.staticIP,
      ataLogin: this.ataLogin,
      ataPassword: this.ataPassword,
      iptvLogin: this.iptvLogin,
      iptvPassword: this.iptvPassword,
      personID: this.personID,
      contractID: this.contractID,
      equipmentModelID: this.equipmentModelID,
      lastWaybillID: this.lastWaybillID,
      lastMovementID: this.lastMovementID,
      lastSalePrice: this.lastSalePrice,
      purchasePrice: this.purchasePrice,
      dateIssue: this.dateIssue,
      note: this.note,
      testPeriodStart: this.testPeriodStart,
      equipmentModel: this.equipmentModel ? this.equipmentModel.toInterface() : null,
      services: [],
    }
  }
}
