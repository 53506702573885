import { PermissionGroup } from '@/utils/permissions'
import coreServer from '@/permissions/core_server'

const prefix = 'core'

export const CAN_ACCESS_CORE = prefix + '-access_module'

const core: PermissionGroup = {
  name: 'Core Module',
  permissions: [
    {
      value: CAN_ACCESS_CORE,
      description: CAN_ACCESS_CORE,
    },
    ...coreServer,
  ],
}

export default core
