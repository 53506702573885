import { RouteConfig } from 'vue-router/types/router'
import { CAN_ACCESS_DISCOUNTS } from '@/permissions/discounts'
import { ROUTER_PARAM_ID } from '@/utils/constants'

export const ROUTE_DISCOUNTS = 'ROUTE_DISCOUNTS'
export const ROUTE_DISCOUNTS_FORM = 'ROUTE_DISCOUNTS_FORM'

const discountsRoutes: RouteConfig[] = [
  {
    name: ROUTE_DISCOUNTS,
    path: '/discounts',
    meta: { permission: CAN_ACCESS_DISCOUNTS },
    component: () =>
      import(/* webpackChunkName: "discounts" */ '../components/discounts/DiscountList.vue'),
  },
  {
    name: ROUTE_DISCOUNTS_FORM,
    path: `/discounts/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: CAN_ACCESS_DISCOUNTS },
    component: () =>
      import(/* webpackChunkName: "discounts" */ '../components/discounts/DiscountForm.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
]

export default discountsRoutes
