// This file is generated automatically, do not make manual changes to it.
// generating script: shell/generate_permissions.go

export const CAN_GEO_ADD = 'geo-CanGeoAdd'
export const CAN_GEO_DELETE = 'geo-CanGeoDelete'
export const CAN_GEO_EDIT = 'geo-CanGeoEdit'
export const CAN_GEO_VIEW = 'geo-CanGeoView'

const geoServer = [
  {
    value: CAN_GEO_ADD,
    description: CAN_GEO_ADD,
  },
  {
    value: CAN_GEO_DELETE,
    description: CAN_GEO_DELETE,
  },
  {
    value: CAN_GEO_EDIT,
    description: CAN_GEO_EDIT,
  },
  {
    value: CAN_GEO_VIEW,
    description: CAN_GEO_VIEW,
  },
]

export default geoServer
