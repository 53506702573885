import { EnumNames, ObjectParams, Searcher } from '@/models/types'

export enum SubscriptionStatus {
  Unknown = 0,
  Active = 1,
  Stopped = 2,
  Canceled = 3,
  Unpaid = 4,
  PaymentFailed = 5,
  StoppedAwaitingPayment = 6,
  Deleted = 7,
}

export const subscriptionStatuses: EnumNames = {
  [SubscriptionStatus.Active]: 'Active',
  [SubscriptionStatus.Stopped]: 'Stopped',
  [SubscriptionStatus.Canceled]: 'Canceled',
  [SubscriptionStatus.Unpaid]: 'Unpaid',
  [SubscriptionStatus.PaymentFailed]: 'Payment Failed',
  [SubscriptionStatus.StoppedAwaitingPayment]: 'Stopped Awaiting Payment',
  [SubscriptionStatus.Deleted]: 'Deleted',
}

export function subscriptionStatusesToSearcher(statuses: SubscriptionStatus[]): Searcher {
  return new Searcher(
    statuses.reduce((acc: ObjectParams, v) => {
      acc[v] = subscriptionStatuses[v]
      return acc
    }, {})
  )
}

export const activeSubscriptionStatuses = [
  SubscriptionStatus.Active,
  SubscriptionStatus.Unpaid,
  SubscriptionStatus.PaymentFailed,
]

export enum InstallationStatus {
  New = 1,
  DateAssigned = 2,
  EquipmentReady = 3,
  IssuedToInstaller = 4,
  Installed = 5,
  Done = 6,

  // This is not a status, but is taken from the is_cancelled field. Needed for drawing dashboards
  Cancelled = 1000,
}

export const installationStatuses: EnumNames = {
  [InstallationStatus.New]: 'New',
  [InstallationStatus.DateAssigned]: 'Date assigned',
  [InstallationStatus.EquipmentReady]: 'Equipment ready',
  [InstallationStatus.IssuedToInstaller]: 'Issued to installer',
  [InstallationStatus.Done]: 'Done',
  [InstallationStatus.Installed]: 'Installed',
}

export enum TransferType {
  Landline = 1,
  Mobile,
}

export const transferTypes: EnumNames = {
  [TransferType.Landline]: 'Landline',
  [TransferType.Mobile]: 'Mobile',
}

export enum OwnEquipmentType {
  IPTVBox = 1,
}

export const ownEquipmentTypes: EnumNames = {
  [OwnEquipmentType.IPTVBox]: 'IPTVBox',
}

export enum AdditionalChargeStatus {
  Unpaid = 1,
  Paid,
  Canceled,
}

export const additionalChargeStatuses: EnumNames = {
  [AdditionalChargeStatus.Unpaid]: 'Unpaid',
  [AdditionalChargeStatus.Paid]: 'Paid',
  [AdditionalChargeStatus.Canceled]: 'Canceled',
}

export enum ActivationType {
  On = 1,
  Off = 2,
  Resume = 3,
}

export const remesaActivationTypes: EnumNames = {
  [ActivationType.On]: 'On',
  [ActivationType.Off]: 'Off',
  [ActivationType.Resume]: 'Resume',
}

export function remesaActivationTypeHtml(type: ActivationType): string {
  let textColorClass = 'font-weight-bold '
  let typeStr = ''
  switch (type) {
    case ActivationType.On:
      textColorClass += 'text-success'
      typeStr = 'SW-ON'
      break
    case ActivationType.Off:
      textColorClass += 'text-danger'
      typeStr = 'SW-OFF'
      break
    case ActivationType.Resume:
      textColorClass += 'text-warning'
      typeStr = 'Resume'
      break
  }

  return `<span class="${textColorClass}">${typeStr}</span>`
}

export enum NetworkType {
  DVBLAB = 1,
  Gofibra = 2,
  Airsat = 3,
  Scansat = 4,
  Flexa = 5,
  Nolimitnet = 6,
}

export const networkTypes: EnumNames = {
  [NetworkType.DVBLAB]: 'DVBLAB',
  [NetworkType.Gofibra]: 'Gofibra',
  [NetworkType.Airsat]: 'Airsat',
  [NetworkType.Scansat]: 'Scansat',
  [NetworkType.Flexa]: 'Flexa',
  [NetworkType.Nolimitnet]: 'Nolimitnet',
}
