import { PermissionGroup } from '@/utils/permissions'
import productsServer from '@/permissions/products_server'

const prefix = 'products'

export const CAN_ACCESS_PRODUCTS = prefix + '-access_module'

const products: PermissionGroup = {
  name: 'Products Module',
  permissions: [
    {
      value: CAN_ACCESS_PRODUCTS,
      description: CAN_ACCESS_PRODUCTS,
    },
    ...productsServer,
  ],
}

export default products
