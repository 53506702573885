// This file is generated automatically, do not make manual changes to it.
// generating script: shell/generate_permissions.go

export const CAN_DISCOUNT_DELETE = 'discounts-CanDiscountDelete'
export const CAN_DISCOUNT_EDIT = 'discounts-CanDiscountEdit'
export const CAN_DISCOUNT_VIEW = 'discounts-CanDiscountView'

const discountsServer = [
  {
    value: CAN_DISCOUNT_DELETE,
    description: CAN_DISCOUNT_DELETE,
  },
  {
    value: CAN_DISCOUNT_EDIT,
    description: CAN_DISCOUNT_EDIT,
  },
  {
    value: CAN_DISCOUNT_VIEW,
    description: CAN_DISCOUNT_VIEW,
  },
]

export default discountsServer
