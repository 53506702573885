import { RouteConfig } from 'vue-router/types/router'
import { CAN_ACCESS_TARIFFS } from '@/permissions/tariffs'
import { ROUTER_PARAM_ID } from '@/utils/constants'

export const ROUTE_TARIFFS = 'ROUTE_TARIFFS'
export const ROUTE_TARIFF_FORM = 'ROUTE_TARIFF_FORM'
export const ROUTE_TARIFF_VIEW = 'ROUTE_TARIFF_VIEW'

export const ROUTE_TARIFFS_PRICES = 'ROUTE_TARIFFS_PRICES'
export const ROUTE_TARIFFS_PRICE_VIEW = 'ROUTE_TARIFFS_PRICE_VIEW'

const tariffRoutes: RouteConfig[] = [
  {
    name: ROUTE_TARIFFS,
    path: '/tariffs',
    meta: { permission: CAN_ACCESS_TARIFFS },
    component: () =>
      import(/* webpackChunkName: "tariffs" */ '../components/tariffs/TariffList.vue'),
  },
  {
    name: ROUTE_TARIFF_FORM,
    path: `/tariffs/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: CAN_ACCESS_TARIFFS },
    component: () =>
      import(/* webpackChunkName: "tariffs" */ '../components/tariffs/TariffEdit.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
  {
    name: ROUTE_TARIFF_VIEW,
    path: `/tariffs/:${ROUTER_PARAM_ID}(\\d+)`,
    meta: { permission: CAN_ACCESS_TARIFFS },
    component: () =>
      import(/* webpackChunkName: "tariffs" */ '../components/tariffs/TariffView.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },

  {
    name: ROUTE_TARIFFS_PRICES,
    path: '/tariffs/prices',
    meta: { permission: CAN_ACCESS_TARIFFS },
    component: () =>
      import(/* webpackChunkName: "tariffs" */ '../components/tariffs/PriceList.vue'),
    props: (route) => ({ id: route.params.id }),
  },
  {
    name: ROUTE_TARIFFS_PRICE_VIEW,
    path: `/tariffs/prices/:${ROUTER_PARAM_ID}(\\d+)`,
    meta: { permission: CAN_ACCESS_TARIFFS },
    component: () =>
      import(/* webpackChunkName: "tariffs" */ '../components/tariffs/PriceView.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
]

export default tariffRoutes
