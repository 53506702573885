import { MainStore } from '@/store/MainStore'
import { geoApi } from '@/api/geo-api'
import { List } from '@/models/List'
import { ObjectParams } from '@/models/types'
import { District } from '@/models/geo/District'

export class DistrictGet {
  static async list(params: ObjectParams): Promise<List<District>> {
    const list: List<District> = { count: 0, items: [] }
    try {
      const l = await geoApi.district.list(params)
      list.count = l.count
      list.items = l.items.map((v) => new District(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return list
  }

  static async versions(params: ObjectParams): Promise<List<District>> {
    const list: List<District> = { count: 0, items: [] }
    try {
      const l = await geoApi.district.versions(params)
      list.count = l.count
      list.items = l.items.map((v) => new District(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return list
  }

  static async byID(id: number): Promise<District> {
    let item = new District()
    try {
      item = new District(await geoApi.district.getByID(id))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return item
  }

  static async count(params: ObjectParams): Promise<number> {
    let count = 0
    try {
      count = await geoApi.district.count(params)
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return count
  }
}
