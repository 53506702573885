import { PermissionGroup } from '@/utils/permissions'
import stockServer from '@/permissions/stock_server'

const prefix = 'stock'

export const CAN_ACCESS_STOCK = prefix + '-access_module'

const stock: PermissionGroup = {
  name: 'Stock Module',
  permissions: [
    {
      value: CAN_ACCESS_STOCK,
      description: CAN_ACCESS_STOCK,
    },
    ...stockServer,
  ],
}

export default stock
