import { PermissionGroup } from '@/utils/permissions'
import usersServer from '@/permissions/users_server'

const prefix = 'users'

export const CAN_ACCESS_USERS = prefix + '-access_module'

const users: PermissionGroup = {
  name: 'Users Module',
  permissions: [
    {
      value: CAN_ACCESS_USERS,
      description: CAN_ACCESS_USERS,
    },
    ...usersServer,
  ],
}

export default users
