import { MainStore } from '@/store/MainStore'
import { stockApi } from '@/api/stock-api'
import { List } from '@/models/List'
import { ObjectParams } from '@/models/types'
import { MAX_LIMIT, paramsByIds } from '@/api/api'
import { Equipment } from '@/models/stock/Equipment'
import { BalanceByModel } from '@/responses/waybills'

export class EquipmentGet {
  static async list(params: ObjectParams): Promise<List<Equipment>> {
    const list: List<Equipment> = { count: 0, items: [] }
    try {
      const l = await stockApi.equipment.list(params)
      list.count = l.count
      list.items = l.items.map((v) => new Equipment(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return list
  }

  static async versions(params: ObjectParams): Promise<List<Equipment>> {
    const list: List<Equipment> = { count: 0, items: [] }
    try {
      const l = await stockApi.equipment.versions(params)
      list.count = l.count
      list.items = l.items.map((v) => new Equipment(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return list
  }

  static async byID(id: number): Promise<Equipment> {
    let item = new Equipment()
    try {
      item = new Equipment(await stockApi.equipment.getByID(id))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return item
  }

  static async balanceByModel(id: number): Promise<BalanceByModel[]> {
    let items: BalanceByModel[] = []
    try {
      items = (await stockApi.equipment.balancesByModel(id)).map((v) => new BalanceByModel(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return items
  }

  static async mapByIDs(ids: number[]): Promise<Map<number, Equipment>> {
    const res = await EquipmentGet.byIDs(ids)

    const pMap = new Map<number, Equipment>()
    res.forEach((v) => pMap.set(v.id, new Equipment(v)))

    return pMap
  }

  static async byIDs(ids: number[]): Promise<Equipment[]> {
    return (await EquipmentGet.list({ ...paramsByIds(ids), limit: MAX_LIMIT })).items
  }

  static async mapByPersonIDs(ids: number[]): Promise<Map<number, Equipment[]>> {
    const pMap = new Map<number, Equipment[]>()
    const obj: { [key: number]: Equipment[] } = {}
    const res = (await EquipmentGet.list({ ...paramsByIds(ids, 'personIDs'), limit: MAX_LIMIT }))
      .items

    res.forEach((v) => {
      if (obj[v.personID]) {
        obj[v.personID].push(new Equipment(v))
      } else {
        obj[v.personID] = [new Equipment(v)]
      }
    })

    for (const key in obj) {
      pMap.set(Number(key), obj[key])
    }

    return pMap
  }

  static async mapByServiceIDs(ids: number[]): Promise<Map<number, Equipment[]>> {
    const pMap = new Map<number, Equipment[]>()
    const obj: { [key: number]: Equipment[] } = {}
    const res = (await EquipmentGet.list({ ...paramsByIds(ids, 'serviceIDs'), limit: MAX_LIMIT }))
      .items

    res.forEach((v) => {
      v.serviceIDs.forEach((serviceID) => {
        if (obj[serviceID]) {
          obj[serviceID].push(new Equipment(v))
        } else {
          obj[serviceID] = [new Equipment(v)]
        }
      })
    })

    for (const key in obj) {
      pMap.set(Number(key), obj[key])
    }

    return pMap
  }

  static async mapByPppoeLogins(pppoeLogins: string[]): Promise<Map<string, Equipment>> {
    const res = (
      await EquipmentGet.list({ ...paramsByIds(pppoeLogins, 'pppoeLogins'), limit: MAX_LIMIT })
    ).items

    const pMap = new Map<string, Equipment>()
    res.forEach((v) => {
      if (v.pppoeLogin) {
        pMap.set(v.pppoeLogin, v)
      }
    })

    return pMap
  }

  static async mapFibreRoutersByContractIDs(ids: number[]): Promise<Map<number, Equipment[]>> {
    return EquipmentGet.getMapByContractIDs(ids, { equipmentModelTypeID: 22 }) // Router Fibre
  }

  static async mapByContractIDs(ids: number[]): Promise<Map<number, Equipment[]>> {
    return EquipmentGet.getMapByContractIDs(ids)
  }

  private static async getMapByContractIDs(
    ids: number[],
    p: ObjectParams = {}
  ): Promise<Map<number, Equipment[]>> {
    const pMap = new Map<number, Equipment[]>()
    const obj: { [key: number]: Equipment[] } = {}
    const res = (
      await EquipmentGet.list({ ...p, ...paramsByIds(ids, 'contractIDs'), limit: MAX_LIMIT })
    ).items

    res.forEach((v) => {
      if (obj[v.contractID]) {
        obj[v.contractID].push(new Equipment(v))
      } else {
        obj[v.contractID] = [new Equipment(v)]
      }
    })

    for (const key in obj) {
      pMap.set(Number(key), obj[key])
    }

    return pMap
  }
}
