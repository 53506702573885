import { CommonModel, IModel } from '@/models/Model'
import { MainStore } from '@/store/MainStore'
import { personsApi } from '@/api/persons-api'
import { PersonSimple } from '@/models/persons/PersonSimple'
import { formatDate } from '@/utils'
import {
  defWorkflowSettings,
  WorkflowStatus,
  workflowStatuses,
  WorkflowType,
  workflowTypes,
} from '@/models/persons/constants'
import { IDates, IWorkflowSettings, WorkflowSettings } from '@/models/persons/WorkflowSettings'
import { checkHtml } from '@/utils/html'
import { Person } from '@/models/persons/Person'

export interface Attribute {
  key: number
  customData: CustomData
  dates: IDates
}

export interface CustomData {
  title: string
  style: string
  icon: string
  item: IWorkflow
}

export interface IWorkflow extends IModel {
  id: number
  title: string
  description: string
  personID?: number
  type: WorkflowType
  status: WorkflowStatus
  settings: IWorkflowSettings
  isRepeated: boolean
  startDate?: Date
  endDate?: Date
  createdBy: number
  createdAt?: Date
  updatedAt?: Date
}

export class Workflow extends CommonModel implements IWorkflow {
  id = 0
  title = ''
  description = ''
  personID?: number
  type = WorkflowType.Unknown
  status = WorkflowStatus.Active
  settings = new WorkflowSettings()
  isRepeated = false
  startDate?: Date
  endDate?: Date
  createdBy = 0
  createdAt?: Date
  updatedAt?: Date

  // after load
  person: Person | null = null
  whoCreated: PersonSimple | null = null

  get typeHtml(): string {
    return this.typeName
  }

  get typeName(): string {
    return this.type ? workflowTypes[this.type] : ''
  }

  get statusHtml(): string {
    return this.status ? workflowStatuses[this.status] : ''
  }

  get startDateHtml(): string {
    return formatDate(this.startDate)
  }

  get endDateHtml(): string {
    return formatDate(this.endDate)
  }

  get isRepeatedHtml(): string {
    return checkHtml(this.isRepeated)
  }

  get createdAtHtml(): string {
    return formatDate(this.createdAt)
  }

  get personTitle(): string {
    return this.person ? this.person.usernameOrFullName : ''
  }

  get whoCreatedHtml(): string {
    return this.whoCreated ? `(${this.whoCreated.id}): ${this.whoCreated.fullName}` : ''
  }

  get userBackgroundColor(): string {
    const color =
      this.person &&
      this.person.user &&
      this.person.user.settings.color != '' &&
      this.person.user.settings.color != ''
        ? this.person.user.settings.color
        : ''

    return color != '' ? `background-color: ${color};` : ''
  }

  constructor(src: IWorkflow | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: IWorkflow) {
    Object.assign(this, src)

    this.settings = new WorkflowSettings(src.settings)

    this.initModel(src)
  }

  async save(): Promise<boolean> {
    try {
      return this.id ? await this.update() : await this.add()
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async add(): Promise<boolean> {
    try {
      this.init(await personsApi.workflow.add(this.prepare().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async update(): Promise<boolean> {
    try {
      this.init(await personsApi.workflow.update(this.prepare().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async delete(): Promise<boolean> {
    if (!confirm(`Are you sure you want to delete workflow?`)) {
      return false
    }

    try {
      await personsApi.workflow.delete(this.id)
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  prepare(): Workflow {
    this.personID = this.person ? this.person.id : undefined
    this.settings.prepare()

    return this
  }

  toInterface(): IWorkflow {
    return {
      id: this.id,
      title: this.title,
      description: this.description,
      personID: this.personID,
      type: this.type,
      status: this.status,
      settings: this.settings.toInterface(),
      isRepeated: this.isRepeated,
      createdBy: this.createdBy,
    }
  }

  toAttribute(): Attribute {
    const def = defWorkflowSettings[this.type]
    const title = this.title
      ? this.title + (this.personTitle ? ' (' + this.personTitle + ')' : '')
      : this.personTitle

    return {
      key: this.id,
      customData: {
        title: title.trim(),
        style: this.settings.style || this.userBackgroundColor || (def ? def.style : ''),
        icon: this.settings.icon || (def ? def.icon : ''),
        item: this,
      },

      dates: this.settings.dates.toAttribute(),
    }
  }
}
