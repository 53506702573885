import { PermissionGroup } from '@/utils/permissions'
import geoServer from '@/permissions/geo_server'

const prefix = 'geo'

export const CAN_ACCESS_GEO = prefix + '-access_module'

const geo: PermissionGroup = {
  name: 'Geo Module',
  permissions: [
    {
      value: CAN_ACCESS_GEO,
      description: CAN_ACCESS_GEO,
    },
    ...geoServer,
  ],
}

export default geo
