
import { Component, Vue } from 'vue-property-decorator'
import AppHeader from '@/components/app/AppHeader.vue'
import Nav from '@/components/app/nav/Nav.vue'
import AppMain from '@/components/app/AppMain.vue'
import AppLogin from '@/components/app/AppLogin.vue'
import AppToasts from '@/components/app/AppToasts.vue'
import { MainStore } from '@/store/MainStore'
import LoadPage from '@/components/app/LoadPage.vue'

@Component({
  components: {
    LoadPage,
    AppToasts,
    AppHeader,
    Nav,
    AppMain,
    AppLogin,
  },
})
export default class App extends Vue {
  get isAuthorized(): boolean {
    return MainStore.isAuthorized()
  }
}
