import { RouteConfig } from 'vue-router/types/router'
import { CAN_ACCESS_CORE } from '@/permissions/core'

export const ROUTE_COMMISSIONS_INSTALLERS = 'ROUTE_COMMISSIONS_INSTALLERS'

const commissionsRoutes: RouteConfig[] = [
  {
    name: ROUTE_COMMISSIONS_INSTALLERS,
    path: '/commissions/installers',
    meta: { permission: CAN_ACCESS_CORE },
    component: () =>
      import(
        /* webpackChunkName: "commissions" */ '../components/commissions/InstallerCommissionsList.vue'
      ),
  },
]

export default commissionsRoutes
