import API from '@/api/api'
import { DatesRequest } from '@/components/dashboards/models/requests'
import { Boxes } from '@/components/dashboards/models/Boxes'
import { AnnualReports } from '@/components/dashboards/models/AnnualReport'
import { PaymentBoxes } from '@/components/dashboards/models/PaymentBoxes'
import { PaymentReports } from '@/components/dashboards/models/PaymentReport'

const MODULE = '/dashboards'

export const dashboardsApi = {
  boxes: async function (req: DatesRequest): Promise<Boxes> {
    const res = await API.get(API.buildURL(MODULE + '/boxes', { ...req }))
    return new Boxes((await res.json()) as Boxes)
  },

  annualReports: async function (year: number): Promise<AnnualReports> {
    const res = await API.get(API.buildURL(MODULE + '/annual-reports', { year }))
    return new AnnualReports(await res.json())
  },

  paymentsBoxes: async function (req: DatesRequest): Promise<PaymentBoxes> {
    const res = await API.get(API.buildURL(MODULE + '/payment/boxes', { ...req }))
    return new PaymentBoxes((await res.json()) as PaymentBoxes)
  },

  paymentsReports: async function (year: number): Promise<PaymentReports> {
    const res = await API.get(API.buildURL(MODULE + '/payment/reports', { year }))
    return new PaymentReports(await res.json())
  },
}
