import { MainStore } from '@/store/MainStore'
import { paymentsApi } from '@/api/payments-api'
import { List } from '@/models/List'
import { ObjectParams } from '@/models/types'
import { MAX_LIMIT, paramsByIds } from '@/api/api'
import { PersonPaymentMethod } from '@/models/payments/PersonPaymentMethod'

export class PersonPaymentMethodGet {
  static async list(params: ObjectParams): Promise<List<PersonPaymentMethod>> {
    const list: List<PersonPaymentMethod> = { count: 0, items: [] }
    try {
      const l = await paymentsApi.personPaymentMethod.list(params)
      list.count = l.count
      list.items = l.items.map((v) => new PersonPaymentMethod(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return list
  }

  static async byPerson(personID: number): Promise<PersonPaymentMethod[]> {
    return (
      await PersonPaymentMethodGet.list({
        limit: MAX_LIMIT,
        personID: personID,
      })
    ).items
  }

  static async activeByPerson(personID: number): Promise<PersonPaymentMethod[]> {
    return (
      await PersonPaymentMethodGet.list({
        limit: MAX_LIMIT,
        isActive: true,
        personID: personID,
      })
    ).items
  }

  static async byIDs(ids: number[]): Promise<PersonPaymentMethod[]> {
    let items: PersonPaymentMethod[] = []
    try {
      items = (await paymentsApi.personPaymentMethod.getByIDs(ids)).map(
        (v) => new PersonPaymentMethod(v)
      )
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return items
  }

  static async mapByIDs(ids: number[]): Promise<Map<number, PersonPaymentMethod>> {
    const res = await PersonPaymentMethodGet.byIDs(ids)

    const pMap = new Map<number, PersonPaymentMethod>()
    res.forEach((v) => pMap.set(v.id, new PersonPaymentMethod(v)))

    return pMap
  }

  static async mapByPersonIDs(
    ids: number[],
    isDeleted?: boolean
  ): Promise<Map<number, PersonPaymentMethod[]>> {
    const pMap = new Map<number, PersonPaymentMethod[]>()
    const obj: { [key: number]: PersonPaymentMethod[] } = {}
    const res = (
      await PersonPaymentMethodGet.list({
        ...paramsByIds(ids, 'personIDs'),
        isDeleted,
        limit: MAX_LIMIT,
      })
    ).items

    res.forEach((v) => {
      if (obj[v.personID]) {
        obj[v.personID].push(new PersonPaymentMethod(v))
      } else {
        obj[v.personID] = [new PersonPaymentMethod(v)]
      }
    })

    for (const key in obj) {
      pMap.set(Number(key), obj[key])
    }

    return pMap
  }
}
