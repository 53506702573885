import { CommonModel, IModel } from '@/models/Model'
import { MainStore } from '@/store/MainStore'
import { TagType, tagTypes } from '@/models/tags/constants'
import { tagsApi } from '@/api/tags-api'
import { checkHtml } from '@/utils/html'
import { Discount } from '@/models/discounts/Discount'
import { List } from '@/models/List'
import { ObjectParams } from '@/models/types'
import { DiscountGet } from '@/models/discounts/DiscountGet'

export interface ITag extends IModel {
  id: number
  name: string
  type: TagType
  description: string
  color: string
  isDeleted: boolean
}

export class Tag extends CommonModel implements ITag {
  id = 0
  name = ''
  type: TagType = 0
  description = ''
  color = '#228cdd'
  isDeleted = false

  // after load
  discounts: Discount[] = []

  get html(): string {
    return `<span class="badge badge-pill text-white text-uppercase py-1 px-2" style="background-color: ${this.color}">${this.name}</span>`
  }

  get htmlWithPointer(): string {
    return `<span class="pointer badge badge-pill text-white text-uppercase py-1 px-2" style="background-color: ${this.color}">${this.name}</span>`
  }

  get typeHtml(): string {
    return tagTypes[this.type]
  }

  get tooltip(): string {
    return this.description || this.name
  }

  get isDeletedHtml(): string {
    return checkHtml(this.isDeleted)
  }

  get discountsHtml(): string {
    return this.discounts.map((v) => v.titleHtml).join('<br>')
  }

  constructor(src: ITag | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: ITag) {
    Object.assign(this, src)

    this.initModel(src)
  }

  async load(): Promise<Tag> {
    try {
      this.discounts = await DiscountGet.byTagID(this.id)
    } catch (e) {
      await MainStore.addError(e as Error)
    }

    return this
  }

  static async loadList(list: List<Tag>): Promise<List<Tag>> {
    if (!list.count) return list

    const discounts = await DiscountGet.mapByTagIDs([
      ...new Set([...list.items.map((v) => v.id).filter((v) => v > 0)]),
    ])

    list.items.forEach((v) => {
      v.discounts = discounts.get(v.id) || []
    })

    return list
  }

  static async list(params: ObjectParams): Promise<List<Tag>> {
    const list: List<Tag> = { count: 0, items: [] }
    try {
      const l = await tagsApi.tags.list(params)
      list.count = l.count
      list.items = l.items.map((v) => new Tag(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return list
  }

  static async getByID(id: number): Promise<Tag> {
    let item = new Tag()
    try {
      item = new Tag(await tagsApi.tags.getByID(id))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return item
  }

  static async mapByIDs(ids: number[]): Promise<Map<number, Tag>> {
    const res = await Tag.getByIDs(ids)

    const pMap = new Map<number, Tag>()
    res.forEach((v) => pMap.set(v.id, new Tag(v)))

    return pMap
  }

  static async getByIDs(ids: number[]): Promise<Tag[]> {
    let items: Tag[] = []
    try {
      items = (await tagsApi.tags.getByIDs(ids)).map((v) => new Tag(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return items
  }

  static async getAll(): Promise<Tag[]> {
    let items: Tag[] = []
    try {
      items = (await tagsApi.tags.getAll({ sort: 'name', asc: true })).map((v) => new Tag(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return items
  }

  async save(): Promise<boolean> {
    try {
      return this.id ? await this.update() : await this.add()
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async add(): Promise<boolean> {
    try {
      this.init(await tagsApi.tags.add(this.toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async update(): Promise<boolean> {
    try {
      this.init(await tagsApi.tags.update(this.toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  toInterface(): ITag {
    return {
      id: this.id,
      name: this.name,
      type: this.type,
      description: this.description,
      color: this.color,
      isDeleted: this.isDeleted,
    }
  }
}
