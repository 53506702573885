import { PermissionGroup } from '@/utils/permissions'
import tagsServer from '@/permissions/tags_server'

const prefix = 'tags'

export const CAN_ACCESS_TAGS = prefix + '-access_module'

const users: PermissionGroup = {
  name: 'Tags Module',
  permissions: [
    {
      value: CAN_ACCESS_TAGS,
      description: CAN_ACCESS_TAGS,
    },
    ...tagsServer,
  ],
}

export default users
