import { CommonModel, IModel } from '@/models/Model'
import { Tag } from '@/models/tags/tag'
import { dateString } from '@/utils'

export interface IPersonTag extends IModel {
  id: number
  tagID: number
  personID: number
  expiryDate: Date | null
  tag: Tag | null
}

export class PersonTag extends CommonModel implements IPersonTag {
  id = 0
  tagID = 0
  personID = 0
  expiryDate: Date | null = null

  tag: Tag | null = null

  expiryDateForm = ''

  get tagHtml(): string {
    return this.tag ? this.tag.html : ''
  }

  constructor(src: IPersonTag | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: IPersonTag) {
    Object.assign(this, src)
    this.expiryDate = this.dateOrNull(src.expiryDate)
    this.tag = src.tag ? new Tag(src.tag) : null

    if (src instanceof PersonTag) {
      this.expiryDateForm = src.expiryDateForm
    } else {
      this.expiryDateForm = dateString(this.expiryDate)
    }

    this.initModel(src)
  }

  prepare(): PersonTag {
    this.expiryDate = this.expiryDateForm ? new Date(this.expiryDateForm) : null
    return this
  }

  toInterface(): IPersonTag {
    return {
      id: this.id,
      tagID: this.tagID,
      personID: this.personID,
      expiryDate: this.expiryDate,
      tag: null,
    }
  }
}
