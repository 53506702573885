import API, { MAX_LIMIT, paramsByIds } from '@/api/api'
import { IDiscount } from '@/models/discounts/Discount'
import { List } from '@/models/List'
import { ObjectParams } from '@/models/types'
import { Discounts } from '@/models/discounts/constants'
import { AllPersonDiscountsRequest } from '@/requests/discounts'

const URL_DISCOUNTS = '/discounts'
const URL_DISCOUNTS_PRICES = URL_DISCOUNTS + '/prices'
const URL_DISCOUNTS_PERSONS = URL_DISCOUNTS + '/persons'
const URL_DISCOUNTS_PERSONS_ALL = URL_DISCOUNTS_PERSONS + '/all'
const URL_DISCOUNTS_TAGS = URL_DISCOUNTS + '/tags'
const URL_DISCOUNTS_CONTRACTS = URL_DISCOUNTS + '/contracts'
const URL_DISCOUNTS_SUBSCRIPTIONS = URL_DISCOUNTS + '/subscriptions'

export const discountsApi = {
  discount: {
    getByID: async function (id: number): Promise<IDiscount> {
      const res = await API.get(API.buildURL(URL_DISCOUNTS + '/' + id))
      return (await res.json()) as Promise<IDiscount>
    },

    getByIDs: async function (IDs: number[]): Promise<IDiscount[]> {
      if (!IDs || !IDs.length) return []
      const res = await API.get(
        API.buildURL(URL_DISCOUNTS, { ...paramsByIds(IDs), limit: MAX_LIMIT })
      )
      return (await ((await res.json()) as Promise<List<IDiscount>>)).items
    },

    add: async function (person: IDiscount): Promise<IDiscount> {
      const res = await API.post(API.buildURL(URL_DISCOUNTS), person)
      return (await res.json()) as Promise<IDiscount>
    },

    update: async function (person: IDiscount): Promise<IDiscount> {
      const res = await API.put(API.buildURL(URL_DISCOUNTS), person)
      return (await res.json()) as Promise<IDiscount>
    },

    list: async function (params: ObjectParams): Promise<List<IDiscount>> {
      const res = await API.get(API.buildURL(URL_DISCOUNTS, { ...params }))
      return (await res.json()) as Promise<List<IDiscount>>
    },

    delete: async function (id: number): Promise<void> {
      const res = await API.delete(API.buildURL(URL_DISCOUNTS + '/' + id))
      if (!res.ok) {
        throw new Error(await res.json())
      }
    },
  },

  price: {
    getByID: async function (id: number): Promise<IDiscount> {
      const res = await API.get(API.buildURL(URL_DISCOUNTS_PRICES + '/' + id))
      return (await res.json()) as Promise<IDiscount>
    },

    getByIDs: async function (IDs: number[]): Promise<IDiscount[]> {
      if (!IDs || !IDs.length) return []
      const res = await API.get(
        API.buildURL(URL_DISCOUNTS_PRICES, { ...paramsByIds(IDs), limit: MAX_LIMIT })
      )
      return ((await res.json()) as Promise<IDiscount[]>) || []
    },
  },

  person: {
    getByID: async function (id: number): Promise<IDiscount[]> {
      const res = await API.get(API.buildURL(URL_DISCOUNTS_PERSONS + '/' + id))
      return ((await res.json()) as Promise<IDiscount[]>) || []
    },

    getByIDs: async function (IDs: number[]): Promise<Discounts> {
      if (!IDs || !IDs.length) return {}
      const res = await API.get(
        API.buildURL(URL_DISCOUNTS_PERSONS, { ...paramsByIds(IDs), limit: MAX_LIMIT })
      )
      return (await res.json()) as Promise<Discounts>
    },

    all: async function (request: AllPersonDiscountsRequest): Promise<IDiscount[]> {
      const res = await API.post(API.buildURL(URL_DISCOUNTS_PERSONS_ALL), request)
      return (await res.json()) as Promise<IDiscount[]>
    },
  },

  tag: {
    getByID: async function (id: number): Promise<IDiscount[]> {
      const res = await API.get(API.buildURL(URL_DISCOUNTS_TAGS + '/' + id))
      return ((await res.json()) as Promise<IDiscount[]>) || []
    },

    getByIDs: async function (IDs: number[]): Promise<Discounts> {
      if (!IDs || !IDs.length) return {}
      const res = await API.get(
        API.buildURL(URL_DISCOUNTS_TAGS, { ...paramsByIds(IDs), limit: MAX_LIMIT })
      )
      return (await res.json()) as Promise<Discounts>
    },
  },

  contract: {
    getByID: async function (id: number): Promise<IDiscount[]> {
      const res = await API.get(API.buildURL(URL_DISCOUNTS_CONTRACTS + '/' + id))
      return ((await res.json()) as Promise<IDiscount[]>) || []
    },

    getByIDs: async function (IDs: number[]): Promise<Discounts> {
      if (!IDs || !IDs.length) return {}
      const res = await API.get(
        API.buildURL(URL_DISCOUNTS_CONTRACTS, { ...paramsByIds(IDs), limit: MAX_LIMIT })
      )
      return (await res.json()) as Promise<Discounts>
    },
  },

  subscription: {
    getByID: async function (id: number): Promise<IDiscount[]> {
      const res = await API.get(API.buildURL(URL_DISCOUNTS_SUBSCRIPTIONS + '/' + id))
      return ((await res.json()) as Promise<IDiscount[]>) || []
    },

    getByIDs: async function (IDs: number[]): Promise<Discounts> {
      if (!IDs || !IDs.length) return {}
      const res = await API.get(
        API.buildURL(URL_DISCOUNTS_SUBSCRIPTIONS, { ...paramsByIds(IDs), limit: MAX_LIMIT })
      )
      return (await res.json()) as Promise<Discounts>
    },
  },
}
