
import { Component, Vue } from 'vue-property-decorator'
import { MainStore } from '@/store/MainStore'

@Component
export default class LoadPage extends Vue {
  get isLoading(): boolean {
    return MainStore.isLoading()
  }
}
