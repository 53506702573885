import { PermissionGroup } from '@/utils/permissions'

const prefix = 'equipment-settings'

export const CAN_ACCESS_EQUIPMENT_SETTINGS = prefix + '-access_module'

const equipmentSettings: PermissionGroup = {
  name: 'Equipment Module',
  permissions: [
    {
      value: CAN_ACCESS_EQUIPMENT_SETTINGS,
      description: CAN_ACCESS_EQUIPMENT_SETTINGS,
    },
  ],
}

export default equipmentSettings
