
import { Component, Vue } from 'vue-property-decorator'
import { ROUTE_INSTALLATION_QUICK_FORM, ROUTE_INSTALLATIONS } from '@/router/subscriptions'
import { ROUTE_SERVICE_CALENDAR } from '@/router/tickets'
import { ROUTE_INTERNET_SESSIONS_ERRORS } from '@/router/internet'

@Component({
  components: {},
})
export default class DashboardShortcuts extends Vue {
  ROUTE_INSTALLATION_QUICK_FORM = ROUTE_INSTALLATION_QUICK_FORM
  ROUTE_SERVICE_CALENDAR = ROUTE_SERVICE_CALENDAR
  ROUTE_INSTALLATIONS = ROUTE_INSTALLATIONS
  ROUTE_INTERNET_SESSIONS_ERRORS = ROUTE_INTERNET_SESSIONS_ERRORS
}
