import { RouteConfig } from 'vue-router'
import { CAN_ACCESS_USERS } from '@/permissions/users'
import { ROUTER_PARAM_ID } from '@/utils/constants'

export const ROUTE_USERS = 'ROUTE_USERS'
export const ROUTE_USER_FORM = 'ROUTE_USER_FORM'
export const ROUTE_USER_VIEW = 'ROUTE_USER_VIEW'
export const ROUTE_ROLES = 'ROUTE_ROLES'
export const ROUTE_ROLE_FORM = 'ROUTE_ROLE_FORM'
export const ROUTE_ROLE_VIEW = 'ROUTE_ROLE_VIEW'

const usersRoutes: RouteConfig[] = [
  {
    name: ROUTE_USERS,
    path: '/users',
    meta: { permission: CAN_ACCESS_USERS },
    component: () => import(/* webpackChunkName: "users" */ '../components/users/UserList.vue'),
    props: true,
  },
  {
    name: ROUTE_USER_FORM,
    path: `/users/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: CAN_ACCESS_USERS },
    component: () => import(/* webpackChunkName: "users" */ '../components/users/UserEdit.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
  {
    name: ROUTE_USER_VIEW,
    path: `/users/:${ROUTER_PARAM_ID}(\\d+)`,
    meta: { permission: CAN_ACCESS_USERS },
    component: () => import(/* webpackChunkName: "users" */ '../components/users/UserView.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
  {
    name: ROUTE_ROLES,
    path: `/users/roles`,
    meta: { permission: CAN_ACCESS_USERS },
    component: () => import(/* webpackChunkName: "users" */ '../components/users/RoleList.vue'),
    props: true,
  },
  {
    name: ROUTE_ROLE_FORM,
    path: `/users/roles/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: CAN_ACCESS_USERS },
    component: () => import(/* webpackChunkName: "users" */ '../components/users/RoleEdit.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
  {
    name: ROUTE_ROLE_VIEW,
    path: `/users/roles/:${ROUTER_PARAM_ID}(\\d+)`,
    meta: { permission: CAN_ACCESS_USERS },
    component: () => import(/* webpackChunkName: "users" */ '../components/users/RoleView.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
]

export default usersRoutes
