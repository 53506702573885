import { RouteConfig } from 'vue-router'
import { CAN_ACCESS_SEARCH } from '@/permissions/search'

export const ROUTE_PERSON_SEARCHER = 'ROUTE_PERSON_SEARCHER'
export const ROUTE_EVENT_LOG = 'ROUTE_EVENT_LOG'

const searcherRoutes: RouteConfig[] = [
  {
    name: ROUTE_PERSON_SEARCHER,
    path: '/person-searcher',
    meta: { permission: CAN_ACCESS_SEARCH },
    component: () =>
      import(/* webpackChunkName: "searcher" */ '../components/searcher/Searcher.vue'),
    props: (route) => ({ query: route.query.q }),
  },

  {
    name: ROUTE_EVENT_LOG,
    path: '/event-log',
    meta: { permission: CAN_ACCESS_SEARCH },
    component: () =>
      import(/* webpackChunkName: "searcher" */ '../components/searcher/EventLogList.vue'),
  },
]

export default searcherRoutes
