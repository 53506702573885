import API, { MAX_LIMIT, paramsByIds } from '@/api/api'
import { IPersonTags } from '@/models/tags/person_tags'
import { ITag } from '@/models/tags/tag'
import { List } from '@/models/List'
import { ObjectParams } from '@/models/types'

const URL_TAGS_MODULE = '/tags'

const URL_TAGS = URL_TAGS_MODULE + '/tags'
const URL_PERSON_TAGS = URL_TAGS_MODULE + '/person-tags'

export const tagsApi = {
  tags: {
    list: async function (params: ObjectParams): Promise<List<ITag>> {
      const res = await API.get(API.buildURL(URL_TAGS, { ...params }))
      return (await res.json()) as Promise<List<ITag>>
    },

    getByID: async function (id: number): Promise<ITag> {
      const res = await API.get(API.buildURL(URL_TAGS + '/' + id))
      return (await res.json()) as Promise<ITag>
    },

    getByIDs: async function (IDs: number[]): Promise<ITag[]> {
      if (!IDs || !IDs.length) return []
      const res = await API.get(API.buildURL(URL_TAGS, { ...paramsByIds(IDs), limit: MAX_LIMIT }))
      return (await ((await res.json()) as Promise<List<ITag>>)).items
    },

    getAll: async function (params: ObjectParams = {}): Promise<ITag[]> {
      const items = await this.list({ ...params, limit: MAX_LIMIT })
      return items.items
    },

    add: async function (tag: ITag): Promise<ITag> {
      const res = await API.post(API.buildURL(URL_TAGS), tag)
      return (await res.json()) as Promise<ITag>
    },

    update: async function (tag: ITag): Promise<ITag> {
      const res = await API.put(API.buildURL(URL_TAGS), tag)
      return (await res.json()) as Promise<ITag>
    },
  },

  personTags: {
    getByPersonID: async function (id: number): Promise<IPersonTags> {
      const res = await API.get(API.buildURL(URL_PERSON_TAGS + '/' + id))
      return (await res.json()) as Promise<IPersonTags>
    },

    getByPersonIDs: async function (IDs: number[]): Promise<IPersonTags[]> {
      if (!IDs || !IDs.length) return []
      const res = await API.get(
        API.buildURL(URL_PERSON_TAGS, { ...paramsByIds(IDs, 'personIDs'), limit: MAX_LIMIT })
      )
      return (await ((await res.json()) as Promise<List<IPersonTags>>)).items
    },

    list: async function (params: ObjectParams): Promise<List<IPersonTags>> {
      const res = await API.get(API.buildURL(URL_PERSON_TAGS, { ...params }))
      return (await res.json()) as Promise<List<IPersonTags>>
    },

    replace: async function (req: IPersonTags): Promise<IPersonTags> {
      const res = await API.put(API.buildURL(URL_PERSON_TAGS), req)
      return (await res.json()) as Promise<IPersonTags>
    },
  },
}
