import { uniqueNonZeroNums } from '@/utils/unique_nums'
import { Nas } from '@/models/internet/Nas'
import { IpPoolGet } from '@/models/internet/IpPoolGet'
import { IpPoolType } from '@/models/internet/constants'

export class NasLoader {
  private readonly items: Nas[] = []

  constructor(items: Nas | Nas[]) {
    this.items = items instanceof Nas ? [items] : [...items]
  }

  load = async (...loaders: Array<Exclude<keyof NasLoader, 'load'>>): Promise<void> => {
    await Promise.all(loaders.map((method) => this[method]()))
  }

  ipPools = async (): Promise<void> => {
    const ids = uniqueNonZeroNums(this.items.map((v) => v.id))
    if (!ids.length) {
      return
    }

    const items = await IpPoolGet.mapByNasIDs(ids, [IpPoolType.Standard])

    this.items.forEach((v) => {
      v.ipPools = items.get(v.id) || []
    })
  }
}
