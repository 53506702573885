// This file is generated automatically, do not make manual changes to it.
// generating script: shell/generate_permissions.go

export const CAN_DELETE_IP_POOL = 'CanDeleteIPPool'
export const CAN_DELETE_IP_POOL_EXPANDED = 'CanDeleteIPPoolExpanded'
export const CAN_DELETE_INTERNET_SESSION = 'CanDeleteInternetSession'
export const CAN_DELETE_NAS = 'CanDeleteNAS'
export const CAN_EDIT_IP_POOL = 'CanEditIPPool'
export const CAN_EDIT_IP_POOL_EXPANDED = 'CanEditIPPoolExpanded'
export const CAN_EDIT_INTERNET_SESSION = 'CanEditInternetSession'
export const CAN_EDIT_NAS = 'CanEditNAS'
export const CAN_VIEW_IP_POOL = 'CanViewIPPool'
export const CAN_VIEW_IP_POOL_EXPANDED = 'CanViewIPPoolExpanded'
export const CAN_VIEW_IP_POOL_RESERVATION = 'CanViewIPPoolReservation'
export const CAN_VIEW_INTERNET_SESSION = 'CanViewInternetSession'
export const CAN_VIEW_INTERNET_SESSION_DETAIL = 'CanViewInternetSessionDetail'
export const CAN_VIEW_INTERNET_SESSION_ERROR = 'CanViewInternetSessionError'
export const CAN_VIEW_NAS = 'CanViewNAS'

const internetServer = [
  {
    value: CAN_DELETE_IP_POOL,
    description: CAN_DELETE_IP_POOL,
  },
  {
    value: CAN_DELETE_IP_POOL_EXPANDED,
    description: CAN_DELETE_IP_POOL_EXPANDED,
  },
  {
    value: CAN_DELETE_INTERNET_SESSION,
    description: CAN_DELETE_INTERNET_SESSION,
  },
  {
    value: CAN_DELETE_NAS,
    description: CAN_DELETE_NAS,
  },
  {
    value: CAN_EDIT_IP_POOL,
    description: CAN_EDIT_IP_POOL,
  },
  {
    value: CAN_EDIT_IP_POOL_EXPANDED,
    description: CAN_EDIT_IP_POOL_EXPANDED,
  },
  {
    value: CAN_EDIT_INTERNET_SESSION,
    description: CAN_EDIT_INTERNET_SESSION,
  },
  {
    value: CAN_EDIT_NAS,
    description: CAN_EDIT_NAS,
  },
  {
    value: CAN_VIEW_IP_POOL,
    description: CAN_VIEW_IP_POOL,
  },
  {
    value: CAN_VIEW_IP_POOL_EXPANDED,
    description: CAN_VIEW_IP_POOL_EXPANDED,
  },
  {
    value: CAN_VIEW_IP_POOL_RESERVATION,
    description: CAN_VIEW_IP_POOL_RESERVATION,
  },
  {
    value: CAN_VIEW_INTERNET_SESSION,
    description: CAN_VIEW_INTERNET_SESSION,
  },
  {
    value: CAN_VIEW_INTERNET_SESSION_DETAIL,
    description: CAN_VIEW_INTERNET_SESSION_DETAIL,
  },
  {
    value: CAN_VIEW_INTERNET_SESSION_ERROR,
    description: CAN_VIEW_INTERNET_SESSION_ERROR,
  },
  {
    value: CAN_VIEW_NAS,
    description: CAN_VIEW_NAS,
  },
]

export default internetServer
