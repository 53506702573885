import { Waybill } from '@/models/stock/Waybill'
import { Equipment } from '@/models/stock/Equipment'
import { EquipmentStatus, equipmentStatuses } from '@/models/stock/constants'

export interface WaybillFromFiles {
  savedWaybill: Waybill
  brokenItems: BrokenItem[]
}

export function emptyUpdatedEquipment(): UpdatedEquipment {
  return {
    brokenItems: [],
    searchedItems: [],
  }
}

export interface UpdatedEquipment {
  brokenItems: BrokenItem[]
  searchedItems: SearchedItem[]
}

export interface BrokenItem {
  index: number
  equipment?: Equipment
  reason: string
}

export interface SearchedItem {
  index: number
  equipment: Equipment
}

export class BalanceByModel {
  status = EquipmentStatus.Unknown
  balance = 0

  constructor(src: BalanceByModel | null = null) {
    if (src) {
      Object.assign(this, src)
    }
  }

  get statusStr(): string {
    return equipmentStatuses[this.status]
  }
}
