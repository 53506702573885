import { EnumNames, ObjectParams, Searcher } from '@/models/types'

export enum EquipmentStatus {
  Unknown = 0,
  TakenToStock = 1,
  InProcessOfSetting = 2,
  InProcessOfRepair = 3,
  ReadyToIssue = 4,
  Issued = 5,
  ReturnedToSupplier = 6,
  Basura = 7,
  Lost = 8,
  ReceiveFromSupplier = 9,
  ReceiveFromPerson = 10,
  Reserved = 11,
  Installed = 12,
}

export const equipmentStatuses: EnumNames = {
  [EquipmentStatus.TakenToStock]: 'Taken to stock',
  [EquipmentStatus.InProcessOfSetting]: 'In process of setting',
  [EquipmentStatus.InProcessOfRepair]: 'In process of repair',
  [EquipmentStatus.ReadyToIssue]: 'Ready to issue',
  [EquipmentStatus.Issued]: 'Issued',
  [EquipmentStatus.ReturnedToSupplier]: 'Returned to supplier',
  [EquipmentStatus.Basura]: 'Basura',
  [EquipmentStatus.Lost]: 'Lost',
  [EquipmentStatus.ReceiveFromSupplier]: 'Receiving from suppliers',
  [EquipmentStatus.ReceiveFromPerson]: 'Receiving from persons',
  [EquipmentStatus.Reserved]: 'Reserved',
  [EquipmentStatus.Installed]: 'Installed',
}

export const equipmentStatusesFromReturn = [
  EquipmentStatus.InProcessOfRepair,
  EquipmentStatus.ReadyToIssue,
  EquipmentStatus.Basura,
]

export const equipmentStatusesFromSetup = [
  EquipmentStatus.TakenToStock,
  EquipmentStatus.ReadyToIssue,
  EquipmentStatus.Lost,
]

export function equipmentStatusesToSearcher(statuses: EquipmentStatus[]): Searcher {
  return new Searcher(
    statuses.reduce((acc: ObjectParams, v) => {
      acc[v] = equipmentStatuses[v]
      return acc
    }, {})
  )
}

export enum WaybillOwnerType {
  Provider = 1,
  Person,
}

export const waybillOwnerTypes: EnumNames = {
  [WaybillOwnerType.Provider]: 'Provider',
  [WaybillOwnerType.Person]: 'Person',
}

export enum WaybillStatus {
  Prepared = 1,
  Issued = 2,
  ReturnedToStock = 3,
  Income = 4,
}

export const waybillStatuses: EnumNames = {
  [WaybillStatus.Prepared]: 'Prepared',
  [WaybillStatus.Issued]: 'Issued',
  [WaybillStatus.ReturnedToStock]: 'Returned to stock',
  [WaybillStatus.Income]: 'Income',
}

export enum WaybillType {
  ReceiveNewFromFile = 1,
}

export const waybillTypes: EnumNames = {
  [WaybillType.ReceiveNewFromFile]: 'ReceiveNewFromFile',
}

export enum WaybillItemType {
  Equipment = 1,
  Consumable,
}

export const waybillItemTypes: EnumNames = {
  [WaybillItemType.Equipment]: 'Equipment',
  [WaybillItemType.Consumable]: 'Consumable',
}

export enum TypeKind {
  EquipmentModel = 1,
  Consumable,
}

export const TypeKinds: EnumNames = {
  [TypeKind.EquipmentModel]: 'Equipment model',
  [TypeKind.Consumable]: 'Consumable',
}

export const enum Unit {
  Piece = 'piece',
  Meter = 'm.',
  SquareMeter = 'sq.m.',
  CubicMeter = 'cbm',
  Centimeter = 'cm.',
  Millimeter = 'mm.',
  Kilogram = 'kg.',
  Gram = 'g.',
}

export const Units = [
  Unit.Piece,
  Unit.Meter,
  Unit.SquareMeter,
  Unit.CubicMeter,
  Unit.Centimeter,
  Unit.Millimeter,
  Unit.Kilogram,
  Unit.Gram,
]

export const enum MovementReason {
  Unknown = 0,
  NewInstallation = 1,
  ChangeFaulty = 2,
  ChangeImprove = 3,
  ReplaceBroken = 4,
  Cancellation = 5,
  IssuedToInstaller = 6,
  ReturnedUnused = 7,
  StockCorrection = 8,
}

export const movementReasons: EnumNames = {
  [MovementReason.NewInstallation]: 'New Installation',
  [MovementReason.ChangeFaulty]: 'Change Faulty',
  [MovementReason.ChangeImprove]: 'Change Improve',
  [MovementReason.ReplaceBroken]: 'Replace Broken',
  [MovementReason.Cancellation]: 'Cancellation',
  [MovementReason.IssuedToInstaller]: 'Issued To Installer',
  [MovementReason.ReturnedUnused]: 'Returned Unused',
  [MovementReason.StockCorrection]: 'Stock Correction',
}

export const enum MovementType {
  Unknown = 0,
  ToContract = 1,
  ToStock = 2,
}

export const movementTypes: EnumNames = {
  [MovementType.ToContract]: 'To Contract',
  [MovementType.ToStock]: 'To Stock',
}
