import { SettingType, settingTypes } from '@/models/service/constants'
import { Service } from '@/models/service/Service'

export class EquipmentService {
  equipmentID = 0
  serviceID = 0
  settingType = SettingType.Unknown

  // after load
  service: Service | null = null

  constructor(src: EquipmentService | null = null) {
    if (src) {
      Object.assign(this, src)

      this.service = src.service ? new Service(src.service) : null
    }
  }

  get settingTypeName(): string {
    return settingTypes[this.settingType]
  }
}
