import { ChartConfiguration } from 'chart.js'
import { CHART_COLORS, MONTHS } from '@/utils/chart'
import { bankFailsReport } from '@/components/dashboards/models/PaymentReport'
import { Money } from '@/models/Money'

export function chartBankFails(data: bankFailsReport): ChartConfiguration {
  const res: number[] = []

  Object.entries(data).forEach((v) => {
    res.push(new Money(v[1].amount).value)
  })

  return {
    type: 'bar',
    data: {
      labels: [...MONTHS],
      datasets: [
        {
          label: 'Amount',
          data: [...res],
          borderColor: CHART_COLORS.Red.main,
          backgroundColor: CHART_COLORS.Red.gradient,
          borderWidth: 1,
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          align: 'start',
          text: 'Amounts of bank fails',
          font: {
            size: 14,
            family: 'tahoma',
            weight: 'bold',
            style: 'italic',
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.dataset.label || ''

              if (label) {
                label += ': '
              }
              if (context.parsed.y !== null) {
                label += new Intl.NumberFormat('es-ES', {
                  style: 'currency',
                  currency: 'EUR',
                }).format(context.parsed.y)
              }
              return label
            },
          },
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          stacked: true,
          ticks: {
            callback: function (value) {
              return value.toLocaleString('es-ES', { style: 'currency', currency: 'EUR' })
            },
          },
        },
      },
    },
  }
}
