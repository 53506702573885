import { RouteConfig } from 'vue-router/types/router'
import { ROUTER_PARAM_ID } from '@/utils/constants'
import { CAN_ACCESS_PRODUCTS } from '@/permissions/products'

export const ROUTE_PRODUCTS = 'ROUTE_PRODUCTS'
export const ROUTE_PRODUCT_FORM = 'ROUTE_PRODUCT_FORM'
export const ROUTE_PRODUCT_GROUPS = 'ROUTE_PRODUCT_GROUPS'
export const ROUTE_PRODUCT_GROUPS_FORM = 'ROUTE_PRODUCT_GROUPS_FORM'

const productRoutes: RouteConfig[] = [
  {
    name: ROUTE_PRODUCTS,
    path: '/products',
    meta: { permission: CAN_ACCESS_PRODUCTS },
    component: () =>
      import(/* webpackChunkName: "products" */ '../components/products/ProductList.vue'),
  },
  {
    name: ROUTE_PRODUCT_FORM,
    path: `/products/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: CAN_ACCESS_PRODUCTS },
    component: () =>
      import(/* webpackChunkName: "products" */ '../components/products/ProductEdit.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },

  {
    name: ROUTE_PRODUCT_GROUPS,
    path: '/product-groups',
    meta: { permission: CAN_ACCESS_PRODUCTS },
    component: () =>
      import(/* webpackChunkName: "productGroups" */ '../components/products/GroupList.vue'),
  },
  {
    name: ROUTE_PRODUCT_GROUPS_FORM,
    path: `/product-groups/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: CAN_ACCESS_PRODUCTS },
    component: () =>
      import(/* webpackChunkName: "productGroups" */ '../components/products/GroupEdit.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
]

export default productRoutes
