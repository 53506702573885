import { ChartConfiguration } from 'chart.js'
import { CHART_COLORS, MONTHS } from '@/utils/chart'
import { bankFailsReport } from '@/components/dashboards/models/PaymentReport'

export function chartBankFailsCounts(data: bankFailsReport): ChartConfiguration {
  const res: number[] = []

  Object.entries(data).forEach((v) => {
    res.push(v[1].count)
  })

  return {
    type: 'bar',
    data: {
      labels: [...MONTHS],
      datasets: [
        {
          label: 'Count',
          data: [...res],
          borderColor: CHART_COLORS.Orange.main,
          backgroundColor: CHART_COLORS.Orange.gradient,
          borderWidth: 1,
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          align: 'start',
          text: 'Number of bank fails',
          font: {
            size: 14,
            family: 'tahoma',
            weight: 'bold',
            style: 'italic',
          },
        },
      },
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    },
  }
}
