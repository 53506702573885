import { Module } from 'vuex'
import getters from '@/store/modules/geo/getters'
import mutations from '@/store/modules/geo/mutations'
import actions from '@/store/modules/geo/actions'
import { RootState } from '@/store'

export class GeoState {
  invalidCounters = new InvalidCounters()
}

class InvalidCounters {
  personAddresses = 0
  countries = 0
  provinces = 0
  municipalities = 0
  communities = 0
  districts = 0
  streets = 0
  urbanizations = 0

  get all(): number {
    return (
      this.countries +
      this.provinces +
      this.municipalities +
      this.communities +
      this.districts +
      this.streets +
      this.urbanizations
    )
  }
}

export const moduleGeo: Module<GeoState, RootState> = {
  state: () => new GeoState(),
  getters,
  actions,
  mutations,
}
