import { CommonModel, IModel } from '@/models/Model'
import { MainStore } from '@/store/MainStore'
import { personsApi } from '@/api/persons-api'

export interface IPersonWhence extends IModel {
  id: number
  title: string
}

export class PersonWhence extends CommonModel implements IPersonWhence {
  id = 0
  title = ''

  constructor(src: IPersonWhence | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: IPersonWhence) {
    Object.assign(this, src)

    this.initModel(src)
  }

  async save(): Promise<boolean> {
    try {
      return this.id ? await this.update() : await this.add()
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async add(): Promise<boolean> {
    try {
      this.init(await personsApi.whence.add(this.toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async update(): Promise<boolean> {
    try {
      this.init(await personsApi.whence.update(this.toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async delete(): Promise<boolean> {
    if (!confirm(`Are you sure you want to delete PersonWhence: ${this.title}?`)) {
      return false
    }

    try {
      await personsApi.whence.delete(this.id)
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  toInterface(): IPersonWhence {
    return {
      id: this.id,
      title: this.title,
    }
  }
}
