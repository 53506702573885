import { CommonModel, IModel } from '@/models/Model'
import { MainStore } from '@/store/MainStore'
import { geoApi } from '@/api/geo-api'
import { IMunicipality, Municipality } from '@/models/geo/Municipality'
import { District, IDistrict } from '@/models/geo/District'
import { coloringName } from '@/models/geo/utils'
import { GeoStatus, isValid, isVerified, statusHtml } from '@/models/geo/constants'

export interface IUrbanization extends IModel {
  id: number
  name: string
  latitude: number
  longitude: number
  municipalityID: number
  districtID?: number
  status: GeoStatus
  weight: number
  district: IDistrict | null
  municipality: IMunicipality | null
}

export class Urbanization extends CommonModel implements IUrbanization {
  id = 0
  name = ''
  latitude = 0
  longitude = 0
  municipalityID = 0
  districtID?: number
  status: GeoStatus = 0
  weight = 0
  district: District | null = null
  municipality: Municipality | null = null

  get isValidForm(): boolean {
    return (
      Boolean(this.id) ||
      Boolean(
        this.isSetFormValues &&
          this.municipality &&
          this.municipality.isValidForm &&
          (this.district ? this.district.isValidForm : true)
      )
    )
  }

  get isSetFormValues(): boolean {
    return !!this.name
  }

  get isValid(): boolean {
    return isValid(this.status)
  }

  get isVerified(): boolean {
    return isVerified(this.status)
  }

  get statusHtml(): string {
    return statusHtml(this.status)
  }

  get nameStr(): string {
    return this.name
  }

  get titleHtml(): string {
    return coloringName(this)
  }

  constructor(src: IUrbanization | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: IUrbanization) {
    Object.assign(this, src)
    this.municipality = src.municipality ? new Municipality(src.municipality) : null
    this.district = src.district ? new District(src.district) : null

    this.initModel(src)
  }

  async save(): Promise<boolean> {
    try {
      return this.id ? await this.update() : await this.add()
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async add(): Promise<boolean> {
    try {
      this.init(await geoApi.urbanization.add(this.prepare().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async update(): Promise<boolean> {
    try {
      this.init(await geoApi.urbanization.update(this.prepare().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async delete(): Promise<boolean> {
    if (!confirm(`Are you sure you want to delete urbanization?`)) {
      return false
    }

    try {
      await geoApi.urbanization.delete(this.id)
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async approve(): Promise<boolean> {
    if (!confirm(`Are you sure you want to confirm the entry?`)) {
      return false
    }

    try {
      this.init(await geoApi.urbanization.approve({ id: this.id }))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async reject(): Promise<boolean> {
    if (!confirm(`Are you sure you want to reject the entry?`)) {
      return false
    }

    try {
      this.init(await geoApi.urbanization.reject({ id: this.id }))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  prepare(): Urbanization {
    this.municipalityID = this.municipality ? this.municipality.id : 0
    this.districtID = this.district ? this.district.id : undefined

    return this
  }

  toInterface(): IUrbanization {
    return {
      id: this.id,
      name: this.name,
      latitude: this.latitude,
      longitude: this.longitude,
      municipalityID: this.municipalityID,
      districtID: this.districtID,
      status: this.status,
      weight: this.weight,
      district: this.district ? this.district.toInterface() : null,
      municipality: this.municipality ? this.municipality.toInterface() : null,
    }
  }
}
