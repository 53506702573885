export class Boxes {
  contractsCreated = 0
  contractsCanceled = 0

  clientsCreated = 0
  clientsCanceled = 0

  installsCreated = 0
  installsDone = 0

  ticketsCreated = 0
  ticketsClosed = 0

  allTicketsSteelOpen = 0

  constructor(src: Boxes | null = null) {
    if (src) {
      this.init(src)
    }
  }

  private init(src: Boxes) {
    Object.assign(this, src)
  }

  boxes(): Box[] {
    return [
      {
        title: 'Contracts',
        subTitle: 'New / Canceled',
        counter: `${this.contractsCreated}/${this.contractsCanceled}`,
        icon: 'fas fa-users',
        tooltip: 'Created contracts / Canceled clients this month',
      },
      {
        title: 'Clients',
        subTitle: 'New / Canceled',
        counter: `${this.clientsCreated}/${this.clientsCanceled}`,
        icon: 'fas fa-users',
        tooltip: 'Created contracts / Canceled clients this month',
      },
      {
        title: 'Install tickets',
        subTitle: 'New / Done',
        counter: `${this.installsCreated}/${this.installsDone}`,
        icon: 'fas fa-clipboard-list',
        tooltip: 'Created / Done Install tickets this month',
      },
      {
        title: 'Service tickets',
        subTitle: 'Created / Open / Closed',
        counter: `${this.ticketsCreated}/${this.allTicketsSteelOpen}/${this.ticketsClosed}`,
        icon: 'fas fa-server',
        tooltip: 'Created / Open / Close Service tickets this month',
      },
    ]
  }
}

export interface Box {
  title: string
  subTitle: string
  counter: string
  icon: string
  tooltip: string
}
