export function shortenString(str: string, last = 0): string {
  return last == 0 || str.length <= last ? str : '...' + str.substr(str.length - last)
}

export function parseBoolean(value: unknown): boolean {
  const v = typeof value == 'string' ? value.toLowerCase() : value

  switch (v) {
    case true:
    case 'true':
    case 1:
    case '1':
    case 'on':
    case 'yes':
      return true

    case false:
    case 'false':
    case 0:
    case '0':
    case 'off':
    case 'no':
      return false

    default:
      return Boolean(value)
  }
}

export function startCase(str: string): string {
  return str.length === 0 ? str : str.substring(0, 1).toUpperCase() + str.substring(1)
}
