import { CommonModel, IModel } from '@/models/Model'
import { MainStore } from '@/store/MainStore'
import { internetApi } from '@/api/internet-api'
import { IpPoolType, ipPoolTypes, IPProtocol } from '@/models/internet/constants'
import { Nas } from '@/models/internet/Nas'

export interface IIpPool extends IModel {
  id: number
  nasID: number
  name: string
  type: IpPoolType
  protocol: IPProtocol
  start: string
  end: string
  priority: number
}

export class IpPool extends CommonModel implements IIpPool {
  id = 0
  nasID = 0
  name = ''
  type = IpPoolType.Unknown
  protocol = IPProtocol.Unknown
  start = ''
  end = ''
  priority = 0

  // after load
  nas: Nas | null = null
  countIP = 0
  activeSessions = 0

  constructor(src: IIpPool | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: IIpPool) {
    Object.assign(this, src)

    this.initModel(src)
  }

  get nameWithId(): string {
    return `(${this.id}) ${this.name}`
  }

  get nasName(): string {
    return this.nas ? this.nas.name : ''
  }

  get typeName(): string {
    return ipPoolTypes[this.type]
  }

  get sessionStat(): string {
    return `${this.activeSessions} / ${this.countIP}`
  }

  get ipRange(): string {
    return this.start + '_' + this.end
  }

  nasViewLinkHtml(target = '_self'): string {
    return this.nas ? this.nas.viewLinkHtml(target) : ''
  }

  async save(): Promise<boolean> {
    try {
      return this.id ? await this.update() : await this.add()
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async add(): Promise<boolean> {
    try {
      this.init(await internetApi.ipPool.add(this.prepare().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async update(): Promise<boolean> {
    try {
      this.init(await internetApi.ipPool.update(this.prepare().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async delete(): Promise<boolean> {
    if (!confirm(`Are you sure you want to delete NAS: ${this.name}`)) {
      return false
    }

    try {
      await internetApi.ipPool.delete(this.id)
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  prepare(): IpPool {
    this.nasID = this.nas ? this.nas.id : this.nasID

    return this
  }

  toInterface(): IIpPool {
    return {
      id: this.id,
      nasID: this.nasID,
      name: this.name,
      type: this.type,
      protocol: this.protocol,
      start: this.start,
      end: this.end,
      priority: this.priority,
    }
  }
}
