import { RouteConfig } from 'vue-router/types/router'
import { CAN_ACCESS_EQUIPMENT_SETTINGS } from '@/permissions/equipment-settings'

export const ROUTE_SETUP_EQUIPMENTS = 'ROUTE_SETUP_EQUIPMENTS'

const equipmentSettingsRoutes: RouteConfig[] = [
  {
    name: ROUTE_SETUP_EQUIPMENTS,
    path: '/settings/equipments-setup',
    meta: { permission: CAN_ACCESS_EQUIPMENT_SETTINGS },
    component: () =>
      import(
        /* webpackChunkName: "equipments" */ '../components/stock/equipment_settings/SetupEquipments.vue'
      ),
  },
]

export default equipmentSettingsRoutes
