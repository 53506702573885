import { MainStore } from '@/store/MainStore'
import { internetApi } from '@/api/internet-api'
import { dateOrNull, formatDateTime } from '@/utils'
import { Service } from '@/models/service/Service'
import { contractToPersonRedirectHtml } from '@/models/persons/views'
import { InternetSessionDetail } from '@/models/internet/InternetSessionDetail'
import { IInternetData, InternetData } from '@/models/internet/InternetData'
import { formatTime } from '@/utils/Converter'
import router from '@/router'
import { ROUTE_STOCK_EQUIPMENT_VIEW_BY_PPPOE_LOGIN } from '@/router/stock'
import { Equipment } from '@/models/stock/Equipment'

export interface IInternetSession extends IInternetData {
  id: number
  zap: boolean
  acctSessionID: string | null
  serviceID: number | null
  duration: number | null
  terminateCause: string | null
  note: string
  start: Date | null
  stop: Date | null
  lastUpdate: Date | null
  expired: boolean
}

export class InternetSession extends InternetData implements IInternetSession {
  id = 0
  zap = false
  acctSessionID: string | null = null
  serviceID: number | null = null
  duration: number | null = null
  terminateCause: string | null = null
  note = ''
  start: Date | null = null
  stop: Date | null = null
  lastUpdate: Date | null = null
  expired = false

  // after load
  service: Service | null = null
  details: InternetSessionDetail[] = []
  equipmentWithoutService: Equipment | null = null

  constructor(src: IInternetSession | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: IInternetSession) {
    Object.assign(this, src)

    this.start = dateOrNull(src.start)
    this.stop = dateOrNull(src.stop)
    this.lastUpdate = dateOrNull(src.lastUpdate)

    this.initParent(src)
  }

  get toPersonRedirectHtml(): string {
    return this.service
      ? contractToPersonRedirectHtml(this.service.contractID)
      : this.equipmentWithoutService
      ? contractToPersonRedirectHtml(this.equipmentWithoutService.contractID)
      : ''
  }

  get isActive(): boolean {
    return Boolean(this.id && !this.stop)
  }

  get serviceHtml(): string {
    return (
      this.service ? `${this.service.id} ${this.service.titleHtml} ${this.service.note}` : ''
    ).trim()
  }

  get startHtml(): string {
    return formatDateTime(this.start)
  }

  get stopHtml(): string {
    return formatDateTime(this.stop)
  }

  get lastUpdateHtml(): string {
    return formatDateTime(this.lastUpdate)
  }

  get durationStr(): string {
    return formatTime(this.duration || 0) || '0'
  }

  get statusHtml(): string {
    const title = this.isActive ? 'Online' : 'Offline'
    const classes = ['font-weight-bold']
    if (this.isActive) {
      this.expired ? classes.push('text-warning') : classes.push('text-success')
    } else {
      classes.push('text-danger')
    }

    return `<span class="${classes.join(' ')}">${title}</span>`
  }

  get sessionTimeoutStr(): string {
    const start = this.sessionTimeout && this.start ? new Date(this.start) : null
    start && start.setSeconds(start.getSeconds() + this.sessionTimeout)

    return formatDateTime(start)
  }

  get toEquipmentByUsernameLink(): string {
    if (!this.username) {
      return ''
    }

    const res = router.resolve({
      name: ROUTE_STOCK_EQUIPMENT_VIEW_BY_PPPOE_LOGIN,
      params: {
        pppoe_login: this.username,
      },
    })

    return `<a href="${res.href}" target="_blank">${this.username}</a>`
  }

  async reset(): Promise<boolean> {
    if (!confirm(`Are you sure you want to reset session: ${this.id}`)) {
      return false
    }

    try {
      await internetApi.internetSession.reset(this.id)
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }
}
