import { CommonModel, IModel } from '@/models/Model'
import { MainStore } from '@/store/MainStore'
import { usersApi } from '@/api/users-api'
import { ISession, Session } from '@/models/user/session'
import { PersonSimple } from '@/models/persons/PersonSimple'
import { PersonSimpleGet } from '@/models/persons/PersonSimpleGet'
import { UserSettings } from '@/models/user/UserSettings'

export interface IUser extends IModel {
  personID: number
  username: string
  isActive: boolean
  settings: UserSettings
  permissions: string[]
  session: ISession | null
}

export class User extends CommonModel implements IUser {
  personID = 0
  username = ''
  isActive = true
  settings = new UserSettings()
  permissions: string[] = []
  session: Session | null = null

  // after load
  person: PersonSimple | null = null

  get personFullName(): string {
    return this.person ? this.person.fullName : ''
  }

  get personLinkHtml(): string {
    return this.person ? this.person.viewLinkHtml('_self') : ''
  }

  get isLogged(): boolean {
    return Boolean(
      this.personID && this.session && this.session.expire.getTime() >= new Date().getTime()
    )
  }

  constructor(src: IUser | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: IUser) {
    Object.assign(this, src)
    this.session = src.session ? new Session(src.session) : null

    this.initModel(src)
  }

  async load(): Promise<User> {
    this.person = await PersonSimpleGet.byID(this.personID)
    return this
  }

  async update(): Promise<boolean> {
    try {
      this.init(await usersApi.user.update(this.toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  toInterface(): IUser {
    return {
      personID: this.personID,
      username: this.username,
      isActive: this.isActive,
      permissions: this.permissions,
      settings: this.settings,
      session: null,
    }
  }
}
