import { ObjectParams } from '@/models/types'
import { ItemToSelect } from '@/components/common/searchable-input/item-to-select'
import { List } from '@/models/List'

const LIMIT_ITEMS = 30

export abstract class Autocomplete<T> {
  private _filter: ((e: T) => boolean) | null = null
  private _additionalParams: ObjectParams = {}
  private _itemsToSearch: ItemToSelect[] = []
  private _returnedField = ''

  requiredParamsObj: ObjectParams = {}
  searchLine = ''

  abstract getKey(item: T): string | number
  abstract getText(item: T): string
  abstract requiredParams(searchLine: string): ObjectParams
  abstract list(params: ObjectParams): Promise<List<T>>

  async emit(item: T | null): Promise<unknown> {
    return !item ? null : this.returnedField() ? this.getFieldValue(item) : item
  }

  withFilter(filter: (e: T) => boolean): Autocomplete<T> {
    this._filter = filter
    return this
  }

  withParams(params: ObjectParams): Autocomplete<T> {
    this._additionalParams = { ...this._additionalParams, ...params }
    return this
  }

  withReturnedField(field: string): Autocomplete<T> {
    this._returnedField = field
    return this
  }

  withRequiredParams(params: ObjectParams): Autocomplete<T> {
    this.requiredParamsObj = params
    return this
  }

  filter = (e: T): boolean => {
    return this._filter ? this._filter(e) : true
  }

  itemsToSearch(): ItemToSelect[] {
    return this._itemsToSearch
  }

  clearItems(): void {
    this._itemsToSearch = []
  }

  isEmptyData(): boolean {
    return !this._itemsToSearch.length
  }

  returnedField(): string {
    return this._returnedField
  }

  params(searchLine: string): ObjectParams {
    return {
      ...this.presetParams(),
      ...this.additionalParams(),
      ...this.requiredParams(searchLine),
    }
  }

  presetParams(): ObjectParams {
    return { limit: LIMIT_ITEMS }
  }

  additionalParams(): ObjectParams {
    return { ...this._additionalParams }
  }

  async find(searchLine: string): Promise<T[]> {
    return (await this.list(this.params(searchLine))).items
  }

  createItems(items: T[]): void {
    this._itemsToSearch = []
    if (items && items.length) {
      items.forEach((v) => {
        if (this.filter(v)) {
          this._itemsToSearch.push({
            key: this.getKey(v),
            text: this.getText(v),
            value: v,
          })
        }
      })
    }
  }

  getFieldValue(item: T): unknown {
    const obj = this.createObj(item)
    if (Object.prototype.hasOwnProperty.call(obj, this.returnedField())) {
      return obj[this.returnedField()]
    }
    throw new Error(`Missing field "${this.returnedField()}" in object`)
  }

  private createObj(item: T): ObjectParams {
    const res: ObjectParams = {}
    for (const [key, value] of Object.entries(item)) {
      res[key] = value
    }

    return res
  }

  async loadSearchForReturnedField(search: string): Promise<void> {
    if (!search || !this.returnedField()) {
      this.searchLine = ''
      return
    }

    const res = await this.list({ [this.returnedField()]: search })
    if (res.items.length == 1) {
      this.searchLine = this.getText(res.items[0])
    }
  }
}
