
import { Component, Prop, Vue } from 'vue-property-decorator'
import { NavItem, NavSection } from '@/components/app/nav/types'
import { MainStore } from '@/store/MainStore'
import NavPart from '@/components/app/nav/NavPart.vue'
import { CAN_ACCESS_SERVICES } from '@/permissions/services'
import {
  ROUTE_SERVICES_SETTINGS,
  ROUTE_SERVICES_CRED,
  ROUTE_SERVICES,
  ROUTE_SERVICES_ORPHANS,
  ROUTE_SERVICES_MULTI_ACTIVE,
} from '@/router/services'
import { EVENT_INPUT } from '@/utils/event-constants'

@Component({
  components: { NavPart },
})
export default class NavPartServices extends Vue {
  @Prop({ type: Boolean }) isOpen!: boolean

  get isShow(): boolean {
    return MainStore.isUserCan(CAN_ACCESS_SERVICES)
  }

  section = new NavSection('Services', 'fas fa-network-wired', ROUTE_SERVICES, [
    new NavItem('Services', ROUTE_SERVICES, 'fas fa-satellite-dish'),
    new NavItem('Configuration', ROUTE_SERVICES_SETTINGS, 'fas fa-tools'),
    new NavItem('Credentials', ROUTE_SERVICES_CRED, 'fas fa-certificate'),
    new NavItem('Orphans', ROUTE_SERVICES_ORPHANS, 'fas fa-unlink'),
    new NavItem('Multiactive', ROUTE_SERVICES_MULTI_ACTIVE, 'fab fa-creative-commons-share'),
  ])

  created(): void {
    this.$emit(EVENT_INPUT, this.section.items)
  }
}
