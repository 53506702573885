import { ObjectParams } from '@/models/types'

const defLocale = 'es-ES'

export const msInDay = 24 * 60 * 60 * 1000

const optionsDate = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
}

const optionsDateTime = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: 'numeric',
  hour12: false,
}

export function subtractTimezone(date: Date): Date {
  const offsetMilliseconds = date.getTimezoneOffset() * 60000
  return new Date(date.getTime() - offsetMilliseconds)
}

export function withTimezone(date: Date): Date {
  const offsetMilliseconds = date.getTimezoneOffset() * 60000
  return new Date(date.getTime() + offsetMilliseconds)
}

export function formatDate(date: Date | string | null | undefined, locales = defLocale): string {
  return date ? _formatDate(new Date(date), optionsDate, locales) : ''
}

export function formatDateTime(
  date: Date | string | null | undefined,
  locales = defLocale
): string {
  return date ? _formatDate(new Date(date), optionsDateTime, locales) : ''
}

export function dateTimeString(date: Date | string | null | undefined): string {
  return date ? new Date(date).toISOString().substring(0, 16) : ''
}

export function timeString(date: Date | string | null | undefined): string {
  return date ? new Date(date).toISOString().substring(11, 16) : ''
}

export function dateString(date: Date | string | null | undefined): string {
  return date ? new Date(date).toISOString().substring(0, 10) : ''
}

function _formatDate(date: Date, options: ObjectParams, locales = defLocale) {
  return new Intl.DateTimeFormat(locales, options).format(date)
}

export function dateOrUndefined(date: Date | string | null | undefined): Date | undefined {
  return dateOrNull(date) || undefined
}

export function dateOrNull(date: Date | string | null | undefined): Date | null {
  if (typeof date == 'string') {
    return !date || date.includes('000', 0) ? null : new Date(date)
  }

  return date ? new Date(date) : null
}

export function dateOrNullUTC(date: Date | string | null | undefined): Date | null {
  if (typeof date == 'string') {
    return !date || date.includes('000', 0) ? null : withTimezone(new Date(date))
  }

  return date ? withTimezone(new Date(date)) : null
}

export const booleanSelector = {
  true: 'Yes',
  false: 'No',
}

export function downloadBlob(data: Blob, fileName = 'file'): void {
  const fileLink = document.createElement('a')
  const name = fileName + '.' + parseFileType(data.type)

  fileLink.href = window.URL.createObjectURL(data)
  fileLink.setAttribute('download', name)
  document.body.appendChild(fileLink)
  fileLink.click()
  document.body.removeChild(fileLink)
}

export function openNewTabBlob(data: Blob): void {
  const fileLink = document.createElement('a')

  fileLink.href = window.URL.createObjectURL(data)
  fileLink.setAttribute('target', '_blank')
  document.body.appendChild(fileLink)
  fileLink.click()
  document.body.removeChild(fileLink)
}

function parseFileType(fileType: string): string {
  const type = fileType.split('/').pop() + ''
  switch (type) {
    case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'docx'
    default:
      return type
  }
}

export function nameWithoutExt(name: string): string {
  const a = name.split('.')
  if (a.length == 1) {
    return a[0]
  }

  a.pop()
  return a.join('.')
}

const chars = 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890'

export function uniqueStr(postfix = '', len = 36): string {
  const randomArray = Array.from(
    { length: len },
    () => chars[Math.floor(Math.random() * chars.length)]
  )

  const randomString = randomArray.join('')

  return postfix ? randomString + '-' + postfix : randomString
}

export function daysInMonth(year: number, month: number) {
  const date = new Date(year, month, 0)
  return date.getDate()
}
