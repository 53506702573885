export default class FileResolver {
  private static readonly _defaultExt = ['png', 'jpeg', 'jpg', 'pdf', 'doc', 'docx', 'xls', 'xlsx']

  static readonly imagesSrc = {
    word: '/img/documents/word.png',
    excel: '/img/documents/excel.jpg',
    pdf: '/img/documents/pdf.png',
    blank: '/img/documents/blank.png',
  }

  static readonly fileTypeExtensions = {
    image: ['jpg', 'jpeg', 'png', 'ico', 'svg', 'gif'],
    word: ['doc', 'docx'],
    excel: ['xls', 'xlsx'],
    text: ['csv'],
  }

  static readonly MaxFileSizeMb = 10
  static readonly MinFileSizeMb = 2

  private _extensions: string[] = []
  private _extRegExp = new RegExp('.')
  private _maxFileSizeMb = 0

  static isImage(fileType: string): boolean {
    return !!fileType.match(
      new RegExp('^image\\/(' + FileResolver.fileTypeExtensions.image.join('|') + ')$', 'i')
    )
  }

  static src(src: string | ArrayBuffer | null, type: string): string | ArrayBuffer | null {
    switch (true) {
      case FileResolver.isImage(type):
        return src
      case !!type.match(/^application\/(.*word.*)$/i):
      case !!type.match(/^application\/(.*doc.*)$/i):
        return FileResolver.imagesSrc.word
      case !!type.match(/^application\/(.*excel.*)$/i):
        return FileResolver.imagesSrc.excel
      case !!type.match(/^application\/(.*pdf.*)$/i):
        return FileResolver.imagesSrc.pdf
      default:
        return FileResolver.imagesSrc.blank
    }
  }

  constructor(exts?: string[], maxFileSizeMb = FileResolver.MaxFileSizeMb) {
    this._extensions = exts && exts.length ? [...exts] : [...FileResolver._defaultExt]
    this._extRegExp = this.createExtRegExp(this._extensions)
    this.setMaxFileSizeMb(maxFileSizeMb)
  }

  setMaxFileSizeMb(size: number): void {
    if (size > FileResolver.MaxFileSizeMb) {
      this._maxFileSizeMb = FileResolver.MaxFileSizeMb
    } else if (size < FileResolver.MinFileSizeMb) {
      this._maxFileSizeMb = FileResolver.MinFileSizeMb
    } else {
      this._maxFileSizeMb = size
    }
  }

  extensions(): string[] {
    return this._extensions
  }

  extRegExp(): RegExp {
    return this._extRegExp
  }

  maxFileSizeMb(): number {
    return this._maxFileSizeMb
  }

  validateFile(file: File): boolean {
    return !!(file.size < this._maxFileSizeMb * 1000000 && file.type.match(this.extRegExp()))
  }

  // /^image\/(jpg|jpeg)|application\/(.*word.*|.*excel.*|.*pdf.*)$/i
  createExtRegExp(exts: string[]): RegExp {
    const partImg: string[] = []
    const partText: string[] = []
    let partApp: string[] = []
    let tReg = ''

    FileResolver.fileTypeExtensions.image.forEach((v) => {
      if (exts.indexOf(v) != -1) {
        partImg.push(v)
      }
    })

    FileResolver.fileTypeExtensions.word.forEach((v) => {
      if (exts.indexOf(v) != -1) {
        partApp.push('.*word.*')
      }
    })

    FileResolver.fileTypeExtensions.excel.forEach((v) => {
      if (exts.indexOf(v) != -1) {
        partApp.push('.*excel.*')
      }
    })

    FileResolver.fileTypeExtensions.text.forEach((v) => {
      if (exts.indexOf(v) != -1) {
        partText.push(v)
      }
    })

    if (exts.indexOf('csv') != -1) {
      partApp.push('vnd\\.ms-excel')
    }

    if (exts.indexOf('pdf') != -1) {
      partApp.push('.*pdf.*')
    }

    // Get unique values
    partApp = Array.from(new Set(partApp))

    if (partImg.length > 0) {
      tReg = 'image\\/(' + partImg.join('|') + ')'
    }

    if (partApp.length > 0) {
      tReg += tReg === '' ? '' : '|'
      tReg += 'application\\/(' + partApp.join('|') + ')'
    }

    if (partText.length > 0) {
      tReg += tReg === '' ? '' : '|'
      tReg += 'text\\/(' + partText.join('|') + ')'
    }

    return new RegExp('^' + tReg + '$', 'i')
  }
}
