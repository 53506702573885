import API, { MAX_LIMIT, paramsByIds } from '@/api/api'
import { List } from '@/models/List'
import { ObjectParams } from '@/models/types'
import { INas } from '@/models/internet/Nas'
import { IIpPool } from '@/models/internet/IpPool'
import { IIpPoolExpanded } from '@/models/internet/IpPoolExpanded'
import { IIpPoolReservation } from '@/models/internet/IpPoolReservation'
import { IInternetSession } from '@/models/internet/InternetSession'
import { IInternetSessionDetail } from '@/models/internet/InternetSessionDetail'
import { IInternetSessionError } from '@/models/internet/InternetSessionError'
import { ISpeedStats } from '@/components/internet/charts/models/SpeedStats'
import { SpeedStatsFixedQuery, SpeedStatsQuery } from '@/components/internet/charts/models/types'
import { SpeedStatsFixed } from '@/components/internet/charts/models/SpeedStatsFixed'
import { SessionCountsOnline, StatsByIPPool } from '@/responses/internet'

const module = '/internet'
const URL_NAS = module + '/nas'
const URL_IP_POOLS = module + '/ip-pools'
const URL_IP_POOL_EXPANDED = module + '/ip-pools-expanded'
const URL_IP_POOL_RESERVATION = module + '/ip-pools-reservations'

const URL_INTERNET_SESSION = module + '/internet-sessions'

const URL_INTERNET_SESSION_DETAILS = module + '/internet-sessions-details'
const URL_INTERNET_SESSION_ERRORS = module + '/internet-sessions-errors'

export const internetApi = {
  nas: {
    add: async function (product: INas): Promise<INas> {
      const res = await API.post(API.buildURL(URL_NAS), product)
      return (await res.json()) as Promise<INas>
    },

    update: async function (product: INas): Promise<INas> {
      const res = await API.put(API.buildURL(URL_NAS), product)
      return (await res.json()) as Promise<INas>
    },

    getByID: async function (id: number): Promise<INas> {
      const res = await API.get(API.buildURL(URL_NAS + '/' + id))
      return (await res.json()) as Promise<INas>
    },

    list: async function (params: ObjectParams): Promise<List<INas>> {
      const res = await API.get(API.buildURL(URL_NAS, { ...params }))
      return (await res.json()) as Promise<List<INas>>
    },

    delete: async function (id: number): Promise<void> {
      const res = await API.delete(API.buildURL(URL_NAS + '/' + id))
      if (!res.ok) {
        throw new Error(await res.json())
      }
    },
  },

  ipPool: {
    add: async function (product: IIpPool): Promise<IIpPool> {
      const res = await API.post(API.buildURL(URL_IP_POOLS), product)
      return (await res.json()) as Promise<IIpPool>
    },

    update: async function (product: IIpPool): Promise<IIpPool> {
      const res = await API.put(API.buildURL(URL_IP_POOLS), product)
      return (await res.json()) as Promise<IIpPool>
    },

    getByID: async function (id: number): Promise<IIpPool> {
      const res = await API.get(API.buildURL(URL_IP_POOLS + '/' + id))
      return (await res.json()) as Promise<IIpPool>
    },

    list: async function (params: ObjectParams): Promise<List<IIpPool>> {
      const res = await API.get(API.buildURL(URL_IP_POOLS, { ...params }))
      return (await res.json()) as Promise<List<IIpPool>>
    },

    delete: async function (id: number): Promise<void> {
      const res = await API.delete(API.buildURL(URL_IP_POOLS + '/' + id))
      if (!res.ok) {
        throw new Error(await res.json())
      }
    },
  },

  ipPoolExpanded: {
    add: async function (product: IIpPoolExpanded): Promise<IIpPoolExpanded> {
      const res = await API.post(API.buildURL(URL_IP_POOL_EXPANDED), product)
      return (await res.json()) as Promise<IIpPoolExpanded>
    },

    update: async function (product: IIpPoolExpanded): Promise<IIpPoolExpanded> {
      const res = await API.put(API.buildURL(URL_IP_POOL_EXPANDED), product)
      return (await res.json()) as Promise<IIpPoolExpanded>
    },

    getByID: async function (id: number): Promise<IIpPoolExpanded> {
      const res = await API.get(API.buildURL(URL_IP_POOL_EXPANDED + '/' + id))
      return (await res.json()) as Promise<IIpPoolExpanded>
    },

    list: async function (params: ObjectParams): Promise<List<IIpPoolExpanded>> {
      const res = await API.get(API.buildURL(URL_IP_POOL_EXPANDED, { ...params }))
      return (await res.json()) as Promise<List<IIpPoolExpanded>>
    },

    delete: async function (id: number): Promise<void> {
      const res = await API.delete(API.buildURL(URL_IP_POOL_EXPANDED + '/' + id))
      if (!res.ok) {
        throw new Error(await res.json())
      }
    },
  },

  ipPoolReservation: {
    list: async function (params: ObjectParams): Promise<List<IIpPoolReservation>> {
      const res = await API.get(API.buildURL(URL_IP_POOL_RESERVATION, { ...params }))
      return (await res.json()) as Promise<List<IIpPoolReservation>>
    },
  },

  internetSession: {
    getByID: async function (id: number): Promise<IInternetSession> {
      const res = await API.get(API.buildURL(URL_INTERNET_SESSION + '/' + id))
      return (await res.json()) as Promise<IInternetSession>
    },

    getLastByServiceIDs: async function (ids: number[]): Promise<IInternetSession[]> {
      const res = await API.get(
        API.buildURL(URL_INTERNET_SESSION + '/services', {
          ...paramsByIds(ids, 'serviceIDs'),
        })
      )
      return (await res.json()) as Promise<IInternetSession[]>
    },

    getLastByUsernames: async function (names: string[]): Promise<IInternetSession[]> {
      const res = await API.get(
        API.buildURL(URL_INTERNET_SESSION + '/users', {
          ...paramsByIds(names, 'usernames'),
        })
      )
      return (await res.json()) as Promise<IInternetSession[]>
    },

    list: async function (params: ObjectParams): Promise<List<IInternetSession>> {
      const res = await API.get(API.buildURL(URL_INTERNET_SESSION, { ...params }))
      return (await res.json()) as Promise<List<IInternetSession>>
    },

    reset: async function (id: number): Promise<void> {
      const res = await API.put(API.buildURL(URL_INTERNET_SESSION + '/' + id + '/reset'), {})
      if (!res.ok) {
        throw new Error(await res.json())
      }
    },

    count: async function (params: ObjectParams): Promise<number> {
      const res = await API.get(API.buildURL(URL_INTERNET_SESSION + '/count', { ...params }))
      return await res.json()
    },

    countOnline: async function (): Promise<SessionCountsOnline> {
      const res = await API.get(API.buildURL(URL_INTERNET_SESSION + '/count/online'))
      return await res.json()
    },

    statsByIpPools: async function (ipPoolIDs: number[]): Promise<StatsByIPPool[]> {
      const res = await API.get(
        API.buildURL(URL_INTERNET_SESSION + '/stats-ip-pools', {
          ...paramsByIds(ipPoolIDs, 'ipPoolIDs'),
          limit: MAX_LIMIT,
        })
      )
      return await res.json()
    },
  },

  internetSessionDetail: {
    list: async function (params: ObjectParams): Promise<List<IInternetSessionDetail>> {
      const res = await API.get(API.buildURL(URL_INTERNET_SESSION_DETAILS, { ...params }))
      return (await res.json()) as Promise<List<IInternetSessionDetail>>
    },

    speedStats: async function (params: SpeedStatsQuery): Promise<ISpeedStats> {
      const res = await API.get(
        API.buildURL(URL_INTERNET_SESSION_DETAILS + '/speed-stats', { ...params })
      )
      return await res.json()
    },

    speedStatsFixed: async function (params: SpeedStatsFixedQuery): Promise<SpeedStatsFixed[]> {
      const res = await API.get(
        API.buildURL(URL_INTERNET_SESSION_DETAILS + '/speed-stats-fixed', { ...params })
      )
      return await res.json()
    },
  },

  internetSessionError: {
    list: async function (params: ObjectParams): Promise<List<IInternetSessionError>> {
      const res = await API.get(API.buildURL(URL_INTERNET_SESSION_ERRORS, { ...params }))
      return (await res.json()) as Promise<List<IInternetSessionError>>
    },

    lastBySessionID: async function (id: number): Promise<IInternetSessionError | null> {
      const res = await API.get(API.buildURL(URL_INTERNET_SESSION_ERRORS + '/last/session/' + id))
      return (await res.json()) as Promise<IInternetSessionError | null>
    },
  },
}
