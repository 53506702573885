import { ChartConfiguration } from 'chart.js'
import { CHART_COLORS, MONTHS } from '@/utils/chart'
import { AnnualReport } from '@/components/dashboards/models/AnnualReport'

export function chartContracts(report: AnnualReport): ChartConfiguration {
  return {
    type: 'bar',
    data: {
      labels: [...MONTHS],
      datasets: [
        {
          label: 'New',
          data: [...report.created],
          borderColor: CHART_COLORS.Green.main,
          backgroundColor: CHART_COLORS.Green.gradient,
          borderWidth: 1,
        },
        {
          label: 'Canceled',
          data: [...report.canceled],
          borderColor: CHART_COLORS.Red.main,
          backgroundColor: CHART_COLORS.Red.gradient,
          borderWidth: 1,
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          align: 'start',
          text: 'New / Canceled Contracts',
          font: {
            size: 14,
            family: 'tahoma',
            weight: 'bold',
            style: 'italic',
          },
        },
      },
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    },
  }
}
