
import { Component, Vue } from 'vue-property-decorator'
import AppSearch from '@/components/app/AppSearch.vue'
import { CHANGE_PASSWORD, HOME_PAGE, ROUTE_LOGIN_AS_USER } from '@/router'
import { Auth } from '@/models/user/auth'
import { clickOutside } from '@/directives/clickOutside'
import { MainStore } from '@/store/MainStore'
import { CAN_LOGIN_AS_USER } from '@/permissions/users_server'

@Component({
  components: { AppSearch },
  directives: {
    clickOutside,
  },
})
export default class AppUserMenu extends Vue {
  isOpened = false

  get showLoginAsUser(): boolean {
    return MainStore.isUserCan(CAN_LOGIN_AS_USER)
  }

  loginAsUser(): void {
    this.$router.push({ name: ROUTE_LOGIN_AS_USER })
  }

  changePassword(): void {
    this.$router.push({ name: CHANGE_PASSWORD })
  }

  async logout(): Promise<void> {
    await Auth.logoutCurrentUser()
    if (this.$router.currentRoute.name != HOME_PAGE) {
      await this.$router.push({ name: HOME_PAGE })
    }
  }

  closeArea(): void {
    this.isOpened = false
  }
}
