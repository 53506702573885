import { CommonModel, IModel } from '@/models/Model'
import { MainStore } from '@/store/MainStore'
import { geoApi } from '@/api/geo-api'
import { IProvince, Province } from '@/models/geo/Province'
import { coloringName } from '@/models/geo/utils'
import { GeoStatus, isValid, isVerified, statusHtml } from '@/models/geo/constants'

export interface IMunicipality extends IModel {
  id: number
  name: string
  code: string
  latitude: number
  longitude: number
  provinceID: number
  status: GeoStatus
  weight: number
  province: IProvince | null
}

export class Municipality extends CommonModel implements IMunicipality {
  id = 0
  name = ''
  code = ''
  latitude = 0
  longitude = 0
  provinceID = 0
  status: GeoStatus = 0
  weight = 0
  province: Province | null = null

  get isValidForm(): boolean {
    return Boolean(this.id) || Boolean(this.name && this.province && this.province.isValidForm)
  }

  get isValid(): boolean {
    return isValid(this.status)
  }

  get isVerified(): boolean {
    return isVerified(this.status)
  }

  get statusHtml(): string {
    return statusHtml(this.status)
  }

  get nameWithProvince(): string {
    return this.province ? `${this.name} (${this.province.name})` : this.name
  }

  get nameStr(): string {
    return this.name
  }

  get titleHtml(): string {
    return coloringName(this)
  }

  constructor(src: IMunicipality | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: IMunicipality) {
    Object.assign(this, src)
    this.province = src.province ? new Province(src.province) : null

    this.initModel(src)
  }

  async save(): Promise<boolean> {
    try {
      return this.id ? await this.update() : await this.add()
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async add(): Promise<boolean> {
    try {
      this.init(await geoApi.municipality.add(this.prepare().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async update(): Promise<boolean> {
    try {
      this.init(await geoApi.municipality.update(this.prepare().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async delete(): Promise<boolean> {
    if (!confirm(`Are you sure you want to delete municipality?`)) {
      return false
    }

    try {
      await geoApi.municipality.delete(this.id)
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async approve(): Promise<boolean> {
    if (!confirm(`Are you sure you want to confirm the entry?`)) {
      return false
    }

    try {
      this.init(await geoApi.municipality.approve({ id: this.id }))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async reject(): Promise<boolean> {
    if (!confirm(`Are you sure you want to reject the entry?`)) {
      return false
    }

    try {
      this.init(await geoApi.municipality.reject({ id: this.id }))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  prepare(): Municipality {
    this.provinceID = this.province ? this.province.id : 0

    return this
  }

  toInterface(): IMunicipality {
    return {
      id: this.id,
      name: this.name,
      code: this.code,
      latitude: this.latitude,
      longitude: this.longitude,
      provinceID: this.provinceID,
      status: this.status,
      weight: this.weight,
      province: this.province ? this.province.toInterface() : null,
    }
  }
}
