import { RouteConfig } from 'vue-router/types/router'
import { ROUTER_PARAM_ID } from '@/utils/constants'
import { CAN_ACCESS_INTEGRATIONS } from '@/permissions/integrations'

export const ROUTE_API_TOKENS = 'ROUTE_API_TOKENS'
export const ROUTE_API_TOKEN_FORM = 'ROUTE_API_TOKEN_FORM'
export const ROUTE_API_TOKEN_VIEW = 'ROUTE_API_TOKEN_VIEW'

const integrationsRoutes: RouteConfig[] = [
  {
    name: ROUTE_API_TOKENS,
    path: '/api-tokens',
    meta: { permission: CAN_ACCESS_INTEGRATIONS },
    component: () =>
      import(/* webpackChunkName: "integrations" */ '../components/integrations/ApiTokenList.vue'),
  },
  {
    name: ROUTE_API_TOKEN_FORM,
    path: `/api-tokens/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: CAN_ACCESS_INTEGRATIONS },
    component: () =>
      import(/* webpackChunkName: "integrations" */ '../components/integrations/ApiTokenEdit.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
  {
    name: ROUTE_API_TOKEN_VIEW,
    path: `/api-tokens/:${ROUTER_PARAM_ID}(\\d+)`,
    meta: { permission: CAN_ACCESS_INTEGRATIONS },
    component: () =>
      import(/* webpackChunkName: "integrations" */ '../components/integrations/ApiTokenView.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
]

export default integrationsRoutes
