import { Person } from '@/models/persons/Person'
import { uniqueNonZeroNums } from '@/utils/unique_nums'
import { PersonTags } from '@/models/tags/person_tags'
import { CountryGet } from '@/models/geo/CountryGet'
import { PersonPaymentMethodGet } from '@/models/payments/PersonPaymentMethodGet'
import { EquipmentGet } from '@/models/stock/EquipmentGet'
import { UserGet } from '@/models/user/UserGet'

export class PersonLoader {
  private readonly items: Person[] = []

  constructor(items: Person | Person[]) {
    this.items = items instanceof Person ? [items] : [...items]
  }

  load = async (...loaders: Array<Exclude<keyof PersonLoader, 'load'>>): Promise<void> => {
    await Promise.all(loaders.map((method) => this[method]()))
  }

  users = async (): Promise<void> => {
    const ids = uniqueNonZeroNums(this.items.map((v) => v.id))
    if (!ids.length) {
      return
    }

    const res = await UserGet.mapByPersonIDs(ids)

    this.items.forEach((v) => {
      v.user = res.get(v.id) || null
    })
  }

  tags = async (): Promise<void> => {
    const ids = uniqueNonZeroNums(this.items.map((v) => v.id))
    if (!ids.length) {
      return
    }

    const res = await PersonTags.mapByPersonIDs(ids)

    this.items.forEach((v) => {
      v.tags = res.get(v.id) || null
    })
  }

  nationalities = async (): Promise<void> => {
    const ids = uniqueNonZeroNums(this.items.map((v) => v.nationalityID))
    if (!ids.length) {
      return
    }

    const res = await CountryGet.mapByIDs(ids)

    this.items.forEach((v) => {
      v.nationality = res.get(v.nationalityID) || null
    })
  }

  equipments = async (): Promise<void> => {
    const ids = uniqueNonZeroNums(this.items.map((v) => v.id))
    if (!ids.length) {
      return
    }

    const res = await EquipmentGet.mapByPersonIDs(ids)

    this.items.forEach((v) => {
      v.equipments = res.get(v.id) || []
    })
  }

  paymentMethods = async (): Promise<void> => {
    const ids = uniqueNonZeroNums(this.items.map((v) => v.id))
    if (!ids.length) {
      return
    }

    const res = await PersonPaymentMethodGet.mapByPersonIDs(ids, false)

    this.items.forEach((v) => {
      v.paymentMethods = res.get(v.id) || []
    })
  }
}
