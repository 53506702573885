import API from '@/api/api'
import { IdentityRequest } from '@/requests/IdentityRequest'
import { PrintTicketsRequest } from '@/requests/PrintTicketsRequest'
import { FibreContractsRequest, PersonEquipmentsRequest } from '@/requests/downloads'

const URL_MODULE = '/downloads'

const URL_STOCK = URL_MODULE + '/stock'
const URL_PERSON_EQUIPMENTS = URL_STOCK + '/person-equipments'

const URL_PAYMENT = URL_MODULE + '/payment'
const URL_PAYMENT_INVOICES = URL_PAYMENT + '/invoices'

const URL_AGREEMENTS = URL_MODULE + '/agreements'
const URL_AGREEMENTS_INSTALLATIONS = URL_AGREEMENTS + '/installations'
const URL_AGREEMENTS_CONTRACT = URL_AGREEMENTS + '/contracts'
const URL_AGREEMENTS_EMPTY = URL_AGREEMENTS + '/empty'

const URL_TICKETS = URL_MODULE + '/tickets'
const URL_TICKETS_APPOINTMENTS = URL_TICKETS + '/appointments'
const URL_TICKETS_INSTALLATIONS = URL_TICKETS + '/installations'
const URL_TICKETS_MULTI = URL_TICKETS + '/multi'

export const downloadApi = {
  payment: {
    invoice: async function (req: IdentityRequest): Promise<Blob> {
      const res = await API.post(API.buildURL(URL_PAYMENT_INVOICES), req)
      return res.blob()
    },
    invoices: async function (ids: number[]): Promise<Blob> {
      const res = await API.post(API.buildURL(URL_PAYMENT_INVOICES + '/multi'), ids)
      return res.blob()
    },
  },

  agreement: {
    installation: async function (req: IdentityRequest): Promise<Blob> {
      const res = await API.post(API.buildURL(URL_AGREEMENTS_INSTALLATIONS), req)
      return res.blob()
    },
    contract: async function (req: IdentityRequest): Promise<Blob> {
      const res = await API.post(API.buildURL(URL_AGREEMENTS_CONTRACT), req)
      return res.blob()
    },
    empty: async function (req: IdentityRequest): Promise<Blob> {
      const res = await API.post(API.buildURL(URL_AGREEMENTS_EMPTY), req)
      return res.blob()
    },
  },

  ticket: {
    appointment: async function (req: IdentityRequest): Promise<Blob> {
      const res = await API.post(API.buildURL(URL_TICKETS_APPOINTMENTS), req)
      return res.blob()
    },
    installation: async function (req: IdentityRequest): Promise<Blob> {
      const res = await API.post(API.buildURL(URL_TICKETS_INSTALLATIONS), req)
      return res.blob()
    },
    multi: async function (req: PrintTicketsRequest): Promise<Blob> {
      const res = await API.post(API.buildURL(URL_TICKETS_MULTI), req)
      return res.blob()
    },
  },

  stock: {
    personEquipments: async function (req: PersonEquipmentsRequest): Promise<Blob> {
      const res = await API.post(API.buildURL(URL_PERSON_EQUIPMENTS), req)
      return res.blob()
    },
  },

  fibreContracts: async function (req: FibreContractsRequest): Promise<Blob> {
    const res = await API.post(API.buildURL(URL_MODULE + '/fibre-contracts'), req)
    return res.blob()
  },
}
