
import { Component, Prop, Vue } from 'vue-property-decorator'
import { NavItem, NavSection } from '@/components/app/nav/types'
import { MainStore } from '@/store/MainStore'
import NavPart from '@/components/app/nav/NavPart.vue'
import { EVENT_INPUT } from '@/utils/event-constants'
import { HOME_PAGE } from '@/router'
import { CAN_ACCESS_DASHBOARD } from '@/permissions/dashboard'
@Component({
  components: { NavPart },
})
export default class NavDashboard extends Vue {
  @Prop({ type: Boolean }) isOpen!: boolean

  get isShow(): boolean {
    return MainStore.isUserCan(CAN_ACCESS_DASHBOARD)
  }

  section = new NavSection('Dashboard', 'fas fa-chart-line', HOME_PAGE, [])

  created(): void {
    this.$emit(EVENT_INPUT, [new NavItem('Dashboards', HOME_PAGE, '')])
  }
}
