
import { Component, Prop, Vue } from 'vue-property-decorator'
import { NavItem, NavSection } from '@/components/app/nav/types'
import { MainStore } from '@/store/MainStore'
import NavPart from '@/components/app/nav/NavPart.vue'
import { CAN_ACCESS_TICKETS } from '@/permissions/tickets'
import {
  ROUTE_APPOINTMENT_ACTIONS,
  ROUTE_CURRENT_USER_TICKETS,
  ROUTE_SUPPORT_PROBLEMS,
  ROUTE_TICKETS,
} from '@/router/tickets'
import { ROUTE_INSTALLATIONS } from '@/router/subscriptions'
import { EVENT_INPUT } from '@/utils/event-constants'
@Component({
  components: { NavPart },
})
export default class NavPartTickets extends Vue {
  @Prop({ type: Boolean }) isOpen!: boolean

  get isShow(): boolean {
    return MainStore.isUserCan(CAN_ACCESS_TICKETS)
  }

  section = new NavSection('Tickets', 'fas fa-clipboard-list', ROUTE_TICKETS, [
    new NavItem('Support Tickets', ROUTE_TICKETS, 'fas fa-ticket-alt'),
    new NavItem('Installation Tickets', ROUTE_INSTALLATIONS, 'fas fa-screwdriver'),
    new NavItem('My tickets', ROUTE_CURRENT_USER_TICKETS, 'fas fa-calendar-check'),
    new NavItem('Support problems', ROUTE_SUPPORT_PROBLEMS, 'fas fa-exclamation-triangle'),
    new NavItem('Appointment actions', ROUTE_APPOINTMENT_ACTIONS, 'fas fa-code-branch'),
  ])

  created(): void {
    this.$emit(EVENT_INPUT, this.section.items)
  }
}
