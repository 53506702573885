import { MainStore } from '@/store/MainStore'
import { List } from '@/models/List'
import { ObjectParams } from '@/models/types'
import { User } from '@/models/user/user'
import { usersApi } from '@/api/users-api'
import { MAX_LIMIT, paramsByIds } from '@/api/api'

export class UserGet {
  static async list(params: ObjectParams): Promise<List<User>> {
    const list: List<User> = { count: 0, items: [] }
    try {
      const l = await usersApi.user.list(params)
      list.count = l.count
      list.items = l.items.map((v) => new User(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return list
  }

  static async getByID(id: number): Promise<User> {
    let item = new User()
    try {
      item = new User(await usersApi.user.getByID(id))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return item
  }

  static async mapByPersonIDs(ids: number[]): Promise<Map<number, User>> {
    const res = await UserGet.getByPersonIDs(ids)

    const pMap = new Map<number, User>()
    res.forEach((v) => pMap.set(v.personID, new User(v)))

    return pMap
  }

  static async getByPersonIDs(ids: number[]): Promise<User[]> {
    return (await UserGet.list({ ...paramsByIds(ids, 'personIDs'), limit: MAX_LIMIT })).items
  }
}
