import { CommonModel, IModel } from '@/models/Model'
import { MainStore } from '@/store/MainStore'
import { stockApi } from '@/api/stock-api'
import { TypeKind, Unit } from '@/models/stock/constants'

export interface IType extends IModel {
  id: number
  name: string
  kind: TypeKind
  purchaseQuantity: number
  minBalance: number
  units: Unit
  balance?: number
}

export class Type extends CommonModel implements IType {
  id = 0
  name = ''
  kind: TypeKind = 0
  purchaseQuantity = 0
  minBalance = 0
  units: Unit = Unit.Piece

  balance?: number // Only for determining stock balances

  constructor(src: IType | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: IType) {
    Object.assign(this, src)

    this.initModel(src)
  }

  async save(): Promise<boolean> {
    try {
      return this.id ? await this.update() : await this.add()
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async add(): Promise<boolean> {
    try {
      this.init(await stockApi.type.add(this.toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async update(): Promise<boolean> {
    try {
      this.init(await stockApi.type.update(this.toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async delete(): Promise<boolean> {
    if (!confirm(`Are you sure you want to delete type?`)) {
      return false
    }

    try {
      await stockApi.type.delete(this.id)
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  toInterface(): IType {
    return {
      id: this.id,
      name: this.name,
      kind: this.kind,
      purchaseQuantity: this.purchaseQuantity,
      minBalance: this.minBalance,
      units: this.units,
    }
  }
}
