import { formatBytes } from '@/utils/Converter'

export function checkHtml(f: boolean): string {
  return f
    ? `<i class="fas fa-check text-success"></i>`
    : `<i class="fas fa-times text-danger"></i>`
}

export function eventTargetValue(event: Event | null | undefined): string {
  return event && event.target ? (event.target as HTMLInputElement).value : ''
}

export function switchHtml(f: boolean, isPointer = true): string {
  const icon = f ? 'fa-toggle-on' : 'fa-toggle-off'
  const pointer = isPointer ? 'pointer' : ''

  return `<span class="switch-html ${pointer}"><i class="fas ${icon}"></i></span>`
}

const textColor = new RegExp(/(^|[;\s])color:\s*(#[0-9a-z\\()_-]{6})/i)
export function parseTextColor(str?: string): string {
  if (!str) {
    return ''
  }
  const res = str.match(textColor)

  return res ? res[2] : ''
}

const cleanText = new RegExp(/(^|[;\s])color:\s*(#*[., 0-9a-z\\()_-]+\s*;*)/gi)
export function replaceTextColor(color: string, str?: string): string {
  const defStyle = 'color: ' + color.replace(';', '') + ';'
  if (!str) {
    return defStyle
  }

  return (defStyle + ' ' + str.replaceAll(cleanText, ' ')).trim().replaceAll(/ +/g, ' ')
}

const backgroundColor = new RegExp(/(^|[;\s]+)background-color:\s*(#[0-9a-z\\()_-]{6})/i)
export function parseBackgroundColor(str?: string): string {
  if (!str) {
    return ''
  }
  const res = str.match(backgroundColor)

  return res ? res[2] : ''
}

const cleanBackground = new RegExp(/(^|[;\s])background-color:\s*(#*[., 0-9a-z\\()_-]+\s*;*)/gi)
export function replaceBackgroundColor(color: string, str?: string): string {
  const defStyle = 'background-color: ' + color.replace(';', '') + ';'
  if (!str) {
    return defStyle
  }

  return (defStyle + ' ' + str.replaceAll(cleanBackground, ' ')).trim().replaceAll(/ +/g, ' ')
}

export function downloadHtml(value: number): string {
  return '<i class="fas fa-download text-green_1"></i> ' + formatBytes(value)
}

export function uploadHtml(value: number): string {
  return '<i class="fas fa-upload text-blue_1"></i> ' + formatBytes(value)
}

export function speedLimitStr(speed: number): string {
  return speedLimit(speed) + ' Mbit/s'
}

export function speedLimit(speed: number): number {
  return speed / 1000000
}

export function internetDataHtml(download: number, upload: number): string {
  return `D:${bytes(download)}/U:${bytes(upload)}`
}

function bytes(data: number): string {
  return formatBytes(data).replaceAll(' ', '')
}

export function speedLimitsHtml(download: number, upload: number): string {
  return `D:${speedLimit(download)}/U:${speedLimit(upload)} Mbit/s`
}
