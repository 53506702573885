import { CommonModel, IModel } from '@/models/Model'
import { MainStore } from '@/store/MainStore'
import {
  ServiceStatus,
  serviceStatuses,
  SettingType,
  settingTypes,
  settingTypesWithSessions,
} from '@/models/service/constants'
import { servicesApi } from '@/api/services-api'
import { Equipment } from '@/models/stock/Equipment'
import { contractToPersonRedirectHtml } from '@/models/persons/views'
import { InternetSession } from '@/models/internet/InternetSession'
import { Contract } from '@/models/persons/Contract'

export interface IService extends IModel {
  id: number
  title: string
  status: ServiceStatus
  settingType: SettingType
  contractID: number
  note: string
}

export class Service extends CommonModel implements IService {
  id = 0
  title = ''
  status = ServiceStatus.Unknown
  settingType = SettingType.Unknown
  contractID = 0
  note = ''

  // after load
  equipments: Equipment[] = []
  session: InternetSession | null = null
  contract: Contract | null = null

  static newActive(): Service {
    const res = new Service()
    res.status = ServiceStatus.Active

    return res
  }

  constructor(src: IService | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: IService) {
    Object.assign(this, src)

    if (src instanceof Service) {
      this.equipments = [...src.equipments]
    }

    this.initModel(src)
  }

  get networkName(): string {
    return this.contract ? this.contract.networkName : ''
  }

  get statusName(): string {
    return serviceStatuses[this.status]
  }

  get sessionStatusHtml(): string {
    return this.canViewSession && this.session ? this.session.statusHtml : ''
  }

  get titleHtml(): string {
    return this.title ? `${this.settingTypeName} ${this.title}` : this.settingTypeName
  }

  get titleWithEquipmentsHtml(): string {
    return (
      `(${this.settingTypeName}): ${this.title}` +
      (this.equipments ? '<br>' + this.equipmentsHtml : '')
    )
  }

  get settingTypeName(): string {
    return settingTypes[this.settingType]
  }

  get contractHtml(): string {
    return contractToPersonRedirectHtml(this.contractID)
  }

  get equipmentsHtml(): string {
    return this.equipments.map((v) => v.shortTitleHtml).join('<br>')
  }

  get canViewSession(): boolean {
    return settingTypesWithSessions.includes(this.settingType)
  }

  statusHtml(skipActive = false): string {
    let textColorClass = 'font-weight-bold '
    switch (this.status) {
      case ServiceStatus.Active:
        if (skipActive) {
          return ''
        }
        textColorClass += 'text-success'
        break
      case ServiceStatus.Cancelled:
        textColorClass += 'text-danger'
        break
    }

    return `<span class="${textColorClass}">${this.statusName}</span>`
  }

  get addressHtml(): string {
    return this.contract ? this.contract.addressHtml : ''
  }

  async save(): Promise<boolean> {
    try {
      return this.id ? await this.update() : await this.add()
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async add(): Promise<boolean> {
    try {
      this.init(await servicesApi.service.add(this.prepare().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async update(): Promise<boolean> {
    try {
      this.init(await servicesApi.service.update(this.prepare().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async delete(): Promise<boolean> {
    if (!confirm(`Are you sure you want to delete service?`)) {
      return false
    }

    try {
      await servicesApi.service.delete(this.id)
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  public prepare(): Service {
    return this
  }

  toInterface(): IService {
    return {
      id: this.id,
      title: this.title,
      status: this.status,
      settingType: this.settingType,
      contractID: this.contractID,
      note: this.note,
    }
  }
}
