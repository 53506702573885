
import { Component, Vue } from 'vue-property-decorator'
import AppSearch from '@/components/app/AppSearch.vue'
import AppUserMenu from '@/components/app/AppUserMenu.vue'
import { ROUTE_INSTALLATION_QUICK_FORM } from '@/router/subscriptions'
import { MainStore } from '@/store/MainStore'
import { CAN_ACCESS_TICKETS } from '@/permissions/tickets'
import { CAN_ACCESS_SEARCH } from '@/permissions/search'

@Component({
  components: {
    AppSearch,
    AppUserMenu,
  },
})
export default class AppHeader extends Vue {
  ROUTE_INSTALLATION_QUICK_FORM = ROUTE_INSTALLATION_QUICK_FORM

  get showQuickInstall(): boolean {
    return MainStore.isUserCan(CAN_ACCESS_TICKETS)
  }

  get showQuickSearch(): boolean {
    return MainStore.isUserCan(CAN_ACCESS_SEARCH)
  }

  get personName(): string {
    return MainStore.getPerson().fullName
  }
}
