
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Nas } from '@/models/internet/Nas'

@Component
export default class NasBoxes extends Vue {
  @Prop({ type: Array, required: true }) items!: Nas[]

  backgroundColor(nas: Nas): string {
    return nas.percentageUsedIPs > 90 ? 'background: #fb5800c1' : 'background: #56a458'
  }

  iconColor(nas: Nas): string {
    return nas.percentageUsedIPs > 90 ? 'color: #fb5800' : 'color: #487c49'
  }
}
