import { RouteConfig } from 'vue-router/types/router'
import { CAN_ACCESS_TICKETS } from '@/permissions/tickets'
import { ROUTER_PARAM_ID } from '@/utils/constants'
import { ticketsPerPage } from '@/models/ticket/constants'

export const ROUTE_TICKETS = 'ROUTE_TICKETS'
export const ROUTE_TICKET_FORM = 'ROUTE_TICKET_FORM'
export const ROUTE_TICKET_VIEW = 'ROUTE_TICKET_VIEW'
export const ROUTE_TICKET_HANDLE = 'ROUTE_TICKET_HANDLE'

export const ROUTE_SUPPORT_PROBLEMS = 'ROUTE_SUPPORT_PROBLEMS'
export const ROUTE_SUPPORT_PROBLEMS_FORM = 'ROUTE_SUPPORT_PROBLEMS_FORM'

export const ROUTE_APPOINTMENT_ACTIONS = 'ROUTE_APPOINTMENT_ACTIONS'
export const ROUTE_APPOINTMENT_ACTIONS_FORM = 'ROUTE_APPOINTMENT_ACTIONS_FORM'

export const ROUTE_CURRENT_USER_TICKETS = 'ROUTE_CURRENT_USER_TICKETS'
export const ROUTE_SERVICE_CALENDAR = 'ROUTE_SERVICE_CALENDAR'

export const ROUTE_SCHEDULES = 'ROUTE_SCHEDULES'
export const ROUTE_SCHEDULES_FORM = 'ROUTE_SCHEDULES_FORM'

const ticketRoutes: RouteConfig[] = [
  {
    name: ROUTE_TICKETS,
    path: `/tickets`,
    meta: { permission: CAN_ACCESS_TICKETS },
    component: () =>
      import(/* webpackChunkName: "tickets" */ '../components/tickets/TicketList.vue'),
    props: () => ({ itemsPerPage: ticketsPerPage }),
  },
  {
    name: ROUTE_TICKET_FORM,
    path: `/tickets/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: CAN_ACCESS_TICKETS },
    component: () =>
      import(/* webpackChunkName: "tickets" */ '../components/tickets/TicketForm.vue'),
    props: (route) => ({ [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]) }),
  },
  {
    name: ROUTE_TICKET_HANDLE,
    path: `/tickets/:${ROUTER_PARAM_ID}(\\d+)/handle`,
    meta: { permission: CAN_ACCESS_TICKETS },
    component: () =>
      import(
        /* webpackChunkName: "tickets" */ '../components/tickets/processor/TicketProcessor.vue'
      ),
    props: (route) => ({ [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]) }),
  },
  {
    name: ROUTE_TICKET_VIEW,
    path: `/tickets/:${ROUTER_PARAM_ID}(\\d+)/view`,
    meta: { permission: CAN_ACCESS_TICKETS },
    component: () =>
      import(/* webpackChunkName: "tickets" */ '../components/tickets/TicketView.vue'),
    props: (route) => ({ [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]) }),
  },

  {
    name: ROUTE_CURRENT_USER_TICKETS,
    path: '/current-user-tickets',
    meta: { permission: CAN_ACCESS_TICKETS },
    component: () =>
      import(/* webpackChunkName: "tickets" */ '../components/tickets/CurrentUserTickets.vue'),
  },

  {
    name: ROUTE_SUPPORT_PROBLEMS,
    path: '/tickets-support-problems',
    meta: { permission: CAN_ACCESS_TICKETS },
    component: () =>
      import(/* webpackChunkName: "tickets" */ '../components/tickets/SupportProblemList.vue'),
  },
  {
    name: ROUTE_SUPPORT_PROBLEMS_FORM,
    path: `/tickets-support-problems/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: CAN_ACCESS_TICKETS },
    component: () =>
      import(/* webpackChunkName: "tickets" */ '../components/tickets/SupportProblemEdit.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },

  {
    name: ROUTE_APPOINTMENT_ACTIONS,
    path: '/appointment-actions',
    meta: { permission: CAN_ACCESS_TICKETS },
    component: () =>
      import(/* webpackChunkName: "tickets" */ '../components/tickets/AppointmentActionList.vue'),
  },
  {
    name: ROUTE_APPOINTMENT_ACTIONS_FORM,
    path: `/appointment-actions/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: CAN_ACCESS_TICKETS },
    component: () =>
      import(/* webpackChunkName: "tickets" */ '../components/tickets/AppointmentActionEdit.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },

  {
    name: ROUTE_SERVICE_CALENDAR,
    path: '/service-calendar',
    meta: { permission: CAN_ACCESS_TICKETS },
    component: () =>
      import(/* webpackChunkName: "tickets" */ '../components/calendars/ServicesCalendar.vue'),
  },

  {
    name: ROUTE_SCHEDULES,
    path: '/schedules',
    meta: { permission: CAN_ACCESS_TICKETS },
    component: () =>
      import(/* webpackChunkName: "tickets" */ '../components/tickets/ScheduleList.vue'),
  },
  {
    name: ROUTE_SCHEDULES_FORM,
    path: `/schedules/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: CAN_ACCESS_TICKETS },
    component: () =>
      import(/* webpackChunkName: "tickets" */ '../components/tickets/ScheduleEdit.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
]

export default ticketRoutes
