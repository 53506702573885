import { uniqueNonZeroNums } from '@/utils/unique_nums'
import { IpPool } from '@/models/internet/IpPool'
import { NasGet } from '@/models/internet/NasGet'
import { InternetSessionGet } from '@/models/internet/InternetSessionGet'

export class IpPoolLoader {
  private readonly items: IpPool[] = []

  constructor(items: IpPool | IpPool[]) {
    this.items = items instanceof IpPool ? [items] : [...items]
  }

  load = async (...loaders: Array<Exclude<keyof IpPoolLoader, 'load'>>): Promise<void> => {
    await Promise.all(loaders.map((method) => this[method]()))
  }

  nas = async (): Promise<void> => {
    const ids = uniqueNonZeroNums(this.items.map((v) => v.nasID))
    if (!ids.length) {
      return
    }

    const items = await NasGet.mapByIDs(ids)

    this.items.forEach((v) => {
      v.nas = items.get(v.nasID) || null
    })
  }

  sessionsInfo = async (): Promise<void> => {
    const ids = uniqueNonZeroNums(this.items.map((v) => v.id))
    if (!ids.length) {
      return
    }

    const items = await InternetSessionGet.mapStatsByIPPool(ids)

    this.items.forEach((v) => {
      const res = items.get(v.id)
      if (res) {
        v.countIP = res.countIP
        v.activeSessions = res.activeSessions
      }
    })
  }
}
