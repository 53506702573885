import { RouteConfig } from 'vue-router/types/router'
import { CAN_ACCESS_STOCK } from '@/permissions/stock'
import { ROUTER_PARAM_ID } from '@/utils/constants'
export const ROUTE_STOCK_HOME = 'ROUTE_STOCK_HOME'
export const ROUTE_STOCK_ENDING_GOODS = 'ROUTE_STOCK_ENDING_GOODS'

export const ROUTE_STOCK_EQUIPMENTS = 'ROUTE_STOCK_EQUIPMENTS'
export const ROUTE_STOCK_EQUIPMENT_VIEW = 'ROUTE_STOCK_EQUIPMENT_VIEW'
export const ROUTE_STOCK_EQUIPMENT_VIEW_BY_PPPOE_LOGIN = 'ROUTE_STOCK_EQUIPMENT_VIEW_BY_PPPOE_LOGIN'
export const ROUTE_STOCK_EQUIPMENT_FORM = 'ROUTE_STOCK_EQUIPMENT_FORM'
export const ROUTE_STOCK_EQUIPMENT_TUNE = 'ROUTE_STOCK_EQUIPMENT_TUNE'
export const ROUTE_EQUIPMENT_ORPHANS = 'ROUTE_EQUIPMENT_ORPHANS'

export const ROUTE_STOCK_EQUIPMENTS_MODELS = 'ROUTE_STOCK_EQUIPMENTS_MODELS'
export const ROUTE_STOCK_EQUIPMENT_MODEL_VIEW = 'ROUTE_STOCK_EQUIPMENT_MODEL_VIEW'
export const ROUTE_STOCK_EQUIPMENT_MODEL_FORM = 'ROUTE_STOCK_EQUIPMENT_MODEL_FORM'

export const ROUTE_STOCK_CONSUMABLES = 'ROUTE_STOCK_CONSUMABLES'
export const ROUTE_STOCK_CONSUMABLE_VIEW = 'ROUTE_STOCK_CONSUMABLE_VIEW'
export const ROUTE_STOCK_CONSUMABLE_FORM = 'ROUTE_STOCK_CONSUMABLE_FORM'

export const ROUTE_STOCK_TYPES = 'ROUTE_STOCK_TYPES'
export const ROUTE_STOCK_TYPE_VIEW = 'ROUTE_STOCK_TYPE_VIEW'
export const ROUTE_STOCK_TYPE_FORM = 'ROUTE_STOCK_TYPE_FORM'

export const ROUTE_STOCK_MANUFACTURERS = 'ROUTE_STOCK_MANUFACTURERS'
export const ROUTE_STOCK_MANUFACTURER_VIEW = 'ROUTE_STOCK_MANUFACTURER_VIEW'
export const ROUTE_STOCK_MANUFACTURER_FORM = 'ROUTE_STOCK_MANUFACTURER_FORM'

export const ROUTE_STOCK_PROVIDERS = 'ROUTE_STOCK_PROVIDERS'
export const ROUTE_STOCK_PROVIDER_VIEW = 'ROUTE_STOCK_PROVIDER_VIEW'
export const ROUTE_STOCK_PROVIDER_FORM = 'ROUTE_STOCK_PROVIDER_FORM'

export const ROUTE_STOCK_WAYBILLS = 'ROUTE_STOCK_WAYBILLS'
export const ROUTE_STOCK_WAYBILLS_ISSUED = 'ROUTE_STOCK_WAYBILLS_ISSUED'
export const ROUTE_STOCK_WAYBILLS_INCOMING = 'ROUTE_STOCK_WAYBILLS_INCOMING'
export const ROUTE_STOCK_WAYBILL_VIEW = 'ROUTE_STOCK_WAYBILL_VIEW'
export const ROUTE_STOCK_WAYBILL_EDIT = 'ROUTE_STOCK_WAYBILL_EDIT'

export const ROUTE_GOODS_RETURN = 'ROUTE_GOODS_RETURN'
export const ROUTE_GOODS_ISSUE = 'ROUTE_GOODS_ISSUE'
export const ROUTE_GOODS_INCOMING = 'ROUTE_GOODS_INCOMING'

export const ROUTE_STOCK_OUTSTANDING = 'ROUTE_STOCK_OUTSTANDING'

export const ROUTE_STOCK_LOAD_EQUIPMENTS_FILE = 'ROUTE_STOCK_LOAD_EQUIPMENTS_FILE'
export const ROUTE_STOCK_EDIT_EQUIPMENTS_FILE = 'ROUTE_STOCK_EDIT_EQUIPMENTS_FILE'

export const PARAM_EQUIPMENT_READINESS_STATUS = 'readinessStatus'

export const ROUTE_STOCK_MOVEMENTS = 'ROUTE_STOCK_MOVEMENTS'
export const ROUTE_STOCK_MOVEMENT_VIEW = 'ROUTE_STOCK_MOVEMENT_VIEW'

const stockRoutes: RouteConfig[] = [
  {
    name: ROUTE_STOCK_HOME,
    path: '/stock',
    meta: { permission: CAN_ACCESS_STOCK },
    component: () =>
      import(/* webpackChunkName: "stock" */ '../components/stock/warehouse/StockHome.vue'),
  },
  {
    name: ROUTE_STOCK_ENDING_GOODS,
    path: '/stock/ending-goods',
    meta: { permission: CAN_ACCESS_STOCK },
    component: () =>
      import(/* webpackChunkName: "stock" */ '../components/stock/StockEndingGoods.vue'),
  },

  {
    name: ROUTE_STOCK_CONSUMABLES,
    path: '/stock/consumables',
    meta: { permission: CAN_ACCESS_STOCK },
    component: () =>
      import(/* webpackChunkName: "stock" */ '../components/stock/consumable/ConsumableList.vue'),
  },
  {
    name: ROUTE_STOCK_CONSUMABLE_VIEW,
    path: `/stock/consumables/:${ROUTER_PARAM_ID}(\\d+)`,
    meta: { permission: CAN_ACCESS_STOCK },
    component: () =>
      import(/* webpackChunkName: "stock" */ '../components/stock/consumable/ConsumableView.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
  {
    name: ROUTE_STOCK_CONSUMABLE_FORM,
    path: `/stock/consumables/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: CAN_ACCESS_STOCK },
    component: () =>
      import(/* webpackChunkName: "stock" */ '../components/stock/consumable/ConsumableEdit.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },

  {
    name: ROUTE_STOCK_EQUIPMENT_VIEW,
    path: `/stock/equipments/:${ROUTER_PARAM_ID}(\\d+)`,
    meta: { permission: CAN_ACCESS_STOCK },
    component: () =>
      import(/* webpackChunkName: "stock" */ '../components/stock/equipment/EquipmentView.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
  {
    name: ROUTE_STOCK_EQUIPMENT_VIEW_BY_PPPOE_LOGIN,
    path: `/stock/equipments/:pppoe_login`,
    meta: { permission: CAN_ACCESS_STOCK },
    component: () =>
      import(
        /* webpackChunkName: "stock" */ '../components/stock/equipment/EquipmentViewByPppoeLogin.vue'
      ),
    props: (route) => ({
      pppoeLogin: route.params['pppoe_login'],
    }),
  },
  {
    name: ROUTE_STOCK_EQUIPMENTS,
    path: `/stock/equipments`,
    meta: { permission: CAN_ACCESS_STOCK },
    component: () =>
      import(/* webpackChunkName: "stock" */ '../components/stock/equipment/EquipmentList.vue'),
  },
  {
    name: ROUTE_EQUIPMENT_ORPHANS,
    path: `/stock/equipments/orphans`,
    meta: { permission: CAN_ACCESS_STOCK },
    component: () =>
      import(/* webpackChunkName: "stock" */ '../components/stock/equipment/EquipmentOrphans.vue'),
  },
  {
    name: ROUTE_STOCK_EQUIPMENT_FORM,
    path: `/stock/equipments/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: CAN_ACCESS_STOCK },
    component: () =>
      import(/* webpackChunkName: "stock" */ '../components/stock/equipment/EquipmentEdit.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
  {
    name: ROUTE_STOCK_EQUIPMENT_TUNE,
    path: `/stock/equipments/tune/:${ROUTER_PARAM_ID}(\\d+)`,
    meta: { permission: CAN_ACCESS_STOCK },
    component: () =>
      import(/* webpackChunkName: "stock" */ '../components/stock/equipment/EquipmentTune.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },

  {
    name: ROUTE_STOCK_EQUIPMENTS_MODELS,
    path: '/stock/equipments-models',
    meta: { permission: CAN_ACCESS_STOCK },
    component: () =>
      import(
        /* webpackChunkName: "stock" */ '../components/stock/equipment_model/EquipmentModelList.vue'
      ),
  },
  {
    name: ROUTE_STOCK_EQUIPMENT_MODEL_VIEW,
    path: `/stock/equipments-models/:${ROUTER_PARAM_ID}(\\d+)`,
    meta: { permission: CAN_ACCESS_STOCK },
    component: () =>
      import(
        /* webpackChunkName: "stock" */ '../components/stock/equipment_model/EquipmentModelView.vue'
      ),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
  {
    name: ROUTE_STOCK_EQUIPMENT_MODEL_FORM,
    path: `/stock/equipments-models/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: CAN_ACCESS_STOCK },
    component: () =>
      import(
        /* webpackChunkName: "stock" */ '../components/stock/equipment_model/EquipmentModelEdit.vue'
      ),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },

  {
    name: ROUTE_STOCK_TYPES,
    path: '/stock/types',
    meta: { permission: CAN_ACCESS_STOCK },
    component: () =>
      import(/* webpackChunkName: "stock" */ '../components/stock/type/TypeList.vue'),
  },
  {
    name: ROUTE_STOCK_TYPE_VIEW,
    path: `/stock/types/:${ROUTER_PARAM_ID}(\\d+)`,
    meta: { permission: CAN_ACCESS_STOCK },
    component: () =>
      import(/* webpackChunkName: "stock" */ '../components/stock/type/TypeView.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
  {
    name: ROUTE_STOCK_TYPE_FORM,
    path: `/stock/types/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: CAN_ACCESS_STOCK },
    component: () =>
      import(/* webpackChunkName: "stock" */ '../components/stock/type/TypeEdit.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },

  {
    name: ROUTE_STOCK_MANUFACTURERS,
    path: '/stock/manufacturers',
    meta: { permission: CAN_ACCESS_STOCK },
    component: () =>
      import(
        /* webpackChunkName: "stock" */ '../components/stock/manufacturer/ManufacturerList.vue'
      ),
  },
  {
    name: ROUTE_STOCK_MANUFACTURER_VIEW,
    path: `/stock/manufacturers/:${ROUTER_PARAM_ID}(\\d+)`,
    meta: { permission: CAN_ACCESS_STOCK },
    component: () =>
      import(
        /* webpackChunkName: "stock" */ '../components/stock/manufacturer/ManufacturerView.vue'
      ),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
  {
    name: ROUTE_STOCK_MANUFACTURER_FORM,
    path: `/stock/manufacturers/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: CAN_ACCESS_STOCK },
    component: () =>
      import(
        /* webpackChunkName: "stock" */ '../components/stock/manufacturer/ManufacturerEdit.vue'
      ),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },

  {
    name: ROUTE_STOCK_PROVIDERS,
    path: '/stock/providers',
    meta: { permission: CAN_ACCESS_STOCK },
    component: () =>
      import(/* webpackChunkName: "stock" */ '../components/stock/provider/ProviderList.vue'),
  },
  {
    name: ROUTE_STOCK_PROVIDER_VIEW,
    path: `/stock/providers/:${ROUTER_PARAM_ID}(\\d+)`,
    meta: { permission: CAN_ACCESS_STOCK },
    component: () =>
      import(/* webpackChunkName: "stock" */ '../components/stock/provider/ProviderView.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
  {
    name: ROUTE_STOCK_PROVIDER_FORM,
    path: `/stock/providers/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: CAN_ACCESS_STOCK },
    component: () =>
      import(/* webpackChunkName: "stock" */ '../components/stock/provider/ProviderEdit.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },

  {
    name: ROUTE_GOODS_RETURN,
    path: '/goods-return',
    meta: { permission: CAN_ACCESS_STOCK },
    component: () =>
      import(/* webpackChunkName: "stock" */ '../components/stock/warehouse/ReturnGoods.vue'),
  },
  {
    name: ROUTE_GOODS_ISSUE,
    path: '/goods-issue/:' + PARAM_EQUIPMENT_READINESS_STATUS + '(\\d+)',
    meta: { permission: CAN_ACCESS_STOCK },
    component: () =>
      import(/* webpackChunkName: "stock" */ '../components/stock/warehouse/IssueGoods.vue'),
    props: (route) => ({
      [PARAM_EQUIPMENT_READINESS_STATUS]: Number(route.params[PARAM_EQUIPMENT_READINESS_STATUS]),
    }),
  },
  {
    name: ROUTE_GOODS_INCOMING,
    path: '/goods-incoming',
    meta: { permission: CAN_ACCESS_STOCK },
    component: () =>
      import(/* webpackChunkName: "stock" */ '../components/stock/warehouse/IncomingGoods.vue'),
  },
  {
    name: ROUTE_STOCK_LOAD_EQUIPMENTS_FILE,
    path: '/add-equipments',
    meta: { permission: CAN_ACCESS_STOCK },
    component: () =>
      import(/* webpackChunkName: "stock" */ '../components/stock/warehouse/AddEquipments.vue'),
  },
  {
    name: ROUTE_STOCK_EDIT_EQUIPMENTS_FILE,
    path: '/update-equipments',
    meta: { permission: CAN_ACCESS_STOCK },
    component: () =>
      import(/* webpackChunkName: "stock" */ '../components/stock/warehouse/UpdateEquipments.vue'),
  },

  {
    name: ROUTE_STOCK_WAYBILLS,
    path: '/stock/waybills',
    meta: { permission: CAN_ACCESS_STOCK },
    component: () =>
      import(/* webpackChunkName: "stock" */ '../components/stock/waybill/WaybillList.vue'),
  },
  {
    name: ROUTE_STOCK_WAYBILLS_ISSUED,
    path: '/stock/waybills/issued',
    meta: { permission: CAN_ACCESS_STOCK },
    component: () =>
      import(/* webpackChunkName: "stock" */ '../components/stock/waybill/WaybillListIssued.vue'),
  },
  {
    name: ROUTE_STOCK_WAYBILLS_INCOMING,
    path: '/stock/waybills/incoming',
    meta: { permission: CAN_ACCESS_STOCK },
    component: () =>
      import(/* webpackChunkName: "stock" */ '../components/stock/waybill/WaybillListIncoming.vue'),
  },
  {
    name: ROUTE_STOCK_WAYBILL_VIEW,
    path: '/stock/waybills/view/:' + ROUTER_PARAM_ID + '(\\d+)',
    meta: { permission: CAN_ACCESS_STOCK },
    component: () =>
      import(/* webpackChunkName: "stock" */ '../components/stock/waybill/WaybillView.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
  {
    name: ROUTE_STOCK_WAYBILL_EDIT,
    path: '/waybill-edit/:' + ROUTER_PARAM_ID + '(\\d+)',
    meta: { permission: CAN_ACCESS_STOCK },
    component: () =>
      import(/* webpackChunkName: "stock" */ '../components/stock/waybill/WaybillUpdater.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },

  {
    name: ROUTE_STOCK_OUTSTANDING,
    path: '/stock/outstanding',
    meta: { permission: CAN_ACCESS_STOCK },
    component: () =>
      import(/* webpackChunkName: "stock" */ '../components/stock/OutstandingList.vue'),
  },

  {
    name: ROUTE_STOCK_MOVEMENTS,
    path: '/stock/movements',
    meta: { permission: CAN_ACCESS_STOCK },
    component: () =>
      import(/* webpackChunkName: "stock" */ '../components/stock/movements/MovementList.vue'),
  },
  {
    name: ROUTE_STOCK_MOVEMENT_VIEW,
    path: `/stock/movements/:${ROUTER_PARAM_ID}(\\d+)`,
    meta: { permission: CAN_ACCESS_STOCK },
    component: () =>
      import(/* webpackChunkName: "stock" */ '../components/stock/movements/MovementView.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
]

export default stockRoutes
