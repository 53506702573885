
import { Component, Vue, Watch } from 'vue-property-decorator'
import NavPartClients from '@/components/app/nav/NavPartClients.vue'
import NavPartTickets from '@/components/app/nav/NavPartTickets.vue'
import NavPartCalendars from '@/components/app/nav/NavPartCalendars.vue'
import NavPartTags from '@/components/app/nav/NavPartTags.vue'
import NavPartStock from '@/components/app/nav/NavPartStock.vue'
import NavPartGeo from '@/components/app/nav/NavPartGeo.vue'
import NavPartSubscriptions from '@/components/app/nav/NavPartSubscriptions.vue'
import NavPartPayments from '@/components/app/nav/NavPartPayments.vue'
import NavPartTariffs from '@/components/app/nav/NavPartTariffs.vue'
import NavPartServices from '@/components/app/nav/NavPartServices.vue'
import NavPartUsers from '@/components/app/nav/NavPartUsers.vue'
import { NavItem } from '@/components/app/nav/types'
import { EVENT_INPUT } from '@/utils/event-constants'
import NavPartInternet from '@/components/app/nav/NavPartInternet.vue'
import NavDashboard from '@/components/app/nav/NavDashboard.vue'
import _debounce from 'lodash/debounce'
import NavPartCablemovil from '@/components/app/nav/NavPartCablemovil.vue'
import NavPartSewan from '@/components/app/nav/NavPartSewan.vue'

const LOCAL_STORAGE_KEY = 'main-menu-is-opened'
@Component({
  components: {
    NavPartSewan,
    NavPartCablemovil,
    NavDashboard,
    NavPartInternet,
    NavPartUsers,
    NavPartServices,
    NavPartTariffs,
    NavPartPayments,
    NavPartSubscriptions,
    NavPartGeo,
    NavPartStock,
    NavPartTags,
    NavPartCalendars,
    NavPartTickets,
    NavPartClients,
  },
})
export default class Nav extends Vue {
  EVENT_INPUT = EVENT_INPUT
  isOpen = true
  navItems: NavItem[] = []

  created(): void {
    this.loadState()
  }

  loadState(): void {
    let res = localStorage.getItem(LOCAL_STORAGE_KEY)
    this.isOpen = res === 'true'
  }

  changeIsOpened(): void {
    this.isOpen = !this.isOpen
    localStorage.setItem(LOCAL_STORAGE_KEY, this.isOpen.toString())
  }

  addNavItems(items: NavItem[]): void {
    this.navItems.push(...items)
    this.debouncedSetTitle()
  }

  debouncedSetTitle: () => void = _debounce(this.setTitle, 100)

  @Watch('$route', { immediate: true })
  setTitle(): void {
    const currentRoute = this.$router.currentRoute.name
    for (let item of this.navItems) {
      if (item.routeName === currentRoute) {
        document.title = item.name
        return
      }
    }

    document.title = ''
  }
}
