import { CommonModel, IModel } from '@/models/Model'
import { MainStore } from '@/store/MainStore'
import { geoApi } from '@/api/geo-api'
import { Country, ICountry } from '@/models/geo/Country'
import { coloringName } from '@/models/geo/utils'
import { GeoStatus, isValid, isVerified, statusHtml } from '@/models/geo/constants'

export interface IProvince extends IModel {
  id: number
  name: string
  code: string
  countryID: number
  status: GeoStatus
  weight: number
  country: ICountry | null
}

export class Province extends CommonModel implements IProvince {
  id = 0
  name = ''
  code = ''
  countryID = 0
  status: GeoStatus = 0
  weight = 0
  country: Country | null = null

  get isValidForm(): boolean {
    return (
      Boolean(this.id) ||
      Boolean(this.name && this.code && this.country && this.country.isValidForm)
    )
  }

  get isValid(): boolean {
    return isValid(this.status)
  }

  get isVerified(): boolean {
    return isVerified(this.status)
  }

  get statusHtml(): string {
    return statusHtml(this.status)
  }

  get nameStr(): string {
    return this.name
  }

  get titleHtml(): string {
    return coloringName(this)
  }

  constructor(src: IProvince | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: IProvince) {
    Object.assign(this, src)
    this.country = src.country ? new Country(src.country) : null

    this.initModel(src)
  }

  async save(): Promise<boolean> {
    try {
      return this.id ? await this.update() : await this.add()
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async add(): Promise<boolean> {
    try {
      this.init(await geoApi.province.add(this.prepare().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async update(): Promise<boolean> {
    try {
      this.init(await geoApi.province.update(this.prepare().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async delete(): Promise<boolean> {
    if (!confirm(`Are you sure you want to delete province?`)) {
      return false
    }

    try {
      await geoApi.province.delete(this.id)
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async approve(): Promise<boolean> {
    if (!confirm(`Are you sure you want to confirm the entry?`)) {
      return false
    }

    try {
      this.init(await geoApi.province.approve({ id: this.id }))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async reject(): Promise<boolean> {
    if (!confirm(`Are you sure you want to reject the entry?`)) {
      return false
    }

    try {
      this.init(await geoApi.province.reject({ id: this.id }))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  prepare(): Province {
    this.countryID = this.country ? this.country.id : 0

    return this
  }

  toInterface(): IProvince {
    return {
      id: this.id,
      name: this.name,
      code: this.code,
      status: this.status,
      countryID: this.countryID,
      weight: this.weight,
      country: this.country ? this.country.toInterface() : null,
    }
  }
}
