
import { Component, Prop, Vue } from 'vue-property-decorator'
import { NavItem, NavSection } from '@/components/app/nav/types'
import { MainStore } from '@/store/MainStore'
import NavPart from '@/components/app/nav/NavPart.vue'
import {
  ROUTE_ADDITIONAL_CHARGES,
  ROUTE_ADDITIONAL_CHARGES_LOAD,
  ROUTE_ADDITIONAL_LANDLINE_CHARGES_LOAD,
  ROUTE_GENERATE_SEPA_CSV,
  ROUTE_PARTNER_FIBRE,
  ROUTE_SUBSCRIPTION_ORPHANS,
  ROUTE_SUBSCRIPTIONS,
} from '@/router/subscriptions'
import { CAN_ACCESS_CORE } from '@/permissions/core'
import { CAN_GENERATE_SEPA } from '@/permissions/core_server'
import { EVENT_INPUT } from '@/utils/event-constants'

@Component({
  components: { NavPart },
})
export default class NavPartSubscriptions extends Vue {
  @Prop({ type: Boolean }) isOpen!: boolean

  get isShow(): boolean {
    return MainStore.isUserCan(CAN_ACCESS_CORE)
  }

  section = new NavSection('Subscriptions', 'fas fa-pen-square', ROUTE_SUBSCRIPTIONS, [
    new NavItem('Subscriptions', ROUTE_SUBSCRIPTIONS, 'fas fa-pen-nib'),
    new NavItem('Additional charges', ROUTE_ADDITIONAL_CHARGES, 'fas fa-puzzle-piece'),
    new NavItem('Load mobile charges', ROUTE_ADDITIONAL_CHARGES_LOAD, 'fas fa-upload'),
    new NavItem(
      'Load landline charges',
      ROUTE_ADDITIONAL_LANDLINE_CHARGES_LOAD,
      'fas fa-file-upload'
    ),
    new NavItem('Generate SEPA', ROUTE_GENERATE_SEPA_CSV, 'fas fa-file-csv').withPerms(
      CAN_GENERATE_SEPA
    ),
    new NavItem('Partner fibre', ROUTE_PARTNER_FIBRE, 'far fa-handshake'),
    new NavItem('Orphans', ROUTE_SUBSCRIPTION_ORPHANS, 'fas fa-unlink'),
  ])

  created(): void {
    this.$emit(EVENT_INPUT, this.section.items)
  }
}
