import { RouteConfig } from 'vue-router'
import { ROUTER_PARAM_ID } from '@/utils/constants'
import { CAN_ACCESS_TAGS } from '@/permissions/tags'

export const ROUTE_TAGS = 'ROUTE_TAGS'
export const ROUTE_TAG_FORM = 'ROUTE_TAG_FORM'
export const ROUTE_TAG_VIEW = 'ROUTE_TAG_VIEW'

export const ROUTE_PERSON_TAGS = 'ROUTE_PERSON_TAGS'

const tagsRoutes: RouteConfig[] = [
  {
    name: ROUTE_TAGS,
    path: `/tags`,
    meta: { permission: CAN_ACCESS_TAGS },
    component: () => import(/* webpackChunkName: "tags" */ '../components/tags/TagList.vue'),
    props: true,
  },
  {
    name: ROUTE_TAG_FORM,
    path: `/tags/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: CAN_ACCESS_TAGS },
    component: () => import(/* webpackChunkName: "tags" */ '../components/tags/TagEdit.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
  {
    name: ROUTE_TAG_VIEW,
    path: `/tags/:${ROUTER_PARAM_ID}(\\d+)`,
    meta: { permission: CAN_ACCESS_TAGS },
    component: () => import(/* webpackChunkName: "tags" */ '../components/tags/TagView.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },

  {
    name: ROUTE_PERSON_TAGS,
    path: `/person-tags`,
    meta: { permission: CAN_ACCESS_TAGS },
    component: () => import(/* webpackChunkName: "tags" */ '../components/tags/PersonTagsList.vue'),
    props: true,
  },
]

export default tagsRoutes
