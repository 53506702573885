import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { moduleGeo } from '@/store/modules/geo/state'
import getters from '@/store/getters'
import actions from '@/store/actions'
import mutations from '@/store/mutations'
import { User } from '@/models/user/user'
import { Toast } from '@/models/Toast'
import { ISettings, Settings } from '@/models/Settings'
import { PersonSimple } from '@/models/persons/PersonSimple'

Vue.use(Vuex)

export class RootState {
  user = new User()
  person = new PersonSimple()
  toasts: Array<Toast> = []
  settings: ISettings = new Settings()
  isLoading = false
}

const store: StoreOptions<RootState> = {
  state: () => new RootState(),
  getters,
  actions,
  mutations,

  modules: {
    moduleGeo,
  },
}

export default new Vuex.Store<RootState>(store)
