import { RouteConfig } from 'vue-router/types/router'
import { ROUTER_PARAM_ID } from '@/utils/constants'
import { CAN_ACCESS_INTERNET } from '@/permissions/internet'

export const ROUTE_INTERNET_NAS = 'ROUTE_INTERNET_NAS'
export const ROUTE_INTERNET_NAS_FORM = 'ROUTE_INTERNET_NAS_FORM'
export const ROUTE_INTERNET_NAS_VIEW = 'ROUTE_INTERNET_NAS_VIEW'

export const ROUTE_INTERNET_IP_POOLS = 'ROUTE_INTERNET_IP_POOLS'
export const ROUTE_INTERNET_IP_POOLS_FORM = 'ROUTE_INTERNET_IP_POOLS_FORM'

export const ROUTE_INTERNET_SESSIONS = 'ROUTE_INTERNET_SESSIONS'
export const ROUTE_INTERNET_SESSIONS_VIEW = 'ROUTE_INTERNET_SESSIONS_VIEW'
export const ROUTE_INTERNET_SESSIONS_VIEW_BY_SERVICE = 'ROUTE_INTERNET_SESSIONS_VIEW_BY_SERVICE'
export const ROUTE_INTERNET_SESSIONS_VIEW_BY_USERNAME = 'ROUTE_INTERNET_SESSIONS_VIEW_BY_USERNAME'
export const ROUTE_INTERNET_SESSIONS_ERRORS = 'ROUTE_INTERNET_SESSIONS_ERRORS'

const internetRoutes: RouteConfig[] = [
  {
    name: ROUTE_INTERNET_NAS,
    path: '/nas',
    meta: { permission: CAN_ACCESS_INTERNET },
    component: () =>
      import(/* webpackChunkName: "internet" */ '../components/internet/NasList.vue'),
  },
  {
    name: ROUTE_INTERNET_NAS_FORM,
    path: `/nas/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: CAN_ACCESS_INTERNET },
    component: () =>
      import(/* webpackChunkName: "internet" */ '../components/internet/NasEdit.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
  {
    name: ROUTE_INTERNET_NAS_VIEW,
    path: `/nas/:${ROUTER_PARAM_ID}(\\d+)`,
    meta: { permission: CAN_ACCESS_INTERNET },
    component: () =>
      import(/* webpackChunkName: "internet" */ '../components/internet/NasView.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },

  {
    name: ROUTE_INTERNET_IP_POOLS,
    path: '/ip-pools',
    meta: { permission: CAN_ACCESS_INTERNET },
    component: () =>
      import(/* webpackChunkName: "internet" */ '../components/internet/IpPoolList.vue'),
  },
  {
    name: ROUTE_INTERNET_IP_POOLS_FORM,
    path: `/ip-pools/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: CAN_ACCESS_INTERNET },
    component: () =>
      import(/* webpackChunkName: "internet" */ '../components/internet/IpPoolEdit.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },

  {
    name: ROUTE_INTERNET_SESSIONS,
    path: '/internet-sessions',
    meta: { permission: CAN_ACCESS_INTERNET },
    component: () =>
      import(/* webpackChunkName: "internet" */ '../components/internet/InternetSessionList.vue'),
  },
  {
    name: ROUTE_INTERNET_SESSIONS_VIEW,
    path: `/internet-sessions/:${ROUTER_PARAM_ID}(\\d+)`,
    meta: { permission: CAN_ACCESS_INTERNET },
    component: () =>
      import(/* webpackChunkName: "internet" */ '../components/internet/InternetSessionView.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
  {
    name: ROUTE_INTERNET_SESSIONS_VIEW_BY_SERVICE,
    path: `/internet-sessions/service/:serviceId(\\d+)`,
    meta: { permission: CAN_ACCESS_INTERNET },
    component: () =>
      import(
        /* webpackChunkName: "internet" */ '../components/internet/InternetSessionViewByService.vue'
      ),
    props: (route) => ({
      serviceId: Number(route.params['serviceId']),
    }),
  },
  {
    name: ROUTE_INTERNET_SESSIONS_VIEW_BY_USERNAME,
    path: `/internet-sessions/user/:username`,
    meta: { permission: CAN_ACCESS_INTERNET },
    component: () =>
      import(
        /* webpackChunkName: "internet" */ '../components/internet/InternetSessionViewByUsername.vue'
      ),
    props: (route) => ({
      username: route.params['username'],
    }),
  },

  {
    name: ROUTE_INTERNET_SESSIONS_ERRORS,
    path: '/internet-sessions-errors',
    meta: { permission: CAN_ACCESS_INTERNET },
    component: () =>
      import(
        /* webpackChunkName: "internet" */ '../components/internet/InternetSessionErrorList.vue'
      ),
  },
]

export default internetRoutes
