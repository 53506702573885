
import { Component, Prop, Vue } from 'vue-property-decorator'
import { NavItem, NavSection } from '@/components/app/nav/types'
import { MainStore } from '@/store/MainStore'
import NavPart from '@/components/app/nav/NavPart.vue'
import { CAN_ACCESS_TICKETS } from '@/permissions/tickets'
import { ROUTE_SCHEDULES, ROUTE_SERVICE_CALENDAR } from '@/router/tickets'
import { EVENT_INPUT } from '@/utils/event-constants'
import { ROUTE_HOLIDAY_REPORT, ROUTE_WORKFLOWS, ROUTE_WORKFLOWS_CALENDAR } from '@/router/persons'
import { CAN_VIEW_WORKFLOW } from '@/permissions/persons_server'
@Component({
  components: { NavPart },
})
export default class NavPartCalendars extends Vue {
  @Prop({ type: Boolean }) isOpen!: boolean

  get isShow(): boolean {
    return MainStore.isUserCan(CAN_ACCESS_TICKETS)
  }

  section = new NavSection('Calendars', 'fas fa-calendar-alt', ROUTE_SERVICE_CALENDAR, [
    new NavItem('Install & Service', ROUTE_SERVICE_CALENDAR, 'fas fa-toolbox'),
    new NavItem('Schedule', ROUTE_SCHEDULES, 'far fa-clock'),
    new NavItem('Holiday Calendar', ROUTE_WORKFLOWS_CALENDAR, 'fas fa-laptop-house').withPerms(
      CAN_VIEW_WORKFLOW
    ),
    new NavItem('Workflows', ROUTE_WORKFLOWS, 'fas fa-list-alt').withPerms(CAN_VIEW_WORKFLOW),
    new NavItem('Holiday Report', ROUTE_HOLIDAY_REPORT, 'fas fa-flag-checkered').withPerms(
      CAN_VIEW_WORKFLOW
    ),
  ])

  created(): void {
    this.$emit(EVENT_INPUT, this.section.items)
  }
}
