import { CommonModel, IModel } from '@/models/Model'
import { MainStore } from '@/store/MainStore'
import { personsApi } from '@/api/persons-api'
import { Installation } from '@/models/subscription/Installation'
import { AddressType, addressTypes } from '@/models/persons/constants'
import { GeoDetails, IGeoDetails } from '@/models/geo/GeoDetails'
import { Contract } from '@/models/persons/Contract'
import { PersonSimple } from '@/models/persons/PersonSimple'

export interface IAddress extends IModel {
  id: number
  personID: number
  isMain: boolean
  num: string
  type: AddressType
  postcode: string
  latitude: number
  longitude: number
  fullName: string
  note: string
  mapLink: string
  w3wString: string
  streetID?: number
  districtID?: number
  communityID?: number
  urbanizationID?: number
  geoDetails: IGeoDetails | null
}

export class Address extends CommonModel implements IAddress {
  id = 0
  personID = 0
  isMain = false
  num = ''
  type: AddressType = 0
  postcode = ''
  latitude = 0
  longitude = 0
  fullName = ''
  note = ''
  mapLink = ''
  w3wString = ''
  streetID = 0
  districtID?: number
  communityID?: number
  urbanizationID?: number
  geoDetails: GeoDetails | null = null

  // after load
  installations: Installation[] = []
  person: PersonSimple | null = null

  contract: Contract | null = null

  constructor(src: IAddress | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: IAddress) {
    Object.assign(this, src)

    this.geoDetails = src.geoDetails ? new GeoDetails(src.geoDetails) : null

    this.initStreetNum()

    this.initModel(src)
  }

  // проверить что уже некоторые поля были заполнены оператором на форме быстрой установки
  get isFilled(): boolean {
    return Boolean(this.num || this.postcode || this.note || this.mapLink)
  }

  get fullNameOrGeoDetail(): string {
    return this.fullName
      ? this.fullName
      : this.geoDetails
      ? this.geoDetails.fullName(this.postcode)
      : ''
  }

  get hasService(): boolean {
    return this.type == AddressType.Service || this.type == AddressType.ServiceAndPayment
  }

  get isValid(): boolean {
    return Boolean(this.type && this.geoDetails && this.geoDetails.isValidForm)
  }

  get nameWithContractHtml(): string {
    let res = this.fullName
    if (this.contract) {
      res = `<b>${this.contract.formattedID}: </b>${this.fullName}`
      if (this.contract.isCanceled) {
        res = `<span class="text-crossed">${res}</span>`
      }
    }

    return res
  }

  get formattedContractID(): string {
    return this.contract ? this.contract.formattedID : ''
  }

  get mapLinkHtml(): string {
    const res: string[] = []
    this.mapLink && res.push(`<a href="${this.mapLink}" target="_blank">Link</a>`)
    this.w3wString && res.push(this.w3wStringHtml())

    return res.join('<br>')
  }

  get mapLinkClass(): string {
    if (this.mapLink) {
      return 'text-success'
    }
    if (this.isValid) {
      return 'text-primary'
    }
    if (this.fullName) {
      return 'text-secondary'
    }

    return ''
  }

  get mapSearchHref(): string {
    if (this.mapLink) {
      return this.mapLink
    }

    let q = ''

    if (this.isValid) {
      if (this.geoDetails && this.geoDetails.street) {
        q = this.geoDetails.street.typeName + ' ' + this.geoDetails.street.name + ' ' + this.num
        if (this.geoDetails.street.municipality) {
          q += ', ' + this.geoDetails.street.municipality.nameStr
        }
      }
    }
    if (!q && this.fullName) {
      q = this.fullName
    }

    return 'https://maps.google.com/maps?q=' + q
  }

  get typeName(): string {
    return addressTypes[this.type]
  }

  w3wStringHtml(width = 36): string {
    return this.w3wString
      ? `<a style="position: relative; top: -4px;" href="https://w3w.co/${this.w3wString}" target="_blank"><img width="${width}" src="/img/w3w/red.svg" alt="W3W"></a>`
      : ''
  }

  async save(): Promise<boolean> {
    try {
      return this.id ? await this.update() : await this.add()
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async add(): Promise<boolean> {
    try {
      this.setGeoNum()
      if (this.geoDetails && !(await this.geoDetails.save())) {
        return false
      }
      this.init(await personsApi.address.add(this.toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    } finally {
      this.initStreetNum()
    }
  }

  async update(): Promise<boolean> {
    try {
      this.setGeoNum()
      if (this.geoDetails && !(await this.geoDetails.save())) {
        return false
      }
      this.init(await personsApi.address.update(this.toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    } finally {
      this.initStreetNum()
    }
  }

  setGeoNum(): Address {
    if (this.geoDetails && this.geoDetails.street) {
      this.num = this.geoDetails.street.numByForm
    }

    return this
  }

  initStreetNum(): void {
    if (this.geoDetails && this.geoDetails.street) {
      this.geoDetails.street.numByForm = this.num
    }
  }

  async delete(): Promise<boolean> {
    if (!confirm(`Are you sure you want to delete address?`)) return false

    try {
      await personsApi.address.delete(this.id)
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  toInterface(): IAddress {
    return {
      id: this.id,
      personID: this.personID,
      isMain: this.isMain,
      num: this.num,
      type: this.type,
      postcode: this.postcode,
      latitude: this.latitude,
      longitude: this.longitude,
      fullName: this.fullName,
      note: this.note,
      mapLink: this.mapLink,
      w3wString: this.w3wString,
      streetID: this.geoDetails && this.geoDetails.street ? this.geoDetails.street.id : 0,
      districtID: this.geoDetails && this.geoDetails.district ? this.geoDetails.district.id : 0,
      communityID: this.geoDetails && this.geoDetails.community ? this.geoDetails.community.id : 0,
      urbanizationID:
        this.geoDetails && this.geoDetails.urbanization ? this.geoDetails.urbanization.id : 0,

      geoDetails: this.geoDetails ? this.geoDetails.toInterface() : null,
    }
  }
}
