import { MainStore } from '@/store/MainStore'
import { personsApi } from '@/api/persons-api'
import { Discount } from '@/models/discounts/Discount'
import { Address, IAddress } from '@/models/persons/Address'
import { BillingLink, IBillingLink } from '@/models/persons/BillingLink'
import { ILanguage, Language } from '@/models/persons/Language'
import { Contact, IContact } from '@/models/persons/Contact'
import { Document, IDocument } from '@/models/persons/Document'
import { IPersonSimple, PersonSimple } from '@/models/persons/PersonSimple'
import { Contract, IContract } from '@/models/persons/Contract'
import { PersonTags } from '@/models/tags/person_tags'
import { Equipment } from '@/models/stock/Equipment'
import { User } from '@/models/user/user'
import { PersonPaymentMethod } from '@/models/payments/PersonPaymentMethod'
import { SettingType } from '@/models/service/constants'
import { ContactType } from '@/models/persons/constants'
import { AllPersonDiscountsRequest } from '@/requests/discounts'
import { HolidayReport } from '@/models/calendars/HolidayReport'
import { Service } from '@/models/service/Service'
import { PersonBankAccount } from '@/models/payments/PersonBankAccount'

export interface IPerson extends IPersonSimple {
  contacts: IContact[]
  addresses: IAddress[]
  documents: IDocument[]
  languages: ILanguage[]
  billingLinks: IBillingLink[]
  contracts: IContract[]
}

export class Person extends PersonSimple implements IPerson {
  contacts: Contact[] = []
  addresses: Address[] = []
  documents: Document[] = []
  languages: Language[] = []
  billingLinks: BillingLink[] = []
  contracts: Contract[] = []

  // after load
  user: User | null = null
  equipments: Equipment[] = []
  discounts: Discount[] = []
  tags: PersonTags | null = null
  paymentMethods: PersonPaymentMethod[] = []
  holidayReport: HolidayReport | null = null

  constructor(src: IPerson | null = null) {
    super(src)

    if (src) {
      this.init(src)
    }
  }

  protected init(src: IPerson): void {
    super.init(src)

    this.types = src.types || []
    this.contacts = src.contacts ? src.contacts.map((v) => new Contact(v)) : []
    this.addresses = src.addresses ? src.addresses.map((v) => new Address(v)) : []
    this.documents = src.documents ? src.documents.map((v) => new Document(v)) : []
    this.languages = src.languages ? src.languages.map((v) => new Language(v)) : []
    this.billingLinks = src.billingLinks ? src.billingLinks.map((v) => new BillingLink(v)) : []
    this.contracts = src.contracts ? src.contracts.map((v) => new Contract(v)) : []
  }

  tagsHtml(separator = '<br />'): string {
    return this.tags ? this.tags.htmlArray.join(separator) : ''
  }

  get discountsWithoutPrices(): Discount[] {
    return this.discounts.filter((v) => !v.priceID)
  }

  get username(): string {
    return this.user ? this.user.username : ''
  }

  get usernameOrFullName(): string {
    return this.user && this.user.username ? this.user.username : this.fullName
  }

  get emails(): Contact[] {
    return this.contacts.filter((v) => v.isEmail)
  }

  get phones(): Contact[] {
    return this.contacts.filter((v) => v.isPhone)
  }

  get contractIDs(): number[] {
    return this.contracts.map((v) => v.id)
  }

  get settingTypes(): SettingType[] {
    const types: SettingType[] = []
    this.contracts.map((a) => a.settingTypes).flat()

    return [...new Set(types)]
  }

  get contactsMap(): Map<number, Contract> {
    return this.contracts.reduce((acc, c) => acc.set(c.id, c), new Map<number, Contract>())
  }

  get fullNameWithOnePhoneHtml(): string {
    let mainPhone = ''
    for (let i = 0; i < this.contacts.length; i++) {
      if (this.contacts[i].type == ContactType.Phone) {
        mainPhone = this.contacts[i].nameHtml
        if (this.contacts[i].isMain) {
          break
        }
      }
    }

    return mainPhone ? this.fullName + '<br>' + mainPhone : this.fullName
  }

  get fullNameWithMainContactsHtml(): string {
    let mainPhone = ''
    let mainEmail = ''
    for (let i = 0; i < this.contacts.length; i++) {
      if (this.contacts[i].type == ContactType.Phone) {
        if (mainPhone == '') {
          mainPhone = this.contacts[i].nameHtml
        }
        if (this.contacts[i].isMain) {
          mainPhone = this.contacts[i].nameHtml
        }
      } else if (this.contacts[i].type == ContactType.Email) {
        if (mainEmail == '') {
          mainEmail = this.contacts[i].nameHtml
        }
        if (this.contacts[i].isMain) {
          mainEmail = this.contacts[i].nameHtml
        }
      }
    }

    return mainPhone || mainEmail
      ? this.fullName + '<br>' + [mainPhone, mainEmail].join('<br>')
      : this.fullName
  }

  get notUsedAddresses(): Address[] {
    const addresses: Address[] = []

    for (const a of this.addresses) {
      let na: Address | null = a
      for (const c of this.contracts) {
        if (c.addressID == a.id) {
          na = null
          break
        }
      }
      na && addresses.push(na)
    }

    return addresses
  }

  get allDiscountsRequest(): AllPersonDiscountsRequest {
    return {
      personID: this.id,
      contractIDs: this.contracts.map((v) => v.id),
      subscriptionIDs: this.contracts.map((v) => v.subscriptions.map((s) => s.id)).flat(),
      priceIDs: this.contracts.map((v) => v.subscriptions.map((s) => s.allPriceIDs).flat()).flat(),
      tagIDs: this.tags ? this.tags.personTags.map((v) => v.tagID) : [],
    }
  }

  get services(): Service[] {
    const services = new Map<number, Service>()
    this.contracts
      .map((v) => v.services)
      .flat()
      .forEach((v) => services.set(v.id, v))

    const res: Service[] = []
    services.forEach((v) => res.push(v))

    return res
  }

  get bankAccounts(): PersonBankAccount[] {
    const res: PersonBankAccount[] = []
    this.paymentMethods.forEach((v) => {
      if (v.personBankAccount) {
        res.push(v.personBankAccount)
      }
    })

    return res
  }

  isServiceWithSubscriptions(serviceID: number): boolean {
    for (const ct of this.contracts) {
      for (const subs of ct.subscriptions) {
        for (const subSe of subs.subscriptionServices) {
          if (subSe.serviceID == serviceID) {
            return true
          }
        }
      }
    }

    return false
  }

  contractDiscounts(contractID: number): Discount[] {
    let discounts: Discount[] = []
    for (const contract of this.contracts) {
      if (contract.id == contractID) {
        discounts = contract.subscriptions.map((sub) => sub.discounts).flat()
      }
    }

    return discounts
  }

  contractByAddress(addressID: number): Contract | null {
    for (const contract of this.contracts) {
      if (contract.addressID == addressID) {
        return contract
      }
    }

    return null
  }

  static BySimple(p: PersonSimple): Person {
    const res = new Person()
    Object.assign(res, p)

    return res
  }

  async save(): Promise<boolean> {
    try {
      return this.id ? await this.prepare().update() : await this.add()
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async add(): Promise<boolean> {
    try {
      this.init(await personsApi.person.add(this.prepare().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  public prepare(): Person {
    this.prepareSimple()

    return this
  }

  toInterface(): IPerson {
    return {
      ...super.simpleToInterface(),
      addresses: this.addresses.map((v) => v.toInterface()),
      billingLinks: this.billingLinks.map((v) => v.toInterface()),
      contacts: this.contacts.map((v) => v.toInterface()),
      contracts: this.contracts.map((v) => v.toInterface()),
      documents: this.documents.map((v) => v.toInterface()),
      languages: this.languages.map((v) => v.toInterface()),
    }
  }
}
