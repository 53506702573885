// This file is generated automatically, do not make manual changes to it.
// generating script: shell/generate_permissions.go

export const CAN_DELETE_APPOINTMENTS = 'tickets-CanDeleteAppointments'
export const CAN_EDIT_OPERATOR = 'tickets-CanEditOperator'
export const CAN_EDIT_SCHEDULES = 'tickets-CanEditSchedules'
export const CAN_EDIT_SUPPORT_PROBLEMS = 'tickets-CanEditSupportProblems'
export const CAN_EDIT_TICKETS = 'tickets-CanEditTickets'
export const CAN_VIEW_SCHEDULES = 'tickets-CanViewSchedules'
export const CAN_VIEW_SUPPORT_PROBLEMS = 'tickets-CanViewSupportProblems'
export const CAN_VIEW_TICKETS = 'tickets-CanViewTickets'

const ticketsServer = [
  {
    value: CAN_DELETE_APPOINTMENTS,
    description: CAN_DELETE_APPOINTMENTS,
  },
  {
    value: CAN_EDIT_OPERATOR,
    description: CAN_EDIT_OPERATOR,
  },
  {
    value: CAN_EDIT_SCHEDULES,
    description: CAN_EDIT_SCHEDULES,
  },
  {
    value: CAN_EDIT_SUPPORT_PROBLEMS,
    description: CAN_EDIT_SUPPORT_PROBLEMS,
  },
  {
    value: CAN_EDIT_TICKETS,
    description: CAN_EDIT_TICKETS,
  },
  {
    value: CAN_VIEW_SCHEDULES,
    description: CAN_VIEW_SCHEDULES,
  },
  {
    value: CAN_VIEW_SUPPORT_PROBLEMS,
    description: CAN_VIEW_SUPPORT_PROBLEMS,
  },
  {
    value: CAN_VIEW_TICKETS,
    description: CAN_VIEW_TICKETS,
  },
]

export default ticketsServer
