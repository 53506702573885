import API, { MAX_LIMIT, paramsByIds } from '@/api/api'
import { IPersonBankAccount } from '@/models/payments/PersonBankAccount'
import { IPayment } from '@/models/payments/Payment'
import { IBank } from '@/models/payments/Bank'
import { ICompany } from '@/models/payments/Company'
import { ICompanyBankAccount } from '@/models/payments/CompanyBankAccount'
import { IPaymentInvoice } from '@/models/payments/PaymentInvoice'
import { IPaymentLine } from '@/models/payments/PaymentLine'
import { IReceiver } from '@/models/payments/Receiver'
import { ICompanyPaymentService } from '@/models/payments/CompanyPaymentService'
import { IPersonPaymentMethod } from '@/models/payments/PersonPaymentMethod'
import {
  PayPaymentRequest,
  PaymentMakerRequest,
  CompositePaymentRequest,
  PaymentForRemesaContract,
  CountUsedProductRequest,
  PaymentFiled,
  PaymentCancel,
} from '@/requests/payment'
import { List } from '@/models/List'
import { ObjectParams } from '@/models/types'
import { IPaymentInvoiceLine } from '@/models/payments/PaymentInvoiceLine'
import { URL_CORE_MODULE } from '@/api/core-api'
import { IdentityRequest } from '@/requests/IdentityRequest'
import { ISepaPayCounter } from '@/models/payments/SepaPayCounter'
import { EmailSendInvoice } from '@/requests/senders'
import { ISentEmailsInvoice } from '@/models/payments/SentEmailsInvoice'
import { ICountUsedProduct } from '@/responses/payments'
import { IBankFail } from '@/models/payments/BankFail'
import { IAccountBlackList } from '@/models/payments/AccountBlackList'

const URL_SEPA_PAY_COUNTER = URL_CORE_MODULE + '/sepa-pay-counter'

const URL_PAYMENTS = URL_CORE_MODULE + '/payments'
const URL_PAYMENTS_TOTAL = URL_PAYMENTS + '/total'
const URL_PAYMENTS_PAY = URL_PAYMENTS + '/pay'
const URL_PAYMENTS_FAILED = URL_PAYMENTS + '/failed'
const URL_PAYMENTS_CANCEL = URL_PAYMENTS + '/cancel'
const URL_PAYMENTS_REFUND = URL_PAYMENTS + '/refund'
const URL_PAYMENTS_COMPOSITE = URL_PAYMENTS + '/composite'
const URL_PAYMENTS_BOUNCED = URL_PAYMENTS + '/bounced'
const URL_PAYMENTS_REMESA_CONTRACT = URL_PAYMENTS + '/remesa-contract'

const URL_PAYMENTS_PAYMENT_LINES = URL_PAYMENTS + '/lines'

const URL_PAYMENTS_PAYMENT_INVOICES = URL_PAYMENTS + '/invoices'
const URL_PAYMENTS_INVOICE_LINES = URL_PAYMENTS_PAYMENT_INVOICES + '/lines'
const URL_PAYMENTS_INVOICE_SEND = URL_PAYMENTS_PAYMENT_INVOICES + '/send'
const URL_PAYMENTS_INVOICE_REFUND = URL_PAYMENTS_PAYMENT_INVOICES + '/refund'
const URL_PAYMENTS_INVOICE_PRODUCTS = URL_PAYMENTS_PAYMENT_INVOICES + '/products'

const URL_PAYMENT_INVOICES_EMAILS = URL_CORE_MODULE + '/invoices/emails'

const URL_PAYMENTS_BANKS = URL_CORE_MODULE + '/banks'
const URL_PAYMENTS_BANK_FAILS = URL_CORE_MODULE + '/bank-fails'
const URL_PAYMENTS_PERSON_BANK_ACCOUNTS = URL_CORE_MODULE + '/person/bank-accounts'
const URL_PAYMENTS_PERSON_PAYMENT_METHODS = URL_CORE_MODULE + '/person/payment-methods'
const URL_PAYMENTS_COMPANIES = URL_CORE_MODULE + '/companies'
const URL_PAYMENTS_COMPANY_BANK_ACCOUNTS = URL_CORE_MODULE + '/company/bank-accounts'
const URL_PAYMENTS_RECEIVERS = URL_CORE_MODULE + '/receivers'
const URL_PAYMENTS_COMPANY_PAYMENT_SERVICE = URL_CORE_MODULE + '/company/payment-services'
const URL_ACCOUNT_BLACK_LIST = URL_CORE_MODULE + '/account-black-list'

export const paymentsApi = {
  bank: {
    add: async function (bank: IBank): Promise<IBank> {
      const res = await API.post(API.buildURL(URL_PAYMENTS_BANKS), bank)
      return (await res.json()) as Promise<IBank>
    },

    update: async function (bank: IBank): Promise<IBank> {
      const res = await API.put(API.buildURL(URL_PAYMENTS_BANKS), bank)
      return (await res.json()) as Promise<IBank>
    },

    getByID: async function (id: number): Promise<IBank> {
      const res = await API.get(API.buildURL(URL_PAYMENTS_BANKS + '/' + id))
      return (await res.json()) as Promise<IBank>
    },

    getByIDs: async function (IDs: number[]): Promise<IBank[]> {
      if (!IDs || !IDs.length) return []
      const res = await API.get(
        API.buildURL(URL_PAYMENTS_BANKS, { ...paramsByIds(IDs), limit: MAX_LIMIT })
      )
      return (await ((await res.json()) as Promise<List<IBank>>)).items
    },

    list: async function (params: ObjectParams): Promise<List<IBank>> {
      const res = await API.get(API.buildURL(URL_PAYMENTS_BANKS, { ...params }))
      return (await res.json()) as Promise<List<IBank>>
    },
  },

  bankFail: {
    add: async function (bank: IBankFail): Promise<IBankFail> {
      const res = await API.post(API.buildURL(URL_PAYMENTS_BANK_FAILS), bank)
      return (await res.json()) as Promise<IBankFail>
    },

    update: async function (bank: IBankFail): Promise<IBankFail> {
      const res = await API.put(API.buildURL(URL_PAYMENTS_BANK_FAILS), bank)
      return (await res.json()) as Promise<IBankFail>
    },

    getByID: async function (id: number): Promise<IBankFail> {
      const res = await API.get(API.buildURL(URL_PAYMENTS_BANK_FAILS + '/' + id))
      return (await res.json()) as Promise<IBankFail>
    },

    getByIDs: async function (IDs: number[]): Promise<IBankFail[]> {
      if (!IDs || !IDs.length) return []
      const res = await API.get(
        API.buildURL(URL_PAYMENTS_BANK_FAILS, { ...paramsByIds(IDs), limit: MAX_LIMIT })
      )
      return (await ((await res.json()) as Promise<List<IBankFail>>)).items
    },

    list: async function (params: ObjectParams): Promise<List<IBankFail>> {
      const res = await API.get(API.buildURL(URL_PAYMENTS_BANK_FAILS, { ...params }))
      return (await res.json()) as Promise<List<IBankFail>>
    },

    delete: async function (id: number): Promise<void> {
      const res = await API.delete(API.buildURL(URL_PAYMENTS_BANK_FAILS + '/' + id))
      if (!res.ok) {
        throw new Error(await res.json())
      }
    },
  },

  sepaPayCounter: {
    list: async function (params: ObjectParams): Promise<List<ISepaPayCounter>> {
      const res = await API.get(API.buildURL(URL_SEPA_PAY_COUNTER, { ...params }))
      return (await res.json()) as Promise<List<ISepaPayCounter>>
    },
  },

  paymentInvoice: {
    add: async function (invoice: IPaymentInvoice): Promise<IPaymentInvoice> {
      const res = await API.post(API.buildURL(URL_PAYMENTS_PAYMENT_INVOICES), invoice)
      return (await res.json()) as Promise<IPaymentInvoice>
    },

    update: async function (invoice: IPaymentInvoice): Promise<IPaymentInvoice> {
      const res = await API.put(API.buildURL(URL_PAYMENTS_PAYMENT_INVOICES), invoice)
      return (await res.json()) as Promise<IPaymentInvoice>
    },

    list: async function (params: ObjectParams): Promise<List<IPaymentInvoice>> {
      const res = await API.get(API.buildURL(URL_PAYMENTS_PAYMENT_INVOICES, { ...params }))
      return (await res.json()) as Promise<List<IPaymentInvoice>>
    },

    getByID: async function (id: number): Promise<IPaymentInvoice> {
      const res = await API.get(API.buildURL(URL_PAYMENTS_PAYMENT_INVOICES + '/' + id))
      return (await res.json()) as Promise<IPaymentInvoice>
    },

    getByIDs: async function (IDs: number[]): Promise<IPaymentInvoice[]> {
      if (!IDs || !IDs.length) return []
      const res = await API.get(
        API.buildURL(URL_PAYMENTS_PAYMENT_INVOICES, { ...paramsByIds(IDs), limit: MAX_LIMIT })
      )
      return (await ((await res.json()) as Promise<List<IPaymentInvoice>>)).items
    },

    getInvoiceProducts: async function (req: CountUsedProductRequest): Promise<ICountUsedProduct> {
      const res = await API.post(API.buildURL(URL_PAYMENTS_INVOICE_PRODUCTS), req)
      return (await res.json()) as Promise<ICountUsedProduct>
    },

    send: async function (req: EmailSendInvoice): Promise<void> {
      await API.post(API.buildURL(URL_PAYMENTS_INVOICE_SEND), req)
    },

    refund: async function (req: IdentityRequest): Promise<void> {
      await API.put(API.buildURL(URL_PAYMENTS_INVOICE_REFUND), req)
    },
  },

  invoiceLines: {
    list: async function (params: ObjectParams): Promise<List<IPaymentInvoiceLine>> {
      const res = await API.get(API.buildURL(URL_PAYMENTS_INVOICE_LINES, { ...params }))
      return (await res.json()) as Promise<List<IPaymentInvoiceLine>>
    },

    getByID: async function (id: number): Promise<IPaymentInvoiceLine> {
      const res = await API.get(API.buildURL(URL_PAYMENTS_INVOICE_LINES + '/' + id))
      return (await res.json()) as Promise<IPaymentInvoiceLine>
    },

    getByIDs: async function (IDs: number[]): Promise<IPaymentInvoiceLine[]> {
      if (!IDs || !IDs.length) return []
      const res = await API.get(
        API.buildURL(URL_PAYMENTS_INVOICE_LINES, { ...paramsByIds(IDs), limit: MAX_LIMIT })
      )
      return (await ((await res.json()) as Promise<List<IPaymentInvoiceLine>>)).items
    },
  },

  payment: {
    list: async function (params: ObjectParams): Promise<List<IPayment>> {
      const res = await API.get(API.buildURL(URL_PAYMENTS, { ...params }))
      return (await res.json()) as Promise<List<IPayment>>
    },

    getByID: async function (id: number): Promise<IPayment> {
      const res = await API.get(API.buildURL(URL_PAYMENTS + '/' + id))
      return (await res.json()) as Promise<IPayment>
    },

    getByIDs: async function (IDs: number[]): Promise<IPayment[]> {
      if (!IDs || !IDs.length) return []
      const res = await API.get(
        API.buildURL(URL_PAYMENTS, { ...paramsByIds(IDs), limit: MAX_LIMIT })
      )
      return (await ((await res.json()) as Promise<List<IPayment>>)).items
    },

    total: async function (req: PaymentMakerRequest): Promise<IPayment> {
      const res = await API.post(API.buildURL(URL_PAYMENTS_TOTAL), req)
      return (await res.json()) as Promise<IPayment>
    },

    pay: async function (req: PayPaymentRequest): Promise<IPayment> {
      const res = await API.put(API.buildURL(URL_PAYMENTS_PAY), req)
      return (await res.json()) as Promise<IPayment>
    },

    composite: async function (req: CompositePaymentRequest): Promise<IPayment> {
      const res = await API.put(API.buildURL(URL_PAYMENTS_COMPOSITE), req)
      return (await res.json()) as Promise<IPayment>
    },

    failed: async function (req: PaymentFiled): Promise<IPayment> {
      const res = await API.put(API.buildURL(URL_PAYMENTS_FAILED), req)
      return (await res.json()) as Promise<IPayment>
    },

    cancel: async function (req: PaymentCancel): Promise<IPayment> {
      const res = await API.put(API.buildURL(URL_PAYMENTS_CANCEL), req)
      return (await res.json()) as Promise<IPayment>
    },

    refund: async function (request: IdentityRequest): Promise<IPayment> {
      const res = await API.put(API.buildURL(URL_PAYMENTS_REFUND), request)
      return (await res.json()) as Promise<IPayment>
    },

    bounced: async function (request: IdentityRequest): Promise<IPayment> {
      const res = await API.put(API.buildURL(URL_PAYMENTS_BOUNCED), request)
      return (await res.json()) as Promise<IPayment>
    },

    remesaContract: async function (request: PaymentForRemesaContract): Promise<IPayment> {
      const res = await API.post(API.buildURL(URL_PAYMENTS_REMESA_CONTRACT), request)
      return (await res.json()) as Promise<IPayment>
    },

    update: async function (payment: IPayment): Promise<IPayment> {
      const res = await API.put(API.buildURL(URL_PAYMENTS), payment)
      return (await res.json()) as Promise<IPayment>
    },

    stories: async function (id: number, params: ObjectParams = {}): Promise<List<IPayment>> {
      const res = await API.get(API.buildURL(URL_PAYMENTS + '/versions/' + id, { ...params }))
      return (await res.json()) as Promise<List<IPayment>>
    },
  },

  paymentLine: {
    list: async function (params: ObjectParams): Promise<List<IPaymentLine>> {
      const res = await API.get(API.buildURL(URL_PAYMENTS_PAYMENT_LINES, { ...params }))
      return (await res.json()) as Promise<List<IPaymentLine>>
    },

    getByID: async function (id: number): Promise<IPaymentLine> {
      const res = await API.get(API.buildURL(URL_PAYMENTS_PAYMENT_LINES + '/' + id))
      return (await res.json()) as Promise<IPaymentLine>
    },

    getByIDs: async function (IDs: number[]): Promise<IPaymentLine[]> {
      if (!IDs || !IDs.length) return []
      const res = await API.get(
        API.buildURL(URL_PAYMENTS_PAYMENT_LINES, { ...paramsByIds(IDs), limit: MAX_LIMIT })
      )
      return (await ((await res.json()) as Promise<List<IPaymentLine>>)).items
    },
  },

  personBankAccount: {
    add: async function (item: IPersonBankAccount): Promise<IPersonBankAccount> {
      const res = await API.post(API.buildURL(URL_PAYMENTS_PERSON_BANK_ACCOUNTS), item)
      return (await res.json()) as Promise<IPersonBankAccount>
    },

    update: async function (item: IPersonBankAccount): Promise<IPersonBankAccount> {
      const res = await API.put(API.buildURL(URL_PAYMENTS_PERSON_BANK_ACCOUNTS), item)
      return (await res.json()) as Promise<IPersonBankAccount>
    },

    getByID: async function (id: number): Promise<IPersonBankAccount> {
      const res = await API.get(API.buildURL(URL_PAYMENTS_PERSON_BANK_ACCOUNTS + '/' + id))
      return (await res.json()) as Promise<IPersonBankAccount>
    },
  },

  accountBlackList: {
    add: async function (item: IAccountBlackList): Promise<IAccountBlackList> {
      const res = await API.post(API.buildURL(URL_ACCOUNT_BLACK_LIST), item)
      return await res.json()
    },

    update: async function (item: IAccountBlackList): Promise<IAccountBlackList> {
      const res = await API.put(API.buildURL(URL_ACCOUNT_BLACK_LIST), item)
      return await res.json()
    },

    delete: async function (id: number): Promise<void> {
      const res = await API.delete(API.buildURL(URL_ACCOUNT_BLACK_LIST + '/' + id))
      if (!res.ok) {
        throw new Error(await res.json())
      }
    },

    list: async function (params: ObjectParams): Promise<List<IAccountBlackList>> {
      const res = await API.get(API.buildURL(URL_ACCOUNT_BLACK_LIST, { ...params }))
      return await res.json()
    },

    getByID: async function (id: number): Promise<IAccountBlackList> {
      const res = await API.get(API.buildURL(URL_ACCOUNT_BLACK_LIST + '/' + id))
      return await res.json()
    },
  },

  personPaymentMethod: {
    add: async function (item: IPersonPaymentMethod): Promise<IPersonPaymentMethod> {
      const res = await API.post(API.buildURL(URL_PAYMENTS_PERSON_PAYMENT_METHODS), item)
      return (await res.json()) as Promise<IPersonPaymentMethod>
    },

    update: async function (item: IPersonPaymentMethod): Promise<IPersonPaymentMethod> {
      const res = await API.put(API.buildURL(URL_PAYMENTS_PERSON_PAYMENT_METHODS), item)
      return (await res.json()) as Promise<IPersonPaymentMethod>
    },

    delete: async function (id: number): Promise<void> {
      const res = await API.delete(API.buildURL(URL_PAYMENTS_PERSON_PAYMENT_METHODS + '/' + id))
      if (!res.ok) {
        throw new Error(await res.json())
      }
    },

    list: async function (params: ObjectParams): Promise<List<IPersonPaymentMethod>> {
      const res = await API.get(API.buildURL(URL_PAYMENTS_PERSON_PAYMENT_METHODS, { ...params }))
      return (await res.json()) as Promise<List<IPersonPaymentMethod>>
    },

    getByID: async function (id: number): Promise<IPersonPaymentMethod> {
      const res = await API.get(API.buildURL(URL_PAYMENTS_PERSON_PAYMENT_METHODS + '/' + id))
      return (await res.json()) as Promise<IPersonPaymentMethod>
    },

    getByIDs: async function (IDs: number[]): Promise<IPersonPaymentMethod[]> {
      if (!IDs || !IDs.length) return []
      const res = await API.get(
        API.buildURL(URL_PAYMENTS_PERSON_PAYMENT_METHODS, { ...paramsByIds(IDs), limit: MAX_LIMIT })
      )
      return (await ((await res.json()) as Promise<List<IPersonPaymentMethod>>)).items
    },
  },

  company: {
    add: async function (company: ICompany): Promise<ICompany> {
      const res = await API.post(API.buildURL(URL_PAYMENTS_COMPANIES), company)
      return (await res.json()) as Promise<ICompany>
    },

    update: async function (company: ICompany): Promise<ICompany> {
      const res = await API.put(API.buildURL(URL_PAYMENTS_COMPANIES), company)
      return (await res.json()) as Promise<ICompany>
    },

    list: async function (params: ObjectParams): Promise<List<ICompany>> {
      const res = await API.get(API.buildURL(URL_PAYMENTS_COMPANIES, { ...params }))
      return (await res.json()) as Promise<List<ICompany>>
    },

    getByIDs: async function (IDs: number[]): Promise<ICompany[]> {
      if (!IDs || !IDs.length) return []
      const res = await API.get(
        API.buildURL(URL_PAYMENTS_COMPANIES, { ...paramsByIds(IDs), limit: MAX_LIMIT })
      )
      return (await ((await res.json()) as Promise<List<ICompany>>)).items
    },

    getByID: async function (id: number): Promise<ICompany> {
      const res = await API.get(API.buildURL(URL_PAYMENTS_COMPANIES + '/' + id))
      return (await res.json()) as Promise<ICompany>
    },

    getAll: async function (params: ObjectParams = {}): Promise<ICompany[]> {
      const items = await this.list({ ...params, limit: MAX_LIMIT })
      return items.items
    },
  },

  receiver: {
    add: async function (item: IReceiver): Promise<IReceiver> {
      const res = await API.post(API.buildURL(URL_PAYMENTS_RECEIVERS), item)
      return (await res.json()) as Promise<IReceiver>
    },

    update: async function (item: IReceiver): Promise<IReceiver> {
      const res = await API.put(API.buildURL(URL_PAYMENTS_RECEIVERS), item)
      return (await res.json()) as Promise<IReceiver>
    },

    list: async function (params: ObjectParams): Promise<List<IReceiver>> {
      const res = await API.get(API.buildURL(URL_PAYMENTS_RECEIVERS, { ...params }))
      return (await res.json()) as Promise<List<IReceiver>>
    },

    getByID: async function (id: number): Promise<IReceiver> {
      const res = await API.get(API.buildURL(URL_PAYMENTS_RECEIVERS + '/' + id))
      return (await res.json()) as Promise<IReceiver>
    },

    getByIDs: async function (IDs: number[]): Promise<IReceiver[]> {
      if (!IDs || !IDs.length) return []
      const res = await API.get(
        API.buildURL(URL_PAYMENTS_RECEIVERS, { ...paramsByIds(IDs), limit: MAX_LIMIT })
      )
      return (await ((await res.json()) as Promise<List<IReceiver>>)).items
    },
  },

  companyBankAccount: {
    add: async function (account: ICompanyBankAccount): Promise<ICompanyBankAccount> {
      const res = await API.post(API.buildURL(URL_PAYMENTS_COMPANY_BANK_ACCOUNTS), account)
      return (await res.json()) as Promise<ICompanyBankAccount>
    },

    update: async function (account: ICompanyBankAccount): Promise<ICompanyBankAccount> {
      const res = await API.put(API.buildURL(URL_PAYMENTS_COMPANY_BANK_ACCOUNTS), account)
      return (await res.json()) as Promise<ICompanyBankAccount>
    },

    list: async function (params: ObjectParams): Promise<List<ICompanyBankAccount>> {
      const res = await API.get(API.buildURL(URL_PAYMENTS_COMPANY_BANK_ACCOUNTS, { ...params }))
      return (await res.json()) as Promise<List<ICompanyBankAccount>>
    },

    getByID: async function (id: number): Promise<ICompanyBankAccount> {
      const res = await API.get(API.buildURL(URL_PAYMENTS_COMPANY_BANK_ACCOUNTS + '/' + id))
      return (await res.json()) as Promise<ICompanyBankAccount>
    },

    getByIDs: async function (IDs: number[]): Promise<ICompanyBankAccount[]> {
      if (!IDs || !IDs.length) return []
      const res = await API.get(
        API.buildURL(URL_PAYMENTS_COMPANY_BANK_ACCOUNTS, { ...paramsByIds(IDs), limit: MAX_LIMIT })
      )
      return (await ((await res.json()) as Promise<List<ICompanyBankAccount>>)).items
    },
  },

  companyPaymentService: {
    update: async function (item: ICompanyPaymentService): Promise<ICompanyPaymentService> {
      const res = await API.put(API.buildURL(URL_PAYMENTS_COMPANY_PAYMENT_SERVICE), item)
      return (await res.json()) as Promise<ICompanyPaymentService>
    },
  },

  invoiceEmails: {
    getByID: async function (id: number): Promise<ISentEmailsInvoice> {
      const res = await API.get(API.buildURL(URL_PAYMENT_INVOICES_EMAILS + '/' + id))
      return (await res.json()) as Promise<ISentEmailsInvoice>
    },

    update: async function (person: ISentEmailsInvoice): Promise<ISentEmailsInvoice> {
      const res = await API.put(API.buildURL(URL_PAYMENT_INVOICES_EMAILS), person)
      return (await res.json()) as Promise<ISentEmailsInvoice>
    },

    list: async function (params: ObjectParams): Promise<List<ISentEmailsInvoice>> {
      const res = await API.get(API.buildURL(URL_PAYMENT_INVOICES_EMAILS, { ...params }))
      return (await res.json()) as Promise<List<ISentEmailsInvoice>>
    },

    delete: async function (id: number): Promise<void> {
      const res = await API.delete(API.buildURL(URL_PAYMENT_INVOICES_EMAILS + '/' + id))
      if (!res.ok) {
        throw new Error(await res.json())
      }
    },

    resend: async function (ids: number[]): Promise<void> {
      const res = await API.get(
        API.buildURL(URL_PAYMENT_INVOICES_EMAILS + '/resend', { ...paramsByIds(ids) })
      )
      if (!res.ok) {
        throw new Error(await res.json())
      }
    },
  },
}
