import { MainStore } from '@/store/MainStore'
import { geoApi } from '@/api/geo-api'
import { List } from '@/models/List'
import { ObjectParams } from '@/models/types'
import { Street } from '@/models/geo/Street'

export class StreetGet {
  static async list(params: ObjectParams): Promise<List<Street>> {
    const list: List<Street> = { count: 0, items: [] }
    try {
      const l = await geoApi.street.list(params)
      list.count = l.count
      list.items = l.items.map((v) => new Street(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return list
  }

  static async versions(params: ObjectParams): Promise<List<Street>> {
    const list: List<Street> = { count: 0, items: [] }
    try {
      const l = await geoApi.street.versions(params)
      list.count = l.count
      list.items = l.items.map((v) => new Street(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return list
  }

  static async byID(id: number): Promise<Street> {
    let item = new Street()
    try {
      item = new Street(await geoApi.street.getByID(id))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return item
  }

  static async count(params: ObjectParams): Promise<number> {
    let count = 0
    try {
      count = await geoApi.street.count(params)
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return count
  }
}
