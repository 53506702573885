import { MainStore } from '@/store/MainStore'

export async function loaderWrapper(fn: () => void): Promise<void> {
  try {
    await MainStore.setLoading()
    await fn()
  } catch (e) {
    await MainStore.addError(e as Error)
  } finally {
    await MainStore.releaseLoading()
  }
}
