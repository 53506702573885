import { RouteConfig } from 'vue-router/types/router'
import { CAN_ACCESS_GEO } from '@/permissions/geo'
import { ROUTER_PARAM_ID } from '@/utils/constants'

export const ROUTE_COUNTRIES = 'ROUTE_COUNTRIES'
export const ROUTE_COUNTRY_VIEW = 'ROUTE_COUNTRY_VIEW'
export const ROUTE_COUNTRY_FORM = 'ROUTE_COUNTRY_FORM'

export const ROUTE_PROVINCES = 'ROUTE_PROVINCES'
export const ROUTE_PROVINCE_VIEW = 'ROUTE_PROVINCE_VIEW'
export const ROUTE_PROVINCE_FORM = 'ROUTE_PROVINCE_FORM'

export const ROUTE_MUNICIPALITIES = 'ROUTE_MUNICIPALITIES'
export const ROUTE_MUNICIPALITY_VIEW = 'ROUTE_MUNICIPALITY_VIEW'
export const ROUTE_MUNICIPALITY_FORM = 'ROUTE_MUNICIPALITY_FORM'

export const ROUTE_STREETS = 'ROUTE_STREETS'
export const ROUTE_STREET_VIEW = 'ROUTE_STREET_VIEW'
export const ROUTE_STREET_FORM = 'ROUTE_STREET_FORM'

export const ROUTE_URBANIZATIONS = 'ROUTE_URBANIZATIONS'
export const ROUTE_URBANIZATION_VIEW = 'ROUTE_URBANIZATION_VIEW'
export const ROUTE_URBANIZATION_FORM = 'ROUTE_URBANIZATION_FORM'

export const ROUTE_DISTRICTS = 'ROUTE_DISTRICTS'
export const ROUTE_DISTRICT_VIEW = 'ROUTE_DISTRICT_VIEW'
export const ROUTE_DISTRICT_FORM = 'ROUTE_DISTRICT_FORM'

export const ROUTE_COMMUNITIES = 'ROUTE_COMMUNITIES'
export const ROUTE_COMMUNITIES_VIEW = 'ROUTE_COMMUNITIES_VIEW'
export const ROUTE_COMMUNITIES_FORM = 'ROUTE_COMMUNITIES_FORM'

export const ROUTE_GEO_ADDRESSES = 'ROUTE_GEO_ADDRESSES'

const geoRoutes: RouteConfig[] = [
  {
    name: ROUTE_COUNTRIES,
    path: '/countries',
    meta: { permission: CAN_ACCESS_GEO },
    component: () =>
      import(/* webpackChunkName: "geo" */ '../components/geo/country/CountryList.vue'),
  },
  {
    name: ROUTE_COUNTRY_VIEW,
    path: `/countries/:${ROUTER_PARAM_ID}(\\d+)`,
    meta: { permission: CAN_ACCESS_GEO },
    component: () =>
      import(/* webpackChunkName: "geo" */ '../components/geo/country/CountryView.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
  {
    name: ROUTE_COUNTRY_FORM,
    path: `/countries/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: CAN_ACCESS_GEO },
    component: () =>
      import(/* webpackChunkName: "geo" */ '../components/geo/country/CountryEdit.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },

  {
    name: ROUTE_PROVINCES,
    path: '/provinces',
    meta: { permission: CAN_ACCESS_GEO },
    component: () =>
      import(/* webpackChunkName: "geo" */ '../components/geo/province/ProvinceList.vue'),
    props: true,
  },
  {
    name: ROUTE_PROVINCE_VIEW,
    path: `/provinces/:${ROUTER_PARAM_ID}(\\d+)`,
    meta: { permission: CAN_ACCESS_GEO },
    component: () =>
      import(/* webpackChunkName: "geo" */ '../components/geo/province/ProvinceView.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
  {
    name: ROUTE_PROVINCE_FORM,
    path: `/provinces/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: CAN_ACCESS_GEO },
    component: () =>
      import(/* webpackChunkName: "geo" */ '../components/geo/province/ProvinceEdit.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },

  {
    name: ROUTE_MUNICIPALITIES,
    path: '/municipalities',
    meta: { permission: CAN_ACCESS_GEO },
    component: () =>
      import(/* webpackChunkName: "geo" */ '../components/geo/municipality/MunicipalityList.vue'),
    props: true,
  },
  {
    name: ROUTE_MUNICIPALITY_VIEW,
    path: `/municipalities/:${ROUTER_PARAM_ID}(\\d+)`,
    meta: { permission: CAN_ACCESS_GEO },
    component: () =>
      import(/* webpackChunkName: "geo" */ '../components/geo/municipality/MunicipalityView.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
  {
    name: ROUTE_MUNICIPALITY_FORM,
    path: `/municipalities/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: CAN_ACCESS_GEO },
    component: () =>
      import(/* webpackChunkName: "geo" */ '../components/geo/municipality/MunicipalityEdit.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },

  {
    name: ROUTE_STREETS,
    path: '/streets',
    meta: { permission: CAN_ACCESS_GEO },
    component: () =>
      import(/* webpackChunkName: "geo" */ '../components/geo/street/StreetList.vue'),
    props: true,
  },
  {
    name: ROUTE_STREET_VIEW,
    path: `/streets/:${ROUTER_PARAM_ID}(\\d+)`,
    meta: { permission: CAN_ACCESS_GEO },
    component: () =>
      import(/* webpackChunkName: "geo" */ '../components/geo/street/StreetView.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
  {
    name: ROUTE_STREET_FORM,
    path: `/streets/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: CAN_ACCESS_GEO },
    component: () =>
      import(/* webpackChunkName: "geo" */ '../components/geo/street/StreetEdit.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },

  {
    name: ROUTE_URBANIZATIONS,
    path: '/urbanizations',
    meta: { permission: CAN_ACCESS_GEO },
    component: () =>
      import(/* webpackChunkName: "geo" */ '../components/geo/urbanization/UrbanizationList.vue'),
    props: true,
  },
  {
    name: ROUTE_URBANIZATION_VIEW,
    path: `/urbanizations/:${ROUTER_PARAM_ID}(\\d+)`,
    meta: { permission: CAN_ACCESS_GEO },
    component: () =>
      import(/* webpackChunkName: "geo" */ '../components/geo/urbanization/UrbanizationView.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
  {
    name: ROUTE_URBANIZATION_FORM,
    path: `/urbanizations/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: CAN_ACCESS_GEO },
    component: () =>
      import(/* webpackChunkName: "geo" */ '../components/geo/urbanization/UrbanizationEdit.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },

  {
    name: ROUTE_DISTRICTS,
    path: '/districts',
    meta: { permission: CAN_ACCESS_GEO },
    component: () =>
      import(/* webpackChunkName: "geo" */ '../components/geo/district/DistrictList.vue'),
    props: true,
  },
  {
    name: ROUTE_DISTRICT_VIEW,
    path: `/districts/:${ROUTER_PARAM_ID}(\\d+)`,
    meta: { permission: CAN_ACCESS_GEO },
    component: () =>
      import(/* webpackChunkName: "geo" */ '../components/geo/district/DistrictView.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
  {
    name: ROUTE_DISTRICT_FORM,
    path: `/districts/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: CAN_ACCESS_GEO },
    component: () =>
      import(/* webpackChunkName: "geo" */ '../components/geo/district/DistrictEdit.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },

  {
    name: ROUTE_COMMUNITIES,
    path: '/communities',
    meta: { permission: CAN_ACCESS_GEO },
    component: () =>
      import(/* webpackChunkName: "geo" */ '../components/geo/community/CommunityList.vue'),
    props: true,
  },
  {
    name: ROUTE_COMMUNITIES_VIEW,
    path: `/communities/:${ROUTER_PARAM_ID}(\\d+)`,
    meta: { permission: CAN_ACCESS_GEO },
    component: () =>
      import(/* webpackChunkName: "geo" */ '../components/geo/community/CommunityView.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
  {
    name: ROUTE_COMMUNITIES_FORM,
    path: `/communities/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: CAN_ACCESS_GEO },
    component: () =>
      import(/* webpackChunkName: "geo" */ '../components/geo/community/CommunityEdit.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },

  {
    name: ROUTE_GEO_ADDRESSES,
    path: '/addresses',
    meta: { permission: CAN_ACCESS_GEO },
    component: () => import(/* webpackChunkName: "geo" */ '../components/geo/AddressList.vue'),
    props: true,
  },
]

export default geoRoutes
