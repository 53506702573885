import { Ticket } from '@/models/ticket/Ticket'
import { EnumNames } from '@/models/types'

export const ticketsPerPage = 30

export enum ProblemType {
  Accounts = 1,
  Other = 2,
  Internet = 3,
  IPTV = 4,
  Landline = 5,
  QPSK = 6,
  Fibre = 7,
  Mobile = 8,
}

export const problemTypes: EnumNames = {
  [ProblemType.Accounts]: 'Account',
  [ProblemType.Other]: 'Other',
  [ProblemType.Internet]: 'Internet',
  [ProblemType.IPTV]: 'IPTV',
  [ProblemType.Landline]: 'Landline',
  [ProblemType.QPSK]: 'QPSK',
  [ProblemType.Fibre]: 'Fibre',
  [ProblemType.Mobile]: 'Mobile',
}

export enum TicketStatus {
  New = 1,
  NotAllocated = 2,
  TecCheck = 3,

  Appointment = 4,
  PassedToInstaller = 5,
  FurtherActionRequired = 6,
  AmbiguousTicket = 7,
  WaitingClientResponse = 8,
  Processing = 9,

  Resolved = 10,
  NoFurtherActionRequired = 11,
  TimedOut = 12,
  NoReplyFromCustomer = 13,
  ReOpen = 14,
}

export const ticketStatusesAll: TicketStatus[] = [
  TicketStatus.New,
  TicketStatus.NotAllocated,
  TicketStatus.TecCheck,
  TicketStatus.Appointment,
  TicketStatus.PassedToInstaller,
  TicketStatus.FurtherActionRequired,
  TicketStatus.AmbiguousTicket,
  TicketStatus.WaitingClientResponse,
  TicketStatus.Processing,
  TicketStatus.Resolved,
  TicketStatus.NoFurtherActionRequired,
  TicketStatus.TimedOut,
  TicketStatus.NoReplyFromCustomer,
  TicketStatus.ReOpen,
]

export const ticketStatuses: EnumNames = {
  [TicketStatus.New]: 'New',
  [TicketStatus.NotAllocated]: 'Not yet allocated',
  [TicketStatus.TecCheck]: 'Code 11 (Tec Check)',
  [TicketStatus.Appointment]: 'Appointment made',
  [TicketStatus.PassedToInstaller]: 'Passed to installer',
  [TicketStatus.FurtherActionRequired]: 'Further action required',
  [TicketStatus.AmbiguousTicket]: 'Ambiguous ticket ',
  [TicketStatus.WaitingClientResponse]: 'Waiting for client response',
  [TicketStatus.Processing]: 'Processing',
  [TicketStatus.Resolved]: 'Resolved',
  [TicketStatus.NoFurtherActionRequired]: 'No further action required',
  [TicketStatus.TimedOut]: 'Timed out',
  [TicketStatus.NoReplyFromCustomer]: 'No reply from customer',
  [TicketStatus.ReOpen]: 'Re-open',
}

export const newTicketStatuses = [
  TicketStatus.New,
  TicketStatus.NotAllocated,
  TicketStatus.TecCheck,
]

export const initialTicketStatuses = [
  ...newTicketStatuses,
  TicketStatus.Resolved,
  TicketStatus.NoFurtherActionRequired,
  TicketStatus.ReOpen,
]

export const workingTicketStatuses = [
  TicketStatus.Appointment,
  TicketStatus.PassedToInstaller,
  TicketStatus.FurtherActionRequired,
  TicketStatus.AmbiguousTicket,
  TicketStatus.WaitingClientResponse,
  TicketStatus.Processing,
]

export const activeTicketStatuses = [...newTicketStatuses, ...workingTicketStatuses]

export const finalTicketStatuses = [
  TicketStatus.Resolved,
  TicketStatus.NoFurtherActionRequired,
  TicketStatus.TimedOut,
  TicketStatus.NoReplyFromCustomer,
]

export function prefIconClass(status: TicketStatus): string {
  return isActive(status) ? 'far fa-clock' : isFinal(status) ? 'fas fa-flag-checkered' : ''
}

export function isActive(status: TicketStatus): boolean {
  return activeTicketStatuses.includes(status)
}

export function isFinal(status: TicketStatus): boolean {
  return finalTicketStatuses.includes(status)
}

export function isNewTicket(ticket: Ticket): boolean {
  return newTicketStatuses.includes(ticket.status)
}

export function isPossibleTakeToWork(ticket: Ticket): boolean {
  return !ticket.responsiblePersonID && !finalTicketStatuses.includes(ticket.status)
}

export function nextAllowedTicketStatuses(status: TicketStatus): TicketStatus[] {
  switch (true) {
    case initialTicketStatuses.includes(status):
      return [...workingTicketStatuses, TicketStatus.Resolved, TicketStatus.NoFurtherActionRequired]
    case workingTicketStatuses.includes(status):
      return [...workingTicketStatuses, ...finalTicketStatuses]
    case finalTicketStatuses.includes(status):
      return []
    default:
      return initialTicketStatuses
  }
}

export function createStatuses(statuses: TicketStatus[]): EnumNames {
  const res: { [key: number]: string } = {}
  statuses.forEach((v) => (res[v] = ticketStatuses[v]))
  return res
}

export enum ScheduleAction {
  Stop = 1,
  Start,
  Remind,
}

export const scheduleActions: EnumNames = {
  [ScheduleAction.Stop]: 'Stop',
  [ScheduleAction.Start]: 'Start',
  [ScheduleAction.Remind]: 'Remind',
}
