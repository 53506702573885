import { PermissionGroup } from '@/utils/permissions'
import ticketsServer from '@/permissions/tickets_server'

const prefix = 'tickets'

export const CAN_ACCESS_TICKETS = prefix + '-access_module'

const tickets: PermissionGroup = {
  name: 'Tickets Module',
  permissions: [
    {
      value: CAN_ACCESS_TICKETS,
      description: CAN_ACCESS_TICKETS,
    },
    ...ticketsServer,
  ],
}

export default tickets
