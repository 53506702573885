
import { Component, Vue, Watch } from 'vue-property-decorator'
import { EVENT_INPUT } from '@/utils/event-constants'
import { uniqueStr } from '@/utils'

@Component
export default class Dashboard extends Vue {
  year = new Date().getUTCFullYear()
  years = createYears(this.year)
  id = uniqueStr()

  @Watch('year', { immediate: true })
  emitYear(): void {
    this.$emit(EVENT_INPUT, this.year)
  }
}

function createYears(end: number, count = 15): number[] {
  const res: number[] = [end]
  for (let i = 1; i < count; i++) {
    res.push(end - i)
  }

  return res
}
