
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import SearchableInput from '@/components/common/searchable-input/SearchableInput.vue'
import {
  EVENT_INPUT,
  EVENT_INPUT_LINE,
  EVENT_IS_DATA,
  EVENT_NO_DATA,
  EVENT_SELECT,
} from '@/utils/event-constants'
import { Autocomplete } from '@/models/autocomplete/Autocomplete'
import { ObjectParams } from '@/models/types'
import _debounce from 'lodash/debounce'

@Component({
  components: { SearchableInput },
})
export default class AutoComplete extends Vue {
  @Prop({ type: Object, required: true }) model!: Autocomplete<unknown>
  @Prop({ type: Object }) value!: unknown
  @Prop({ type: Object }) params!: ObjectParams

  @Prop({ type: Boolean }) isSm!: boolean
  @Prop({ type: Boolean }) offInvalid!: boolean
  @Prop({ type: Boolean }) disabled!: boolean
  @Prop({ type: Boolean }) disableEnter!: boolean
  @Prop({ type: Boolean }) focusTrigger!: boolean
  @Prop({ type: Boolean }) cleanAfterSelection!: boolean
  @Prop({ type: String, default: '' }) placeholder!: string
  @Prop({ type: String, default: '' }) label!: string
  @Prop({ type: String, default: '' }) searchLine!: string
  @Prop({ type: Boolean }) isInputInvalid!: boolean
  @Prop({ type: Boolean }) disableSearch!: boolean
  @Prop({ type: String }) inputInvalidTitle?: string

  get listeners(): unknown {
    return {
      ...this.$listeners,
      input: this.debouncedLoadItems,
      [EVENT_SELECT]: this.selectItem,
    }
  }

  debouncedLoadItems: (s: string) => void = _debounce(this.loadItems, 300)

  @Watch('params', { immediate: true })
  setParams(): void {
    this.model.withParams(this.params || {})
  }

  @Watch('value', { immediate: true })
  onValue(): void {
    this.model.searchLine = this.value ? this.model.getText(this.value) : ''
  }

  @Watch('disableSearch', { immediate: true })
  onDisableSearch(): void {
    this.model.clearItems()
  }

  get lineForSearch(): string {
    return this.searchLine || this.model.searchLine
  }

  async loadItems(searchLine: string): Promise<void> {
    this.model.searchLine = searchLine
    this.$emit(EVENT_INPUT_LINE, searchLine)
    if (!this.disableSearch) {
      this.createItems(await this.model.find(searchLine))
    }
  }

  async selectItem(item: unknown): Promise<void> {
    this.$emit(EVENT_INPUT, await this.model.emit(item))
  }

  createItems(items: unknown[]): void {
    this.model.createItems(items)
    this.model.isEmptyData() ? this.$emit(EVENT_NO_DATA) : this.$emit(EVENT_IS_DATA)
  }
}
