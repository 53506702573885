import { MainStore } from '@/store/MainStore'
import { List } from '@/models/List'
import { ObjectParams } from '@/models/types'
import { InternetSession } from '@/models/internet/InternetSession'
import { internetApi } from '@/api/internet-api'
import { StatsByIPPool } from '@/responses/internet'

export class InternetSessionGet {
  static async list(params: ObjectParams): Promise<List<InternetSession>> {
    const list: List<InternetSession> = { count: 0, items: [] }
    try {
      const l = await internetApi.internetSession.list(params)
      list.count = l.count
      list.items = l.items.map((v) => new InternetSession(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return list
  }

  static async byID(id: number): Promise<InternetSession> {
    let item = new InternetSession()
    try {
      item = new InternetSession(await internetApi.internetSession.getByID(id))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return item
  }

  static async mapLastByServiceIDs(ids: number[]): Promise<Map<number, InternetSession>> {
    const contracts = await InternetSessionGet.getLastByServiceIDs(ids)

    const pMap = new Map<number, InternetSession>()
    contracts.forEach((v) => pMap.set(v.serviceID || 0, new InternetSession(v)))

    return pMap
  }

  static async getLastByServiceIDs(ids: number[]): Promise<InternetSession[]> {
    let item: InternetSession[] = []
    try {
      item = (await internetApi.internetSession.getLastByServiceIDs(ids)).map(
        (v) => new InternetSession(v)
      )
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return item
  }

  static async getLastByUsernames(names: string[]): Promise<InternetSession[]> {
    let item: InternetSession[] = []
    try {
      item = (await internetApi.internetSession.getLastByUsernames(names)).map(
        (v) => new InternetSession(v)
      )
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return item
  }

  static async mapStatsByIPPool(ids: number[]): Promise<Map<number, StatsByIPPool>> {
    let items: StatsByIPPool[] = []
    try {
      items = await internetApi.internetSession.statsByIpPools(ids)
    } catch (e) {
      await MainStore.addError(e as Error)
    }

    const pMap = new Map<number, StatsByIPPool>()
    items.forEach((v) => pMap.set(v.ipPoolID, v))

    return pMap
  }
}
