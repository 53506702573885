import { PermissionGroup } from '@/utils/permissions'
import searchServer from '@/permissions/search_server'

const prefix = 'search'

export const CAN_ACCESS_SEARCH = prefix + '-access_module'

const users: PermissionGroup = {
  name: 'Search Module',
  permissions: [
    {
      value: CAN_ACCESS_SEARCH,
      description: CAN_ACCESS_SEARCH,
    },
    ...searchServer,
  ],
}

export default users
