import { CommonModel, IModel } from '@/models/Model'
import { ContactType } from '@/models/persons/constants'
import { MainStore } from '@/store/MainStore'
import { personsApi } from '@/api/persons-api'
import { List } from '@/models/List'
import { ObjectParams } from '@/models/types'
import { MAX_LIMIT } from '@/api/api'
import { formatContractID } from '@/models/persons/Contract'

export interface IContact extends IModel {
  id: number
  type: ContactType
  value: string
  isMain: boolean
  personID: number
  note: string
}

export class Contact extends CommonModel implements IContact {
  readonly regexPhone = '^[0-9]{4,20}$'

  id = 0
  type: ContactType = ContactType.Phone
  value = ''
  isMain = true
  personID = 0
  note = ''

  get formattedID(): string {
    return formatContractID(this.id)
  }

  get nameWithNoteHtml(): string {
    return this.nameHtml + (this.note ? ` (${this.note})` : '')
  }

  get valueWithNote(): string {
    return this.value + (this.note ? ` (${this.note})` : '')
  }

  get isEmail(): boolean {
    return this.type == ContactType.Email
  }

  get isPhone(): boolean {
    return this.type == ContactType.Phone
  }

  get nameHtml(): string {
    let label = this.value

    if (this.type == ContactType.Phone) {
      label = `<i class="fas fa-phone"></i> <a href="${this.href}">${this.value}</a>`
    } else if (this.type == ContactType.Email) {
      label = `<i class="fas fa-at"></i> <a href="${this.href}">${this.value}</a>`
    }

    if (this.isMain) {
      label = `${label} <i class="fas fa-check text-success"></i>`
    }

    return label
  }

  get href(): string {
    if (this.type === ContactType.Phone) {
      return `tel:${this.value}`
    }
    if (this.type === ContactType.Email) {
      return `mailto:${this.value}`
    }
    return this.value
  }

  constructor(src: IContact | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: IContact) {
    Object.assign(this, src)

    this.initModel(src)
  }

  async load(): Promise<Contact> {
    return this
  }

  static async getByID(id: number): Promise<Contact> {
    let item = new Contact()
    try {
      item = new Contact(await personsApi.contact.getByID(id))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return item
  }

  static async list(params: ObjectParams): Promise<List<Contact>> {
    const list: List<Contact> = { count: 0, items: [] }
    try {
      const l = await personsApi.contact.list(params)
      list.count = l.count
      list.items = l.items.map((v) => new Contact(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return list
  }

  static async personContacts(personID: number): Promise<Contact[]> {
    let items: Contact[] = []
    try {
      items = (await Contact.list({ personID: personID, limit: MAX_LIMIT })).items.map(
        (v) => new Contact(v)
      )
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return items
  }

  async save(): Promise<boolean> {
    try {
      return this.id ? await this.update() : await this.add()
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async add(): Promise<boolean> {
    try {
      this.init(await personsApi.contact.add(this.toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async update(): Promise<boolean> {
    try {
      this.init(await personsApi.contact.update(this.toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async delete(): Promise<boolean> {
    if (!confirm(`Are you sure you want to delete contact ${this.value}?`)) return false

    try {
      await personsApi.contact.delete(this.id)
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  toInterface(): IContact {
    return {
      id: this.id,
      type: this.type,
      value: this.value,
      isMain: this.isMain,
      personID: this.personID,
      note: this.note,
    }
  }
}
