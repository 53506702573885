import { settings } from '@/models/Settings'
import { toNumber } from '@/utils/Converter'

export const VAT_VALUE = settings().vat
export const MONEY_COEFFICIENT = 10000
export const HUNDRED_PERCENT = 100
export const FRACTION = 2
export const CURRENCY = '€'
const FRACTION_WITH_VAT = 4

export class Money {
  private _vatPercentage?: number

  value = 0

  // takes a value in the storage format in the database (multiplied by 10000)
  constructor(value: number | string | Money = 0) {
    if (value instanceof Money) {
      this.value = toNumber(value.value)
    } else {
      this.value = Money.toViewed(toNumber(value), 6)
    }
  }

  static withValue(value: number): Money {
    const money = new Money()
    money.value = toNumber(value)
    return money
  }

  get vatPercentage(): number {
    return this._vatPercentage == undefined ? VAT_VALUE : this._vatPercentage
  }

  withVatPercentage(value: number): Money {
    this._vatPercentage = toNumber(value)
    return this
  }

  get forStorage(): number {
    return Money.toValue(this.value)
  }

  get euro(): string {
    return toNumber(this.value).toFixed(FRACTION) + ' ' + CURRENCY
  }

  get vatEuro(): string {
    return this.vat.toFixed(FRACTION) + ' ' + CURRENCY
  }

  get withVatEuro(): string {
    return this.withVat.toFixed(FRACTION) + ' ' + CURRENCY
  }

  get vat(): number {
    return Money.percentageOfValue(this.value, this.vatPercentage)
  }

  get withVat(): number {
    return parseFloat((toNumber(this.value) + this.vat).toFixed(FRACTION_WITH_VAT))
  }

  percentage(percent: number): number {
    return Money.percentageOfValue(this.value, percent)
  }

  static toViewed(price: number, fraction: number = FRACTION): number {
    return parseFloat((price / MONEY_COEFFICIENT).toFixed(fraction))
  }

  static toValue(price: number): number {
    return Math.round(toNumber(price) * MONEY_COEFFICIENT)
  }

  static percentageOfValue(value: number, percent: number): number {
    return (toNumber(value) / HUNDRED_PERCENT) * toNumber(percent)
  }

  static includesPercentageOfMoney(value: number, percent: number): number {
    return (toNumber(value) / (HUNDRED_PERCENT + percent)) * toNumber(percent)
  }
}

export function vatLabel(): string {
  return `VAT ${VAT_VALUE}%`
}
