import FileResolver from '@/components/common/file-upload/file-resolver'
import { downloadBlob, nameWithoutExt } from '@/utils'

export class FileInfo {
  public name = ''
  public body: string | ArrayBuffer | null = ''
  public src: string | ArrayBuffer | null = ''
  public file?: Blob

  get isImage(): boolean {
    return Boolean(this.file && FileResolver.isImage(this.file.type))
  }

  constructor(src: FileInfo | null = null) {
    if (src) {
      this.init(src)
    }
  }

  private init(src: FileInfo) {
    Object.assign(this, src)
  }

  download = (): void => {
    if (this.file) {
      downloadBlob(this.file, nameWithoutExt(this.name))
    }
  }
}
