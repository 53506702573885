
import { Component, Vue, Watch } from 'vue-property-decorator'
import DashboardBoxes from '@/components/dashboards/DashboardBoxes.vue'
import { MainStore } from '@/store/MainStore'
import { CAN_DASHBOARD_BOXES, CAN_DASHBOARD_CUSTOMERS } from '@/permissions/dashboard'
import { dashboardsApi } from '@/api/dashboards-api'
import { Boxes } from '@/components/dashboards/models/Boxes'
import DashboardShortcuts from '@/components/dashboards/DashboardShortcuts.vue'
import Chart from '@/components/dashboards/TheChart.vue'
import CheckYear from '@/components/dashboards/CheckYear.vue'
import { ChartConfiguration } from 'chart.js'
import { loaderWrapper } from '@/utils/loaderWrapper'
import { personsApi } from '@/api/persons-api'
import { Workflow } from '@/models/persons/Workflow'
import { WorkflowLoader } from '@/models/persons/WorkflowLoader'
import DashboardAbsent from '@/components/dashboards/DashboardAbsent.vue'
import { AnnualReports } from '@/components/dashboards/models/AnnualReport'
import { dateString } from '@/utils'
import { Nas } from '@/models/internet/Nas'
import { NasGet } from '@/models/internet/NasGet'
import { NasLoader } from '@/models/internet/NasLoader'
import { IpPoolLoader } from '@/models/internet/IpPoolLoader'
import NasBoxes from '@/components/dashboards/NasBoxes.vue'
import { NasStatus } from '@/models/internet/constants'

@Component({
  components: {
    NasBoxes,
    DashboardAbsent,
    CheckYear,
    Chart,
    DashboardShortcuts,
    DashboardBoxes,
  },
})
export default class Dashboard extends Vue {
  boxes: Boxes | null = null
  absentEmployees: Workflow[] = []
  customersChart: ChartConfiguration | null = null
  contractsChart: ChartConfiguration | null = null
  ticketsChart: ChartConfiguration | null = null
  installationsChart: ChartConfiguration | null = null
  reports: AnnualReports | null = null
  nasItems: Nas[] = []

  year = new Date().getUTCFullYear()

  @Watch('year')
  reloadData(): void {
    this.loadCustomersChart()
  }

  async created(): Promise<void> {
    await loaderWrapper(async () => {
      await Promise.all([
        this.loadBoxes(),
        this.loadCustomersChart(),
        this.loadAbsentEmployees(),
        this.loadNas(),
      ])
    })
  }

  async loadBoxes(): Promise<void> {
    if (MainStore.isUserCan(CAN_DASHBOARD_BOXES)) {
      const now = new Date()

      this.boxes = await dashboardsApi.boxes({
        fromDate: dateString(new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth()))),
      })
    }
  }

  async loadCustomersChart(): Promise<void> {
    if (!MainStore.isUserCan(CAN_DASHBOARD_CUSTOMERS)) {
      return
    }

    const res = await dashboardsApi.annualReports(this.year)
    this.customersChart = res.chartCustomers
    this.contractsChart = res.chartContracts
    this.ticketsChart = res.chartTickets
    this.installationsChart = res.chartInstallations

    this.reports = new AnnualReports(res)
  }

  async loadAbsentEmployees(): Promise<void> {
    const absentEmployees = (await personsApi.workflow.absentEmployees(new Date())).map(
      (v) => new Workflow(v)
    )

    if (absentEmployees.length) {
      await new WorkflowLoader(absentEmployees).load('personsWithUsers')
    }

    this.absentEmployees = absentEmployees
  }

  async loadNas(): Promise<void> {
    const items = (await NasGet.all()).filter((v) => v.status == NasStatus.On)

    await new NasLoader(items).load('ipPools')
    await new IpPoolLoader(items.map((v) => v.ipPools).flat()).load('sessionsInfo')

    this.nasItems = items
  }
}
