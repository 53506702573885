import { MainStore } from '@/store/MainStore'
import { usersApi } from '@/api/users-api'

export const DEF_LOCALE = 'en'
export const DEF_VAT = 21

export function settings(): ISettings {
  return MainStore.getSettings()
}

export interface ISettings {
  locale: string
  languages: { [key: string]: string }
  vat: number
  contractPrefix: string
  sizeContractID: number
  maxDaysReopenTickets: number
  maxInvoiceDueDate: number
}

export class Settings implements ISettings {
  private readonly _locale: string = DEF_LOCALE
  private readonly _languages: { [p: string]: string } = {}
  private readonly _vat: number = DEF_VAT
  private readonly _contractPrefix: string = 'C'
  private readonly _sizeContractID: number = 5
  private readonly _maxDaysReopenTickets: number = 10
  private readonly _maxInvoiceDueDate: number = 30

  get vat(): number {
    return this._vat
  }

  get languages(): { [p: string]: string } {
    return { ...this._languages }
  }

  get locale(): string {
    return this._locale
  }

  get contractPrefix(): string {
    return this._contractPrefix
  }

  get sizeContractID(): number {
    return this._sizeContractID
  }

  get maxDaysReopenTickets(): number {
    return this._maxDaysReopenTickets
  }

  get maxInvoiceDueDate(): number {
    return this._maxInvoiceDueDate
  }

  constructor(src: ISettings | null = null) {
    if (src) {
      this._vat = src.vat || this._vat
      this._locale = src.locale || this._locale
      this._languages = src.languages ? { ...src.languages } : {}
      this._contractPrefix = src.contractPrefix || this._contractPrefix
      this._sizeContractID = src.sizeContractID || this._sizeContractID
      this._maxDaysReopenTickets = src.maxDaysReopenTickets || this._maxDaysReopenTickets
      this._maxInvoiceDueDate = src.maxInvoiceDueDate || this._maxInvoiceDueDate
    }
  }

  static async get(): Promise<Settings> {
    let item = new Settings()
    try {
      const s = await usersApi.settings.getSystem()
      item = new Settings(s)
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return item
  }

  toInterface(): ISettings {
    return {
      locale: this.locale,
      languages: { ...this.languages },
      vat: this.vat,
      contractPrefix: this.contractPrefix,
      sizeContractID: this.sizeContractID,
      maxDaysReopenTickets: this.maxDaysReopenTickets,
      maxInvoiceDueDate: this.maxInvoiceDueDate,
    }
  }
}
