import API, { MAX_LIMIT, paramsByIds } from '@/api/api'
import { ICountry } from '@/models/geo/Country'
import { IProvince } from '@/models/geo/Province'
import { IMunicipality } from '@/models/geo/Municipality'
import { IUrbanization } from '@/models/geo/Urbanization'
import { IStreet } from '@/models/geo/Street'
import { IDistrict } from '@/models/geo/District'
import { ICommunity } from '@/models/geo/Community'
import { IGeoDetails } from '@/models/geo/GeoDetails'
import { List } from '@/models/List'
import { ObjectParams } from '@/models/types'
import { GeoStore } from '@/store/modules/geo/GeoStore'
import { IdentityRequest } from '@/requests/IdentityRequest'
import { GeoDetailsRequest } from '@/requests/GeoDetailsRequest'

const URL_COUNTRIES = '/geo/countries'
const URL_COUNTRIES_APPROVE = URL_COUNTRIES + '/approve'
const URL_COUNTRIES_REJECT = URL_COUNTRIES + '/reject'
const URL_COUNTRIES_VERSIONS = URL_COUNTRIES + '/versions'

const URL_PROVINCES = '/geo/provinces'
const URL_PROVINCES_APPROVE = URL_PROVINCES + '/approve'
const URL_PROVINCES_REJECT = URL_PROVINCES + '/reject'
const URL_PROVINCES_VERSIONS = URL_PROVINCES + '/versions'

const URL_MUNICIPALITIES = '/geo/municipalities'
const URL_MUNICIPALITIES_APPROVE = URL_MUNICIPALITIES + '/approve'
const URL_MUNICIPALITIES_REJECT = URL_MUNICIPALITIES + '/reject'
const URL_MUNICIPALITIES_VERSIONS = URL_MUNICIPALITIES + '/versions'

const URL_URBANIZATIONS = '/geo/urbanizations'
const URL_URBANIZATIONS_APPROVE = URL_URBANIZATIONS + '/approve'
const URL_URBANIZATIONS_REJECT = URL_URBANIZATIONS + '/reject'
const URL_URBANIZATIONS_VERSIONS = URL_URBANIZATIONS + '/versions'

const URL_STREETS = '/geo/streets'
const URL_STREETS_APPROVE = URL_STREETS + '/approve'
const URL_STREETS_REJECT = URL_STREETS + '/reject'
const URL_STREETS_VERSIONS = URL_STREETS + '/versions'

const URL_COMMUNITIES = '/geo/communities'
const URL_COMMUNITIES_APPROVE = URL_COMMUNITIES + '/approve'
const URL_COMMUNITIES_REJECT = URL_COMMUNITIES + '/reject'
const URL_COMMUNITIES_VERSIONS = URL_COMMUNITIES + '/versions'

const URL_DISTRICTS = '/geo/districts'
const URL_DISTRICTS_APPROVE = URL_DISTRICTS + '/approve'
const URL_DISTRICTS_REJECT = URL_DISTRICTS + '/reject'
const URL_DISTRICTS_VERSIONS = URL_DISTRICTS + '/versions'

const URL_DETAILS = '/geo/details'
const URL_DETAILS_GET = '/geo/get-details'

const COUNT = '/count'

export const geoApi = {
  country: {
    getByID: async function (id: number): Promise<ICountry> {
      const res = await API.get(API.buildURL(URL_COUNTRIES + '/' + id))
      return (await res.json()) as Promise<ICountry>
    },

    getByIDs: async function (IDs: number[]): Promise<ICountry[]> {
      if (!IDs || !IDs.length) return []
      const res = await API.get(
        API.buildURL(URL_COUNTRIES, { ...paramsByIds(IDs), limit: MAX_LIMIT })
      )
      return (await ((await res.json()) as Promise<List<ICountry>>)).items
    },

    list: async function (params: ObjectParams): Promise<List<ICountry>> {
      const res = await API.get(API.buildURL(URL_COUNTRIES, { ...params }))
      return (await res.json()) as Promise<List<ICountry>>
    },

    versions: async function (params: ObjectParams): Promise<List<ICountry>> {
      const res = await API.get(API.buildURL(URL_COUNTRIES_VERSIONS, { ...params }))
      return (await res.json()) as Promise<List<ICountry>>
    },

    count: async function (params: ObjectParams): Promise<number> {
      const res = await API.get(API.buildURL(URL_COUNTRIES + COUNT, { ...params }))
      return (await res.json()) as Promise<number>
    },

    add: async function (country: ICountry): Promise<ICountry> {
      const res = await API.post(API.buildURL(URL_COUNTRIES), country)
      await GeoStore.loadAllAddressesParts()
      return (await res.json()) as Promise<ICountry>
    },

    update: async function (country: ICountry): Promise<ICountry> {
      const res = await API.put(API.buildURL(URL_COUNTRIES), country)
      await GeoStore.loadAllAddressesParts()
      return (await res.json()) as Promise<ICountry>
    },

    delete: async function (id: number): Promise<void> {
      const res = await API.delete(API.buildURL(URL_COUNTRIES + '/' + id))

      if (!res.ok) {
        throw new Error(await res.json())
      }
      await GeoStore.loadAllAddressesParts()
    },

    approve: async function (request: IdentityRequest): Promise<ICountry> {
      const res = await API.put(API.buildURL(URL_COUNTRIES_APPROVE), request)
      await GeoStore.loadAllAddressesParts()
      return (await res.json()) as Promise<ICountry>
    },

    reject: async function (request: IdentityRequest): Promise<ICountry> {
      const res = await API.put(API.buildURL(URL_COUNTRIES_REJECT), request)
      await GeoStore.loadAllAddressesParts()
      return (await res.json()) as Promise<ICountry>
    },
  },

  province: {
    getByID: async function (id: number): Promise<IProvince> {
      const res = await API.get(API.buildURL(URL_PROVINCES + '/' + id))
      return (await res.json()) as Promise<IProvince>
    },

    list: async function (params: ObjectParams): Promise<List<IProvince>> {
      const res = await API.get(API.buildURL(URL_PROVINCES, { ...params }))
      return (await res.json()) as Promise<List<IProvince>>
    },

    versions: async function (params: ObjectParams): Promise<List<IProvince>> {
      const res = await API.get(API.buildURL(URL_PROVINCES_VERSIONS, { ...params }))
      return (await res.json()) as Promise<List<IProvince>>
    },

    count: async function (params: ObjectParams): Promise<number> {
      const res = await API.get(API.buildURL(URL_PROVINCES + COUNT, { ...params }))
      return (await res.json()) as Promise<number>
    },

    add: async function (province: IProvince): Promise<IProvince> {
      const res = await API.post(API.buildURL(URL_PROVINCES), province)
      await GeoStore.loadAllAddressesParts()
      return (await res.json()) as Promise<IProvince>
    },

    update: async function (province: IProvince): Promise<IProvince> {
      const res = await API.put(API.buildURL(URL_PROVINCES), province)
      await GeoStore.loadAllAddressesParts()
      return (await res.json()) as Promise<IProvince>
    },

    delete: async function (id: number): Promise<void> {
      const res = await API.delete(API.buildURL(URL_PROVINCES + '/' + id))
      if (!res.ok) {
        throw new Error(await res.json())
      }
      await GeoStore.loadAllAddressesParts()
    },

    approve: async function (request: IdentityRequest): Promise<IProvince> {
      const res = await API.put(API.buildURL(URL_PROVINCES_APPROVE), request)
      await GeoStore.loadAllAddressesParts()
      return (await res.json()) as Promise<IProvince>
    },

    reject: async function (request: IdentityRequest): Promise<IProvince> {
      const res = await API.put(API.buildURL(URL_PROVINCES_REJECT), request)
      await GeoStore.loadAllAddressesParts()
      return (await res.json()) as Promise<IProvince>
    },
  },

  municipality: {
    getByID: async function (id: number): Promise<IMunicipality> {
      const res = await API.get(API.buildURL(URL_MUNICIPALITIES + '/' + id))
      return (await res.json()) as Promise<IMunicipality>
    },

    list: async function (params: ObjectParams): Promise<List<IMunicipality>> {
      const res = await API.get(API.buildURL(URL_MUNICIPALITIES, { ...params }))
      return (await res.json()) as Promise<List<IMunicipality>>
    },

    versions: async function (params: ObjectParams): Promise<List<IMunicipality>> {
      const res = await API.get(API.buildURL(URL_MUNICIPALITIES_VERSIONS, { ...params }))
      return (await res.json()) as Promise<List<IMunicipality>>
    },

    count: async function (params: ObjectParams): Promise<number> {
      const res = await API.get(API.buildURL(URL_MUNICIPALITIES + COUNT, { ...params }))
      return (await res.json()) as Promise<number>
    },

    add: async function (municipality: IMunicipality): Promise<IMunicipality> {
      const res = await API.post(API.buildURL(URL_MUNICIPALITIES), municipality)
      await GeoStore.loadAllAddressesParts()
      return (await res.json()) as Promise<IMunicipality>
    },

    update: async function (municipality: IMunicipality): Promise<IMunicipality> {
      const res = await API.put(API.buildURL(URL_MUNICIPALITIES), municipality)
      await GeoStore.loadAllAddressesParts()
      return (await res.json()) as Promise<IMunicipality>
    },

    delete: async function (id: number): Promise<void> {
      const res = await API.delete(API.buildURL(URL_MUNICIPALITIES + '/' + id))
      if (!res.ok) {
        throw new Error(await res.json())
      }
      await GeoStore.loadAllAddressesParts()
    },

    approve: async function (request: IdentityRequest): Promise<IMunicipality> {
      const res = await API.put(API.buildURL(URL_MUNICIPALITIES_APPROVE), request)
      await GeoStore.loadAllAddressesParts()
      return (await res.json()) as Promise<IMunicipality>
    },

    reject: async function (request: IdentityRequest): Promise<IMunicipality> {
      const res = await API.put(API.buildURL(URL_MUNICIPALITIES_REJECT), request)
      await GeoStore.loadAllAddressesParts()
      return (await res.json()) as Promise<IMunicipality>
    },
  },

  urbanization: {
    getByID: async function (id: number): Promise<IUrbanization> {
      const res = await API.get(API.buildURL(URL_URBANIZATIONS + '/' + id))
      return (await res.json()) as Promise<IUrbanization>
    },

    list: async function (params: ObjectParams): Promise<List<IUrbanization>> {
      const res = await API.get(API.buildURL(URL_URBANIZATIONS, { ...params }))
      return (await res.json()) as Promise<List<IUrbanization>>
    },

    versions: async function (params: ObjectParams): Promise<List<IUrbanization>> {
      const res = await API.get(API.buildURL(URL_URBANIZATIONS_VERSIONS, { ...params }))
      return (await res.json()) as Promise<List<IUrbanization>>
    },

    count: async function (params: ObjectParams): Promise<number> {
      const res = await API.get(API.buildURL(URL_URBANIZATIONS + COUNT, { ...params }))
      return (await res.json()) as Promise<number>
    },

    add: async function (urbanization: IUrbanization): Promise<IUrbanization> {
      const res = await API.post(API.buildURL(URL_URBANIZATIONS), urbanization)
      await GeoStore.loadAllAddressesParts()
      return (await res.json()) as Promise<IUrbanization>
    },

    update: async function (urbanization: IUrbanization): Promise<IUrbanization> {
      const res = await API.put(API.buildURL(URL_URBANIZATIONS), urbanization)
      await GeoStore.loadAllAddressesParts()
      return (await res.json()) as Promise<IUrbanization>
    },

    delete: async function (id: number): Promise<void> {
      const res = await API.delete(API.buildURL(URL_URBANIZATIONS + '/' + id))
      if (!res.ok) {
        throw new Error(await res.json())
      }
      await GeoStore.loadAllAddressesParts()
    },

    approve: async function (request: IdentityRequest): Promise<IUrbanization> {
      const res = await API.put(API.buildURL(URL_URBANIZATIONS_APPROVE), request)
      await GeoStore.loadAllAddressesParts()
      return (await res.json()) as Promise<IUrbanization>
    },

    reject: async function (request: IdentityRequest): Promise<IUrbanization> {
      const res = await API.put(API.buildURL(URL_URBANIZATIONS_REJECT), request)
      await GeoStore.loadAllAddressesParts()
      return (await res.json()) as Promise<IUrbanization>
    },
  },

  street: {
    getByID: async function (id: number): Promise<IStreet> {
      const res = await API.get(API.buildURL(URL_STREETS + '/' + id))
      return (await res.json()) as Promise<IStreet>
    },

    list: async function (params: ObjectParams): Promise<List<IStreet>> {
      const res = await API.get(API.buildURL(URL_STREETS, { ...params }))
      return (await res.json()) as Promise<List<IStreet>>
    },

    versions: async function (params: ObjectParams): Promise<List<IStreet>> {
      const res = await API.get(API.buildURL(URL_STREETS_VERSIONS, { ...params }))
      return (await res.json()) as Promise<List<IStreet>>
    },

    count: async function (params: ObjectParams): Promise<number> {
      const res = await API.get(API.buildURL(URL_STREETS + COUNT, { ...params }))
      return (await res.json()) as Promise<number>
    },

    add: async function (street: IStreet): Promise<IStreet> {
      const res = await API.post(API.buildURL(URL_STREETS), street)
      await GeoStore.loadAllAddressesParts()
      return (await res.json()) as Promise<IStreet>
    },

    update: async function (street: IStreet): Promise<IStreet> {
      const res = await API.put(API.buildURL(URL_STREETS), street)
      await GeoStore.loadAllAddressesParts()
      return (await res.json()) as Promise<IStreet>
    },

    delete: async function (id: number): Promise<void> {
      const res = await API.delete(API.buildURL(URL_STREETS + '/' + id))
      if (!res.ok) {
        throw new Error(await res.json())
      }
      await GeoStore.loadAllAddressesParts()
    },

    approve: async function (request: IdentityRequest): Promise<IStreet> {
      const res = await API.put(API.buildURL(URL_STREETS_APPROVE), request)
      await GeoStore.loadAllAddressesParts()
      return (await res.json()) as Promise<IStreet>
    },

    reject: async function (request: IdentityRequest): Promise<IStreet> {
      const res = await API.put(API.buildURL(URL_STREETS_REJECT), request)
      await GeoStore.loadAllAddressesParts()
      return (await res.json()) as Promise<IStreet>
    },
  },

  community: {
    getByID: async function (id: number): Promise<ICommunity> {
      const res = await API.get(API.buildURL(URL_COMMUNITIES + '/' + id))
      return (await res.json()) as Promise<ICommunity>
    },

    list: async function (params: ObjectParams): Promise<List<ICommunity>> {
      const res = await API.get(API.buildURL(URL_COMMUNITIES, { ...params }))
      return (await res.json()) as Promise<List<ICommunity>>
    },

    versions: async function (params: ObjectParams): Promise<List<ICommunity>> {
      const res = await API.get(API.buildURL(URL_COMMUNITIES_VERSIONS, { ...params }))
      return (await res.json()) as Promise<List<ICommunity>>
    },

    count: async function (params: ObjectParams): Promise<number> {
      const res = await API.get(API.buildURL(URL_COMMUNITIES + COUNT, { ...params }))
      return (await res.json()) as Promise<number>
    },

    add: async function (street: ICommunity): Promise<ICommunity> {
      const res = await API.post(API.buildURL(URL_COMMUNITIES), street)
      await GeoStore.loadAllAddressesParts()
      return (await res.json()) as Promise<ICommunity>
    },

    update: async function (street: ICommunity): Promise<ICommunity> {
      const res = await API.put(API.buildURL(URL_COMMUNITIES), street)
      await GeoStore.loadAllAddressesParts()
      return (await res.json()) as Promise<ICommunity>
    },

    delete: async function (id: number): Promise<void> {
      const res = await API.delete(API.buildURL(URL_COMMUNITIES + '/' + id))
      if (!res.ok) {
        throw new Error(await res.json())
      }
      await GeoStore.loadAllAddressesParts()
    },

    approve: async function (request: IdentityRequest): Promise<ICommunity> {
      const res = await API.put(API.buildURL(URL_COMMUNITIES_APPROVE), request)
      await GeoStore.loadAllAddressesParts()
      return (await res.json()) as Promise<ICommunity>
    },

    reject: async function (request: IdentityRequest): Promise<ICommunity> {
      const res = await API.put(API.buildURL(URL_COMMUNITIES_REJECT), request)
      await GeoStore.loadAllAddressesParts()
      return (await res.json()) as Promise<ICommunity>
    },
  },

  district: {
    getByID: async function (id: number): Promise<IDistrict> {
      const res = await API.get(API.buildURL(URL_DISTRICTS + '/' + id))
      return (await res.json()) as Promise<IDistrict>
    },

    list: async function (params: ObjectParams): Promise<List<IDistrict>> {
      const res = await API.get(API.buildURL(URL_DISTRICTS, { ...params }))
      return (await res.json()) as Promise<List<IDistrict>>
    },

    versions: async function (params: ObjectParams): Promise<List<IDistrict>> {
      const res = await API.get(API.buildURL(URL_DISTRICTS_VERSIONS, { ...params }))
      return (await res.json()) as Promise<List<IDistrict>>
    },

    count: async function (params: ObjectParams): Promise<number> {
      const res = await API.get(API.buildURL(URL_DISTRICTS + COUNT, { ...params }))
      return (await res.json()) as Promise<number>
    },

    add: async function (address: IDistrict): Promise<IDistrict> {
      const res = await API.post(API.buildURL(URL_DISTRICTS), address)
      await GeoStore.loadAllAddressesParts()
      return (await res.json()) as Promise<IDistrict>
    },

    update: async function (address: IDistrict): Promise<IDistrict> {
      const res = await API.put(API.buildURL(URL_DISTRICTS), address)
      await GeoStore.loadAllAddressesParts()
      return (await res.json()) as Promise<IDistrict>
    },

    delete: async function (id: number): Promise<void> {
      const res = await API.delete(API.buildURL(URL_DISTRICTS + '/' + id))
      if (!res.ok) {
        throw new Error(await res.json())
      }
      await GeoStore.loadAllAddressesParts()
    },

    approve: async function (request: IdentityRequest): Promise<IDistrict> {
      const res = await API.put(API.buildURL(URL_DISTRICTS_APPROVE), request)
      await GeoStore.loadAllAddressesParts()
      return (await res.json()) as Promise<IDistrict>
    },

    reject: async function (request: IdentityRequest): Promise<IDistrict> {
      const res = await API.put(API.buildURL(URL_DISTRICTS_REJECT), request)
      await GeoStore.loadAllAddressesParts()
      return (await res.json()) as Promise<IDistrict>
    },
  },

  details: {
    save: async function (model: IGeoDetails): Promise<IGeoDetails> {
      const res = await API.post(API.buildURL(URL_DETAILS), model)
      await GeoStore.loadAllAddressesParts()
      return (await res.json()) as Promise<IGeoDetails>
    },

    get: async function (request: GeoDetailsRequest): Promise<IGeoDetails[]> {
      const res = await API.post(API.buildURL(URL_DETAILS_GET), request)
      return (await res.json()) as Promise<IGeoDetails[]>
    },
  },
}
