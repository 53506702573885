import { NavigationGuard } from 'vue-router'
import { CHANGE_PASSWORD, ERROR_PAGE } from '@/router'
import { MainStore } from '@/store/MainStore'

const permissionGuard: NavigationGuard = async (to, from, next) => {
  if (to.name === ERROR_PAGE || to.name === CHANGE_PASSWORD || can(to.meta?.permission)) {
    next()
  } else {
    /* eslint-disable-next-line no-console */
    console.error('[permissionGuard] permission is missing: ' + to.meta?.permission)
    next(false)
  }
}

function can(perm?: string | string[]): boolean {
  if (!perm) {
    return false
  }

  if (typeof perm == 'string') {
    return MainStore.isUserCan(perm)
  }

  return MainStore.isUserCanOne(...perm)
}

export default permissionGuard
