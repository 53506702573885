
import { Component, Vue } from 'vue-property-decorator'
import { ToastDanger } from '@/models/Toast'
import { MainStore } from '@/store/MainStore'
import { Settings } from '@/models/Settings'
import { Auth } from '@/models/user/auth'

@Component
export default class AppLogin extends Vue {
  auth = new Auth()

  errorText = ''

  async send(): Promise<void> {
    try {
      await this.auth.login()
      await MainStore.initSettings(await Settings.get())
    } catch (e) {
      const err = e as Error
      if (err.name === '404') {
        this.errorText = 'Incorrect login or password'
      } else {
        this.errorText = 'Something going wrong, please, contact with support'
        await new ToastDanger(err.message).show()
      }
    }
  }
}
