
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class BToastContainer extends Vue {
  @Prop({
    type: String,
    default: 'position: fixed; width:410px; top: 130px; right: 20px; z-index: 9999',
  })
  readonly mainStyle?: string
}
