import API, { MAX_LIMIT, paramsByIds } from '@/api/api'
import { FileInfo } from '@/models/FileInfo'
import { IPerson } from '@/models/persons/Person'
import { IContact } from '@/models/persons/Contact'
import { IContract } from '@/models/persons/Contract'
import { IAddress } from '@/models/persons/Address'
import { IDocument } from '@/models/persons/Document'
import { ILanguage } from '@/models/persons/Language'
import { IPersonWhence } from '@/models/persons/PersonWhence'
import { IBillingLink } from '@/models/persons/BillingLink'
import { IPersonSimple } from '@/models/persons/PersonSimple'
import { List } from '@/models/List'
import { ObjectParams } from '@/models/types'
import { GeoStore } from '@/store/modules/geo/GeoStore'
import { DataSendInvoice, HolidayReportResponse } from '@/responses/persons'
import { IWorkflow } from '@/models/persons/Workflow'
import { IHoliday } from '@/models/calendars/Holiday'
import { IHolidayReport } from '@/models/calendars/HolidayReport'

const URL_PERSONS_MODULE = '/persons'

const URL_PERSONS = URL_PERSONS_MODULE + '/persons'
const URL_PERSONS_SIMPLE = URL_PERSONS_MODULE + '/simple'
const URL_PERSONS_SIMPLE_BY_SUBSCRIPTIONS = URL_PERSONS_SIMPLE + '/subscriptions'
const URL_PERSON_WHENCES = URL_PERSONS_MODULE + '/person-whence'
const URL_BILLING_LINK = URL_PERSONS_MODULE + '/billing-link'
const URL_PERSONS_LANGUAGES = URL_PERSONS_MODULE + '/languages'
const URL_PERSONS_WORKFLOWS = URL_PERSONS_MODULE + '/workflows'
const URL_HOLIDAYS = URL_PERSONS_WORKFLOWS + '/holidays'
const URL_HOLIDAY_REPORTS = URL_HOLIDAYS + '/reports'
const URL_CONTACTS = URL_PERSONS_MODULE + '/contacts'
const URL_CONTRACTS = URL_PERSONS_MODULE + '/contracts'
const URL_CONTRACTS_VER = URL_CONTRACTS + '/versions'
const URL_ADDRESSES = URL_PERSONS_MODULE + '/addresses'
const URL_ADDRESSES_VER = URL_ADDRESSES + '/versions'
const URL_DOCUMENTS = URL_PERSONS_MODULE + '/documents'
const URL_DOCUMENT_FILE = URL_DOCUMENTS + '/file'

const COUNT = '/count'

export const personsApi = {
  person: {
    getByID: async function (id: number): Promise<IPerson> {
      const res = await API.get(API.buildURL(URL_PERSONS + '/' + id))
      return (await res.json()) as Promise<IPerson>
    },

    dataSendInvoice: async function (id: number): Promise<DataSendInvoice> {
      const res = await API.get(API.buildURL(URL_PERSONS + '/' + id + '/data-send-invoice'))
      return (await res.json()) as Promise<DataSendInvoice>
    },

    getByIDs: async function (IDs: number[]): Promise<IPerson[]> {
      if (!IDs || !IDs.length) return []
      const res = await API.get(
        API.buildURL(URL_PERSONS, { ...paramsByIds(IDs), limit: MAX_LIMIT })
      )
      return (await ((await res.json()) as Promise<List<IPerson>>)).items
    },

    add: async function (person: IPerson): Promise<IPerson> {
      const res = await API.post(API.buildURL(URL_PERSONS), person)
      return (await res.json()) as Promise<IPerson>
    },

    list: async function (params: ObjectParams): Promise<List<IPerson>> {
      const res = await API.get(API.buildURL(URL_PERSONS, { ...params }))
      return (await res.json()) as Promise<List<IPerson>>
    },
  },

  simple: {
    getByID: async function (id: number): Promise<IPersonSimple> {
      const res = await API.get(API.buildURL(URL_PERSONS_SIMPLE + '/' + id))
      return (await res.json()) as Promise<IPersonSimple>
    },

    getByIDs: async function (IDs: number[]): Promise<IPersonSimple[]> {
      if (!IDs || !IDs.length) return []
      const res = await API.get(
        API.buildURL(URL_PERSONS_SIMPLE, { ...paramsByIds(IDs), limit: MAX_LIMIT })
      )
      return (await ((await res.json()) as Promise<List<IPersonSimple>>)).items
    },

    update: async function (person: IPersonSimple): Promise<IPersonSimple> {
      const res = await API.put(API.buildURL(URL_PERSONS), person)
      return (await res.json()) as Promise<IPersonSimple>
    },

    list: async function (params: ObjectParams): Promise<List<IPersonSimple>> {
      const res = await API.get(API.buildURL(URL_PERSONS_SIMPLE, { ...params }))
      return (await res.json()) as Promise<List<IPersonSimple>>
    },

    mapBySubscriptions: async function (IDs: number[]): Promise<{ [key: number]: IPersonSimple }> {
      const res = await API.get(API.buildURL(URL_PERSONS_SIMPLE_BY_SUBSCRIPTIONS, paramsByIds(IDs)))
      return (await res.json()) as Promise<{ [key: number]: IPersonSimple }>
    },
  },

  contact: {
    getByID: async function (id: number): Promise<IContact> {
      const res = await API.get(API.buildURL(URL_CONTACTS + '/' + id))
      return (await res.json()) as Promise<IContact>
    },

    add: async function (contact: IContact): Promise<IContact> {
      const res = await API.post(API.buildURL(URL_CONTACTS), contact)
      return (await res.json()) as Promise<IContact>
    },

    update: async function (contact: IContact): Promise<IContact> {
      const res = await API.put(API.buildURL(URL_CONTACTS), contact)
      return (await res.json()) as Promise<IContact>
    },

    delete: async function (id: number): Promise<void> {
      const res = await API.delete(API.buildURL(URL_CONTACTS + '/' + id))
      if (!res.ok) {
        throw new Error(await res.json())
      }
    },

    list: async function (params: ObjectParams): Promise<List<IContact>> {
      const res = await API.get(API.buildURL(URL_CONTACTS, { ...params }))
      return (await res.json()) as Promise<List<IContact>>
    },
  },

  contract: {
    getByID: async function (id: number): Promise<IContract> {
      const res = await API.get(API.buildURL(URL_CONTRACTS + '/' + id))
      return (await res.json()) as Promise<IContract>
    },

    getByIDs: async function (
      IDs: number[] | string[],
      params: ObjectParams = {}
    ): Promise<IContract[]> {
      if (!IDs || !IDs.length) return []
      const res = await API.get(
        API.buildURL(URL_CONTRACTS, { ...params, ...paramsByIds(IDs), limit: MAX_LIMIT })
      )
      return (await ((await res.json()) as Promise<List<IContract>>)).items
    },

    add: async function (contract: IContract): Promise<IContract> {
      const res = await API.post(API.buildURL(URL_CONTRACTS), contract)
      return (await res.json()) as Promise<IContract>
    },

    update: async function (contract: IContract): Promise<IContract> {
      const res = await API.put(API.buildURL(URL_CONTRACTS), contract)
      return (await res.json()) as Promise<IContract>
    },

    cancel: async function (contract: IContract): Promise<IContract> {
      const res = await API.put(API.buildURL(URL_CONTRACTS + '/cancel'), contract)
      return (await res.json()) as Promise<IContract>
    },

    list: async function (params: ObjectParams): Promise<List<IContract>> {
      const res = await API.get(API.buildURL(URL_CONTRACTS, { ...params }))
      return (await res.json()) as Promise<List<IContract>>
    },

    stories: async function (id: number, params: ObjectParams = {}): Promise<List<IContract>> {
      const res = await API.get(API.buildURL(URL_CONTRACTS_VER + '/' + id, { ...params }))
      return (await res.json()) as Promise<List<IContract>>
    },
  },

  address: {
    getByID: async function (id: number): Promise<IAddress> {
      const res = await API.get(API.buildURL(URL_ADDRESSES + '/' + id))
      return (await res.json()) as Promise<IAddress>
    },

    getByIDs: async function (IDs: number[]): Promise<IAddress[]> {
      if (!IDs || !IDs.length) return []
      const res = await API.get(
        API.buildURL(URL_ADDRESSES, { ...paramsByIds(IDs), limit: MAX_LIMIT })
      )
      return (await ((await res.json()) as Promise<List<IAddress>>)).items
    },

    add: async function (address: IAddress): Promise<IAddress> {
      const res = await API.post(API.buildURL(URL_ADDRESSES), address)
      return (await res.json()) as Promise<IAddress>
    },

    update: async function (address: IAddress): Promise<IAddress> {
      const res = await API.put(API.buildURL(URL_ADDRESSES), address)
      return (await res.json()) as Promise<IAddress>
    },

    delete: async function (id: number): Promise<void> {
      const res = await API.delete(API.buildURL(URL_ADDRESSES + '/' + id))
      if (!res.ok) {
        throw new Error(await res.json())
      }
      await GeoStore.loadAllAddressesParts()
    },

    list: async function (params: ObjectParams): Promise<List<IAddress>> {
      const res = await API.get(API.buildURL(URL_ADDRESSES, { ...params }))
      return (await res.json()) as Promise<List<IAddress>>
    },

    count: async function (params: ObjectParams): Promise<number> {
      const res = await API.get(API.buildURL(URL_ADDRESSES + COUNT, { ...params }))
      return (await res.json()) as Promise<number>
    },

    stories: async function (id: number, params: ObjectParams = {}): Promise<List<IAddress>> {
      const res = await API.get(API.buildURL(URL_ADDRESSES_VER + '/' + id, { ...params }))
      return (await res.json()) as Promise<List<IAddress>>
    },
  },

  document: {
    getByID: async function (id: number): Promise<IDocument> {
      const res = await API.get(API.buildURL(URL_DOCUMENTS + '/' + id))
      return (await res.json()) as Promise<IDocument>
    },

    add: async function (document: IDocument): Promise<IDocument> {
      const res = await API.post(API.buildURL(URL_DOCUMENTS), document)
      return (await res.json()) as Promise<IDocument>
    },

    update: async function (document: IDocument): Promise<IDocument> {
      const res = await API.put(API.buildURL(URL_DOCUMENTS), document)
      return (await res.json()) as Promise<IDocument>
    },

    delete: async function (id: number): Promise<void> {
      const res = await API.delete(API.buildURL(URL_DOCUMENTS + '/' + id))
      if (!res.ok) {
        throw new Error(await res.json())
      }
    },

    list: async function (params: ObjectParams): Promise<List<IDocument>> {
      const res = await API.get(API.buildURL(URL_DOCUMENTS, { ...params }))
      return (await res.json()) as Promise<List<IDocument>>
    },

    addFile: async function (
      documentID: number,
      personID: number,
      files: FileInfo[]
    ): Promise<void> {
      const res = await API.post(API.buildURL(URL_DOCUMENT_FILE), { documentID, personID, files })
      if (!res.ok) {
        throw new Error(await res.json())
      }
    },

    fileContent: async function (
      documentID: number,
      personID: number,
      fileName: string
    ): Promise<Blob> {
      const res = await API.get(API.buildURL(URL_DOCUMENT_FILE, { documentID, personID, fileName }))
      return res.blob()
    },

    deleteFile: async function (
      documentID: number,
      personID: number,
      fileName: string
    ): Promise<void> {
      const res = await API.delete(
        API.buildURL(URL_DOCUMENT_FILE, { documentID, personID, fileName })
      )
      if (!res.ok) {
        throw new Error(await res.json())
      }
    },
  },

  language: {
    getByID: async function (id: number): Promise<ILanguage> {
      const res = await API.get(API.buildURL(URL_PERSONS_LANGUAGES + '/' + id))
      return (await res.json()) as Promise<ILanguage>
    },

    add: async function (language: ILanguage): Promise<ILanguage> {
      const res = await API.post(API.buildURL(URL_PERSONS_LANGUAGES), language)
      return (await res.json()) as Promise<ILanguage>
    },

    update: async function (language: ILanguage): Promise<ILanguage> {
      const res = await API.put(API.buildURL(URL_PERSONS_LANGUAGES), language)
      return (await res.json()) as Promise<ILanguage>
    },

    delete: async function (id: number): Promise<void> {
      const res = await API.delete(API.buildURL(URL_PERSONS_LANGUAGES + '/' + id))
      if (!res.ok) {
        throw new Error(await res.json())
      }
    },

    list: async function (params: ObjectParams): Promise<List<ILanguage>> {
      const res = await API.get(API.buildURL(URL_PERSONS_LANGUAGES, { ...params }))
      return (await res.json()) as Promise<List<ILanguage>>
    },
  },

  workflow: {
    getByID: async function (id: number): Promise<IWorkflow> {
      const res = await API.get(API.buildURL(URL_PERSONS_WORKFLOWS + '/' + id))
      return (await res.json()) as Promise<IWorkflow>
    },

    add: async function (language: IWorkflow): Promise<IWorkflow> {
      const res = await API.post(API.buildURL(URL_PERSONS_WORKFLOWS), language)
      return (await res.json()) as Promise<IWorkflow>
    },

    update: async function (language: IWorkflow): Promise<IWorkflow> {
      const res = await API.put(API.buildURL(URL_PERSONS_WORKFLOWS), language)
      return (await res.json()) as Promise<IWorkflow>
    },

    delete: async function (id: number): Promise<void> {
      const res = await API.delete(API.buildURL(URL_PERSONS_WORKFLOWS + '/' + id))
      if (!res.ok) {
        throw new Error(await res.json())
      }
    },

    list: async function (params: ObjectParams): Promise<List<IWorkflow>> {
      const res = await API.get(API.buildURL(URL_PERSONS_WORKFLOWS, { ...params }))
      return (await res.json()) as Promise<List<IWorkflow>>
    },

    holidays: async function (params: ObjectParams): Promise<IHoliday[]> {
      const res = await API.get(API.buildURL(URL_HOLIDAYS, { ...params }))
      return (await res.json()) as Promise<IHoliday[]>
    },

    holidayReports: async function (r: HolidayReportResponse): Promise<IHolidayReport[]> {
      const res = await API.get(
        API.buildURL(URL_HOLIDAY_REPORTS, {
          reportYear: r.reportYear,
          ...paramsByIds(r.personIDs, 'personIDs'),
          ...paramsByIds(r.types, 'types'),
        })
      )

      return (await res.json()) as Promise<IHolidayReport[]>
    },

    absentEmployees: async function (date: Date): Promise<IWorkflow[]> {
      const dateString = date.toISOString().substring(0, 10)
      const res = await API.get(
        API.buildURL(URL_PERSONS_WORKFLOWS + `/absent-employees/${dateString}`)
      )

      return (await res.json()) as Promise<IWorkflow[]>
    },
  },

  whence: {
    getByID: async function (id: number): Promise<IPersonWhence> {
      const res = await API.get(API.buildURL(URL_PERSON_WHENCES + '/' + id))
      return (await res.json()) as Promise<IPersonWhence>
    },

    add: async function (whence: IPersonWhence): Promise<IPersonWhence> {
      const res = await API.post(API.buildURL(URL_PERSON_WHENCES), whence)
      return (await res.json()) as Promise<IPersonWhence>
    },

    update: async function (whence: IPersonWhence): Promise<IPersonWhence> {
      const res = await API.put(API.buildURL(URL_PERSON_WHENCES), whence)
      return (await res.json()) as Promise<IPersonWhence>
    },

    delete: async function (id: number): Promise<void> {
      const res = await API.delete(API.buildURL(URL_PERSON_WHENCES + '/' + id))
      if (!res.ok) {
        throw new Error(await res.json())
      }
    },

    list: async function (params: ObjectParams): Promise<List<IPersonWhence>> {
      const res = await API.get(API.buildURL(URL_PERSON_WHENCES, { ...params }))
      return (await res.json()) as Promise<List<IPersonWhence>>
    },
  },

  billingLink: {
    getByID: async function (id: number): Promise<IBillingLink> {
      const res = await API.get(API.buildURL(URL_BILLING_LINK + '/' + id))
      return (await res.json()) as Promise<IBillingLink>
    },

    add: async function (link: IBillingLink): Promise<IBillingLink> {
      const res = await API.post(API.buildURL(URL_BILLING_LINK), link)
      return (await res.json()) as Promise<IBillingLink>
    },

    update: async function (link: IBillingLink): Promise<IBillingLink> {
      const res = await API.put(API.buildURL(URL_BILLING_LINK), link)
      return (await res.json()) as Promise<IBillingLink>
    },

    delete: async function (id: number): Promise<void> {
      const res = await API.delete(API.buildURL(URL_BILLING_LINK + '/' + id))
      if (!res.ok) {
        throw new Error(await res.json())
      }
    },

    list: async function (params: ObjectParams): Promise<List<IBillingLink>> {
      const res = await API.get(API.buildURL(URL_BILLING_LINK, { ...params }))
      return (await res.json()) as Promise<List<IBillingLink>>
    },
  },
}
