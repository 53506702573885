import { PermissionGroup } from '@/utils/permissions'
import cablemovilServer from '@/permissions/cdr_server'

export const CAN_ACCESS_CDR = 'cdr-access_module'

const cdr: PermissionGroup = {
  name: 'Cdr Module',
  permissions: [
    {
      value: CAN_ACCESS_CDR,
      description: CAN_ACCESS_CDR,
    },
    ...cablemovilServer,
  ],
}

export default cdr
