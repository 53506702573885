import { MutationTree } from 'vuex'
import { Toast } from '@/models/Toast'
import { User } from '@/models/user/user'
import { RootState } from '@/store/index'
import { ISettings, Settings } from '@/models/Settings'
import { PersonSimple } from '@/models/persons/PersonSimple'

export const M_SET_USER = 'M_SET_USER'
export const M_SET_PERSON = 'M_SET_PERSON'
export const M_CLEAN_TOASTS = 'M_CLEAN_TOASTS'
export const M_ADD_TOAST = 'M_ADD_TOAST'
export const M_REMOVE_TOAST = 'M_REMOVE_TOAST'
export const M_INIT_SETTINGS = 'M_INIT_SETTINGS'
export const M_SET_LOADING = 'M_SET_LOADING'

const mutations: MutationTree<RootState> = {
  [M_SET_USER]: (state, user: User | null) => {
    state.user = new User(user)
  },

  [M_SET_PERSON]: (state, person: PersonSimple | null) => {
    state.person = new PersonSimple(person)
  },

  [M_CLEAN_TOASTS]: (state) => {
    state.toasts = []
  },

  [M_ADD_TOAST]: (state, toast: Toast) => {
    state.toasts.push(toast)
  },

  [M_REMOVE_TOAST]: (state, toast: Toast) => {
    const index = state.toasts.indexOf(toast)
    if (index != -1) {
      state.toasts.splice(index, 1)
    }
  },

  [M_INIT_SETTINGS]: (state, settings: ISettings) => {
    state.settings = new Settings(settings)
  },

  [M_SET_LOADING]: (state, isLoading: boolean) => {
    state.isLoading = isLoading
  },
}

export default mutations
