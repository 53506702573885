
import { Component, Prop, Vue } from 'vue-property-decorator'
import { NavItem, NavSection } from '@/components/app/nav/types'
import { MainStore } from '@/store/MainStore'
import NavPart from '@/components/app/nav/NavPart.vue'
import { EVENT_INPUT } from '@/utils/event-constants'
import {
  ROUTE_CABLEMOVIL_ADD_CHARGES,
  ROUTE_CABLEMOVIL,
  ROUTE_CABLEMOVIL_FILES,
  ROUTE_RECONCILIATION_CABLEMOVIL_FILES,
} from '@/router/cdr'
import {
  CAN_ADD_CABLEMOVIL_CHARGES,
  CAN_VIEW_CABLEMOVIL,
  CAN_VIEW_CABLEMOVIL_FILE,
} from '@/permissions/cdr_server'
import { CAN_ACCESS_CDR } from '@/permissions/cdr'

@Component({
  components: { NavPart },
})
export default class NavPartCablemovil extends Vue {
  @Prop({ type: Boolean }) isOpen!: boolean

  get isShow(): boolean {
    return MainStore.isUserCan(CAN_ACCESS_CDR)
  }

  section = new NavSection('Cablemovil', 'fas fa-calculator', ROUTE_CABLEMOVIL, [
    new NavItem('CDR', ROUTE_CABLEMOVIL, 'fas fa-phone-volume').withPerms(CAN_VIEW_CABLEMOVIL),
    new NavItem('CDR Files', ROUTE_CABLEMOVIL_FILES, 'fas fa-file-import').withPerms(
      CAN_VIEW_CABLEMOVIL_FILE
    ),
    new NavItem(
      'Add charges',
      ROUTE_CABLEMOVIL_ADD_CHARGES,
      'fas fa-file-invoice-dollar'
    ).withPerms(CAN_ADD_CABLEMOVIL_CHARGES),
    new NavItem('Reconciliation', ROUTE_RECONCILIATION_CABLEMOVIL_FILES, 'fas fa-retweet'),
  ])

  created(): void {
    this.$emit(EVENT_INPUT, this.section.items)
  }
}
