
import { Component, Prop, Vue } from 'vue-property-decorator'
import { NavItem, NavSection } from '@/components/app/nav/types'
import { MainStore } from '@/store/MainStore'
import NavPart from '@/components/app/nav/NavPart.vue'
import { EVENT_INPUT } from '@/utils/event-constants'
import {
  ROUTE_SEWAN_ADD_CHARGES,
  ROUTE_SEWAN,
  ROUTE_SEWAN_FILES,
  ROUTE_RECONCILIATION_SEWAN_FILES,
} from '@/router/cdr'
import {
  CAN_ADD_SEWAN_CHARGES,
  CAN_VIEW_SEWAN,
  CAN_VIEW_SEWAN_FILE,
} from '@/permissions/cdr_server'
import { CAN_ACCESS_CDR } from '@/permissions/cdr'

@Component({
  components: { NavPart },
})
export default class NavPartSewan extends Vue {
  @Prop({ type: Boolean }) isOpen!: boolean

  get isShow(): boolean {
    return MainStore.isUserCan(CAN_ACCESS_CDR)
  }

  section = new NavSection('Sewan', 'fas fa-calculator', ROUTE_SEWAN, [
    new NavItem('CDR', ROUTE_SEWAN, 'fas fa-phone-volume').withPerms(CAN_VIEW_SEWAN),
    new NavItem('CDR Files', ROUTE_SEWAN_FILES, 'fas fa-file-import').withPerms(
      CAN_VIEW_SEWAN_FILE
    ),
    new NavItem('Add charges', ROUTE_SEWAN_ADD_CHARGES, 'fas fa-file-invoice-dollar').withPerms(
      CAN_ADD_SEWAN_CHARGES
    ),
    new NavItem('Reconciliation', ROUTE_RECONCILIATION_SEWAN_FILES, 'fas fa-retweet'),
  ])

  created(): void {
    this.$emit(EVENT_INPUT, this.section.items)
  }
}
