import { MainStore } from '@/store/MainStore'
import { List } from '@/models/List'
import { ObjectParams, Searcher } from '@/models/types'
import { Nas } from '@/models/internet/Nas'
import { internetApi } from '@/api/internet-api'
import { MAX_LIMIT, paramsByIds } from '@/api/api'

export class NasGet {
  static async list(params: ObjectParams): Promise<List<Nas>> {
    const list: List<Nas> = { count: 0, items: [] }
    try {
      const l = await internetApi.nas.list(params)
      list.count = l.count
      list.items = l.items.map((v) => new Nas(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return list
  }

  static async byID(id: number): Promise<Nas> {
    let item = new Nas()
    try {
      item = new Nas(await internetApi.nas.getByID(id))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return item
  }

  static async mapByIDs(ids: number[]): Promise<Map<number, Nas>> {
    const res = await NasGet.byIDs(ids)

    const pMap = new Map<number, Nas>()
    res.forEach((v) => pMap.set(v.id, new Nas(v)))

    return pMap
  }

  static async byIDs(ids: number[]): Promise<Nas[]> {
    let items: Nas[] = []
    try {
      items = (await NasGet.list({ ...paramsByIds(ids), limit: MAX_LIMIT })).items
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return items
  }

  static async allToSearcher(): Promise<Searcher> {
    const items = await NasGet.all()
    const entries: ObjectParams = {}
    items.forEach((v) => {
      entries[v.name] = v.name
    })

    return new Searcher(entries)
  }

  static async all(): Promise<Nas[]> {
    let items: Nas[] = []
    try {
      const res = (await NasGet.list({ limit: MAX_LIMIT })).items
      items = res.map((v) => new Nas(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return items
  }
}
