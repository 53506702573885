import { subtractTimezone } from '@/utils/index'

export class Converter {
  static uniqueNonZeroNums(nums: number[]): number[] {
    return [...new Set(nums.filter((v) => v > 0))]
  }

  static numberOrNull(value: unknown): number | null {
    return isStringOrNumber(value) ? Number(value) : null
  }

  static stringOrNull(value: unknown): string | null {
    return isStringOrNumber(value) ? String(value) : null
  }

  static dateOrNull(value: unknown): Date | null {
    if (isStringOrNumber(value) && value) {
      return date(value)
    }

    return value instanceof Date ? new Date(value) : null
  }

  static dateWithTimezoneOrNull(value: unknown): Date | null {
    if (isStringOrNumber(value) && value) {
      const d = date(value)
      return d ? subtractTimezone(d) : null
    }

    return value instanceof Date ? new Date(value) : null
  }

  static boolean(value: unknown): boolean {
    if (!value) {
      return false
    }
    if (typeof value == 'string') {
      switch (value.toLowerCase()) {
        case '0':
        case 'f':
        case 'false':
        case 'off':
        case 'no':
          return false
      }
    }
    return true
  }
}

export function toDateTimeStr(value: unknown): string {
  const d = value instanceof Date ? new Date(value) : date(value)

  return d ? d.toISOString().substring(0, 16) : ''
}

function date(value: unknown): Date | null {
  let d: Date | null = null

  if (typeof value == 'number') {
    d = new Date(value)
  } else if (typeof value == 'string') {
    let res = value.match(/^(\d{2})[.\-/_](\d{2})[.\-/_](\d{4})$/)
    if (res) {
      d = res ? new Date(`${res[3]}-${res[2]}-${res[1]}T00:00:00Z`) : new Date(value)
    } else {
      res = value.match(/^(\d{4})[.\-/_](\d{2})[.\-/_](\d{2})$/)
      d = res ? new Date(`${res[1]}-${res[2]}-${res[3]}T00:00:00Z`) : new Date(value)
    }
  }

  return !d || d.toString() == 'Invalid Date' ? null : d
}

export function toNumber(value: unknown): number {
  const res = isStringOrNumber(value) ? Number(value) : 0

  return isNaN(res) ? 0 : res
}

function isStringOrNumber(value: unknown): boolean {
  return typeof value == 'number' ? !isNaN(value) : typeof value == 'string'
}

export function formatTime(seconds: number, hideDays = false): string {
  let days = 0

  if (!hideDays) {
    days = Math.floor(seconds / (24 * 3600))
    seconds %= 24 * 3600
  }

  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = seconds % 60

  const formattedHours = String(hours).padStart(2, '0')
  const formattedMinutes = String(minutes).padStart(2, '0')
  const formattedSeconds = String(remainingSeconds).padStart(2, '0')

  const timeString = []
  if (days > 0) {
    timeString.push(`${days} d.`)
  }
  if (hours > 0) {
    timeString.push(`${formattedHours} h.`)
  }
  if (minutes > 0) {
    timeString.push(`${formattedMinutes} min.`)
  }
  if (remainingSeconds > 0) {
    timeString.push(`${formattedSeconds} sec.`)
  }

  return timeString.join(', ')
}

export function formatBytes(bytes: number): string {
  if (bytes >= 1099511627776) {
    return (bytes / 1099511627776).toFixed(2) + ' TB'
  } else if (bytes >= 1073741824) {
    return (bytes / 1073741824).toFixed(2) + ' GB'
  } else if (bytes >= 1048576) {
    return (bytes / 1048576).toFixed(2) + ' MB'
  } else if (bytes >= 1024) {
    return (bytes / 1024).toFixed(2) + ' KB'
  } else {
    return bytes + ' bytes'
  }
}
