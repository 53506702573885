// This file is generated automatically, do not make manual changes to it.
// generating script: shell/generate_permissions.go

export const CAN_EDIT_PRICES = 'tariffs-CanEditPrices'
export const CAN_EDIT_TARIFFS = 'tariffs-CanEditTariffs'
export const CAN_VIEW_PRICES = 'tariffs-CanViewPrices'
export const CAN_VIEW_TARIFFS = 'tariffs-CanViewTariffs'

const tariffsServer = [
  {
    value: CAN_EDIT_PRICES,
    description: CAN_EDIT_PRICES,
  },
  {
    value: CAN_EDIT_TARIFFS,
    description: CAN_EDIT_TARIFFS,
  },
  {
    value: CAN_VIEW_PRICES,
    description: CAN_VIEW_PRICES,
  },
  {
    value: CAN_VIEW_TARIFFS,
    description: CAN_VIEW_TARIFFS,
  },
]

export default tariffsServer
