import { CommonModel, IModel } from '@/models/Model'
import { MainStore } from '@/store/MainStore'
import { personsApi } from '@/api/persons-api'

export interface ILanguage extends IModel {
  id: number
  personID: number
  code: string
  isPreferred: boolean
}

export class Language extends CommonModel implements ILanguage {
  id = 0
  personID = 0
  code = ''
  isPreferred = false

  constructor(src: ILanguage | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: ILanguage) {
    Object.assign(this, src)

    this.initModel(src)
  }

  async save(): Promise<boolean> {
    try {
      return this.id ? await this.update() : await this.add()
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async add(): Promise<boolean> {
    try {
      this.init(await personsApi.language.add(this.toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async update(): Promise<boolean> {
    try {
      this.init(await personsApi.language.update(this.toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async delete(): Promise<boolean> {
    if (!confirm(`Are you sure you want to delete language ${this.code}?`)) {
      return false
    }

    try {
      await personsApi.language.delete(this.id)
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  toInterface(): ILanguage {
    return {
      id: this.id,
      personID: this.personID,
      code: this.code,
      isPreferred: this.isPreferred,
    }
  }
}
