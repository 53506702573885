// This file is generated automatically, do not make manual changes to it.
// generating script: shell/generate_permissions.go

export const CAN_EVENT_LOG_VIEW = 'search-CanEventLogView'
export const CAN_USERS_VIEW = 'search-CanUsersView'

const searchServer = [
  {
    value: CAN_EVENT_LOG_VIEW,
    description: CAN_EVENT_LOG_VIEW,
  },
  {
    value: CAN_USERS_VIEW,
    description: CAN_USERS_VIEW,
  },
]

export default searchServer
