import { MutationTree } from 'vuex'
import { GeoState } from '@/store/modules/geo/state'

export const M_PERSON_ADDRESSES_INVALID_COUNT = 'M_PERSON_ADDRESSES_INVALID_COUNT'
export const M_GEO_SET_COUNTRY_INVALID_COUNT = 'M_GEO_SET_COUNTRY_INVALID_COUNT'
export const M_GEO_SET_PROVINCES_INVALID_COUNT = 'M_GEO_SET_PROVINCES_INVALID_COUNT'
export const M_GEO_SET_MUNICIPALITIES_INVALID_COUNT = 'M_GEO_SET_MUNICIPALITIES_INVALID_COUNT'
export const M_GEO_SET_COMMUNITIES_INVALID_COUNT = 'M_GEO_SET_COMMUNITIES_INVALID_COUNT'
export const M_GEO_SET_DISTRICTS_INVALID_COUNT = 'M_GEO_SET_DISTRICTS_INVALID_COUNT'
export const M_GEO_SET_STREETS_INVALID_COUNT = 'M_GEO_SET_STREETS_INVALID_COUNT'
export const M_GEO_SET_URBANIZATIONS_INVALID_COUNT = 'M_GEO_SET_URBANIZATIONS_INVALID_COUNT'

const mutations: MutationTree<GeoState> = {
  [M_PERSON_ADDRESSES_INVALID_COUNT]: (state, count: number) => {
    state.invalidCounters.personAddresses = count
  },
  [M_GEO_SET_COUNTRY_INVALID_COUNT]: (state, count: number) => {
    state.invalidCounters.countries = count
  },

  [M_GEO_SET_PROVINCES_INVALID_COUNT]: (state, count: number) => {
    state.invalidCounters.provinces = count
  },

  [M_GEO_SET_MUNICIPALITIES_INVALID_COUNT]: (state, count: number) => {
    state.invalidCounters.municipalities = count
  },

  [M_GEO_SET_COMMUNITIES_INVALID_COUNT]: (state, count: number) => {
    state.invalidCounters.communities = count
  },

  [M_GEO_SET_DISTRICTS_INVALID_COUNT]: (state, count: number) => {
    state.invalidCounters.districts = count
  },

  [M_GEO_SET_STREETS_INVALID_COUNT]: (state, count: number) => {
    state.invalidCounters.streets = count
  },

  [M_GEO_SET_URBANIZATIONS_INVALID_COUNT]: (state, count: number) => {
    state.invalidCounters.urbanizations = count
  },
}

export default mutations
