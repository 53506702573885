import API, { MAX_LIMIT, paramsByIds } from '@/api/api'
import { IServiceSettings } from '@/models/service/ServiceSettings'
import { IService } from '@/models/service/Service'
import { List } from '@/models/List'
import { ObjectParams } from '@/models/types'
import { IServiceCred } from '@/models/service/ServiceCred'

const URL_SERVICES_MODULE = '/core/services'
const URL_SERVICES = URL_SERVICES_MODULE
const URL_SERVICES_SETTINGS = URL_SERVICES + '/settings'
const URL_SERVICES_CREDS = URL_SERVICES + '/creds'

export const servicesApi = {
  serviceSettings: {
    list: async function (params: ObjectParams): Promise<List<IServiceSettings>> {
      const res = await API.get(API.buildURL(URL_SERVICES_SETTINGS, { ...params }))
      return (await res.json()) as Promise<List<IServiceSettings>>
    },

    getByID: async function (id: number): Promise<IServiceSettings> {
      const res = await API.get(API.buildURL(URL_SERVICES_SETTINGS + '/' + id))
      return (await res.json()) as Promise<IServiceSettings>
    },

    getByIDs: async function (IDs: number[]): Promise<IServiceSettings[]> {
      if (!IDs || !IDs.length) return []
      const res = await API.get(
        API.buildURL(URL_SERVICES_SETTINGS, { ...paramsByIds(IDs), limit: MAX_LIMIT })
      )
      return (await ((await res.json()) as Promise<List<IServiceSettings>>)).items
    },

    add: async function (service: IServiceSettings): Promise<IServiceSettings> {
      const res = await API.post(API.buildURL(URL_SERVICES_SETTINGS), service)
      return (await res.json()) as Promise<IServiceSettings>
    },

    update: async function (service: IServiceSettings): Promise<IServiceSettings> {
      const res = await API.put(API.buildURL(URL_SERVICES_SETTINGS), service)
      return (await res.json()) as Promise<IServiceSettings>
    },
  },

  serviceCred: {
    list: async function (params: ObjectParams): Promise<List<IServiceCred>> {
      const res = await API.get(API.buildURL(URL_SERVICES_CREDS, { ...params }))
      return (await res.json()) as Promise<List<IServiceCred>>
    },

    getByID: async function (id: number): Promise<IServiceCred> {
      const res = await API.get(API.buildURL(URL_SERVICES_CREDS + '/' + id))
      return (await res.json()) as Promise<IServiceCred>
    },

    getByIDs: async function (IDs: number[]): Promise<IServiceCred[]> {
      if (!IDs || !IDs.length) return []
      const res = await API.get(
        API.buildURL(URL_SERVICES_CREDS, { ...paramsByIds(IDs), limit: MAX_LIMIT })
      )
      return (await ((await res.json()) as Promise<List<IServiceCred>>)).items
    },

    add: async function (item: IServiceCred): Promise<IServiceCred> {
      const res = await API.post(API.buildURL(URL_SERVICES_CREDS), item)
      return (await res.json()) as Promise<IServiceCred>
    },

    update: async function (item: IServiceCred): Promise<IServiceCred> {
      const res = await API.put(API.buildURL(URL_SERVICES_CREDS), item)
      return (await res.json()) as Promise<IServiceCred>
    },
  },

  service: {
    list: async function (params: ObjectParams): Promise<List<IService>> {
      const res = await API.get(API.buildURL(URL_SERVICES, { ...params }))
      return (await res.json()) as Promise<List<IService>>
    },

    getByID: async function (id: number): Promise<IService> {
      const res = await API.get(API.buildURL(URL_SERVICES + '/' + id))
      return (await res.json()) as Promise<IService>
    },

    getByIDs: async function (IDs: number[]): Promise<IService[]> {
      if (!IDs || !IDs.length) return []
      const res = await API.get(
        API.buildURL(URL_SERVICES, { ...paramsByIds(IDs), limit: MAX_LIMIT })
      )
      return (await ((await res.json()) as Promise<List<IService>>)).items
    },

    add: async function (personService: IService): Promise<IService> {
      const res = await API.post(API.buildURL(URL_SERVICES), personService)
      return (await res.json()) as Promise<IService>
    },

    update: async function (personService: IService): Promise<IService> {
      const res = await API.put(API.buildURL(URL_SERVICES), personService)
      return (await res.json()) as Promise<IService>
    },

    delete: async function (id: number): Promise<void> {
      const res = await API.delete(API.buildURL(URL_SERVICES + '/' + id))
      if (!res.ok) {
        throw new Error(await res.json())
      }
    },
  },
}
