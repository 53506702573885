import { CommonModel, IModel } from '@/models/Model'
import { DocumentType, documentTypes } from '@/models/persons/constants'
import { MainStore } from '@/store/MainStore'
import { personsApi } from '@/api/persons-api'
import { FileInfo } from '@/models/FileInfo'
import FileResolver from '@/components/common/file-upload/file-resolver'

export interface IDocument extends IModel {
  id: number
  type: DocumentType
  number: string
  isMain: boolean
  personID: number
  addressID?: number
  files: FileInfo[]
}

export class Document extends CommonModel implements IDocument {
  id = 0
  type: DocumentType = 0
  number = ''
  isMain = false
  personID = 0
  addressID?: number
  files: FileInfo[] = []

  isFilesLoad = false

  get typeHtml(): string {
    return documentTypes[this.type]
  }

  get titleHtml(): string {
    return `<i class="font-weight-bold">${documentTypes[this.type]}: </i> ${this.number}`
  }

  constructor(src: IDocument | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: IDocument) {
    Object.assign(this, src)

    this.initModel(src)
  }

  async loadFiles(): Promise<Document> {
    if (!this.files.length) return this

    const files: FileInfo[] = []
    try {
      for (const v of this.files) {
        const file = await personsApi.document.fileContent(this.id, this.personID, v.name)
        const fileInfo = Object.assign(new FileInfo(), v)
        const reader = new FileReader()

        reader.readAsDataURL(file)
        reader.addEventListener('load', () => {
          fileInfo.file = file
          fileInfo.body = reader.result
          fileInfo.src = FileResolver.src(reader.result, file.type)
          files.push(fileInfo)
        })
      }
      this.files = files
      this.isFilesLoad = true
    } catch (e) {
      await MainStore.addError(e as Error)
    }

    return this
  }

  async addFiles(files: FileInfo[]): Promise<boolean> {
    try {
      if (this.id) {
        await personsApi.document.addFile(this.id, this.personID, files)
      }

      files.forEach((f) => {
        for (const file of this.files) {
          if (file.name == f.name) {
            file.body = f.body
            file.file = f.file
            return
          }
        }
        this.files.push(f)
      })
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async deleteFile(name: string): Promise<boolean> {
    try {
      if (this.id) {
        if (!confirm(`Are you sure you want to delete the file: ${name}?`)) return false

        await personsApi.document.deleteFile(this.id, this.personID, name)
      }

      let index = -1
      for (let i = 0; i < this.files.length; i++) {
        if (this.files[i].name == name) {
          index = i
          break
        }
      }
      if (index != -1) {
        this.files.splice(index, 1)
      }
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async save(): Promise<boolean> {
    try {
      return this.id ? await this.update() : await this.add()
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async add(): Promise<boolean> {
    try {
      this.init(await personsApi.document.add(this.toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  //The method updates only document data, not files
  async update(): Promise<boolean> {
    try {
      const files = [...this.files]
      let d = new Document(this)
      d.files = []
      d = new Document(await personsApi.document.update(d.toInterface()))
      d.files = files
      this.init(d)
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async delete(): Promise<boolean> {
    if (!confirm(`Are you sure you want to delete document ${this.number}?`)) {
      return false
    }

    try {
      await personsApi.document.delete(this.id)
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  toInterface(): IDocument {
    return {
      id: this.id,
      type: this.type,
      number: this.number,
      isMain: this.isMain,
      personID: this.personID,
      addressID: this.addressID,
      files: this.files,
    }
  }
}
