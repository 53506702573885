import { RouteConfig } from 'vue-router/types/router'
import { ROUTER_PARAM_ID } from '@/utils/constants'
import { CAN_ACCESS_CORE } from '@/permissions/core'
import {
  CAN_ADD_BANK_FAIL_REASONS,
  CAN_EDIT_BANK_FAIL_REASONS,
  CAN_VIEW_BANK_FAIL_REASONS,
} from '@/permissions/core_server'

export const ROUTE_SEPA_PAY_COUNTER = 'ROUTE_SEPA_PAY_COUNTER'

export const ROUTE_PAYMENTS_BANKS = 'ROUTE_PAYMENTS_BANKS'
export const ROUTE_PAYMENTS_BANK_FORM = 'ROUTE_PAYMENTS_BANK_FORM'
export const ROUTE_PAYMENTS_BANK_VIEW = 'ROUTE_PAYMENTS_BANK_VIEW'

export const ROUTE_PAYMENTS_BANK_FAILS = 'ROUTE_PAYMENTS_BANK_FAILS'
export const ROUTE_PAYMENTS_BANK_FAIL_FORM = 'ROUTE_PAYMENTS_BANK_FAIL_FORM'
export const ROUTE_PAYMENTS_BANK_FAIL_VIEW = 'ROUTE_PAYMENTS_BANK_FAIL_VIEW'

export const ROUTE_PAYMENTS_COMPANIES = 'ROUTE_PAYMENTS_COMPANIES'
export const ROUTE_PAYMENTS_COMPANY_FORM = 'ROUTE_PAYMENTS_COMPANY_FORM'
export const ROUTE_PAYMENTS_COMPANY_VIEW = 'ROUTE_PAYMENTS_COMPANY_VIEW'

export const ROUTE_PAYMENTS = 'ROUTE_PAYMENTS'
export const ROUTE_PAYMENTS_FORM = 'ROUTE_PAYMENTS_FORM'
export const ROUTE_PAYMENTS_VIEW = 'ROUTE_PAYMENTS_VIEW'
export const ROUTE_PAYMENTS_DASHBOARDS = 'ROUTE_PAYMENTS_DASHBOARDS'

export const ROUTE_PAYMENTS_PAYMENT_INVOICES = 'ROUTE_PAYMENTS_PAYMENT_INVOICES'
export const ROUTE_PAYMENTS_PAYMENT_INVOICES_VIEW = 'ROUTE_PAYMENTS_PAYMENT_INVOICES_VIEW'
export const ROUTE_PAYMENTS_PAYMENT_INVOICES_EXPORT = 'ROUTE_PAYMENTS_PAYMENT_INVOICES_EXPORT'
export const ROUTE_PAYMENTS_PAYMENT_INVOICES_PRODUCTS = 'ROUTE_PAYMENTS_PAYMENT_INVOICES_PRODUCTS'

export const ROUTE_PAYMENT_INVOICES_EMAILS = 'ROUTE_PAYMENT_INVOICES_EMAILS'
export const ROUTE_PAYMENT_INVOICES_EMAILS_VIEW = 'ROUTE_PAYMENT_INVOICES_EMAILS_VIEW'

export const ROUTE_ACCOUNT_BLACK_LIST = 'ROUTE_ACCOUNT_BLACK_LIST'
export const ROUTE_ACCOUNT_BLACK_LIST_FORM = 'ROUTE_ACCOUNT_BLACK_LIST_FORM'

const paymentRoutes: RouteConfig[] = [
  {
    name: ROUTE_PAYMENTS_DASHBOARDS,
    path: `/payments/dashboards`,
    meta: { permission: CAN_ACCESS_CORE },
    component: () =>
      import(/* webpackChunkName: "payments" */ '../components/dashboards/PaymentDashboard.vue'),
  },

  {
    name: ROUTE_SEPA_PAY_COUNTER,
    path: `/sepa-pay-counter`,
    meta: { permission: CAN_ACCESS_CORE },
    component: () =>
      import(
        /* webpackChunkName: "payments" */ '../components/payments/sepa_pay_counter/SepaPayCounterList.vue'
      ),
  },

  {
    name: ROUTE_PAYMENTS_BANKS,
    path: `/payments/banks`,
    meta: { permission: CAN_ACCESS_CORE },
    component: () =>
      import(/* webpackChunkName: "payments" */ '../components/payments/bank/BankList.vue'),
  },
  {
    name: ROUTE_PAYMENTS_BANK_FORM,
    path: `/payments/banks/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: CAN_ACCESS_CORE },
    component: () =>
      import(/* webpackChunkName: "payments" */ '../components/payments/bank/BankEdit.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
  {
    name: ROUTE_PAYMENTS_BANK_VIEW,
    path: `/payments/banks/:${ROUTER_PARAM_ID}(\\d+)`,
    meta: { permission: CAN_ACCESS_CORE },
    component: () =>
      import(/* webpackChunkName: "payments" */ '../components/payments/bank/BankView.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },

  {
    name: ROUTE_PAYMENTS_BANK_FAILS,
    path: `/payments/bank-fails`,
    meta: { permission: CAN_VIEW_BANK_FAIL_REASONS },
    component: () =>
      import(
        /* webpackChunkName: "payments" */ '../components/payments/bank_fails/BankFailList.vue'
      ),
  },
  {
    name: ROUTE_PAYMENTS_BANK_FAIL_FORM,
    path: `/payments/bank-fails/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: [CAN_ADD_BANK_FAIL_REASONS, CAN_EDIT_BANK_FAIL_REASONS] },
    component: () =>
      import(
        /* webpackChunkName: "payments" */ '../components/payments/bank_fails/BankFailEdit.vue'
      ),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
  {
    name: ROUTE_PAYMENTS_BANK_FAIL_VIEW,
    path: `/payments/bank-fails/:${ROUTER_PARAM_ID}(\\d+)`,
    meta: { permission: CAN_VIEW_BANK_FAIL_REASONS },
    component: () =>
      import(
        /* webpackChunkName: "payments" */ '../components/payments/bank_fails/BankFailView.vue'
      ),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },

  {
    name: ROUTE_PAYMENTS_COMPANIES,
    path: `/payments/companies`,
    meta: { permission: CAN_ACCESS_CORE },
    component: () =>
      import(/* webpackChunkName: "payments" */ '../components/payments/company/CompanyList.vue'),
  },
  {
    name: ROUTE_PAYMENTS_COMPANY_FORM,
    path: `/payments/companies/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: CAN_ACCESS_CORE },
    component: () =>
      import(/* webpackChunkName: "payments" */ '../components/payments/company/CompanyEdit.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
  {
    name: ROUTE_PAYMENTS_COMPANY_VIEW,
    path: `/payments/companies/:${ROUTER_PARAM_ID}(\\d+)`,
    meta: { permission: CAN_ACCESS_CORE },
    component: () =>
      import(/* webpackChunkName: "payments" */ '../components/payments/company/CompanyView.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },

  {
    name: ROUTE_PAYMENTS,
    path: '/payments',
    meta: { permission: CAN_ACCESS_CORE },
    component: () =>
      import(/* webpackChunkName: "payments" */ '../components/payments/payment/PaymentList.vue'),
  },
  {
    name: ROUTE_PAYMENTS_VIEW,
    path: `/payments/:${ROUTER_PARAM_ID}(\\d+)`,
    meta: { permission: CAN_ACCESS_CORE },
    component: () =>
      import(/* webpackChunkName: "payments" */ '../components/payments/payment/PaymentView.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
  {
    name: ROUTE_PAYMENTS_FORM,
    path: `/payments/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: CAN_ACCESS_CORE },
    component: () =>
      import(/* webpackChunkName: "payments" */ '../components/payments/payment/PaymentEdit.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },

  {
    name: ROUTE_ACCOUNT_BLACK_LIST,
    path: '/account-black-list',
    meta: { permission: CAN_ACCESS_CORE },
    component: () =>
      import(
        /* webpackChunkName: "payments" */ '../components/payments/account_black_list/AccountBlackListList.vue'
      ),
  },
  {
    name: ROUTE_ACCOUNT_BLACK_LIST_FORM,
    path: `/account-black-list/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: CAN_ACCESS_CORE },
    component: () =>
      import(
        /* webpackChunkName: "payments" */ '../components/payments/account_black_list/AccountBlackListEdit.vue'
      ),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },

  {
    name: ROUTE_PAYMENTS_PAYMENT_INVOICES,
    path: '/payments/invoices',
    meta: { permission: CAN_ACCESS_CORE },
    component: () =>
      import(
        /* webpackChunkName: "payments" */ '../components/payments/payment_invoice/PaymentInvoicesList.vue'
      ),
  },
  {
    name: ROUTE_PAYMENTS_PAYMENT_INVOICES_VIEW,
    path: `/payments/invoices/:${ROUTER_PARAM_ID}(\\d+)`,
    meta: { permission: CAN_ACCESS_CORE },
    component: () =>
      import(
        /* webpackChunkName: "payments" */ '../components/payments/payment_invoice/PaymentInvoiceView.vue'
      ),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },

  {
    name: ROUTE_PAYMENTS_PAYMENT_INVOICES_EXPORT,
    path: `/payments/invoices/export`,
    meta: { permission: CAN_ACCESS_CORE },
    component: () =>
      import(
        /* webpackChunkName: "payments" */ '../components/payments/payment_invoice/InvoicesExportCsv.vue'
      ),
  },

  {
    name: ROUTE_PAYMENTS_PAYMENT_INVOICES_PRODUCTS,
    path: `/payments/invoices/products`,
    meta: { permission: CAN_ACCESS_CORE },
    component: () =>
      import(
        /* webpackChunkName: "payments" */ '../components/payments/payment_invoice/InvoicesProducts.vue'
      ),
  },

  {
    name: ROUTE_PAYMENT_INVOICES_EMAILS,
    path: '/payments/invoices/emails',
    meta: { permission: CAN_ACCESS_CORE },
    component: () =>
      import(
        /* webpackChunkName: "payments" */ '../components/payments/payment_invoice/SentEmailsInvoiceList.vue'
      ),
  },
  {
    name: ROUTE_PAYMENT_INVOICES_EMAILS_VIEW,
    path: `/payments/invoices/emails/:${ROUTER_PARAM_ID}(\\d+)`,
    meta: { permission: CAN_ACCESS_CORE },
    component: () =>
      import(
        /* webpackChunkName: "payments" */ '../components/payments/payment_invoice/SentEmailsInvoiceView.vue'
      ),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
]

export default paymentRoutes
