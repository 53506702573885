
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Toast } from '@/models/Toast'

@Component
export default class BToast extends Vue {
  @Prop({ type: Object, required: true }) readonly toast!: Toast

  readonly minDelay = 100
  readonly maxDelay = 30000

  isShow = false

  created(): void {
    setTimeout(() => {
      this.isShow = true
    }, 50)
  }

  close(): void {
    this.toast.hide()
  }
}
