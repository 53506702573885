import Vue from 'vue'
import './styles.styl'
import './styles.css'
import App from './App.vue'
import router from './router'
import store from './store'
import { MainStore } from '@/store/MainStore'

Vue.config.productionTip = false

MainStore.appInit().then(() =>
  new Vue({
    store,
    router,
    render: (h) => h(App),
  }).$mount('#app')
)
