// This file is generated automatically, do not make manual changes to it.
// generating script: shell/generate_permissions.go

export const CAN_DELETE_API_TOKEN = 'integrations-CanDeleteApiToken'
export const CAN_EDIT_API_TOKEN = 'integrations-CanEditApiToken'
export const CAN_VIEW_API_TOKEN = 'integrations-CanViewApiToken'

const integrationsServer = [
  {
    value: CAN_DELETE_API_TOKEN,
    description: CAN_DELETE_API_TOKEN,
  },
  {
    value: CAN_EDIT_API_TOKEN,
    description: CAN_EDIT_API_TOKEN,
  },
  {
    value: CAN_VIEW_API_TOKEN,
    description: CAN_VIEW_API_TOKEN,
  },
]

export default integrationsServer
