import { ActionTree, Commit, Dispatch } from 'vuex'
import { LC_KEY_SETTINGS, LC_KEY_USER } from '@/utils/local-storage-constants'
import API from '@/api/api'
import { User } from '@/models/user/user'
import { RootState } from '@/store/index'
import { Settings } from '@/models/Settings'
import {
  M_ADD_TOAST,
  M_CLEAN_TOASTS,
  M_INIT_SETTINGS,
  M_REMOVE_TOAST,
  M_SET_LOADING,
  M_SET_PERSON,
  M_SET_USER,
} from '@/store/mutations'
import { A_GEO_INIT } from '@/store/modules/geo/actions'
import { PersonSimpleGet } from '@/models/persons/PersonSimpleGet'
import { usersApi } from '@/api/users-api'
import { userByLocalStorage } from '@/store/utils'

export const A_USER_LOGIN = 'A_USER_LOGIN'
export const A_USER_LOGOUT = 'A_USER_LOGOUT'
export const A_APP_INIT = 'A_APP_INIT'
export const A_ADD_TOAST = 'A_ADD_TOAST'
export const A_REMOVE_TOAST = 'A_REMOVE_TOAST'
export const A_INIT_SETTINGS = 'A_INIT_SETTINGS'
export const A_SET_LOADING = 'A_SET_LOADING'

const actions: ActionTree<RootState, RootState> = {
  [A_USER_LOGIN]: async ({ commit, dispatch }, user: User) => {
    localStorage.setItem(LC_KEY_USER, JSON.stringify(user))
    setAPISession(user)
    sessionExpire(user, commit, dispatch)
    commit(M_CLEAN_TOASTS)
    commit(M_SET_USER, user)
    commit(M_SET_PERSON, await PersonSimpleGet.byID(user.personID))

    await dispatch(A_APP_INIT)
  },

  [A_USER_LOGOUT]: ({ commit }) => {
    localStorage.removeItem(LC_KEY_USER)
    setAPISession(null)
    commit(M_CLEAN_TOASTS)
    commit(M_SET_USER, null)
    commit(M_SET_PERSON, null)
  },

  [A_APP_INIT]: async ({ commit, dispatch }) => {
    const user = userByLocalStorage()
    commit(M_SET_USER, user)

    commit(
      M_INIT_SETTINGS,
      new Settings(JSON.parse(localStorage.getItem(LC_KEY_SETTINGS) || 'null'))
    )

    if (user.isLogged) {
      setAPISession(user)
      sessionExpire(user, commit, dispatch)
      await dispatch(A_GEO_INIT)
      commit(M_SET_PERSON, await PersonSimpleGet.byID(user.personID))
    }

    const intervalId = Number(
      setInterval(() => {
        const user = userByLocalStorage()
        if (!user.isLogged) {
          if (intervalId) {
            clearInterval(intervalId)
          }
          dispatch(A_USER_LOGOUT)
        }
      }, 1000)
    )
  },

  [A_ADD_TOAST]: ({ commit }, e) => {
    commit(M_ADD_TOAST, e)
  },

  [A_REMOVE_TOAST]: ({ commit }, e) => {
    commit(M_REMOVE_TOAST, e)
  },

  [A_INIT_SETTINGS]: ({ commit }, e: Settings) => {
    commit(M_INIT_SETTINGS, e)
    localStorage.setItem(LC_KEY_SETTINGS, JSON.stringify(e.toInterface()))
  },

  [A_SET_LOADING]: ({ commit }, e: boolean) => {
    commit(M_SET_LOADING, e)
  },
}

const setAPISession = (user: User | null) => {
  if (user && user.session) {
    user.session.expire = new Date(user.session.expire)
    API.setSession(user.session)
  } else {
    API.setSession(null)
  }
}

const sessionExpire = (user: User, commit: Commit, dispatch: Dispatch) => {
  if (user.session) {
    setTimeout(async () => {
      try {
        const newUser = new User(await usersApi.auth.reLogin())
        await dispatch(A_USER_LOGIN, newUser)
      } catch (e) {
        commit(M_SET_USER, null)
        localStorage.setItem(LC_KEY_USER, 'null')
      }
    }, user.session.expire.getTime() - new Date().getTime())
  }
}

export default actions
