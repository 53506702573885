import { ChartConfiguration } from 'chart.js'
import { chartPayments } from '@/components/dashboards/models/chartPayments'
import { chartPaymentsCounts } from '@/components/dashboards/models/chartPaymentsCounts'
import { chartBankFails } from '@/components/dashboards/models/chartBankFails'
import { chartBankFailsCounts } from '@/components/dashboards/models/chartBankFailsCounts'

export type paymentReport = {
  [key: number]: { [key in number]: monthlyReport }
}
export type bankFailsReport = {
  [key: number]: monthlyReport
}

interface monthlyReport {
  count: number
  amount: number
}

export class PaymentReports {
  payments: paymentReport = {}
  bankFails: bankFailsReport = {}

  paymentsChart: ChartConfiguration | null = null
  paymentsCountsChart: ChartConfiguration | null = null

  bankFailsChart: ChartConfiguration | null = null
  bankFailsCountsChart: ChartConfiguration | null = null

  constructor(src: PaymentReports) {
    this.paymentsChart = chartPayments(src.payments)
    this.paymentsCountsChart = chartPaymentsCounts(src.payments)
    this.bankFailsChart = chartBankFails(src.bankFails)
    this.bankFailsCountsChart = chartBankFailsCounts(src.bankFails)
  }
}
