import { PermissionGroup } from '@/utils/permissions'
import internetServer from '@/permissions/internet_server'

const prefix = 'internet'

export const CAN_ACCESS_INTERNET = prefix + '-access_module'

const internet: PermissionGroup = {
  name: 'Internet Module',
  permissions: [
    {
      value: CAN_ACCESS_INTERNET,
      description: CAN_ACCESS_INTERNET,
    },
    ...internetServer,
  ],
}

export default internet
