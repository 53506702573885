import { CommonModel, IModel } from '@/models/Model'
import { MainStore } from '@/store/MainStore'
import { internetApi } from '@/api/internet-api'
import { NasStatus, nasStatuses, NasType, nasTypes } from '@/models/internet/constants'
import router from '@/router'
import { ROUTER_PARAM_ID } from '@/utils/constants'
import { ROUTE_INTERNET_NAS_VIEW } from '@/router/internet'
import { IpPool } from '@/models/internet/IpPool'

export interface INas extends IModel {
  id: number
  name: string
  ip: string
  type: NasType
  status: NasStatus
  description: string
}

export class Nas extends CommonModel implements INas {
  id = 0
  name = ''
  ip = ''
  type = NasType.Unknown
  status = NasStatus.Unknown
  description = ''

  // after load
  ipPools: IpPool[] = []

  get nameWithId(): string {
    return `(${this.id}) ${this.name}`
  }

  get typeName(): string {
    return nasTypes[this.type]
  }

  get statusName(): string {
    return nasStatuses[this.status]
  }

  get usedIpPoolsIPs(): number {
    return this.ipPools.reduce((sum, ipPool) => sum + ipPool.activeSessions, 0)
  }

  get totalIpPoolsIPs(): number {
    return this.ipPools.reduce((sum, ipPool) => sum + ipPool.countIP, 0)
  }

  get percentageUsedIPs(): number {
    if (this.totalIpPoolsIPs <= 0) {
      return 0
    }

    return (this.usedIpPoolsIPs / this.totalIpPoolsIPs) * 100
  }

  viewLinkHtml(target = '_self'): string {
    const res = router.resolve({
      name: ROUTE_INTERNET_NAS_VIEW,
      params: {
        [ROUTER_PARAM_ID]: this.id.toString(),
      },
    })

    return this.id ? `<a href="${res.href}" target="${target}">${this.name}</a>` : ''
  }

  constructor(src: INas | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: INas) {
    Object.assign(this, src)

    this.initModel(src)
  }

  async save(): Promise<boolean> {
    try {
      return this.id ? await this.update() : await this.add()
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async add(): Promise<boolean> {
    try {
      this.init(await internetApi.nas.add(this.prepare().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async update(): Promise<boolean> {
    try {
      this.init(await internetApi.nas.update(this.prepare().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async delete(): Promise<boolean> {
    if (!confirm(`Are you sure you want to delete NAS: ${this.name}`)) {
      return false
    }

    try {
      await internetApi.nas.delete(this.id)
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  prepare(): Nas {
    return this
  }

  toInterface(): INas {
    return {
      id: this.id,
      name: this.name,
      ip: this.ip,
      type: this.type,
      status: this.status,
      description: this.description,
    }
  }
}
