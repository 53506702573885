import { MainStore } from '@/store/MainStore'
import { personsApi } from '@/api/persons-api'
import { MAX_LIMIT } from '@/api/api'
import { List } from '@/models/List'
import { ObjectParams } from '@/models/types'
import { Address } from '@/models/persons/Address'

export class AddressGet {
  static async byID(id: number): Promise<Address> {
    let item = new Address()
    try {
      item = new Address(await personsApi.address.getByID(id))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return item
  }

  static async byIDs(ids: number[]): Promise<Address[]> {
    let items: Address[] = []
    try {
      items = (await personsApi.address.getByIDs(ids)).map((v) => new Address(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return items
  }

  static async mapByIDs(ids: number[]): Promise<Map<number, Address>> {
    const res = await AddressGet.byIDs(ids)

    const pMap = new Map<number, Address>()
    res.forEach((v) => pMap.set(v.id, new Address(v)))

    return pMap
  }

  static async list(params: ObjectParams): Promise<List<Address>> {
    const list: List<Address> = { count: 0, items: [] }
    try {
      const l = await personsApi.address.list(params)
      list.count = l.count
      list.items = l.items.map((v) => new Address(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return list
  }

  static async personAddresses(personID: number): Promise<Address[]> {
    return (await AddressGet.list({ personID: personID, limit: MAX_LIMIT })).items.map(
      (v) => new Address(v)
    )
  }

  static async count(params: ObjectParams): Promise<number> {
    let count = 0
    try {
      count = await personsApi.address.count(params)
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return count
  }

  static async stories(id: number, params: ObjectParams = {}): Promise<List<Address>> {
    const list: List<Address> = { count: 0, items: [] }
    try {
      const l = await personsApi.address.stories(id, { limit: MAX_LIMIT, ...params })
      list.count = l.count
      list.items = l.items.map((v) => new Address(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return list
  }
}
