
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Attribute, Workflow } from '@/models/persons/Workflow'
import { ROUTE_WORKFLOWS_CALENDAR } from '@/router/persons'

@Component
export default class DashboardAbsent extends Vue {
  @Prop({ type: Array, required: true }) workflows!: Workflow[]

  get workflowAttributes(): Attribute[] {
    return this.workflows.map((v) => v.toAttribute())
  }

  toCalendar(): void {
    this.$router.push({
      name: ROUTE_WORKFLOWS_CALENDAR,
    })
  }
}
