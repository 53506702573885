import { EnumNames, ObjectParams, Searcher } from '@/models/types'

export enum FiberStatus {
  Active = 'active',
  Stopped = 'stopped',
}

export const fiberStatuses: EnumNames = {
  [FiberStatus.Active]: 'Active',
  [FiberStatus.Stopped]: 'Stopped',
}

export enum PersonType {
  Customer = 1,
  Installer,
  Employee,
}

export const personTypes: EnumNames = {
  [PersonType.Customer]: 'Customer',
  [PersonType.Installer]: 'Installer',
  [PersonType.Employee]: 'Employee',
}

export const personTypesToIssueStock = [PersonType.Installer, PersonType.Employee]

export function personTypeToSearcher(items: PersonType[]): Searcher {
  return new Searcher(
    items.reduce((acc: ObjectParams, v) => {
      acc[v] = personTypes[v]
      return acc
    }, {})
  )
}

export enum BillingLinkType {
  Internet = 1,
  IPTV,
  Phone,
}

export const billingLinkTypes: EnumNames = {
  [BillingLinkType.Internet]: 'Internet',
  [BillingLinkType.IPTV]: 'IPTV',
  [BillingLinkType.Phone]: 'Phone',
}

export enum DocumentType {
  DocumentNIF = 1,
  DocumentNIE,
  DocumentCIF,
  DocumentPassport,
  DocumentContract,
  DocumentInvoice,
  DocumentOther,
}

export const documentTypes: EnumNames = {
  [DocumentType.DocumentNIF]: 'NIF',
  [DocumentType.DocumentNIE]: 'NIE',
  [DocumentType.DocumentCIF]: 'CIF',
  [DocumentType.DocumentPassport]: 'Passport',
  [DocumentType.DocumentContract]: 'Contract',
  [DocumentType.DocumentInvoice]: 'Invoice',
  [DocumentType.DocumentOther]: 'Other',
}

export const editableDocumentTypes: DocumentType[] = [
  DocumentType.DocumentNIF,
  DocumentType.DocumentNIE,
  DocumentType.DocumentCIF,
  DocumentType.DocumentPassport,
  DocumentType.DocumentInvoice,
  DocumentType.DocumentOther,
]

export enum AddressType {
  Service = 1,
  Payment,
  ServiceAndPayment,
}

export const addressTypes: EnumNames = {
  [AddressType.Service]: 'Service',
  [AddressType.Payment]: 'Payment',
  [AddressType.ServiceAndPayment]: 'ServiceAndPayment',
}

export enum ContactType {
  Phone = 1,
  Email = 2,
}

export const contactTypes: EnumNames = {
  [ContactType.Phone]: 'Phone',
  [ContactType.Email]: 'Email',
}

export enum WorkflowType {
  Unknown = '',
  Holiday = 'holiday',
  SickDay = 'sick_day',
  DayOff = 'day_off',
  Vacation = 'vacation',
}

export const defWorkflowSettings: { [p: string]: { icon: string; style: string } } = {
  [WorkflowType.Holiday]: {
    icon: 'fas fa-holly-berry',
    style: 'background-color: #ed8936',
  },
  [WorkflowType.SickDay]: { icon: 'fas fa-virus', style: 'background-color: #f56565' },
  [WorkflowType.DayOff]: { icon: 'fas fa-power-off', style: 'background-color: #a0aec0' },
  [WorkflowType.Vacation]: { icon: 'fas fa-snowboarding', style: 'background-color: #667eea' },
}

export const workflowTypes: EnumNames = {
  [WorkflowType.Holiday]: 'Holiday',
  [WorkflowType.SickDay]: 'Sick Day',
  [WorkflowType.DayOff]: 'Day Off',
  [WorkflowType.Vacation]: 'Vacation',
}

export enum WorkflowStatus {
  Unknown = '',
  Active = 'active',
}

export const workflowStatuses: EnumNames = {
  [WorkflowStatus.Active]: 'Active',
}
