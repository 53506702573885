
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { badge, NavItem, NavSection } from '@/components/app/nav/types'
import { MainStore } from '@/store/MainStore'
import NavPart from '@/components/app/nav/NavPart.vue'
import { CAN_ACCESS_GEO } from '@/permissions/geo'
import {
  ROUTE_COMMUNITIES,
  ROUTE_COUNTRIES,
  ROUTE_DISTRICTS,
  ROUTE_GEO_ADDRESSES,
  ROUTE_MUNICIPALITIES,
  ROUTE_PROVINCES,
  ROUTE_STREETS,
  ROUTE_URBANIZATIONS,
} from '@/router/geo'
import { ObjectParams } from '@/models/types'
import { GeoStatus } from '@/models/geo/constants'
import { EVENT_INPUT } from '@/utils/event-constants'

@Component({
  components: { NavPart },
})
export default class NavPartGeo extends Vue {
  @Prop({ type: Boolean }) isOpen!: boolean

  itemAddresses = new NavItem('Addresses', ROUTE_GEO_ADDRESSES, 'fas fa-map-marker-alt')
  itemCountries = new NavItem('Countries', ROUTE_COUNTRIES, 'fas fa-globe-europe')
  itemProvinces = new NavItem('Provinces', ROUTE_PROVINCES, 'fas fa-sitemap')
  itemMunicipalities = new NavItem('Municipalities', ROUTE_MUNICIPALITIES, 'fas fa-project-diagram')
  itemCommunities = new NavItem('Communities', ROUTE_COMMUNITIES, 'fas fa-book')
  itemDistricts = new NavItem('Districts', ROUTE_DISTRICTS, 'fas fa-layer-group')
  itemStreets = new NavItem('Streets', ROUTE_STREETS, 'fas fa-road')
  itemUrbanizations = new NavItem('Urbanizations', ROUTE_URBANIZATIONS, 'fas fa-city')

  section = new NavSection('Geo', 'fas fa-atlas', ROUTE_COUNTRIES, [
    this.itemAddresses,
    this.itemCountries,
    this.itemProvinces,
    this.itemMunicipalities,
    this.itemDistricts,
    this.itemUrbanizations,
    this.itemStreets,
    this.itemCommunities,
  ])

  created(): void {
    this.$emit(EVENT_INPUT, this.section.items)
  }

  get isShow(): boolean {
    return MainStore.isUserCan(CAN_ACCESS_GEO)
  }

  @Watch('$store.state.moduleGeo.invalidCounters.all', { immediate: true })
  onAllCount(count: number): void {
    this.section.extraName = badge(count)
  }

  @Watch('$store.state.moduleGeo.invalidCounters.personAddresses', { immediate: true })
  onPersonAddresses(count: number): void {
    this.itemAddresses.extraName = badge(count)
    this.itemAddresses.extraRoute = {
      name: ROUTE_GEO_ADDRESSES,
      query: { geoStatuses: GeoStatus.New },
    }
  }

  @Watch('$store.state.moduleGeo.invalidCounters.countries', { immediate: true })
  onCountries(count: number): void {
    this.itemCountries.extraName = badge(count)
    this.itemCountries.extraRoute = badgeRoute(ROUTE_COUNTRIES)
  }

  @Watch('$store.state.moduleGeo.invalidCounters.provinces', { immediate: true })
  onProvinces(count: number): void {
    this.itemProvinces.extraName = badge(count)
    this.itemProvinces.extraRoute = badgeRoute(ROUTE_PROVINCES)
  }

  @Watch('$store.state.moduleGeo.invalidCounters.municipalities', { immediate: true })
  onMunicipalities(count: number): void {
    this.itemMunicipalities.extraName = badge(count)
    this.itemMunicipalities.extraRoute = badgeRoute(ROUTE_MUNICIPALITIES)
  }

  @Watch('$store.state.moduleGeo.invalidCounters.communities', { immediate: true })
  onCommunities(count: number): void {
    this.itemCommunities.extraName = badge(count)
    this.itemCommunities.extraRoute = badgeRoute(ROUTE_COMMUNITIES)
  }

  @Watch('$store.state.moduleGeo.invalidCounters.districts', { immediate: true })
  onDistricts(count: number): void {
    this.itemDistricts.extraName = badge(count)
    this.itemDistricts.extraRoute = badgeRoute(ROUTE_DISTRICTS)
  }

  @Watch('$store.state.moduleGeo.invalidCounters.streets', { immediate: true })
  onStreets(count: number): void {
    this.itemStreets.extraName = badge(count)
    this.itemStreets.extraRoute = badgeRoute(ROUTE_STREETS)
  }

  @Watch('$store.state.moduleGeo.invalidCounters.urbanizations', { immediate: true })
  onUrbanizations(count: number): void {
    this.itemUrbanizations.extraName = badge(count)
    this.itemUrbanizations.extraRoute = badgeRoute(ROUTE_URBANIZATIONS)
  }
}

function badgeRoute(routeName: string): ObjectParams {
  return {
    name: routeName,
    query: { status: GeoStatus.New },
  }
}
