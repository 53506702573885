export const EVENT_INPUT = 'input' //Contains the native name of the event; you cannot change the value
export const EVENT_CHANGE = 'change' //Contains the native name of the event; you cannot change the value

export const EVENT_INPUT_LINE = 'EVENT_INPUT_LINE'
export const EVENT_DELETE = 'EVENT_DELETE'
export const EVENT_EDIT = 'EVENT_EDIT'
export const EVENT_SAVE_ITEM = 'EVENT_SAVE_ITEM'
export const EVENT_UPDATE_ITEM = 'EVENT_UPDATE_ITEM'
export const EVENT_VIEW_ITEM = 'EVENT_VIEW_ITEM'
export const EVENT_NEW_ITEM = 'EVENT_NEW_ITEM'
export const EVENT_ITEM_SAVED = 'EVENT_ITEM_SAVED'
export const EVENT_SUBMIT_FORM = 'EVENT_SUBMIT_FORM'
export const EVENT_CANCEL_FORM = 'EVENT_CANCEL_FORM'
export const EVENT_OPEN_FORM = 'EVENT_OPEN_FORM'
export const EVENT_RELOAD = 'EVENT_RELOAD'
export const EVENT_SELECT = 'EVENT_SELECT'
export const EVENT_SELECT_ALL = 'EVENT_SELECT_ALL'
export const EVENT_CLOSE = 'EVENT_CLOSE'
export const EVENT_SHOW = 'EVENT_SHOW'
export const EVENT_RESET = 'EVENT_RESET'
export const EVENT_NO_DATA = 'EVENT_NO_DATA'
export const EVENT_IS_DATA = 'EVENT_IS_DATA'
export const EVENT_TOGGLE = 'EVENT_TOGGLE'
export const EVENT_DISABLE = 'EVENT_DISABLE'
export const EVENT_SET_PER_PAGE = 'EVENT_SET_PER_PAGE'
export const EVENT_SEND_INVOICE_EMAIL = 'EVENT_SEND_INVOICE_EMAIL'
export const EVENT_REFUND_INVOICE = 'EVENT_REFUND_INVOICE'
export const EVENT_SELECT_COLOR = 'EVENT_SELECT_COLOR'
export const EVENT_SELECT_VALUE = 'EVENT_SELECT_VALUE'
export const EVENT_VALUE_IS_PRESENT = 'EVENT_VALUE_IS_PRESENT'
