import { GeoStatus } from '@/models/geo/constants'

interface IColoring {
  status: GeoStatus
  nameStr: string
}

export function coloringName(value: IColoring): string {
  let className = 'text-secondary'
  if (value.status == GeoStatus.NotValid) {
    className = 'text-danger'
  } else if (value.status == GeoStatus.New) {
    className = 'text-orangered'
  }

  return `<b class="${className}">${value.nameStr}</b>`
}
