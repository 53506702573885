import { PermissionGroup } from '@/utils/permissions'
import integrationsServer from '@/permissions/integrations_server'

const prefix = 'integrations'

export const CAN_ACCESS_INTEGRATIONS = prefix + '-access_module'

const integrations: PermissionGroup = {
  name: 'Integrations Module',
  permissions: [
    {
      value: CAN_ACCESS_INTEGRATIONS,
      description: CAN_ACCESS_INTEGRATIONS,
    },
    ...integrationsServer,
  ],
}

export default integrations
