import { IUrbanization, Urbanization } from '@/models/geo/Urbanization'
import { Community, ICommunity } from '@/models/geo/Community'
import { IStreet, Street } from '@/models/geo/Street'
import { geoApi } from '@/api/geo-api'
import { MainStore } from '@/store/MainStore'
import { District, IDistrict } from '@/models/geo/District'

export interface IGeoDetails {
  addressID: number
  street: IStreet | null
  district: IDistrict | null
  community: ICommunity | null
  urbanization: IUrbanization | null
}

export class GeoDetails implements IGeoDetails {
  addressID = 0
  street: Street | null = null
  district: District | null = null
  community: Community | null = null
  urbanization: Urbanization | null = null

  get isValidForm(): boolean {
    return Boolean(
      this.street &&
        this.street.isValidForm &&
        (this.district ? this.district.isValidForm : true) &&
        (this.community ? this.community.isValidForm && this.community.type : true) &&
        (this.urbanization ? this.urbanization.isValidForm : true)
    )
  }

  constructor(src: IGeoDetails | null = null) {
    if (src) {
      this.init(src)
    }
  }

  private init(src: IGeoDetails) {
    this.street = src.street ? new Street(src.street) : null
    this.district = src.district ? new District(src.district) : null
    this.community = src.community ? new Community(src.community) : null
    this.urbanization = src.urbanization ? new Urbanization(src.urbanization) : null
  }

  // issue #214, format rule: {StreetType} {StreetName} {Number}, {CommunityType} {CommunityName}, Urbanizacion {UrbanizationName}, {DistrictName}, {MunicipalityName} ({ProvinceName}), {CountryName}, {PostCode}
  fullName(postcode: string): string {
    const res: string[] = []
    this.street && res.push(this.street.nameStr + ' ' + this.street.numByForm)
    this.community && res.push(this.community.nameStr)
    this.urbanization && res.push(this.urbanization.nameStr)
    this.district && res.push(this.district.nameStr)
    this.street && this.street.municipality && res.push(this.street.municipality.nameWithProvince)

    this.street &&
      this.street.municipality &&
      this.street.municipality.province &&
      this.street.municipality.province.country &&
      res.push(this.street.municipality.province.country.nameStr)

    res.push(postcode)

    return res.join(', ')
  }

  async save(): Promise<boolean> {
    try {
      this.init(await geoApi.details.save(this.toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  toInterface(): IGeoDetails {
    return {
      addressID: this.addressID,
      street: this.street ? this.street.toInterface() : null,
      district: this.district ? this.district.toInterface() : null,
      community: this.community ? this.community.toInterface() : null,
      urbanization: this.urbanization ? this.urbanization.toInterface() : null,
    }
  }
}
