import { CommonModel, IModel } from '@/models/Model'
import { MainStore } from '@/store/MainStore'
import { geoApi } from '@/api/geo-api'
import { IMunicipality, Municipality } from '@/models/geo/Municipality'
import { District, IDistrict } from '@/models/geo/District'
import { IUrbanization, Urbanization } from '@/models/geo/Urbanization'
import { IStreet, Street } from '@/models/geo/Street'
import { coloringName } from '@/models/geo/utils'
import {
  CommunityType,
  communityTypes,
  GeoStatus,
  isValid,
  isVerified,
  statusHtml,
} from '@/models/geo/constants'

export interface ICommunity extends IModel {
  id: number
  type: CommunityType
  name: string
  latitude: number
  longitude: number
  streetID: number
  municipalityID: number
  districtID?: number
  urbanizationID?: number
  weight: number
  status: GeoStatus
  street: IStreet | null
  district: IDistrict | null
  municipality: IMunicipality | null
  urbanization: IUrbanization | null
}

export class Community extends CommonModel implements ICommunity {
  id = 0
  type = 0
  name = ''
  latitude = 0
  longitude = 0
  streetID = 0
  municipalityID = 0
  districtID?: number
  urbanizationID?: number
  weight = 0
  status: GeoStatus = 0
  street: Street | null = null
  district: District | null = null
  municipality: Municipality | null = null
  urbanization: Urbanization | null = null

  get isValidForm(): boolean {
    return (
      Boolean(this.id) ||
      Boolean(
        this.isSetFormValues &&
          this.municipality &&
          this.municipality.isValidForm &&
          this.street &&
          this.street.isValidForm &&
          (this.district ? this.district.isValidForm : true) &&
          (this.urbanization ? this.urbanization.isValidForm : true)
      )
    )
  }

  get isSetFormValues(): boolean {
    return !!this.name
  }

  get isValid(): boolean {
    return isValid(this.status)
  }

  get isVerified(): boolean {
    return isVerified(this.status)
  }

  get statusHtml(): string {
    return statusHtml(this.status)
  }

  get typeName(): string {
    return communityTypes[this.type] || ''
  }

  get nameStr(): string {
    return `${this.typeName} ${this.name}`.trim()
  }

  get titleHtml(): string {
    return coloringName(this)
  }

  constructor(src: ICommunity | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: ICommunity) {
    Object.assign(this, src)
    this.street = src.street ? new Street(src.street) : null
    this.district = src.district ? new District(src.district) : null
    this.municipality = src.municipality ? new Municipality(src.municipality) : null
    this.urbanization = src.urbanization ? new Urbanization(src.urbanization) : null

    this.initModel(src)
  }

  async save(): Promise<boolean> {
    try {
      return this.id ? await this.update() : await this.add()
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async add(): Promise<boolean> {
    try {
      this.init(await geoApi.community.add(this.prepare().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async update(): Promise<boolean> {
    try {
      this.init(await geoApi.community.update(this.prepare().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async delete(): Promise<boolean> {
    if (!confirm(`Are you sure you want to delete community?`)) {
      return false
    }

    try {
      await geoApi.community.delete(this.id)
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async approve(): Promise<boolean> {
    if (!confirm(`Are you sure you want to confirm the entry?`)) {
      return false
    }

    try {
      this.init(await geoApi.community.approve({ id: this.id }))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async reject(): Promise<boolean> {
    if (!confirm(`Are you sure you want to reject the entry?`)) {
      return false
    }

    try {
      this.init(await geoApi.community.reject({ id: this.id }))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  prepare(): Community {
    this.streetID = this.street ? this.street.id : 0
    this.municipalityID = this.municipality ? this.municipality.id : 0
    this.districtID = this.district ? this.district.id : undefined
    this.urbanizationID = this.urbanization ? this.urbanization.id : undefined

    return this
  }

  toInterface(): ICommunity {
    return {
      id: this.id,
      type: this.type,
      name: this.name,
      latitude: this.latitude,
      longitude: this.longitude,
      streetID: this.streetID,
      municipalityID: this.municipalityID,
      districtID: this.districtID,
      urbanizationID: this.urbanizationID,
      status: this.status,
      weight: this.weight,
      street: this.street ? this.street.toInterface() : null,
      district: this.district ? this.district.toInterface() : null,
      municipality: this.municipality ? this.municipality.toInterface() : null,
      urbanization: this.urbanization ? this.urbanization.toInterface() : null,
    }
  }
}
