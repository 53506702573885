
import { Component, Prop, Vue } from 'vue-property-decorator'
import { NavItem, NavSection } from '@/components/app/nav/types'
import { MainStore } from '@/store/MainStore'
import NavPart from '@/components/app/nav/NavPart.vue'
import { EVENT_INPUT } from '@/utils/event-constants'
import { CAN_ACCESS_INTERNET } from '@/permissions/internet'
import {
  ROUTE_INTERNET_IP_POOLS,
  ROUTE_INTERNET_NAS,
  ROUTE_INTERNET_SESSIONS,
  ROUTE_INTERNET_SESSIONS_ERRORS,
} from '@/router/internet'
import {
  CAN_VIEW_INTERNET_SESSION,
  CAN_VIEW_INTERNET_SESSION_ERROR,
  CAN_VIEW_IP_POOL,
  CAN_VIEW_NAS,
} from '@/permissions/internet_server'
@Component({
  components: { NavPart },
})
export default class NavPartInternet extends Vue {
  @Prop({ type: Boolean }) isOpen!: boolean

  get isShow(): boolean {
    return MainStore.isUserCan(CAN_ACCESS_INTERNET)
  }

  section = new NavSection('Internet', 'fas fa-ethernet', ROUTE_INTERNET_NAS, [
    new NavItem('Sessions', ROUTE_INTERNET_SESSIONS, 'fas fa-list-ul').withPerms(
      CAN_VIEW_INTERNET_SESSION
    ),
    new NavItem(
      'Session errors',
      ROUTE_INTERNET_SESSIONS_ERRORS,
      'fas fa-exclamation-triangle'
    ).withPerms(CAN_VIEW_INTERNET_SESSION_ERROR),
    new NavItem('NAS', ROUTE_INTERNET_NAS, 'fas fa-server').withPerms(CAN_VIEW_NAS),
    new NavItem('IP pools', ROUTE_INTERNET_IP_POOLS, 'fas fa-network-wired').withPerms(
      CAN_VIEW_IP_POOL
    ),
  ])

  created(): void {
    this.$emit(EVENT_INPUT, this.section.items)
  }
}
