import { MainStore } from '@/store/MainStore'
import { geoApi } from '@/api/geo-api'
import { List } from '@/models/List'
import { ObjectParams } from '@/models/types'
import { Country } from '@/models/geo/Country'

export class CountryGet {
  static async list(params: ObjectParams): Promise<List<Country>> {
    const list: List<Country> = { count: 0, items: [] }
    try {
      const l = await geoApi.country.list(params)
      list.count = l.count
      list.items = l.items.map((v) => new Country(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return list
  }

  static async versions(params: ObjectParams): Promise<List<Country>> {
    const list: List<Country> = { count: 0, items: [] }
    try {
      const l = await geoApi.country.versions(params)
      list.count = l.count
      list.items = l.items.map((v) => new Country(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return list
  }

  static async mapByIDs(ids: number[]): Promise<Map<number, Country>> {
    const res = await CountryGet.byIDs(ids)

    const pMap = new Map<number, Country>()
    res.forEach((v) => pMap.set(v.id, new Country(v)))

    return pMap
  }

  static async byID(id: number): Promise<Country> {
    let item = new Country()
    try {
      item = new Country(await geoApi.country.getByID(id))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return item
  }

  static async byIDs(ids: number[]): Promise<Country[]> {
    let items: Country[] = []
    try {
      items = (await geoApi.country.getByIDs(ids)).map((v) => new Country(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return items
  }

  static async count(params: ObjectParams): Promise<number> {
    let count = 0
    try {
      count = await geoApi.country.count(params)
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return count
  }
}
