import { GetterTree } from 'vuex'
import { Toast } from '@/models/Toast'
import { RootState } from '@/store/index'
import { ISettings } from '@/models/Settings'

const getters: GetterTree<RootState, RootState> = {
  isAuthorized(state): boolean {
    if (state.user && state.user.session) {
      return state.user.session.expire.getTime() >= new Date().getTime()
    }

    return false
  },

  userCan:
    (state: RootState) =>
    (permission: string): boolean => {
      return Boolean(
        state.user && state.user.permissions && state.user.permissions.includes(permission)
      )
    },

  personID: (state: RootState): number => {
    return state.user.personID
  },

  toasts: (state: RootState): Array<Toast> => {
    return state.toasts || []
  },

  settings: (state: RootState): ISettings => {
    return state.settings
  },
}

export default getters
