import API from '@/api/api'
import { FileInfo } from '@/models/FileInfo'
import { IEquipment } from '@/models/stock/Equipment'
import { IEquipmentModel } from '@/models/stock/EquipmentModel'
import { IConsumable } from '@/models/stock/Consumable'
import { IType } from '@/models/stock/Type'
import { IManufacturer } from '@/models/stock/Manufacturer'
import { IProvider } from '@/models/stock/Provider'
import { IWaybill } from '@/models/stock/Waybill'
import { IWaybillItem } from '@/models/stock/WaybillItem'
import { List } from '@/models/List'
import { ObjectParams } from '@/models/types'
import { BalanceByModel, UpdatedEquipment, WaybillFromFiles } from '@/responses/waybills'
import { ChangeEquipmentsStatus, EquipmentsEditor, EquipmentsLoader } from '@/requests/equipments'
import { ITransferEquipments } from '@/requests/TransferEquipments'
import { IEquipmentAttachService, IEquipmentDetachService } from '@/requests/stock'
import { IMovement } from '@/models/stock/Movement'
import { IMovementItem } from '@/models/stock/MovementItem'

const URL_EQUIPMENTS_MODULE = '/stock'

const URL_EQUIPMENTS = URL_EQUIPMENTS_MODULE + '/equipments'
const URL_EQUIPMENTS_VERSIONS = URL_EQUIPMENTS + '/versions'
const URL_EQUIPMENTS_STATUSES = URL_EQUIPMENTS + '/statuses'
const URL_EQUIPMENTS_FROM_FILES = URL_EQUIPMENTS + '/from-files'
const URL_EQUIPMENTS_MODELS = URL_EQUIPMENTS_MODULE + '/equipments-models'
const URL_CONSUMABLES = URL_EQUIPMENTS_MODULE + '/consumables'
const URL_TYPES = URL_EQUIPMENTS_MODULE + '/types'
const URL_TYPES_ENDING_CONSUMABLES = URL_TYPES + '/ending-consumables'
const URL_TYPES_ENDING_EQUIPMENT_MODELS = URL_TYPES + '/ending-equipment-models'
const URL_MANUFACTURERS = URL_EQUIPMENTS_MODULE + '/manufacturers'
const URL_PROVIDERS = URL_EQUIPMENTS_MODULE + '/providers'
const URL_WAYBILLS = URL_EQUIPMENTS_MODULE + '/waybills'
const URL_WAYBILLS_FILES = URL_WAYBILLS + '/files'
const URL_WAYBILLS_ITEMS = URL_EQUIPMENTS_MODULE + '/waybill-items'
const URL_WAYBILLS_CREATE_FROM_FILES = URL_WAYBILLS + '/create-from-files'
const URL_WAYBILLS_RETURN_TO_STOCK = URL_WAYBILLS + '/return-to-stock'
const URL_WAYBILLS_VERSIONS = URL_WAYBILLS + '/versions'
const URL_MOVEMENTS = URL_EQUIPMENTS_MODULE + '/movements'

export const stockApi = {
  equipment: {
    getByID: async function (id: number): Promise<IEquipment> {
      const res = await API.get(API.buildURL(URL_EQUIPMENTS + '/' + id))
      return (await res.json()) as Promise<IEquipment>
    },

    add: async function (unitIEquipment: IEquipment): Promise<IEquipment> {
      const res = await API.post(API.buildURL(URL_EQUIPMENTS), unitIEquipment)
      return (await res.json()) as Promise<IEquipment>
    },

    update: async function (unitIEquipment: IEquipment): Promise<IEquipment> {
      const res = await API.put(API.buildURL(URL_EQUIPMENTS), unitIEquipment)
      return (await res.json()) as Promise<IEquipment>
    },

    resetStartTestPeriod: async function (unitIEquipment: IEquipment): Promise<IEquipment> {
      const res = await API.put(
        API.buildURL(URL_EQUIPMENTS + '/reset-start-period'),
        unitIEquipment
      )
      return (await res.json()) as Promise<IEquipment>
    },

    list: async function (params: ObjectParams): Promise<List<IEquipment>> {
      const res = await API.get(API.buildURL(URL_EQUIPMENTS, { ...params }))
      return (await res.json()) as Promise<List<IEquipment>>
    },

    versions: async function (params: ObjectParams): Promise<List<IEquipment>> {
      const res = await API.get(API.buildURL(URL_EQUIPMENTS_VERSIONS, { ...params }))
      return (await res.json()) as Promise<List<IEquipment>>
    },

    changeStatuses: async function (request: ChangeEquipmentsStatus): Promise<void> {
      await API.put(API.buildURL(URL_EQUIPMENTS_STATUSES), request)
    },

    updateFromFiles: async function (request: EquipmentsEditor): Promise<UpdatedEquipment> {
      const res = await API.put(API.buildURL(URL_EQUIPMENTS_FROM_FILES), request)
      return (await res.json()) as Promise<UpdatedEquipment>
    },

    balancesByModel: async function (id: number): Promise<BalanceByModel[]> {
      const res = await API.get(API.buildURL(URL_EQUIPMENTS + '/balances-by-model/' + id))
      return (await res.json()) as Promise<BalanceByModel[]>
    },

    attachService: async function (request: IEquipmentAttachService): Promise<void> {
      await API.put(API.buildURL(URL_EQUIPMENTS + '/services/attach'), request)
    },

    detachService: async function (request: IEquipmentDetachService): Promise<void> {
      await API.put(API.buildURL(URL_EQUIPMENTS + '/services/detach'), request)
    },

    withoutServices: async function (personID: number): Promise<IEquipment[]> {
      const res = await API.get(
        API.buildURL(URL_EQUIPMENTS + '/persons/' + personID + '/without-services')
      )
      return await res.json()
    },
  },

  model: {
    getByID: async function (id: number): Promise<IEquipmentModel> {
      const res = await API.get(API.buildURL(URL_EQUIPMENTS_MODELS + '/' + id))
      return (await res.json()) as Promise<IEquipmentModel>
    },

    add: async function (address: IEquipmentModel): Promise<IEquipmentModel> {
      const res = await API.post(API.buildURL(URL_EQUIPMENTS_MODELS), address)
      return (await res.json()) as Promise<IEquipmentModel>
    },

    update: async function (address: IEquipmentModel): Promise<IEquipmentModel> {
      const res = await API.put(API.buildURL(URL_EQUIPMENTS_MODELS), address)
      return (await res.json()) as Promise<IEquipmentModel>
    },

    delete: async function (id: number): Promise<void> {
      const res = await API.delete(API.buildURL(URL_EQUIPMENTS_MODELS + '/' + id))
      if (!res.ok) {
        throw new Error(await res.json())
      }
    },

    list: async function (params: ObjectParams): Promise<List<IEquipmentModel>> {
      const res = await API.get(API.buildURL(URL_EQUIPMENTS_MODELS, { ...params }))
      return (await res.json()) as Promise<List<IEquipmentModel>>
    },
  },

  consumable: {
    getByID: async function (id: number): Promise<IConsumable> {
      const res = await API.get(API.buildURL(URL_CONSUMABLES + '/' + id))
      return (await res.json()) as Promise<IConsumable>
    },

    add: async function (address: IConsumable): Promise<IConsumable> {
      const res = await API.post(API.buildURL(URL_CONSUMABLES), address)
      return (await res.json()) as Promise<IConsumable>
    },

    update: async function (address: IConsumable): Promise<IConsumable> {
      const res = await API.put(API.buildURL(URL_CONSUMABLES), address)
      return (await res.json()) as Promise<IConsumable>
    },

    delete: async function (id: number): Promise<void> {
      const res = await API.delete(API.buildURL(URL_CONSUMABLES + '/' + id))
      if (!res.ok) {
        throw new Error(await res.json())
      }
    },

    list: async function (params: ObjectParams): Promise<List<IConsumable>> {
      const res = await API.get(API.buildURL(URL_CONSUMABLES, { ...params }))
      return (await res.json()) as Promise<List<IConsumable>>
    },
  },

  type: {
    getByID: async function (id: number): Promise<IType> {
      const res = await API.get(API.buildURL(URL_TYPES + '/' + id))
      return (await res.json()) as Promise<IType>
    },

    add: async function (address: IType): Promise<IType> {
      const res = await API.post(API.buildURL(URL_TYPES), address)
      return (await res.json()) as Promise<IType>
    },

    update: async function (address: IType): Promise<IType> {
      const res = await API.put(API.buildURL(URL_TYPES), address)
      return (await res.json()) as Promise<IType>
    },

    delete: async function (id: number): Promise<void> {
      const res = await API.delete(API.buildURL(URL_TYPES + '/' + id))
      if (!res.ok) {
        throw new Error(await res.json())
      }
    },

    list: async function (params: ObjectParams): Promise<List<IType>> {
      const res = await API.get(API.buildURL(URL_TYPES, { ...params }))
      return (await res.json()) as Promise<List<IType>>
    },

    endingConsumables: async function (params: ObjectParams): Promise<IType[]> {
      const res = await API.get(API.buildURL(URL_TYPES_ENDING_CONSUMABLES, { ...params }))
      return ((await res.json()) as Promise<IType[]>) || []
    },

    endingEquipmentModels: async function (params: ObjectParams): Promise<IType[]> {
      const res = await API.get(API.buildURL(URL_TYPES_ENDING_EQUIPMENT_MODELS, { ...params }))
      return ((await res.json()) as Promise<IType[]>) || []
    },
  },

  manufacturer: {
    getByID: async function (id: number): Promise<IManufacturer> {
      const res = await API.get(API.buildURL(URL_MANUFACTURERS + '/' + id))
      return (await res.json()) as Promise<IManufacturer>
    },

    add: async function (address: IManufacturer): Promise<IManufacturer> {
      const res = await API.post(API.buildURL(URL_MANUFACTURERS), address)
      return (await res.json()) as Promise<IManufacturer>
    },

    update: async function (address: IManufacturer): Promise<IManufacturer> {
      const res = await API.put(API.buildURL(URL_MANUFACTURERS), address)
      return (await res.json()) as Promise<IManufacturer>
    },

    delete: async function (id: number): Promise<void> {
      const res = await API.delete(API.buildURL(URL_MANUFACTURERS + '/' + id))
      if (!res.ok) {
        throw new Error(await res.json())
      }
    },

    list: async function (params: ObjectParams): Promise<List<IManufacturer>> {
      const res = await API.get(API.buildURL(URL_MANUFACTURERS, { ...params }))
      return (await res.json()) as Promise<List<IManufacturer>>
    },
  },

  provider: {
    getByID: async function (id: number): Promise<IProvider> {
      const res = await API.get(API.buildURL(URL_PROVIDERS + '/' + id))
      return (await res.json()) as Promise<IProvider>
    },

    add: async function (address: IProvider): Promise<IProvider> {
      const res = await API.post(API.buildURL(URL_PROVIDERS), address)
      return (await res.json()) as Promise<IProvider>
    },

    update: async function (address: IProvider): Promise<IProvider> {
      const res = await API.put(API.buildURL(URL_PROVIDERS), address)
      return (await res.json()) as Promise<IProvider>
    },

    delete: async function (id: number): Promise<void> {
      const res = await API.delete(API.buildURL(URL_PROVIDERS + '/' + id))
      if (!res.ok) {
        throw new Error(await res.json())
      }
    },

    list: async function (params: ObjectParams): Promise<List<IProvider>> {
      const res = await API.get(API.buildURL(URL_PROVIDERS, { ...params }))
      return (await res.json()) as Promise<List<IProvider>>
    },
  },

  waybill: {
    getByID: async function (id: number): Promise<IWaybill> {
      const res = await API.get(API.buildURL(URL_WAYBILLS + '/' + id))
      return (await res.json()) as Promise<IWaybill>
    },

    add: async function (address: IWaybill): Promise<IWaybill> {
      const res = await API.post(API.buildURL(URL_WAYBILLS), address)
      return (await res.json()) as Promise<IWaybill>
    },

    update: async function (address: IWaybill): Promise<IWaybill> {
      const res = await API.put(API.buildURL(URL_WAYBILLS), address)
      return (await res.json()) as Promise<IWaybill>
    },

    delete: async function (id: number): Promise<void> {
      const res = await API.delete(API.buildURL(URL_WAYBILLS + '/' + id))
      if (!res.ok) {
        throw new Error(await res.json())
      }
    },

    returnToStock: async function (id: number): Promise<void> {
      const res = await API.put(API.buildURL(URL_WAYBILLS_RETURN_TO_STOCK + '/' + id), {})
      if (!res.ok) {
        throw new Error(await res.json())
      }
    },

    list: async function (params: ObjectParams): Promise<List<IWaybill>> {
      const res = await API.get(API.buildURL(URL_WAYBILLS, { ...params }))
      return (await res.json()) as Promise<List<IWaybill>>
    },

    versions: async function (params: ObjectParams): Promise<List<IWaybill>> {
      const res = await API.get(API.buildURL(URL_WAYBILLS_VERSIONS, { ...params }))
      return (await res.json()) as Promise<List<IWaybill>>
    },

    addFile: async function (waybillID: number, files: FileInfo[]): Promise<void> {
      const res = await API.post(API.buildURL(URL_WAYBILLS_FILES), { waybillID, files })

      if (!res.ok) {
        throw new Error(await res.json())
      }
    },

    fileContent: async function (waybillID: number, fileName: string): Promise<Blob> {
      const res = await API.get(API.buildURL(URL_WAYBILLS_FILES, { waybillID, fileName }))
      return res.blob()
    },

    deleteFile: async function (waybillID: number, fileName: string): Promise<void> {
      const res = await API.delete(API.buildURL(URL_WAYBILLS_FILES, { waybillID, fileName }))

      if (!res.ok) {
        throw new Error(await res.json())
      }
    },

    createFromFiles: async function (request: EquipmentsLoader): Promise<WaybillFromFiles> {
      const res = await API.post(API.buildURL(URL_WAYBILLS_CREATE_FROM_FILES), request)
      return (await res.json()) as Promise<WaybillFromFiles>
    },
  },

  waybillItem: {
    add: async function (address: IWaybillItem): Promise<IWaybillItem> {
      const res = await API.post(API.buildURL(URL_WAYBILLS_ITEMS), address)
      return (await res.json()) as Promise<IWaybillItem>
    },

    update: async function (address: IWaybillItem): Promise<IWaybillItem> {
      const res = await API.put(API.buildURL(URL_WAYBILLS_ITEMS), address)
      return (await res.json()) as Promise<IWaybillItem>
    },

    delete: async function (id: number): Promise<void> {
      const res = await API.delete(API.buildURL(URL_WAYBILLS_ITEMS + '/' + id))
      if (!res.ok) {
        throw new Error(await res.json())
      }
    },
  },

  movement: {
    transfer: async function (request: ITransferEquipments): Promise<IMovement> {
      const res = await API.post(API.buildURL(URL_MOVEMENTS), request)
      return (await res.json()) as Promise<IMovement>
    },

    getByID: async function (id: number): Promise<IMovement> {
      const res = await API.get(API.buildURL(URL_MOVEMENTS + '/' + id))
      return (await res.json()) as Promise<IMovement>
    },

    list: async function (params: ObjectParams): Promise<List<IMovement>> {
      const res = await API.get(API.buildURL(URL_MOVEMENTS, { ...params }))
      return (await res.json()) as Promise<List<IMovement>>
    },

    items: async function (params: ObjectParams): Promise<IMovementItem[]> {
      const res = await API.get(API.buildURL(URL_MOVEMENTS + '/items', { ...params }))
      return await res.json()
    },
  },
}
