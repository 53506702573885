// This file is generated automatically, do not make manual changes to it.
// generating script: shell/generate_permissions.go

export const CAN_ADD_CABLEMOVIL_CHARGES = 'cdr-CanAddCablemovilCharges'
export const CAN_ADD_SEWAN_CHARGES = 'cdr-CanAddSewanCharges'
export const CAN_CREATE_CABLEMOVIL = 'cdr-CanCreateCablemovil'
export const CAN_CREATE_CABLEMOVIL_FILE = 'cdr-CanCreateCablemovilFile'
export const CAN_CREATE_SEWAN = 'cdr-CanCreateSewan'
export const CAN_CREATE_SEWAN_FILE = 'cdr-CanCreateSewanFile'
export const CAN_DELETE_CABLEMOVIL = 'cdr-CanDeleteCablemovil'
export const CAN_DELETE_CABLEMOVIL_FILE = 'cdr-CanDeleteCablemovilFile'
export const CAN_DELETE_SEWAN = 'cdr-CanDeleteSewan'
export const CAN_DELETE_SEWAN_FILE = 'cdr-CanDeleteSewanFile'
export const CAN_EDIT_CABLEMOVIL = 'cdr-CanEditCablemovil'
export const CAN_EDIT_CABLEMOVIL_FILE = 'cdr-CanEditCablemovilFile'
export const CAN_EDIT_SEWAN = 'cdr-CanEditSewan'
export const CAN_EDIT_SEWAN_FILE = 'cdr-CanEditSewanFile'
export const CAN_VIEW_CABLEMOVIL = 'cdr-CanViewCablemovil'
export const CAN_VIEW_CABLEMOVIL_FILE = 'cdr-CanViewCablemovilFile'
export const CAN_VIEW_SEWAN = 'cdr-CanViewSewan'
export const CAN_VIEW_SEWAN_FILE = 'cdr-CanViewSewanFile'

const cdrServer = [
  {
    value: CAN_ADD_CABLEMOVIL_CHARGES,
    description: CAN_ADD_CABLEMOVIL_CHARGES,
  },
  {
    value: CAN_ADD_SEWAN_CHARGES,
    description: CAN_ADD_SEWAN_CHARGES,
  },
  {
    value: CAN_CREATE_CABLEMOVIL,
    description: CAN_CREATE_CABLEMOVIL,
  },
  {
    value: CAN_CREATE_CABLEMOVIL_FILE,
    description: CAN_CREATE_CABLEMOVIL_FILE,
  },
  {
    value: CAN_CREATE_SEWAN,
    description: CAN_CREATE_SEWAN,
  },
  {
    value: CAN_CREATE_SEWAN_FILE,
    description: CAN_CREATE_SEWAN_FILE,
  },
  {
    value: CAN_DELETE_CABLEMOVIL,
    description: CAN_DELETE_CABLEMOVIL,
  },
  {
    value: CAN_DELETE_CABLEMOVIL_FILE,
    description: CAN_DELETE_CABLEMOVIL_FILE,
  },
  {
    value: CAN_DELETE_SEWAN,
    description: CAN_DELETE_SEWAN,
  },
  {
    value: CAN_DELETE_SEWAN_FILE,
    description: CAN_DELETE_SEWAN_FILE,
  },
  {
    value: CAN_EDIT_CABLEMOVIL,
    description: CAN_EDIT_CABLEMOVIL,
  },
  {
    value: CAN_EDIT_CABLEMOVIL_FILE,
    description: CAN_EDIT_CABLEMOVIL_FILE,
  },
  {
    value: CAN_EDIT_SEWAN,
    description: CAN_EDIT_SEWAN,
  },
  {
    value: CAN_EDIT_SEWAN_FILE,
    description: CAN_EDIT_SEWAN_FILE,
  },
  {
    value: CAN_VIEW_CABLEMOVIL,
    description: CAN_VIEW_CABLEMOVIL,
  },
  {
    value: CAN_VIEW_CABLEMOVIL_FILE,
    description: CAN_VIEW_CABLEMOVIL_FILE,
  },
  {
    value: CAN_VIEW_SEWAN,
    description: CAN_VIEW_SEWAN,
  },
  {
    value: CAN_VIEW_SEWAN_FILE,
    description: CAN_VIEW_SEWAN_FILE,
  },
]

export default cdrServer
