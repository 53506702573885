import { Workflow } from '@/models/persons/Workflow'
import { uniqueNonZeroNums } from '@/utils/unique_nums'
import { PersonSimpleGet } from '@/models/persons/PersonSimpleGet'
import { Person } from '@/models/persons/Person'
import { PersonLoader } from '@/models/persons/PersonLoader'

export class WorkflowLoader {
  private readonly items: Workflow[] = []

  constructor(items: Workflow | Workflow[]) {
    this.items = items instanceof Workflow ? [items] : [...items]
  }

  load = async (...loaders: Array<Exclude<keyof WorkflowLoader, 'load'>>): Promise<void> => {
    await Promise.all(loaders.map((method) => this[method]()))
  }

  personsWithUsers = async (): Promise<void> => {
    const ids = uniqueNonZeroNums(
      this.items.map((v) => [v.personID || 0, v.createdBy, v.changedBy || 0]).flat()
    )
    if (!ids.length) {
      return
    }

    const items = await PersonSimpleGet.mapByIDs(ids)
    const persons: Person[] = []

    this.items.forEach((v) => {
      const p = items.get(Number(v.personID))
      if (p) {
        const person = Person.BySimple(p)
        v.person = person
        persons.push(person)
      }

      v.whoChanged = items.get(Number(v.changedBy)) || null
      v.whoCreated = items.get(v.createdBy) || null
    })

    await new PersonLoader(persons).load('users')
  }
}
