import { MainStore } from '@/store/MainStore'
import { discountsApi } from '@/api/discounts-api'
import { List } from '@/models/List'
import { ObjectParams } from '@/models/types'
import { Discounts } from '@/models/discounts/constants'
import { Discount, IDiscount } from '@/models/discounts/Discount'
import { AllPersonDiscountsRequest } from '@/requests/discounts'

export class DiscountGet {
  static async list(params: ObjectParams): Promise<List<Discount>> {
    const list: List<Discount> = { count: 0, items: [] }
    try {
      const l = await discountsApi.discount.list(params)
      list.count = l.count
      list.items = l.items.map((v) => new Discount(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return list
  }

  static async allPersonDiscounts(req: AllPersonDiscountsRequest): Promise<Discount[]> {
    let res: Discount[] = []
    try {
      const items = await discountsApi.person.all(req)
      res = items.map((v) => new Discount(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return res
  }

  static async byID(id: number): Promise<Discount> {
    let item = new Discount()
    try {
      item = new Discount(await discountsApi.discount.getByID(id))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return item
  }

  static async byIDs(ids: number[]): Promise<Discount[]> {
    let items: Discount[] = []
    try {
      items = (await discountsApi.discount.getByIDs(ids)).map((v) => new Discount(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return items
  }

  static async byPriceID(id: number): Promise<Discount | null> {
    let item: Discount | null = null
    try {
      const res = await discountsApi.price.getByID(id)
      if (res) {
        item = new Discount(res)
      }
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return item
  }

  static async mapByIDs(ids: number[]): Promise<Map<number, Discount>> {
    const contracts = await DiscountGet.byIDs(ids)

    const pMap = new Map<number, Discount>()
    contracts.forEach((v) => pMap.set(v.id, new Discount(v)))

    return pMap
  }

  static async mapByPriceIDs(ids: number[]): Promise<Map<number, Discount>> {
    const items = new Map<number, Discount>()
    try {
      const res = await discountsApi.price.getByIDs(ids)
      if (res && res.length) {
        res.forEach((v) => items.set(v.priceID, new Discount(v)))
      }
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return items
  }

  static async byPersonID(id: number): Promise<Discount[]> {
    return await getByID(id, discountsApi.person.getByID)
  }

  static async byTagID(id: number): Promise<Discount[]> {
    return await getByID(id, discountsApi.tag.getByID)
  }

  static async byContractID(id: number): Promise<Discount[]> {
    return await getByID(id, discountsApi.contract.getByID)
  }

  static async byPersonIDs(ids: number[]): Promise<Map<number, Discount[]>> {
    return await mapByIDs(ids, discountsApi.person.getByIDs)
  }

  static async mapByTagIDs(ids: number[]): Promise<Map<number, Discount[]>> {
    return await mapByIDs(ids, discountsApi.tag.getByIDs)
  }

  static async mapByContractIDs(ids: number[]): Promise<Map<number, Discount[]>> {
    return await mapByIDs(ids, discountsApi.contract.getByIDs)
  }

  static async mapBySubscriptionIDs(ids: number[]): Promise<Map<number, Discount[]>> {
    return await mapByIDs(ids, discountsApi.subscription.getByIDs)
  }

  static async mapByPersonIDs(ids: number[]): Promise<Map<number, Discount[]>> {
    return await mapByIDs(ids, discountsApi.person.getByIDs)
  }
}

async function getByID(
  id: number,
  getFn: (id: number) => Promise<IDiscount[]>
): Promise<Discount[]> {
  let items: Discount[] = []
  try {
    const res = await getFn(id)
    if (res && res.length) {
      items = res.map((v) => new Discount(v))
    }
  } catch (e) {
    await MainStore.addError(e as Error)
  }
  return items
}

async function mapByIDs(
  ids: number[],
  getFn: (ids: number[]) => Promise<Discounts>
): Promise<Map<number, Discount[]>> {
  const items = new Map<number, Discount[]>()
  try {
    const res = await getFn(ids)
    if (res) {
      Object.keys(res).forEach((tagID) => {
        items.set(
          Number(tagID),
          (res[tagID] as Discount[]).map((v) => new Discount(v))
        )
      })
    }
  } catch (e) {
    await MainStore.addError(e as Error)
  }
  return items
}
