import { PermissionGroup } from '@/utils/permissions'
import tariffsServer from '@/permissions/tariffs_server'

const prefix = 'tariffs'

export const CAN_ACCESS_TARIFFS = prefix + '-access_module'

const tariffs: PermissionGroup = {
  name: 'Tariffs Module',
  permissions: [
    {
      value: CAN_ACCESS_TARIFFS,
      description: CAN_ACCESS_TARIFFS,
    },
    ...tariffsServer,
  ],
}

export default tariffs
