
import { Component, Vue } from 'vue-property-decorator'
import { MainStore } from '@/store/MainStore'
import { Auth } from '@/models/user/auth'

@Component
export default class PasswordChanger extends Vue {
  errorMsg = ''
  newPasswordRepeated = ''
  auth = new Auth()

  async send(): Promise<void> {
    if (this.auth.newPassword === this.newPasswordRepeated) {
      this.errorMsg = ''
      this.auth.personID = MainStore.getPersonID()
      await this.auth.changePassword()
    } else {
      this.errorMsg = 'New Password does not correspond to the Repeated'
    }
  }
}
