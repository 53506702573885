import { CommonModel, IModel } from '@/models/Model'
import { MainStore } from '@/store/MainStore'
import { personsApi } from '@/api/persons-api'
import { dateString, downloadBlob, formatDate, openNewTabBlob } from '@/utils'
import { IPersonWhence, PersonWhence } from '@/models/persons/PersonWhence'
import { PersonType } from '@/models/persons/constants'
import { personViewHref } from '@/models/persons/hrefs'
import { downloadApi } from '@/api/downloads-api'
import { EquipmentStatus } from '@/models/stock/constants'
import { Country } from '@/models/geo/Country'
import { loaderWrapper } from '@/utils/loaderWrapper'
import { startCase } from '@/utils/strings'

export function formatPersonID(id: number): string {
  return id > 0 ? 'P' + id.toString().padStart(6, '0') : ''
}

export interface IPersonSimple extends IModel {
  id: number
  firstName: string
  lastName: string
  isClient: boolean
  isPhysical: boolean
  isInternalInstaller: boolean
  nationalityID: number
  whenceID: number
  isPrepaid: boolean
  registrationDate: Date | null
  cancellationDate: Date | null
  whence: IPersonWhence | null
  types: PersonType[]
}

export class PersonSimple extends CommonModel implements IPersonSimple {
  id = 0
  firstName = ''
  lastName = ''
  isClient = true
  isPhysical = true
  isInternalInstaller = false
  nationalityID = 0
  whenceID = 0
  isPrepaid = false
  registrationDate: Date | null = null
  cancellationDate: Date | null = null
  whence: PersonWhence | null = null
  types: PersonType[] = [PersonType.Customer]

  // after load
  nationality: Country | null = null

  // for Form
  cancellationDateForm = ''

  get isCanceled(): boolean {
    return !!this.cancellationDate
  }

  get isInstaller(): boolean {
    return this.types.includes(PersonType.Installer)
  }

  get formattedID(): string {
    return formatPersonID(this.id)
  }

  get registrationDateStr(): string {
    return formatDate(this.registrationDate)
  }

  get cancellationDateStr(): string {
    return formatDate(this.cancellationDate)
  }

  get fullName(): string {
    return startCase((this.firstName + ' ' + this.lastName).trim())
  }

  constructor(src: PersonSimple | IPersonSimple | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  protected init(src: PersonSimple | IPersonSimple): void {
    Object.assign(this, src)

    this.registrationDate = this.dateOrNull(src.registrationDate)
    this.cancellationDate = this.dateOrNull(src.cancellationDate)
    this.whence = src.whence ? new PersonWhence(src.whence) : null
    this.firstName = startCase(src.firstName)
    this.lastName = startCase(src.lastName)

    if (src instanceof PersonSimple) {
      this.cancellationDateForm = src.cancellationDateForm
    } else {
      this.cancellationDateForm = dateString(this.cancellationDate)
    }

    this.initModel(src)
  }

  viewLinkHtml(target: '_self' | '_blank' = '_blank'): string {
    return this.id
      ? `<a href="${personViewHref(this.id)}" target="${target}">${this.fullName}</a>`
      : ''
  }

  async update(): Promise<boolean> {
    try {
      this.init(await personsApi.simple.update(this.prepareSimple().simpleToInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async printEquipments(equipmentStatus?: EquipmentStatus, inNewTab = true): Promise<boolean> {
    await loaderWrapper(async () => {
      const res = await downloadApi.stock.personEquipments({
        personID: this.id,
        equipmentStatus: equipmentStatus,
      })
      inNewTab ? openNewTabBlob(res) : downloadBlob(res, 'equipments_by_person_' + this.id)
    })

    return false
  }

  public prepareSimple(): PersonSimple {
    this.cancellationDate = this.cancellationDateForm ? new Date(this.cancellationDateForm) : null
    this.whenceID = this.whence ? this.whence.id : 0
    this.nationalityID = this.nationality ? this.nationality.id : this.nationalityID

    return this
  }

  simpleToInterface(): IPersonSimple {
    return {
      id: this.id,
      firstName: startCase(this.firstName),
      lastName: startCase(this.lastName),
      isClient: this.isClient,
      isPhysical: this.isPhysical,
      isInternalInstaller: this.isInternalInstaller,
      nationalityID: this.nationalityID,
      whenceID: this.whenceID,
      isPrepaid: this.isPrepaid,
      registrationDate: this.registrationDate,
      cancellationDate: this.cancellationDate,
      whence: null,
      types: [...this.types],
    }
  }
}
