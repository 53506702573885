import { CommonModel, IModel } from '@/models/Model'
import { MainStore } from '@/store/MainStore'
import { geoApi } from '@/api/geo-api'
import { IMunicipality, Municipality } from '@/models/geo/Municipality'
import { coloringName } from '@/models/geo/utils'
import { GeoStatus, isValid, isVerified, statusHtml } from '@/models/geo/constants'

export interface IDistrict extends IModel {
  id: number
  name: string
  municipalityID: number
  status: GeoStatus
  weight: number
  municipality: IMunicipality | null
}

export class District extends CommonModel implements IDistrict {
  id = 0
  name = ''
  municipalityID = 0
  status: GeoStatus = 0
  weight = 0
  municipality: Municipality | null = null

  get isValidForm(): boolean {
    return (
      Boolean(this.id) ||
      Boolean(this.isSetFormValues && this.municipality && this.municipality.isValidForm)
    )
  }

  get isSetFormValues(): boolean {
    return !!this.name
  }

  get isValid(): boolean {
    return isValid(this.status)
  }

  get isVerified(): boolean {
    return isVerified(this.status)
  }

  get statusHtml(): string {
    return statusHtml(this.status)
  }

  get nameStr(): string {
    return this.name
  }

  get titleHtml(): string {
    return coloringName(this)
  }

  constructor(src: IDistrict | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: IDistrict) {
    Object.assign(this, src)
    this.municipality = src.municipality ? new Municipality(src.municipality) : null

    this.initModel(src)
  }

  async save(): Promise<boolean> {
    try {
      return this.id ? await this.update() : await this.add()
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async add(): Promise<boolean> {
    try {
      this.init(await geoApi.district.add(this.prepare().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async update(): Promise<boolean> {
    try {
      this.init(await geoApi.district.update(this.prepare().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async delete(): Promise<boolean> {
    if (!confirm(`Are you sure you want to delete district?`)) {
      return false
    }

    try {
      await geoApi.district.delete(this.id)
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async approve(): Promise<boolean> {
    if (!confirm(`Are you sure you want to confirm the entry?`)) {
      return false
    }

    try {
      this.init(await geoApi.district.approve({ id: this.id }))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async reject(): Promise<boolean> {
    if (!confirm(`Are you sure you want to reject the entry?`)) {
      return false
    }

    try {
      this.init(await geoApi.district.reject({ id: this.id }))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  prepare(): District {
    this.municipalityID = this.municipality ? this.municipality.id : 0

    return this
  }

  toInterface(): IDistrict {
    return {
      id: this.id,
      name: this.name,
      municipalityID: this.municipalityID,
      status: this.status,
      weight: this.weight,
      municipality: this.municipality ? this.municipality.toInterface() : null,
    }
  }
}
