import { formatPersonID, PersonSimple } from '@/models/persons/PersonSimple'
import { Person } from '@/models/persons/Person'
import { contractToPersonRedirectHref, personViewHref } from '@/models/persons/hrefs'
import { formatContractID } from '@/models/persons/Contract'

export function personNameWithContactsHtml(person: PersonSimple | Person | null): string {
  if (!person) {
    return ''
  }

  let contacts = ''
  if (person instanceof Person) {
    contacts =
      '<br>' +
      person.contacts
        .map((v) => {
          return v.nameWithNoteHtml
        })
        .join('<br>')
  }

  return person.fullName + contacts
}

export function contractToPersonRedirectHtml(
  contractID: number,
  target: '_self' | '_blank' = '_self'
): string {
  return contractID
    ? `<a href="${contractToPersonRedirectHref(contractID)}" target="${target}">
${formatContractID(contractID)}</a>`
    : ''
}

export function toPersonBoardHtml(personID: number, target: '_self' | '_blank' = '_self'): string {
  return personID
    ? `<a href="${personViewHref(personID)}" target="${target}">${formatPersonID(personID)}</a>`
    : ''
}
