import { CommonModel, IModel } from '@/models/Model'
import { MainStore } from '@/store/MainStore'
import { geoApi } from '@/api/geo-api'
import { coloringName } from '@/models/geo/utils'
import { GeoStatus, isValid, isVerified, statusHtml } from '@/models/geo/constants'

export interface ICountry extends IModel {
  id: number
  name: string
  code: string
  idd: string
  status: GeoStatus
  weight: number
}

export class Country extends CommonModel implements ICountry {
  id = 0
  name = ''
  code = ''
  idd = ''
  status: GeoStatus = 0
  weight = 0

  get isValidForm(): boolean {
    return Boolean(this.id) || Boolean(this.name && this.code)
  }

  get isValid(): boolean {
    return isValid(this.status)
  }

  get isVerified(): boolean {
    return isVerified(this.status)
  }

  get statusHtml(): string {
    return statusHtml(this.status)
  }

  get nameStr(): string {
    return this.name
  }

  get titleHtml(): string {
    return coloringName(this)
  }

  constructor(src: ICountry | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: ICountry) {
    Object.assign(this, src)
    this.initModel(src)
  }

  async save(): Promise<boolean> {
    try {
      return this.id ? await this.update() : await this.add()
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async add(): Promise<boolean> {
    try {
      this.init(await geoApi.country.add(this.toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async update(): Promise<boolean> {
    try {
      this.init(await geoApi.country.update(this.toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async delete(): Promise<boolean> {
    if (!confirm(`Are you sure you want to delete country?`)) {
      return false
    }

    try {
      await geoApi.country.delete(this.id)
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async approve(): Promise<boolean> {
    if (!confirm(`Are you sure you want to confirm the entry?`)) {
      return false
    }

    try {
      this.init(await geoApi.country.approve({ id: this.id }))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async reject(): Promise<boolean> {
    if (!confirm(`Are you sure you want to reject the entry?`)) {
      return false
    }

    try {
      this.init(await geoApi.country.reject({ id: this.id }))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  toInterface(): ICountry {
    return {
      id: this.id,
      name: this.name,
      code: this.code,
      idd: this.idd,
      status: this.status,
      weight: this.weight,
    }
  }
}
