
import { Component, Vue } from 'vue-property-decorator'
import { MainStore } from '@/store/MainStore'
import AutoComplete from '@/components/common/AutoComplete.vue'
import { usersApi } from '@/api/users-api'
import { User } from '@/models/user/user'
import { AutoUser } from '@/models/autocomplete/AutoUser'
import { CAN_LOGIN_AS_USER } from '@/permissions/users_server'

@Component({
  components: { AutoComplete },
})
export default class LoginAsUser extends Vue {
  autoUser = new AutoUser()
  user: User | null = null

  get showComponent(): boolean {
    return MainStore.isUserCan(CAN_LOGIN_AS_USER)
  }

  async send(): Promise<void> {
    if (!this.user) {
      return
    }

    try {
      const user = new User(await usersApi.auth.loginAsUser({ id: this.user.personID }))
      await MainStore.userLogin(user)
    } catch (e) {
      await MainStore.addError(e as Error)
    }
  }
}
