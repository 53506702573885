import { MainStore } from '@/store/MainStore'
import { usersApi } from '@/api/users-api'
import { User } from '@/models/user/user'

export interface IAuth {
  personID: number
  username: string
  password: string
  newPassword: string
}

export class Auth implements IAuth {
  personID = 0
  username = ''
  password = ''
  newPassword = ''

  static async logoutCurrentUser(): Promise<boolean> {
    const auth = new Auth()
    auth.personID = MainStore.getPersonID()

    return auth.logout()
  }

  async logout(): Promise<boolean> {
    try {
      await usersApi.auth.logout()
      await MainStore.userLogout()
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async createUser(): Promise<User> {
    return new User(await usersApi.auth.createUser(this))
  }

  async updateUser(): Promise<User> {
    return new User(await usersApi.auth.updateUser(this))
  }

  async login(): Promise<User> {
    const user = new User(await usersApi.auth.login(this))
    await MainStore.userLogin(user)

    return user
  }

  async changePassword(): Promise<boolean> {
    try {
      await usersApi.auth.changePassword(this)
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }
}
