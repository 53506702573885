import { PermissionGroup } from '@/utils/permissions'
import servicesServer from '@/permissions/services_server'

const prefix = 'services'

export const CAN_ACCESS_SERVICES = prefix + '-access_module'

const services: PermissionGroup = {
  name: 'Services Module',
  permissions: [
    {
      value: CAN_ACCESS_SERVICES,
      description: CAN_ACCESS_SERVICES,
    },
    ...servicesServer,
  ],
}

export default services
