
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class BTooltip extends Vue {
  @Prop({ type: String, default: '' }) readonly title?: string
  @Prop({ type: String, default: '' }) readonly extraClasses?: string

  readonly tooltipAfter = 5

  top = 0
  left = 0

  tooltipWidth = 0
  tooltipHeight = 0
  wrapperWidth = 0
  wrapperHeight = 0

  get tooltipStyle(): string {
    return 'top: ' + this.top + 'px; left: ' + this.left + 'px;'
  }

  mounted(): void {
    this.init()
  }

  updated(): void {
    this.init()
  }

  init(): void {
    const tooltip = this.$refs.tooltip as HTMLElement
    const wrapper = this.$refs.wrapper as HTMLElement

    this.tooltipWidth = tooltip.clientWidth
    this.tooltipHeight = tooltip.clientHeight

    this.wrapperWidth = wrapper.clientWidth
    this.wrapperHeight = wrapper.clientHeight

    this.top = -(this.tooltipHeight + this.tooltipAfter)

    this.left =
      this.wrapperWidth > this.tooltipWidth
        ? (this.wrapperWidth - this.tooltipWidth) / 2
        : -(this.tooltipWidth / 2 - this.wrapperWidth / 2)

    // const rect = tooltip.getBoundingClientRect()
    // const windowWidth = window.innerWidth
  }
}
