// This file is generated automatically, do not make manual changes to it.
// generating script: shell/generate_permissions.go

export const CAN_DELETE_PRODUCT = 'products-CanDeleteProduct'
export const CAN_DELETE_PRODUCT_GROUP = 'CanDeleteProductGroup'
export const CAN_EDIT_PRODUCT = 'products-CanEditProduct'
export const CAN_EDIT_PRODUCT_GROUP = 'CanEditProductGroup'
export const CAN_VIEW_PRODUCT = 'products-CanViewProduct'
export const CAN_VIEW_PRODUCT_GROUP = 'CanViewProductGroup'

const productsServer = [
  {
    value: CAN_DELETE_PRODUCT,
    description: CAN_DELETE_PRODUCT,
  },
  {
    value: CAN_DELETE_PRODUCT_GROUP,
    description: CAN_DELETE_PRODUCT_GROUP,
  },
  {
    value: CAN_EDIT_PRODUCT,
    description: CAN_EDIT_PRODUCT,
  },
  {
    value: CAN_EDIT_PRODUCT_GROUP,
    description: CAN_EDIT_PRODUCT_GROUP,
  },
  {
    value: CAN_VIEW_PRODUCT,
    description: CAN_VIEW_PRODUCT,
  },
  {
    value: CAN_VIEW_PRODUCT_GROUP,
    description: CAN_VIEW_PRODUCT_GROUP,
  },
]

export default productsServer
