export const CHART_COLORS = {
  Red: {
    main: 'rgba(255, 99, 132, 1)',
    gradient: 'rgba(255, 99, 132, 0.4)',
    color1: 'rgb(255,0,0)',
  },
  Blue: {
    main: 'rgba(54, 162, 235, 1)',
    gradient: 'rgba(54, 162, 235, 0.4)',
    color1: 'rgb(0,164,255)',
  },
  Yellow: {
    main: 'rgba(255, 206, 86, 1)',
    gradient: 'rgba(255, 206, 86, 0.4)',
    color1: 'rgb(255,172,0)',
  },
  Green: {
    main: 'rgba(75, 192, 192, 1)',
    gradient: 'rgba(75, 192, 192, 0.4)',
    color1: 'rgb(0,255,255)',
  },
  Purple: {
    main: 'rgba(153, 102, 255, 1)',
    gradient: 'rgba(153, 102, 255, 0.4)',
    color1: 'rgb(85,0,255)',
  },
  Orange: {
    main: 'rgba(255, 159, 64, 1)',
    gradient: 'rgba(255, 159, 64, 0.6)',
    color1: 'rgb(255,123,0)',
  },
}

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
