import { ChartConfiguration } from 'chart.js'
import { chartCustomers } from '@/components/dashboards/models/chartCustomers'
import { chartContracts } from '@/components/dashboards/models/chartContracts'
import { chartTickets } from '@/components/dashboards/models/chartTickets'
import { chartInstallations } from '@/components/dashboards/models/chartInstallations'

type annualReport = {
  [key: number]: monthlyReport
}
type statusesType = { [key in number]: number }

interface monthlyReport {
  created: number
  canceled: number
  statuses: statusesType
}

export class AnnualReports {
  customers: annualReport = {}
  contracts: annualReport = {}
  tickets: annualReport = {}
  installations: annualReport = {}

  constructor(src: AnnualReports | null = null) {
    if (src) {
      this.init(src)
    }
  }

  private init(src: AnnualReports) {
    Object.assign(this, src)
  }

  get chartCustomers(): ChartConfiguration {
    return chartCustomers(new AnnualReport(this.customers))
  }

  get chartContracts(): ChartConfiguration {
    return chartContracts(new AnnualReport(this.contracts))
  }

  get chartTickets(): ChartConfiguration {
    return chartTickets(new AnnualReport(this.tickets))
  }

  get chartInstallations(): ChartConfiguration {
    return chartInstallations(new AnnualReport(this.installations))
  }
}

export class AnnualReport {
  created = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  canceled = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  statuses = new Map<string, statusesType>()

  constructor(src: annualReport | null = null) {
    if (src) {
      this.init(src)
    }
  }

  private init(src: annualReport) {
    this.created = []
    this.canceled = []
    this.canceled = []

    Object.entries(src).forEach((k) => {
      this.created.push(k[1].created)
      this.canceled.push(k[1].canceled)
      this.statuses.set(k[0], k[1].statuses)
    })
  }

  withStatuses(statuses: number[]): number[] {
    const res: number[] = []
    this.statuses.forEach((ss: statusesType) => {
      let count = 0
      statuses.forEach((status) => {
        ss[status] && (count += ss[status])
      })
      res.push(count)
    })

    return res
  }
}
