
import { Component, Prop, Vue } from 'vue-property-decorator'
import { NavItem, NavSection } from '@/components/app/nav/types'
import { MainStore } from '@/store/MainStore'
import NavPart from '@/components/app/nav/NavPart.vue'
import { CAN_ACCESS_USERS } from '@/permissions/users'
import { ROUTE_ROLES, ROUTE_USERS } from '@/router/users'
import { EVENT_INPUT } from '@/utils/event-constants'
import { ROUTE_API_TOKENS } from '@/router/integrations'
import { CAN_VIEW_API_TOKEN } from '@/permissions/integrations_server'
import { ROUTE_EVENT_LOG } from '@/router/searcher'
import { CAN_EVENT_LOG_VIEW } from '@/permissions/search_server'

@Component({
  components: { NavPart },
})
export default class NavPartUsers extends Vue {
  @Prop({ type: Boolean }) isOpen!: boolean

  get isShow(): boolean {
    return MainStore.isUserCan(CAN_ACCESS_USERS)
  }

  section = new NavSection('Users', 'fas fa-user', ROUTE_USERS, [
    new NavItem('Users', ROUTE_USERS, 'fas fa-user-edit'),
    new NavItem('Roles', ROUTE_ROLES, 'fas fa-user-tag'),
    new NavItem('API Keys', ROUTE_API_TOKENS, 'fas fa-key').withPerms(CAN_VIEW_API_TOKEN),
    new NavItem('Events', ROUTE_EVENT_LOG, 'fas fa-boxes').withPerms(CAN_EVENT_LOG_VIEW),
  ])
  created(): void {
    this.$emit(EVENT_INPUT, this.section.items)
  }
}
