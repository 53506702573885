import Vue from 'vue'
import Router from 'vue-router'
import ErrorPage from '@/views/ErrorPage.vue'
import personsRoutes from './persons'
import geoRoutes from './geo'
import tariffRoutes from '@/router/tariffs'
import serviceRoutes from '@/router/services'
import stockRoutes from '@/router/stock'
import discountsRoutes from '@/router/discounts'
import paymentRoutes from '@/router/payments'
import subscriptionRoutes from '@/router/subscriptions'
import ticketRoutes from '@/router/tickets'
import searcherRoutes from '@/router/searcher'
import usersRoutes from '@/router/users'
import equipmentSettingsRoutes from '@/router/equipment-settings'
import permissionGuard from '@/router/guards/permission-guard'
import tagsRoutes from '@/router/tags'
import PasswordChanger from '@/components/users/PasswordChanger.vue'
import Dashboard from '@/components/dashboards/Dashboard.vue'
import productRoutes from '@/router/products'
import commissionsRoutes from '@/router/commissions'
import internetRoutes from '@/router/internet'
import { CAN_ACCESS_DASHBOARD } from '@/permissions/dashboard'
import cdrRoutes from '@/router/cdr'
import LoginAsUser from '@/components/users/LoginAsUser.vue'
import { CAN_LOGIN_AS_USER } from '@/permissions/users_server'
import integrationsRoutes from '@/router/integrations'

Vue.use(Router)

export const ROUTE_LOGIN_AS_USER = 'ROUTE_LOGIN_AS_USER'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const ERROR_PAGE = 'ERROR_PAGE'
export const HOME_PAGE = 'HOME_PAGE'

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: HOME_PAGE,
      meta: { permission: CAN_ACCESS_DASHBOARD },
      component: Dashboard,
    },
    {
      path: '/change-password',
      name: CHANGE_PASSWORD,
      component: PasswordChanger,
    },
    {
      path: `/login-as-user`,
      name: ROUTE_LOGIN_AS_USER,
      meta: { permission: CAN_LOGIN_AS_USER },
      component: LoginAsUser,
    },
    ...usersRoutes,
    ...searcherRoutes,
    ...personsRoutes,
    ...geoRoutes,
    ...tariffRoutes,
    ...serviceRoutes,
    ...stockRoutes,
    ...discountsRoutes,
    ...paymentRoutes,
    ...subscriptionRoutes,
    ...ticketRoutes,
    ...equipmentSettingsRoutes,
    ...tagsRoutes,
    ...productRoutes,
    ...commissionsRoutes,
    ...internetRoutes,
    ...cdrRoutes,
    ...integrationsRoutes,
    {
      name: ERROR_PAGE,
      path: '/:pathMatch(.*)*',
      component: ErrorPage,
    },
  ],
})

router.beforeEach(permissionGuard)

export default router
