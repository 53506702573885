import { ObjectParams } from '@/models/types'
import { MainStore } from '@/store/MainStore'

export class NavSection {
  extraName = ''
  extraRoute?: ObjectParams

  constructor(
    public name = '',
    public icon = '',
    public routeName = '',
    public items: NavItem[] = []
  ) {}
}

export class NavItem {
  private perms: string[] = []
  extraName = ''
  extraRoute?: ObjectParams

  constructor(
    public name = '',
    public routeName = '',
    public icon = '',
    public params: NavParam | undefined = undefined
  ) {}

  get routeTo(): ObjectParams {
    return {
      name: this.routeName,
      params: this.params ? { [this.params.name]: this.params.value } : {},
    }
  }

  get isUserCan(): boolean {
    return MainStore.isUserCanAll(...this.perms)
  }

  withPerms(perms: string | string[]): NavItem {
    this.perms = typeof perms == 'string' ? [perms] : [...perms]
    return this
  }
}

export type NavParam = {
  name: string
  value: string
}

export function badge(count: number): string {
  return count ? `<span class="badge badge-pill badge-danger fs-0-75rem">${count}</span>` : ''
}
