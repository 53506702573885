import { PermissionGroup } from '@/utils/permissions'

const prefix = 'dashboard'

export const CAN_ACCESS_DASHBOARD = prefix + '_access_module'
export const CAN_DASHBOARD_BOXES = prefix + '_boxes'
export const CAN_DASHBOARD_CUSTOMERS = prefix + '_customers'

const paymentPrefix = 'payments_dashboard'
export const CAN_ACCESS_PAYMENTS_DASHBOARD = paymentPrefix + '_access_module'
export const CAN_ACCESS_PAYMENTS_BOXES = paymentPrefix + '_boxes'
export const CAN_ACCESS_PAYMENTS_CHARTS = paymentPrefix + '_charts'

const dashboards: PermissionGroup = {
  name: 'Dashboards',
  permissions: [
    {
      value: CAN_ACCESS_DASHBOARD,
      description: CAN_ACCESS_DASHBOARD,
    },
    {
      value: CAN_DASHBOARD_BOXES,
      description: CAN_DASHBOARD_BOXES,
    },
    {
      value: CAN_DASHBOARD_CUSTOMERS,
      description: CAN_DASHBOARD_CUSTOMERS,
    },
    {
      value: CAN_ACCESS_PAYMENTS_DASHBOARD,
      description: CAN_ACCESS_PAYMENTS_DASHBOARD,
    },
    {
      value: CAN_ACCESS_PAYMENTS_BOXES,
      description: CAN_ACCESS_PAYMENTS_BOXES,
    },
    {
      value: CAN_ACCESS_PAYMENTS_CHARTS,
      description: CAN_ACCESS_PAYMENTS_CHARTS,
    },
  ],
}

export default dashboards
