import { MainStore } from '@/store/MainStore'
import { personsApi } from '@/api/persons-api'
import { List } from '@/models/List'
import { ObjectParams } from '@/models/types'
import { Contract } from '@/models/persons/Contract'
import { MAX_LIMIT } from '@/api/api'

export class ContractGet {
  static async byID(id: number): Promise<Contract> {
    let item = new Contract()
    try {
      item = new Contract(await personsApi.contract.getByID(id))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return item
  }

  static async byIDs(ids: number[] | string[], params: ObjectParams = {}): Promise<Contract[]> {
    let items: Contract[] = []
    try {
      items = (await personsApi.contract.getByIDs(ids, params)).map((v) => new Contract(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return items
  }

  static async list(params: ObjectParams): Promise<List<Contract>> {
    const list: List<Contract> = { count: 0, items: [] }
    try {
      const l = await personsApi.contract.list(params)
      list.count = l.count
      list.items = l.items.map((v) => new Contract(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return list
  }

  static async byAddress(addressID: number): Promise<Contract | null> {
    const items = (await ContractGet.list({ addressID: addressID, limit: 1 })).items

    return items.length ? items[0] : null
  }

  static async byPerson(personID: number): Promise<Contract[]> {
    return (await ContractGet.list({ personID: personID, limit: MAX_LIMIT })).items
  }

  static async mapByIDs(
    ids: number[] | string[],
    params: ObjectParams = {}
  ): Promise<Map<number, Contract>> {
    const contracts = await ContractGet.byIDs(ids, params)

    const pMap = new Map<number, Contract>()
    contracts.forEach((v) => pMap.set(v.id, new Contract(v)))

    return pMap
  }

  static async stories(id: number, params: ObjectParams = {}): Promise<List<Contract>> {
    const list: List<Contract> = { count: 0, items: [] }
    try {
      const l = await personsApi.contract.stories(id, { limit: MAX_LIMIT, ...params })
      list.count = l.count
      list.items = l.items.map((v) => new Contract(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return list
  }
}
