import { RouteConfig } from 'vue-router/types/router'
import { CAN_ACCESS_PERSONS } from '@/permissions/persons'
import { ROUTER_PARAM_ID } from '@/utils/constants'
import { CAN_EDIT_WORKFLOW, CAN_VIEW_WORKFLOW } from '@/permissions/persons_server'

export const ROUTE_PERSONS = 'ROUTE_PERSONS'

export const ROUTE_PERSON_WHENCES = 'ROUTE_PERSON_WHENCES'
export const ROUTE_PERSON_WHENCE_VIEW = 'ROUTE_PERSON_WHENCE_VIEW'
export const ROUTE_PERSON_WHENCE_FORM = 'ROUTE_PERSON_WHENCE_FORM'

export const ROUTE_WORKFLOWS = 'ROUTE_WORKFLOWS'
export const ROUTE_WORKFLOWS_FORM = 'ROUTE_WORKFLOWS_FORM'
export const ROUTE_WORKFLOWS_CALENDAR = 'ROUTE_WORKFLOWS_CALENDAR'

export const ROUTE_CUSTOMER_BOARD = 'ROUTE_CUSTOMER_BOARD'
export const ROUTE_PERSONS_EDIT = 'ROUTE_PERSONS_EDIT'

export const ROUTE_PERSONS_CONTRACTS = 'ROUTE_PERSONS_CONTRACTS'
export const ROUTE_CONTRACT_REDIRECT_TO_PERSON = 'ROUTE_CONTRACT_REDIRECT_TO_PERSON'
export const ROUTE_PERSONS_WORKFLOWS = 'ROUTE_PERSONS_WORKFLOWS'

export const ROUTE_HOLIDAY_REPORT = 'ROUTE_HOLIDAY_REPORT'

const persons: RouteConfig[] = [
  {
    name: ROUTE_PERSON_WHENCES,
    path: '/person-whences',
    meta: { permission: CAN_ACCESS_PERSONS },
    component: () =>
      import(/* webpackChunkName: "whences" */ '../components/persons/PersonWhenceList.vue'),
  },
  {
    name: ROUTE_PERSON_WHENCE_VIEW,
    path: `/person-whences/:${ROUTER_PARAM_ID}(\\d+)`,
    meta: { permission: CAN_ACCESS_PERSONS },
    component: () =>
      import(/* webpackChunkName: "whences" */ '../components/persons/PersonWhenceView.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
  {
    name: ROUTE_PERSON_WHENCE_FORM,
    path: `/person-whences/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: CAN_ACCESS_PERSONS },
    component: () =>
      import(/* webpackChunkName: "whences" */ '../components/persons/PersonWhenceEdit.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },

  {
    name: ROUTE_WORKFLOWS,
    path: '/workflows',
    meta: { permission: CAN_ACCESS_PERSONS },
    component: () =>
      import(/* webpackChunkName: "workflows" */ '../components/persons/WorkflowList.vue'),
  },
  {
    name: ROUTE_WORKFLOWS_FORM,
    path: `/workflows/:${ROUTER_PARAM_ID}(\\d+)?/form`,
    meta: { permission: CAN_ACCESS_PERSONS },
    component: () =>
      import(/* webpackChunkName: "workflows" */ '../components/persons/WorkflowEdit.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
  {
    name: ROUTE_WORKFLOWS_CALENDAR,
    path: '/holiday-calendar',
    meta: { permission: [CAN_ACCESS_PERSONS, CAN_VIEW_WORKFLOW] },
    component: () =>
      import(/* webpackChunkName: "workflows" */ '../components/calendars/HolidayCalendar.vue'),
  },

  {
    name: ROUTE_PERSONS,
    path: '/persons',
    meta: { permission: CAN_ACCESS_PERSONS },
    component: () =>
      import(/* webpackChunkName: "persons" */ '../components/persons/PersonList.vue'),
  },
  {
    name: ROUTE_PERSONS_EDIT,
    path: '/edit-persons/:' + ROUTER_PARAM_ID + '(\\d+)?',
    meta: { permission: CAN_ACCESS_PERSONS },
    component: () =>
      import(/* webpackChunkName: "persons" */ '../components/persons/edit/EditPerson.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },
  {
    name: ROUTE_CUSTOMER_BOARD,
    path: '/customer-board/:' + ROUTER_PARAM_ID + '(\\d+)',
    meta: { permission: CAN_ACCESS_PERSONS },
    component: () =>
      import(/* webpackChunkName: "persons" */ '../components/persons/board/CustomerBoard.vue'),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },

  {
    name: ROUTE_CONTRACT_REDIRECT_TO_PERSON,
    path: '/contract-to-person-redirect/:' + ROUTER_PARAM_ID + '(\\d+)',
    meta: { permission: CAN_ACCESS_PERSONS },
    component: () =>
      import(
        /* webpackChunkName: "persons" */ '../components/persons/ContractToPersonRedirect.vue'
      ),
    props: (route) => ({
      [ROUTER_PARAM_ID]: Number(route.params[ROUTER_PARAM_ID]),
    }),
  },

  {
    name: ROUTE_PERSONS_CONTRACTS,
    path: '/persons/contracts',
    meta: { permission: CAN_ACCESS_PERSONS },
    component: () =>
      import(/* webpackChunkName: "persons" */ '../components/persons/ContractList.vue'),
  },

  {
    name: ROUTE_PERSONS_WORKFLOWS,
    path: '/persons/workflows-calendar',
    meta: { permission: [CAN_ACCESS_PERSONS, CAN_EDIT_WORKFLOW] },
    component: () =>
      import(/* webpackChunkName: "persons" */ '../components/persons/ContractList.vue'),
  },

  {
    name: ROUTE_HOLIDAY_REPORT,
    path: '/persons/holiday-report',
    meta: { permission: [CAN_ACCESS_PERSONS, CAN_EDIT_WORKFLOW] },
    component: () =>
      import(/* webpackChunkName: "persons" */ '../components/persons/HolidayReport.vue'),
  },
]

export default persons
