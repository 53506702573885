import { dateOrNull, dateOrNullUTC, formatDateTime, withTimezone } from '@/utils'
import { PersonSimple } from '@/models/persons/PersonSimple'

export const SYSTEM_USER = -10

export interface IModel {
  changedBy?: number
  appActionID?: string
  prevActionID?: string
  wasChanged?: boolean

  // version fields
  rowStart?: Date
  rowEnd?: Date
}

export class CommonModel implements IModel {
  changedBy?: number
  appActionID = ''
  prevActionID = ''
  wasChanged = false

  // version fields
  rowStart?: Date
  rowEnd?: Date

  // after load
  whoChanged: PersonSimple | null = null

  initModel(src: IModel): void {
    this.rowStart = src.rowStart ? new Date(src.rowStart) : undefined
    this.rowEnd = src.rowEnd ? new Date(src.rowEnd) : undefined
    this.prevActionID = this.appActionID
  }

  get rowStartHtml(): string {
    return this.rowStart ? formatDateTime(withTimezone(this.rowStart)) : ''
  }

  get rowEndHtml(): string {
    return this.rowEnd ? formatDateTime(withTimezone(this.rowEnd)) : ''
  }

  get whoChangedName(): string {
    if (this.changedBy == SYSTEM_USER) {
      return 'SYSTEM'
    }

    return this.whoChanged ? this.whoChanged.fullName : ''
  }

  dateOrNull(date: Date | string | null | undefined): Date | null {
    return dateOrNull(date)
  }

  dateOrNullUTC(date: Date | string | null | undefined): Date | null {
    return dateOrNullUTC(date)
  }

  modelToInterface(): IModel {
    return {
      prevActionID: this.prevActionID,
    }
  }
}
