import { PermissionGroup } from '@/utils/permissions'
import personsServer from '@/permissions/persons_server'

const prefix = 'persons'

export const CAN_ACCESS_PERSONS = prefix + '-access_module'

const persons: PermissionGroup = {
  name: 'Persons Module',
  permissions: [
    {
      value: CAN_ACCESS_PERSONS,
      description: CAN_ACCESS_PERSONS,
    },
    ...personsServer,
  ],
}

export default persons
