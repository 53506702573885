
import { Component, Prop, Vue } from 'vue-property-decorator'
import { NavItem, NavSection } from '@/components/app/nav/types'
import { MainStore } from '@/store/MainStore'
import NavPart from '@/components/app/nav/NavPart.vue'
import { CAN_ACCESS_CORE } from '@/permissions/core'
import {
  ROUTE_ACCOUNT_BLACK_LIST,
  ROUTE_PAYMENT_INVOICES_EMAILS,
  ROUTE_PAYMENTS,
  ROUTE_PAYMENTS_BANK_FAILS,
  ROUTE_PAYMENTS_BANKS,
  ROUTE_PAYMENTS_COMPANIES,
  ROUTE_PAYMENTS_DASHBOARDS,
  ROUTE_PAYMENTS_PAYMENT_INVOICES,
  ROUTE_PAYMENTS_PAYMENT_INVOICES_EXPORT,
  ROUTE_PAYMENTS_PAYMENT_INVOICES_PRODUCTS,
  ROUTE_SEPA_PAY_COUNTER,
} from '@/router/payments'
import { ROUTE_COMMISSIONS_INSTALLERS } from '@/router/commissions'
import { ROUTE_PRODUCT_GROUPS, ROUTE_PRODUCTS } from '@/router/products'
import { ROUTE_DISCOUNTS } from '@/router/discounts'
import { CAN_VIEW_ACCOUNT_BLACK_LIST, CAN_VIEW_BANK_FAIL_REASONS } from '@/permissions/core_server'
import { EVENT_INPUT } from '@/utils/event-constants'
import { CAN_ACCESS_PAYMENTS_DASHBOARD } from '@/permissions/dashboard'
@Component({
  components: { NavPart },
})
export default class NavPartPayments extends Vue {
  @Prop({ type: Boolean }) isOpen!: boolean

  get isShow(): boolean {
    return MainStore.isUserCan(CAN_ACCESS_CORE)
  }

  mainRoute = MainStore.isUserCan(CAN_ACCESS_PAYMENTS_DASHBOARD)
    ? ROUTE_PAYMENTS_DASHBOARDS
    : ROUTE_PAYMENTS

  section = new NavSection('Payments', 'far fa-credit-card', this.mainRoute, [
    new NavItem('Dashboards', ROUTE_PAYMENTS_DASHBOARDS, 'fas fa-chart-line').withPerms(
      CAN_ACCESS_PAYMENTS_DASHBOARD
    ),
    new NavItem('Payments', ROUTE_PAYMENTS, 'far fa-money-bill-alt'),
    new NavItem('Invoices', ROUTE_PAYMENTS_PAYMENT_INVOICES, 'fas fa-money-check-alt'),
    new NavItem('Banks', ROUTE_PAYMENTS_BANKS, 'fas fa-university'),
    new NavItem(
      'Bank fail reasons',
      ROUTE_PAYMENTS_BANK_FAILS,
      'fas fa-exclamation-triangle'
    ).withPerms(CAN_VIEW_BANK_FAIL_REASONS),
    new NavItem('Companies', ROUTE_PAYMENTS_COMPANIES, 'fas fa-laptop-house'),
    new NavItem('Installers commissions', ROUTE_COMMISSIONS_INSTALLERS, 'fas fa-percent'),
    new NavItem('Products', ROUTE_PRODUCTS, 'fab fa-product-hunt'),
    new NavItem('Product groups', ROUTE_PRODUCT_GROUPS, 'fas fa-layer-group'),
    new NavItem('Discounts', ROUTE_DISCOUNTS, 'fas fa-percentage'),
    new NavItem('Sepa counters', ROUTE_SEPA_PAY_COUNTER, 'fas fa-donate'),
    new NavItem('Export invoices', ROUTE_PAYMENTS_PAYMENT_INVOICES_EXPORT, 'fas fa-file-export'),
    new NavItem('Invoices products', ROUTE_PAYMENTS_PAYMENT_INVOICES_PRODUCTS, 'fab fa-r-project'),
    new NavItem('Invoices emails', ROUTE_PAYMENT_INVOICES_EMAILS, 'fas fa-mail-bulk'),
    new NavItem(
      'Account black list',
      ROUTE_ACCOUNT_BLACK_LIST,
      'fas fa-exclamation-circle'
    ).withPerms(CAN_VIEW_ACCOUNT_BLACK_LIST),
  ])

  created(): void {
    this.$emit(EVENT_INPUT, this.section.items)
  }
}
