
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { uniqueStr } from '@/utils'
import Chart from 'chart.js/auto'
import { ChartConfiguration, ChartItem } from 'chart.js'

@Component
export default class DashboardChart extends Vue {
  @Prop({ type: Object, required: true }) readonly config!: ChartConfiguration

  id = uniqueStr()
  chart: Chart | null = null

  mounted(): void {
    this.reloadData()
  }

  @Watch('config')
  reloadData(): void {
    const ctx = document.getElementById(this.id)

    if (ctx) {
      ctx.style.height = '360px'
      if (this.chart) {
        this.chart.destroy()
      }

      this.chart = new Chart(ctx as ChartItem, this.config)
    }
  }
}
