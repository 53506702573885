// This file is generated automatically, do not make manual changes to it.
// generating script: shell/generate_permissions.go

export const CAN_ADD_SERVICE = 'services-CanAddService'
export const CAN_DELETE_SERVICE = 'services-CanDeleteService'
export const CAN_EDIT_SERVICE = 'services-CanEditService'
export const CAN_EDIT_SERVICE_CRED = 'services-CanEditServiceCred'
export const CAN_EDIT_SERVICE_SETTINGS = 'services-CanEditServiceSettings'
export const CAN_VIEW_SERVICE = 'services-CanViewService'
export const CAN_VIEW_SERVICE_CRED = 'services-CanViewServiceCred'
export const CAN_VIEW_SERVICE_SETTINGS = 'services-CanViewServiceSettings'

const servicesServer = [
  {
    value: CAN_ADD_SERVICE,
    description: CAN_ADD_SERVICE,
  },
  {
    value: CAN_DELETE_SERVICE,
    description: CAN_DELETE_SERVICE,
  },
  {
    value: CAN_EDIT_SERVICE,
    description: CAN_EDIT_SERVICE,
  },
  {
    value: CAN_EDIT_SERVICE_CRED,
    description: CAN_EDIT_SERVICE_CRED,
  },
  {
    value: CAN_EDIT_SERVICE_SETTINGS,
    description: CAN_EDIT_SERVICE_SETTINGS,
  },
  {
    value: CAN_VIEW_SERVICE,
    description: CAN_VIEW_SERVICE,
  },
  {
    value: CAN_VIEW_SERVICE_CRED,
    description: CAN_VIEW_SERVICE_CRED,
  },
  {
    value: CAN_VIEW_SERVICE_SETTINGS,
    description: CAN_VIEW_SERVICE_SETTINGS,
  },
]

export default servicesServer
