import { ActionTree } from 'vuex'
import { GeoState } from '@/store/modules/geo/state'
import { MainStore } from '@/store/MainStore'
import { CAN_GEO_EDIT } from '@/permissions/geo_server'
import {
  M_GEO_SET_COMMUNITIES_INVALID_COUNT,
  M_GEO_SET_COUNTRY_INVALID_COUNT,
  M_GEO_SET_DISTRICTS_INVALID_COUNT,
  M_GEO_SET_MUNICIPALITIES_INVALID_COUNT,
  M_GEO_SET_PROVINCES_INVALID_COUNT,
  M_GEO_SET_STREETS_INVALID_COUNT,
  M_GEO_SET_URBANIZATIONS_INVALID_COUNT,
  M_PERSON_ADDRESSES_INVALID_COUNT,
} from '@/store/modules/geo/mutations'
import { RootState } from '@/store'
import { ProvinceGet } from '@/models/geo/ProvinceGet'
import { UrbanizationGet } from '@/models/geo/UrbanizationGet'
import { StreetGet } from '@/models/geo/StreetGet'
import { DistrictGet } from '@/models/geo/DistrictGet'
import { CommunityGet } from '@/models/geo/CommunityGet'
import { MunicipalityGet } from '@/models/geo/MunicipalityGet'
import { CountryGet } from '@/models/geo/CountryGet'
import { AddressGet } from '@/models/persons/AddressGet'
import { GeoStatus } from '@/models/geo/constants'

export const A_GEO_INIT = 'A_GEO_INIT'
export const A_GEO_INIT_ALL_ADDRESSES_PARTS = 'A_GEO_INIT_ALL_ADDRESSES_PARTS'
export const A_PERSON_ADDRESSES_INVALID_COUNT = 'A_PERSON_ADDRESSES_INVALID_COUNT'
export const A_GEO_SET_COUNTRY_INVALID_COUNT = 'A_GEO_SET_COUNTRY_INVALID_COUNT'
export const A_GEO_SET_PROVINCES_INVALID_COUNT = 'A_GEO_SET_PROVINCES_INVALID_COUNT'
export const A_GEO_SET_MUNICIPALITIES_INVALID_COUNT = 'A_GEO_SET_MUNICIPALITIES_INVALID_COUNT'
export const A_GEO_SET_COMMUNITIES_INVALID_COUNT = 'A_GEO_SET_COMMUNITIES_INVALID_COUNT'
export const A_GEO_SET_DISTRICTS_INVALID_COUNT = 'A_GEO_SET_DISTRICTS_INVALID_COUNT'
export const A_GEO_SET_STREETS_INVALID_COUNT = 'A_GEO_SET_STREETS_INVALID_COUNT'
export const A_GEO_SET_URBANIZATIONS_INVALID_COUNT = 'A_GEO_SET_URBANIZATIONS_INVALID_COUNT'

const countParams = { status: GeoStatus.New }

const actions: ActionTree<GeoState, RootState> = {
  [A_GEO_INIT]: async ({ dispatch }) => {
    if (!MainStore.isUserCan(CAN_GEO_EDIT)) {
      return
    }

    await dispatch(A_GEO_INIT_ALL_ADDRESSES_PARTS)
  },

  [A_GEO_INIT_ALL_ADDRESSES_PARTS]: async ({ dispatch }) => {
    await Promise.all([
      dispatch(A_PERSON_ADDRESSES_INVALID_COUNT),
      dispatch(A_GEO_SET_COUNTRY_INVALID_COUNT),
      dispatch(A_GEO_SET_PROVINCES_INVALID_COUNT),
      dispatch(A_GEO_SET_MUNICIPALITIES_INVALID_COUNT),
      dispatch(A_GEO_SET_COMMUNITIES_INVALID_COUNT),
      dispatch(A_GEO_SET_DISTRICTS_INVALID_COUNT),
      dispatch(A_GEO_SET_STREETS_INVALID_COUNT),
      dispatch(A_GEO_SET_URBANIZATIONS_INVALID_COUNT),
    ])
  },

  [A_PERSON_ADDRESSES_INVALID_COUNT]: async ({ commit }) => {
    const count = await AddressGet.count({ geoStatuses: GeoStatus.New })
    commit(M_PERSON_ADDRESSES_INVALID_COUNT, count)
  },

  [A_GEO_SET_COUNTRY_INVALID_COUNT]: async ({ commit }) => {
    const count = await CountryGet.count(countParams)
    commit(M_GEO_SET_COUNTRY_INVALID_COUNT, count)
  },

  [A_GEO_SET_PROVINCES_INVALID_COUNT]: async ({ commit }) => {
    const count = await ProvinceGet.count(countParams)
    commit(M_GEO_SET_PROVINCES_INVALID_COUNT, count)
  },

  [A_GEO_SET_MUNICIPALITIES_INVALID_COUNT]: async ({ commit }) => {
    const count = await MunicipalityGet.count(countParams)
    commit(M_GEO_SET_MUNICIPALITIES_INVALID_COUNT, count)
  },

  [A_GEO_SET_COMMUNITIES_INVALID_COUNT]: async ({ commit }) => {
    const count = await CommunityGet.count(countParams)
    commit(M_GEO_SET_COMMUNITIES_INVALID_COUNT, count)
  },

  [A_GEO_SET_DISTRICTS_INVALID_COUNT]: async ({ commit }) => {
    const count = await DistrictGet.count(countParams)
    commit(M_GEO_SET_DISTRICTS_INVALID_COUNT, count)
  },

  [A_GEO_SET_STREETS_INVALID_COUNT]: async ({ commit }) => {
    const count = await StreetGet.count(countParams)
    commit(M_GEO_SET_STREETS_INVALID_COUNT, count)
  },

  [A_GEO_SET_URBANIZATIONS_INVALID_COUNT]: async ({ commit }) => {
    const count = await UrbanizationGet.count(countParams)
    commit(M_GEO_SET_URBANIZATIONS_INVALID_COUNT, count)
  },
}

export default actions
