// This file is generated automatically, do not make manual changes to it.
// generating script: shell/generate_permissions.go

export const CAN_DELETE_ADDRESS = 'persons-CanDeleteAddress'
export const CAN_DELETE_BILLING_LINK = 'persons-CanDeleteBillingLink'
export const CAN_DELETE_CONTACT = 'persons-CanDeleteContact'
export const CAN_DELETE_DOCUMENT = 'persons-CanDeleteDocument'
export const CAN_DELETE_LANGUAGE = 'persons-CanDeleteLanguage'
export const CAN_DELETE_PERSON_WHENCE = 'persons-CanDeletePersonWhence'
export const CAN_DELETE_WORKFLOW = 'CanDeleteWorkflow'
export const CAN_EDIT_ADDRESS = 'persons-CanEditAddress'
export const CAN_EDIT_BILLING_LINK = 'persons-CanEditBillingLink'
export const CAN_EDIT_CONTACT = 'persons-CanEditContact'
export const CAN_EDIT_CONTRACT = 'persons-CanEditContract'
export const CAN_EDIT_DOCUMENT = 'persons-CanEditDocument'
export const CAN_EDIT_LANGUAGE = 'persons-CanEditLanguage'
export const CAN_EDIT_PERSON = 'persons-CanEditPerson'
export const CAN_EDIT_PERSON_WHENCE = 'persons-CanEditPersonWhence'
export const CAN_EDIT_WORKFLOW = 'CanEditWorkflow'
export const CAN_POPULATE_PERSON = 'persons-CanPopulatePerson'
export const CAN_VIEW_ADDRESS = 'persons-CanViewAddress'
export const CAN_VIEW_BILLING_LINK = 'persons-CanViewBillingLink'
export const CAN_VIEW_CONTACT = 'persons-CanViewContact'
export const CAN_VIEW_CONTRACT = 'persons-CanViewContract'
export const CAN_VIEW_DOCUMENT = 'persons-CanViewDocument'
export const CAN_VIEW_LANGUAGE = 'persons-CanViewLanguage'
export const CAN_VIEW_PERSON = 'persons-CanViewPerson'
export const CAN_VIEW_PERSON_WHENCE = 'persons-CanViewPersonWhence'
export const CAN_VIEW_WORKFLOW = 'CanViewWorkflow'

const personsServer = [
  {
    value: CAN_DELETE_ADDRESS,
    description: CAN_DELETE_ADDRESS,
  },
  {
    value: CAN_DELETE_BILLING_LINK,
    description: CAN_DELETE_BILLING_LINK,
  },
  {
    value: CAN_DELETE_CONTACT,
    description: CAN_DELETE_CONTACT,
  },
  {
    value: CAN_DELETE_DOCUMENT,
    description: CAN_DELETE_DOCUMENT,
  },
  {
    value: CAN_DELETE_LANGUAGE,
    description: CAN_DELETE_LANGUAGE,
  },
  {
    value: CAN_DELETE_PERSON_WHENCE,
    description: CAN_DELETE_PERSON_WHENCE,
  },
  {
    value: CAN_DELETE_WORKFLOW,
    description: CAN_DELETE_WORKFLOW,
  },
  {
    value: CAN_EDIT_ADDRESS,
    description: CAN_EDIT_ADDRESS,
  },
  {
    value: CAN_EDIT_BILLING_LINK,
    description: CAN_EDIT_BILLING_LINK,
  },
  {
    value: CAN_EDIT_CONTACT,
    description: CAN_EDIT_CONTACT,
  },
  {
    value: CAN_EDIT_CONTRACT,
    description: CAN_EDIT_CONTRACT,
  },
  {
    value: CAN_EDIT_DOCUMENT,
    description: CAN_EDIT_DOCUMENT,
  },
  {
    value: CAN_EDIT_LANGUAGE,
    description: CAN_EDIT_LANGUAGE,
  },
  {
    value: CAN_EDIT_PERSON,
    description: CAN_EDIT_PERSON,
  },
  {
    value: CAN_EDIT_PERSON_WHENCE,
    description: CAN_EDIT_PERSON_WHENCE,
  },
  {
    value: CAN_EDIT_WORKFLOW,
    description: CAN_EDIT_WORKFLOW,
  },
  {
    value: CAN_POPULATE_PERSON,
    description: CAN_POPULATE_PERSON,
  },
  {
    value: CAN_VIEW_ADDRESS,
    description: CAN_VIEW_ADDRESS,
  },
  {
    value: CAN_VIEW_BILLING_LINK,
    description: CAN_VIEW_BILLING_LINK,
  },
  {
    value: CAN_VIEW_CONTACT,
    description: CAN_VIEW_CONTACT,
  },
  {
    value: CAN_VIEW_CONTRACT,
    description: CAN_VIEW_CONTRACT,
  },
  {
    value: CAN_VIEW_DOCUMENT,
    description: CAN_VIEW_DOCUMENT,
  },
  {
    value: CAN_VIEW_LANGUAGE,
    description: CAN_VIEW_LANGUAGE,
  },
  {
    value: CAN_VIEW_PERSON,
    description: CAN_VIEW_PERSON,
  },
  {
    value: CAN_VIEW_PERSON_WHENCE,
    description: CAN_VIEW_PERSON_WHENCE,
  },
  {
    value: CAN_VIEW_WORKFLOW,
    description: CAN_VIEW_WORKFLOW,
  },
]

export default personsServer
