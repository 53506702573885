import { formatDate } from '@/utils'
import { Tag } from '@/models/tags/tag'
import { IPersonTag, PersonTag } from '@/models/tags/person_tag'
import { MainStore } from '@/store/MainStore'
import { PersonSimple } from '@/models/persons/PersonSimple'
import { tagsApi } from '@/api/tags-api'
import { List } from '@/models/List'
import { ObjectParams } from '@/models/types'
import { PersonSimpleGet } from '@/models/persons/PersonSimpleGet'

export interface IPersonTags {
  personID: number
  personTags: IPersonTag[]
}

export class PersonTags {
  personID = 0
  personTags: PersonTag[] = []

  // after load
  person: PersonSimple | null = null

  get personFullName(): string {
    return this.person ? this.person.fullName : ''
  }

  get htmlArray(): string[] {
    return this.personTags.map((v) => {
      let res = v.tag ? new Tag(v.tag).html : ''
      if (v.expiryDate) {
        res += ' exp.: ' + formatDate(v.expiryDate) + ';'
      }
      return res
    })
  }

  constructor(src: IPersonTags | null = null) {
    if (src) {
      this.init(src)
    }
  }

  private init(src: IPersonTags) {
    Object.assign(this, src)
    this.personTags = src.personTags ? src.personTags.map((v) => new PersonTag(v)) : []
  }

  async load(): Promise<PersonTags> {
    try {
      if (this.personID) {
        this.person = await PersonSimpleGet.byID(this.personID)
      }
    } catch (e) {
      await MainStore.addError(e as Error)
    }

    return this
  }

  static async loadList(list: List<PersonTags>): Promise<List<PersonTags>> {
    if (!list.count) return list

    const persons = await PersonSimpleGet.mapByIDs([
      ...new Set([...list.items.map((v) => v.personID).filter((v) => v > 0)]),
    ])

    list.items.forEach((v) => {
      v.person = persons.get(v.personID) || null
    })

    return list
  }

  static async list(params: ObjectParams): Promise<List<PersonTags>> {
    const list: List<PersonTags> = { count: 0, items: [] }
    try {
      const l = await tagsApi.personTags.list(params)
      list.count = l.count
      list.items = l.items.map((v) => new PersonTags(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return list
  }

  static async getByPersonID(id: number): Promise<PersonTags> {
    let item = new PersonTags()
    try {
      item = new PersonTags(await tagsApi.personTags.getByPersonID(id))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return item
  }

  static async getByPersonIDs(ids: number[]): Promise<PersonTags[]> {
    let items: PersonTags[] = []
    try {
      items = (await tagsApi.personTags.getByPersonIDs(ids)).map((v) => new PersonTags(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return items
  }

  static async mapByPersonIDs(ids: number[]): Promise<Map<number, PersonTags>> {
    const res = await PersonTags.getByPersonIDs(ids)

    const pMap = new Map<number, PersonTags>()
    res.forEach((v) => pMap.set(v.personID, new PersonTags(v)))

    return pMap
  }

  async replace(): Promise<boolean> {
    try {
      this.init(await tagsApi.personTags.replace(this.prepare().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async deleteAll(): Promise<boolean> {
    if (!confirm(`Are you sure you want to remove all person tags?`)) {
      return false
    }

    try {
      const t = new PersonTags(this)
      t.personTags = []
      this.init(await tagsApi.personTags.replace(t.prepare().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  prepare(): PersonTags {
    this.personTags.forEach((v) => v.prepare())
    this.personID = this.person ? this.person.id : this.personID
    return this
  }

  toInterface(): IPersonTags {
    return {
      personID: this.personID,
      personTags: this.personTags.map((v) => v.toInterface()),
    }
  }
}
