import { MainStore } from '@/store/MainStore'
import { List } from '@/models/List'
import { ObjectParams } from '@/models/types'
import { IpPool } from '@/models/internet/IpPool'
import { internetApi } from '@/api/internet-api'
import { MAX_LIMIT, paramsByIds } from '@/api/api'
import { IpPoolType } from '@/models/internet/constants'

export class IpPoolGet {
  static async list(params: ObjectParams): Promise<List<IpPool>> {
    const list: List<IpPool> = { count: 0, items: [] }
    try {
      const l = await internetApi.ipPool.list(params)
      list.count = l.count
      list.items = l.items.map((v) => new IpPool(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return list
  }

  static async byID(id: number): Promise<IpPool> {
    let item = new IpPool()
    try {
      item = new IpPool(await internetApi.ipPool.getByID(id))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return item
  }

  static async mapByIDs(ids: number[]): Promise<Map<number, IpPool>> {
    const res = await IpPoolGet.byIDs(ids)

    const pMap = new Map<number, IpPool>()
    res.forEach((v) => pMap.set(v.id, new IpPool(v)))

    return pMap
  }

  static async byIDs(ids: number[]): Promise<IpPool[]> {
    let items: IpPool[] = []
    try {
      items = (await IpPoolGet.list({ ...paramsByIds(ids), limit: MAX_LIMIT })).items
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return items
  }

  static async mapByNasIDs(ids: number[], types: IpPoolType[]): Promise<Map<number, IpPool[]>> {
    const pMap = new Map<number, IpPool[]>()
    const obj: { [key: number]: IpPool[] } = {}
    const res = (
      await IpPoolGet.list({
        ...paramsByIds(ids, 'nasIDs'),
        ...paramsByIds(types, 'types'),
        limit: MAX_LIMIT,
      })
    ).items

    res.forEach((v) => {
      if (obj[v.nasID]) {
        obj[v.nasID].push(new IpPool(v))
      } else {
        obj[v.nasID] = [new IpPool(v)]
      }
    })

    for (const key in obj) {
      pMap.set(Number(key), obj[key])
    }

    return pMap
  }
}
