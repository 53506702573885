// This file is generated automatically, do not make manual changes to it.
// generating script: shell/generate_permissions.go

export const CAN_DELETE_ROLES = 'users-CanDeleteRoles'
export const CAN_EDIT_ROLE_PERMISSIONS = 'users-CanEditRolePermissions'
export const CAN_EDIT_ROLES = 'users-CanEditRoles'
export const CAN_EDIT_USER_PERMISSIONS = 'users-CanEditUserPermissions'
export const CAN_EDIT_USERS = 'users-CanEditUsers'
export const CAN_LOGIN_AS_USER = 'users-CanLoginAsUser'
export const CAN_VIEW_ROLE_PERMISSIONS = 'users-CanViewRolePermissions'
export const CAN_VIEW_ROLES = 'users-CanViewRoles'
export const CAN_VIEW_USER_PERMISSIONS = 'users-CanViewUserPermissions'
export const CAN_VIEW_USERS = 'users-CanViewUsers'

const usersServer = [
  {
    value: CAN_DELETE_ROLES,
    description: CAN_DELETE_ROLES,
  },
  {
    value: CAN_EDIT_ROLE_PERMISSIONS,
    description: CAN_EDIT_ROLE_PERMISSIONS,
  },
  {
    value: CAN_EDIT_ROLES,
    description: CAN_EDIT_ROLES,
  },
  {
    value: CAN_EDIT_USER_PERMISSIONS,
    description: CAN_EDIT_USER_PERMISSIONS,
  },
  {
    value: CAN_EDIT_USERS,
    description: CAN_EDIT_USERS,
  },
  {
    value: CAN_LOGIN_AS_USER,
    description: CAN_LOGIN_AS_USER,
  },
  {
    value: CAN_VIEW_ROLE_PERMISSIONS,
    description: CAN_VIEW_ROLE_PERMISSIONS,
  },
  {
    value: CAN_VIEW_ROLES,
    description: CAN_VIEW_ROLES,
  },
  {
    value: CAN_VIEW_USER_PERMISSIONS,
    description: CAN_VIEW_USER_PERMISSIONS,
  },
  {
    value: CAN_VIEW_USERS,
    description: CAN_VIEW_USERS,
  },
]

export default usersServer
