import store from '@/store/index'
import { User } from '@/models/user/user'
import { Toast, ToastDanger } from '@/models/Toast'
import { ISettings } from '@/models/Settings'
import {
  A_ADD_TOAST,
  A_APP_INIT,
  A_INIT_SETTINGS,
  A_REMOVE_TOAST,
  A_SET_LOADING,
  A_USER_LOGIN,
  A_USER_LOGOUT,
} from '@/store/actions'
import { PersonSimple } from '@/models/persons/PersonSimple'

export class MainStore {
  static async userLogin(user: User): Promise<void> {
    try {
      await store.dispatch(A_USER_LOGIN, user)
    } catch (e) {
      await MainStore.addError(e as Error)
    }
  }

  static async userLogout(): Promise<void> {
    try {
      await store.dispatch(A_USER_LOGOUT)
    } catch (e) {
      await MainStore.addError(e as Error)
    }
  }

  static appInit(): Promise<void> {
    return store.dispatch(A_APP_INIT)
  }

  static async addError(err: Error, delayMs = 0): Promise<void> {
    if (err.name == '403') {
      /* eslint-disable-next-line no-console */
      console.error(err)
      return
    }

    const toast = new ToastDanger(resolveError(err), delayMs)
    toast.title = "<span class='text-dark font-weight-bold'>Error</span>"

    await store.dispatch(A_ADD_TOAST, toast)
  }

  static async addToast(toast: Toast): Promise<void> {
    try {
      await store.dispatch(A_ADD_TOAST, toast)
    } catch (e) {
      await MainStore.addError(e as Error)
    }
  }

  static async removeToast(toast: Toast): Promise<void> {
    try {
      await store.dispatch(A_REMOVE_TOAST, toast)
    } catch (e) {
      await MainStore.addError(e as Error)
    }
  }

  static async initSettings(settings: ISettings): Promise<void> {
    try {
      await store.dispatch(A_INIT_SETTINGS, settings)
    } catch (e) {
      await MainStore.addError(e as Error)
    }
  }

  static isAuthorized(): boolean {
    return store.getters.isAuthorized
  }

  static isUserCan(perm: string): boolean {
    return store.getters.userCan(perm)
  }

  static isUserCanAll(...perms: string[]): boolean {
    return perms.length ? perms.every((v) => store.getters.userCan(v)) : true
  }

  static isUserCanOne(...perms: string[]): boolean {
    return perms.length ? perms.some((v) => store.getters.userCan(v)) : true
  }

  static getPersonID(): number {
    return store.getters.personID
  }

  static getPerson(): PersonSimple {
    return store.state.person
  }

  static getToasts(): Toast[] {
    return store.getters.toasts
  }

  static getSettings(): ISettings {
    return store.getters.settings
  }

  static async setLoading(): Promise<void> {
    try {
      await store.dispatch(A_SET_LOADING, true)
    } catch (e) {
      await MainStore.addError(e as Error)
    }
  }

  static async releaseLoading(): Promise<void> {
    try {
      await store.dispatch(A_SET_LOADING, false)
    } catch (e) {
      await MainStore.addError(e as Error)
    }
  }

  static isLoading(): boolean {
    return store.state.isLoading
  }
}

function resolveError(err: Error): string {
  return err.message
}
