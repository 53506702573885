
import { Component, Prop, Vue } from 'vue-property-decorator'
import { NavItem, NavSection } from '@/components/app/nav/types'
import { MainStore } from '@/store/MainStore'
import NavPart from '@/components/app/nav/NavPart.vue'
import { CAN_ACCESS_TAGS } from '@/permissions/tags'
import { ROUTE_TAGS } from '@/router/tags'
import { EVENT_INPUT } from '@/utils/event-constants'
@Component({
  components: { NavPart },
})
export default class NavPartTags extends Vue {
  @Prop({ type: Boolean }) isOpen!: boolean

  get isShow(): boolean {
    return MainStore.isUserCan(CAN_ACCESS_TAGS)
  }

  section = new NavSection('Tags', 'fas fa-tags', ROUTE_TAGS, [
    new NavItem('Tags', ROUTE_TAGS, 'fas fa-tags'),
  ])

  created(): void {
    this.$emit(EVENT_INPUT, this.section.items)
  }
}
